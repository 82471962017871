import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StripeService } from '../../services/stripe.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html'
})
export class CouponComponent implements OnInit {

  @Input() customerError = false;
  @Output() couponData = new EventEmitter<any>();
  @Output() customerErrorChanged = new EventEmitter<boolean>();

  couponError = false;
  coupon = null;
  code = null;

  constructor(private svc: StripeService) { }

  ngOnInit() {
  }
  onKey() {
    if (this.couponError) {
      this.couponError = false;
    }
    if (this.customerError) {
      this.customerError = false;
      this.customerErrorChanged.emit(this.customerError);
    }
  }
  verifyCoupon(code: string) {
    this.svc.VerifyCoupon(code)
      .subscribe(
        response => {
          this.coupon = response;
          this.couponError = false;
          if (this.coupon == null) {
            this.couponError = true;
            this.couponData.emit(null);
          } else {
            this.couponData.emit(this.coupon);
          }
        },
        error => {
          console.log(error);
          this.couponError = true;
          this.couponData.emit(null);
        },
        () => this.code = null
      );
  }
}
