import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SharedModule } from '../shared/shared.module';
import { PDFLiteViewerComponent } from './pdf-lite/pdf-lite-viewer.component';
import { PDFSignViewerComponent } from './pdf-sign/pdf-sign-viewer.component';
import { PDFViewerComponent } from './pdf/pdf-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxExtendedPdfViewerModule,
    SharedModule
  ],
  declarations: [
    PDFLiteViewerComponent,
    PDFViewerComponent,
    PDFSignViewerComponent
  ],
  exports: [
    PDFLiteViewerComponent,
    PDFViewerComponent,
    PDFSignViewerComponent
  ]
})
export class ViewersModule { }
