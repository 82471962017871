import { EmailDatePipe } from './../email/email-helper.pipe';
import { OrderPipe } from 'ngx-order-pipe';

export default class ArrayUtils {

  public static generateNumberArray(count: number = 100, startNumber: number = 1, ascending: boolean = true): number[] {
    const days: number[] = [];

    if (ascending) {
      for (let i = startNumber; i < (startNumber + count); i++) {
        days.push(i);
      }
    } else {
      for (let i = startNumber; i < (startNumber - count); i--) {
        days.push(i);
      }
    }


    return days;
  }

  public static sortArray(values: any[], sortByField: string): any[] {
    return new OrderPipe().transform(values, sortByField, false, true);
  }

  public static GetObjectText(item: any, formatDate: string = ''): any[] {
    const data: any[] = [];

    Object.values(item).forEach(value => {
      if (typeof value === 'object') {
        if (!value) { return; }
        data.push(JSON.stringify(value));
      } else if (formatDate != '' && typeof value == 'string' && new Date(value as string).toString() !== 'Invalid Date') {
        if (!value) { return; }
        data.push(new EmailDatePipe().transform(new Date(value), formatDate, true));
      } else {
        data.push(value);
      }
    });

    return data;
  }
}
