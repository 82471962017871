
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Brokerage, User } from '../../classes/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from '../../layout/site-layout/site-layout.component';
import { RegistrationFormUtils } from '../../utils/form/RegistrationFormUtils';
import { RegistrationFlowType, RegistrationSteps } from '../classes';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html'
})
export class RegisterModalComponent implements OnInit {
  @ViewChild('template', { static: true }) template: any;

  // REGULAR REGISTRATION REQUIRES "SITE" AND "PLANID"
  // GUEST REGISTRATION REQUIRES "APP" SO WE HAVE ACCESS TO USERS DATA - THIS.APP.CU.USER
  // GUEST REGISTRATION WILL NEED FULL FLOW SET TRUE IF USER IS NON VALIDATED AND DOING A PAID ACTION
  @Input() app: AppLayoutComponent = null;
  @Input() site: SiteLayoutComponent = null;
  @Input() planid: string = null;
  @Input() fullFlow = false;
  @Output() finished = new EventEmitter<any>();

  _step: RegistrationSteps = null;
  _steps = RegistrationSteps;
  _modalRef: BsModalRef;
  _regFlowType: RegistrationFlowType;
  _user: User = null;
  _brokerage: Brokerage = null;
  _fg: UntypedFormGroup;

  constructor(private msvc: BsModalService, private fb: UntypedFormBuilder, private router: Router) { }

  ngOnInit() {
    this.InitModal();
    this.InitFlowType();
    this.InitRegistrationForm();
  }
  private InitRegistrationForm() {
    const fgUser = RegistrationFormUtils.InitUser(this.fb, this._user);
    this._fg = RegistrationFormUtils.initRegistrationData(this.fb, fgUser, this._step, this._regFlowType, this.router.url, this.planid);
  }
  private InitFlowType() {
    if (this.app != null) {
      this._user = this.app.cu.User;
      this._brokerage = this.app.cu.User.Brokerage;
      if (this._user.Validated) {
        this._regFlowType = RegistrationFlowType.PaymentOnly;
        this._step = this._steps.CartStep;
      } else if (!this._user.Validated && this.fullFlow) {
        this._regFlowType = RegistrationFlowType.GuestValidationWithPayment;
        this._step = this._steps.UserDetailsStep;
      } else {
        this._regFlowType = RegistrationFlowType.GuestValidation;
        this._step = this._steps.UserDetailsStep;
      }
    } else {
      this._regFlowType = RegistrationFlowType.Standard;
      this._step = this._steps.UserDetailsStep;

    }
  }

  private InitModal() {
    const config: ModalOptions = {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-dialog-centered modal-md',
    };
    this._modalRef = this.msvc.show(this.template, config);
  }

  public Next() {
    this._step = this._fg.get('Step').value;
    if (this._fg.get('Brokerage').value == null && this._fg.get('User').get('Brokerage').value != null) {
      this._fg.get('Brokerage').setValue(this._fg.get('User').get('Brokerage').value);
    }
    if (this._step === RegistrationSteps.Finished) {
      this.Close(true);
    }
  }
  public Back() {
    this._step = this._steps.UserDetailsStep;
    this._fg.get('Passcode').setValue(null);
    // this._fg.get('PlanId').setValue(null);
    this._fg.get('Token').setValue(null);
    this._fg.get('Step').setValue(this._step);
  }
  public Close(value = false) {
    if (this.app) {
      this._fg.get('User').get('Brokerage').setValue(this._fg.get('Brokerage').value);
      this.app.RefreshToken(this.app.router.url);
      this.app.cu.User = this._fg.get('User').value;
    }
    const temp = { Success: value, Email: this._fg.get('User').get('EmailAddress').value, Password: this._fg.get('Password').value };
    this.finished.emit(temp);
    this._modalRef.hide();
  }
}
