import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailActivityModalComponent } from './email-activity-modal/email-activity-modal.component';
import { EmailActivityViewerComponent } from './email-activity-viewer/email-activity-viewer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmailDatePipe, EmailIndexPipe } from './email-helper.pipe';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignerEmailControlComponent } from './signer-email-control/signer-email-control.component';
import { EnvelopeService } from '../envelopes/service/envelope.service';
import { ConveyancerEmailModalComponent } from './conveyancer-email-modal/conveyancer-email-modal.component';



@NgModule({
  declarations: [
    EmailActivityModalComponent,
    EmailActivityViewerComponent,
    EmailIndexPipe,
    EmailDatePipe,
    SignerEmailControlComponent,
    ConveyancerEmailModalComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [
    EmailActivityModalComponent,
    EmailDatePipe,
    ConveyancerEmailModalComponent
  ],
  providers: [
    EnvelopeService
  ]
})
export class EmailModule { }
