<div class="alert alert-danger" role="alert" *ngIf="_showErrors">
  <div *ngIf="invalidFiles && invalidFiles.length > 0">
    <div *ngFor="let file of invalidFiles;">
      <span *ngIf="file.type === 'accept'">{{file.file.name}} is an <strong>invalid file type</strong></span>
      <span *ngIf="file.type === 'fileSize'">{{file.file.name}} is too big. <strong>Max file size is {{ maxSize }}
          MB.</strong></span>
    </div>
  </div>
</div>

<div ngfDrop selectable="true" [(validDrag)]="validComboDrag" [(invalidDrag)]="invalidComboDrag"
  (lastInvalidsChange)="lastInvalidsChange($event)" [accept]="accept" [maxSize]="maxSize * 1024 * 1024"
  [ngClass]="{'alert-success':validComboDrag, 'alert-danger':invalidComboDrag}" class="well my-drop-zone"
  (filesChange)="upload($event)">
  <b>Drag file here</b> or <b>click</b> to browse and upload
</div>