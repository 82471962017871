<form novalidate *ngIf="((!upgrade && subscription) || upgrade)">

  <h4>{{title}}</h4>

  <section class="pricing py-1">
    <div class="container">
      <div class="form-row">
        <div *ngFor="let plan of plans" class="col-md-4">
          <div class="card mb-5 mb-lg-0">
            <div class="card-body card-{{plan.nickname}}">
              <h5 class="card-title text-uppercase text-center">{{plan.tiers ? ('Team ' + plan.nickname) : plan.nickname}}</h5>
              <h5 class="card-subtitle text-muted text-uppercase text-center">{{GetSavings(plan)}}</h5>
              <h6 class="card-price text-center">${{GetPrice(plan)}}<span
                  class="period text-muted">/{{plan.interval}}</span></h6>
              <div class="pricing text-muted text-center tax-text"><i>*Tax not included</i></div>
              <hr>
              <ul *ngIf="!plan.tiers" class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span><b>Billed {{plan.nickname}}</b></li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Dynamic Contract Creation for Buyer Brokerage, Listings and Offers</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Access Across Your Devices (Desktop/Tablet/Phone)</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Manage Conditions and Amendments Simply</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>True Transaction Management™</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Automatic Trade Reporting</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Complete Audit History</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Scales as you Grow</li>
              </ul>
              <ul *ngIf="plan.tiers" class="fa-ul">
                <li><span class="fa-li"><i class="fas fa-check"></i></span><b>Billed Monthly on a Yearly Contract</b></li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Everything from Single Agent Subscriptions</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Manage Team Member Access (<a href="http://help.dealsimple.ca/en/articles/3162955-my-brokerage-is-interested-in-your-product-can-we-buy-licenses-for-all-of-our-agents">Learn More</a>)</li>
                <li><span class="fa-li"><i class="fas fa-check"></i></span>Single Invoice for Multiple Users</li>
              </ul>

              <button *ngIf="!plan.tiers && !upgrade && CanCancel(plan)" type="button"
                class="btn btn-block btn-danger text-uppercase button-{{plan.nickname}}"
                (click)="CancelPlan(plan)">Cancel</button>
              <button *ngIf="!plan.tiers && !upgrade && CanUnCancel(plan)" type="button"
                class="btn btn-block btn-outline-primary text-uppercase button-{{plan.nickname}}"
                (click)="UnCancelPlan(plan)">Reinstate</button>
              <button *ngIf="!plan.tiers && !upgrade && !CanCancel(plan) && !CanUnCancel(plan)" type="button"
                class="btn btn-block btn-primary text-uppercase button-{{plan.nickname}}"
                (click)="SwitchPlan(plan)">{{CanCancel(plan) ? 'Cancel' : CanUnCancel(plan) ? 'Reinstate' : 'Select'}}</button>
              <button *ngIf="!plan.tiers && upgrade" type="button"
                class="btn btn-block btn-primary text-uppercase button-{{plan.nickname}}"
                (click)="SwitchPlan(plan)">Select</button>

              <div *ngIf="plan.tiers" class="btn-group button-{{plan.nickname}}" style="width:100%">
                <button *ngIf="!upgrade && CanCancel(plan)" type="button"
                  class="btn btn-block btn-danger text-uppercase" (click)="CancelPlan(plan)">Cancel</button>
                <button *ngIf="!upgrade && CanUnCancel(plan)" type="button"
                  class="btn btn-block btn-outline-primary text-uppercase"
                  (click)="UnCancelPlan(plan)">Reinstate</button>
                <button *ngIf="!upgrade && !CanCancel(plan) && !CanUnCancel(plan)" type="button"
                  class="btn btn-block btn-primary text-uppercase"
                  (click)="SwitchPlan(plan)">{{CanCancel(plan) ? 'Cancel' : CanUnCancel(plan) ? 'Reinstate' : 'Buy ' + quantity + ' agents'}}</button>
                <button *ngIf="upgrade" type="button" class="btn btn-block btn-primary text-uppercase"
                  (click)="SwitchPlan(plan)">Buy {{quantity}} agents</button>

                <div *ngIf="(!upgrade && !CanCancel(plan) && !CanUnCancel(plan)) || upgrade" class="btn-group"
                  ngbDropdown role="group">
                  <button id="dropdownConfig" class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button *ngFor="let num of quantityArr;" ngbDropdownItem
                      (click)="updateQty(num);">{{subscription && subscription.Quantity > 1 ? subscription.Quantity : num}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container mt-2">
    <div class="form-row">
      <button *ngIf="!upgrade" type="button" class="btn btn-secondary btn-block "
        (click)="Back_OnClick()">Back</button>
    </div>
  </div>
</form>