export enum DataFileUploadType {
  Office = 0,
  User = 1
}

export class GraphRelationship {
  fromField: string; // BrokerageId
  fromType: string; // Brokerage
  fromId: string; // BrokerageId
  toField: string; // ParentBrokerageId
  toType: string; // Brokerage
  toId: string; // BrokerageId
}

export abstract class Graphable {
  type: string;
  primaryKey: string;
  constructor(type: string, primaryKey: string) {
    this.type = type;
    this.primaryKey = primaryKey;
  }
}

export class GraphBrokerageBoard extends Graphable {
  Name: string;
  ParentId: string;
  Id: string;
  constructor(init?: Partial<GraphBrokerageBoard>) {
    super('GraphBrokerageBoard', 'Id');
    Object.assign(this, init);
  }
}

export class ConfigBuilder {
  private readonly _config: GraphConfig;

  constructor() {
    this._config = new GraphConfig();
  }

  public withData(data: Array<Graphable>): ConfigBuilder {
    this._config.data = data;
    return this;
  }

  public withDisplayFields(displayFields: Array<[string, DisplayField[]]>): ConfigBuilder {
    this._config.displayFields = new Map<string, DisplayField[]>(displayFields);
    return this;
  }

  public withDisplayFieldsFor(type: string, fields: DisplayField[]): ConfigBuilder {
    if (!this._config.displayFields)
      this._config.displayFields = new Map<string, DisplayField[]>();
    if (!this._config.displayFields.has(type))
      this._config.displayFields.set(type, new Array<DisplayField>());
    this._config.displayFields.get(type).push(...fields);
    return this;
  }

  public withDisplayFieldsForDefault(type: string, fields: string[]): ConfigBuilder {
    if (!this._config.displayFields)
      this._config.displayFields = new Map<string, DisplayField[]>();
    if (!this._config.displayFields.has(type))
      this._config.displayFields.set(type, new Array<DisplayField>());
    this._config.displayFields.get(type).push(...fields.map(x => new DisplayField({ field: x, label: null, separator: null })));
    return this;
  }

  public withDisplayFieldFor(type: string, field: string, label: string = null, separator: string = null): ConfigBuilder {
    if (!this._config.displayFields)
      this._config.displayFields = new Map<string, DisplayField[]>();
    if (!this._config.displayFields.has(type))
      this._config.displayFields.set(type, new Array<DisplayField>());
    this._config.displayFields.get(type).push(new DisplayField({ field: field, label: label, separator: separator }));
    return this;
  }

  public withRelationships(relationships: Array<GraphRelationship>): ConfigBuilder {
    this._config.relationships = relationships;
    return this;
  }

  public withRelationship(relationship: GraphRelationship): ConfigBuilder {
    if (!this._config.relationships)
      this._config.relationships = new Array<GraphRelationship>();
    this._config.relationships.push(relationship);
    return this;
  }

  public withCallbackFor(type: string, callbackName: string, callback: (param: string) => void): ConfigBuilder {
    if (!this._config.clickForType)
      this._config.clickForType = new Map<string, string>();
    this._config.clickForType.set(type, callbackName);
    (window as any)[callbackName] = callback;
    return this;
  }

  public withTooltipFor(type: string, tooltip: string): ConfigBuilder {
    if (!this._config.tooltipsForType)
      this._config.tooltipsForType = new Map<string, string>();
    this._config.tooltipsForType.set(type, tooltip);
    return this;
  }

  public build(): GraphConfig {
    return this._config;
  }
}

export class GraphConfig {
  public data: Array<Graphable>;
  public displayFields: Map<string, DisplayField[]>;
  public relationships: Array<GraphRelationship>;
  public clickForType: Map<string, string>;
  public tooltipsForType: Map<string, string>;
}

export class DisplayField {
  public field: string;
  public label: string;
  public separator: string;

  constructor(init?: Partial<DisplayField>) {
    Object.assign(this, init);
  }
}