import { Component, OnInit, Input } from '@angular/core';
import { Alert } from './classes';

@Component({
  selector: 'app-dismissible-alerts',
  templateUrl: './dismissible-alerts.component.html'
})
export class DismissibleAlertsComponent {

  @Input() Alerts: Alert[] = [];

  constructor() { }

  close(alert: Alert) {
    this.Alerts.splice(this.Alerts.indexOf(alert), 1);
  }

}
