import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgxPaginationModule } from 'ngx-pagination';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PipesModule } from '../pipes/pipes.module';
import { RegisterModule } from '../register/register.module';
import { PageLayoutModule } from '../shared/page-layout/page-layout.module';
import { SharedModule } from '../shared/shared.module';
import { TilesModule } from '../tiles/tiles.module';
import { SearchAgentComponent } from './search-agent/search-agent.component';
import { SearchContractComponent } from './search-contract/search-contract.component';
import { SearchContactComponent } from './search-contact/search-contact.component';
import { SearchConveyancerComponent } from './search-conveyancer/search-conveyancer.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgxPaginationModule,
    PageLayoutModule,
    PipesModule,
    ReactiveFormsModule,
    SharedModule,
    ButtonsModule.forRoot(),
    TilesModule,
    UiSwitchModule,
    RegisterModule,
    VirtualScrollerModule
  ],
  declarations: [
    SearchAgentComponent,
    SearchContractComponent,
    SearchContactComponent,
    SearchConveyancerComponent
  ],
  exports: [
    SearchAgentComponent,
    SearchContractComponent,
    SearchContactComponent,
    SearchConveyancerComponent
  ]
})
export class SearchModule { }
