<div class="form-row" [formGroup]="fg">
    <div class="form-group col-12">
        Subscription *
        <select name="selPlan" class="form-select" formControlName="PlanId" autocomplete="off"
            (change)="this.selected.emit()">
            <option value="null">Select Subscription...</option>
            <option *ngFor="let plan of plans" value="{{plan.id}}">
                {{ plan.nickname }}
                {{ (fg.get('User').get('CustomerIdDefault').value == null ? '- Bonus: ' + plan.trial_period_days + ' Day Free Trial' : '') }}
            </option>

        </select>

    </div>
</div>