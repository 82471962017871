import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import FormUtils from '../../../utils/form/FormUtils';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html'
})
export class PlanComponent implements OnInit {
  @Input() fg: UntypedFormGroup;
  @Input() plans: any[];
  @Output() selected = new EventEmitter<any>();

  _fu = FormUtils;

  constructor() { }

  ngOnInit() {
  }
}
