<div class="alert alert-secondary" role="alert">
  <div class="row">
    <div class="col">
      PARENT BROKERAGE
    </div>
  </div>
</div>

<div class="row mb-3 g-3">
  <div *ngIf="!_showAddParent && !_parent">
    This brokerage has a child brokerage, so it cannot have its own parent brokerage.
  </div>
  <div *ngIf="_showAddParent" class="col-12">
    <app-search-brokerage [navigate]="false" [getGroupless]="false" [groupAnimations]="true"
      (selected)="selectParent($event)">
    </app-search-brokerage>
  </div>
  <div *ngIf="_parent != null" class="col-md-6 col-12">
    <app-brokerage-tile [data]="_parent" [clickable]="false" [showRemove]="true" (remove)="removeParent()">
    </app-brokerage-tile>
  </div>
</div>

<div class="alert alert-secondary" role="alert">
  <div class="row">
    <div class="col">
      CHILD BROKERAGES
    </div>
  </div>
</div>

<div *ngIf="_showAddChild">
  <app-search-brokerage [navigate]="false" [getGroupless]="false" [groupAnimations]="true"
    (selected)="addAsChild($event)">
  </app-search-brokerage>

  <div *ngIf="_hasChild" class="row">
      <div *ngFor="let child of _children" class="col-md-6 col-12">
        <app-brokerage-tile [data]="child" [clickable]="false" [showRemove]="true" (remove)="removeChild($event)">
        </app-brokerage-tile>
      </div>
  </div>
</div>

<div *ngIf="!_showAddChild">
  This brokerage has a parent brokerage, so it cannot have any child brokerages of its own.
</div>