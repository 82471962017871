<a id="__hiddenLauncher" class="hidden" target="_self" [href]="_safeUrl" [download]="_downloadName"></a>
<div [ngClass]="{'container': !largeScreen, 'container-fluid': largeScreen }" (window:resize)="OnResize()">
  <app-header></app-header>
  <app-nav-top></app-nav-top>
  <div *ngIf="_alerts.length > 0">
    <app-dismissible-alerts [Alerts]="_alerts"></app-dismissible-alerts>
  </div>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>

<div *ngIf="modal.config">
  <app-modal [modalConfig]="modal.config" (selected)="modal.OnSelected($event);"></app-modal>
</div>