<div class="alert alert-danger" role="alert" *ngIf="
        _fu.FormGroupHasError(fgUser) || 
        _fu.FormGroupHasError(fg) || 
        fg.get('Password').value !== fg.get('ConfirmPassword').value">
    <div *ngIf="_fu.ShowControlError(fgUser.get('BrokerageId'))">
        <b>Brokerage</b> is required
    </div>
    <div *ngIf="_fu.ShowControlError(fgUser.get('FirstName'))">
        <b>First Name</b> is required
    </div>
    <div *ngIf="_fu.ShowControlError(fgUser.get('LastName'))">
        <b>Last Name</b> is required
    </div>
    <div *ngIf="_fu.ShowControlError(fgUser.get('EmailAddress'))">
        A valid <b>Email Address</b> is required
    </div>
    <div *ngIf="_fu.ShowControlError(fgUser.get('MobilePhoneNumber'))">
        A valid <b>Mobile Number</b> is required
    </div>
    <div *ngIf="_fu.ShowControlError(fg.get('Password'))">
        Your password must:
        <ul>
            <li>be at least 8 characters long</li>
            <li>have 1 uppercase & 1 lowercase character</li>
            <li>have 1 number</li>
        </ul>
    </div>
    <div *ngIf="fg.get('ConfirmPassword').value !== fg.get('Password').value">
        Passwords do not match
    </div>
    <div *ngIf="_fu.ShowControlError(fg.get('TOS'))">
        You must review and accept the <b>Terms of Use</b> and <b>Privacy Policy</b> before continuing
    </div>
</div>
<div *ngIf="_alerts.length > 0">
    <app-dismissible-alerts [Alerts]="_alerts"></app-dismissible-alerts>
</div>

<div *ngIf="_getMLSID" class="form-row">
    <div class="form-group col-12">
        <label for="MLSID">Please enter your MLSID below (This is your Pillar9 username)</label>
        <input type="text" class="form-control" id="MLSID" placeholder="Enter MLS ID" [(ngModel)]="_MLSID" name="MLSID"
            required>
    </div>
</div>

<div *ngIf="!_getMLSID" class="form-row" [formGroup]="fgUser">
    <div class="form-group col-12">
        Brokerage *
        <ng-select #select formControlName="BrokerageId" [items]="_brokerages | async" bindLabel="Name" autofocus
            bindValue="BrokerageId" groupBy="City" placeholder="Search by Brokerage or City..."
            [attr.disabled]="_disableDetails == true ? true : null" [editableSearchTerm]="false" [virtualScroll]="true">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div><span [ngOptionHighlight]="search">{{ item.Name }}</span></div>
                <small>{{item.Address}}</small>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item">
                {{item.City || 'Unnamed group'}}
            </ng-template>
        </ng-select>
    </div>
    <div class="form-group col-md-6 col-12">
        First Name *
        <input type="text" class="form-control" formControlName="FirstName" autocomplete="new-password"
            [attr.disabled]="_disableDetails == true ? true : null">
    </div>
    <div class="form-group col-md-6 col-12">
        Last Name *
        <input type="text" class="form-control" formControlName="LastName" autocomplete="new-password"
            [attr.disabled]="_disableDetails == true ? true : null">
    </div>
    <div class="form-group col-md-6 col-12">
        Mobile Phone Number *
        <input type="text" autocomplete="new-password" class="form-control" formControlName="MobilePhoneNumber"
            minlength="10" maxlength="20" (change)="tempMobile = $any($event).srcElement.value">
    </div>
    <div class="form-group col-md-6 col-12">
        Email Address *
        <input type="text" class="form-control" formControlName="EmailAddress" autocomplete="new-password"
            (change)="tempEmail = $any($event).srcElement.value">
    </div>
    <div class="form-group col-md-6 col-12">
        New Password *
        <app-password-form-input [ParentForm]="fg" [GivenFormControlName]="'Password'" [GivenId]="'Password'">
        </app-password-form-input>
    </div>
    <div class="form-group col-md-6 col-12">
        Confirm New Password *
        <app-password-form-input [ParentForm]="fg" [GivenFormControlName]="'ConfirmPassword'"
            [GivenId]="'ConfirmPassword'">
        </app-password-form-input>
    </div>
</div>
<div *ngIf="!_getMLSID" [formGroup]="fg">
    <div class="form-row">
        <div class="form-group col">
            <label class="terms-of-service-label"><input class="terms-of-service-input" type="checkbox"
                    formControlName="TOS" />
                By registering, you agree to our
                <a target="_blank" routerLink="/terms">Terms of Use</a> and <a target="_blank"
                    routerLink="/privacy">Privacy
                    Policy</a> </label>
        </div>
    </div>
    <div class="form-row" [formGroup]="fgUser">
        <div class="col">
            <label class="terms-of-service-label"><input class="terms-of-service-input" type="checkbox"
                    formControlName="GANotifiy" />
                Please send me emails from DealSimple™ and Real Deal Transaction Management Inc. about new information,
                features, promotions, content and more. (You can unsubscribe at any time).</label>
        </div>
    </div>
</div>
<hr>
<div class="form-row">
    <div class="col p-0">
        <button type="button" class="btn btn-outline-secondary" (click)="Close()">Cancel
        </button>
    </div>
    <div class="col-auto">
        <button type="button" class="btn btn-primary" (click)="Next()">Next</button>
    </div>
</div>