<div class="card" [ngClass]="{'hover': hover, 'disabledtile': IsTileDisabled()}" (click)="selected.emit(contract);">
  <div class="card-body">
    <div class="row">
      <div class="col">

        <div class="row">
          <h5 class="card-title mb-0">
            {{ _fu.ConcatOwnerNames(contract.ContractOwners, false) }}
          </h5>
        </div>
        <div *ngIf="reportType === _rt.Buyers" class="row">
          <div class="col">
            <span>
              <b [ngClass]="_cfu.getContractSignClass(contract.Status)">{{ _cfu.GetContractStatusByContract(contract)
                }}</b>
              {{ _cfu.getSubStatus(contract.SubStatus) === '' ? '' : ' |'}}
            </span>
            <span *ngIf="contract.SubStatus">
              <b>{{ _cfu.getSubStatus(contract.SubStatus) | uppercase }}</b>
            </span>
            <span *ngIf="(contract.Prop | buyerType) != null">
              | <b>{{ contract.Prop | buyerType }}</b>
            </span>
          </div>
        </div>
        <div *ngIf="contract.AgreementStart && contract.AgreementEnd" class="row">
          <div class="col">
            {{ (contract.AgreementStart | newDate) }} <b>to</b> {{ (contract.AgreementEnd | newDate ) }}
          </div>
        </div>

      </div>
      <div *ngIf="df && df.GetActions().length > 0" class="col-auto text-end">
        <div class="d-sm-block d-none">
          <div ngbDropdown placement="bottom-right">
            <button class="btn btn-primary" ngbDropdownToggle>Actions</button>
            <div ngbDropdownMenu>
              <button *ngFor="let action of df.GetActions()" ngbDropdownItem
                (click)="click.emit(action.ActionType.Id)">{{ action.Name ? action.Name : action.ActionType.Name }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-sm-none">
          <div ngbDropdown placement="bottom-right">
            <button class="btn btn-dropdown" ngbDropdownToggle>
              <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
            </button>
            <div ngbDropdownMenu>
              <button *ngFor="let action of df.GetActions()" class="dropdown-item pointerCursor"
                (click)="click.emit(action.ActionType.Id)">{{ action.Name ? action.Name : action.ActionType.Name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="hasOffer" class="alert alert-warning alert-slim">
      WARNING: This buyer is involved in an active offer.
    </div>
    <div *ngIf="IsTileDisabled()" class="alert alert-warning alert-slim">
      WARNING: This buyer cannot be selected until
      {{ contract.AgreementStart | newDate:'MMM d' }} at {{ contract.AgreementStart | newDate:'h:mm a' }}.
    </div>
    
  </div>
</div>