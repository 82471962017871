import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableControlDirective } from './disable-control.directive';
import { ShowPasswordInputDirective } from './show-password-input.directive';
import { ShowHideService } from 'ngx-show-hide-password';

@NgModule({
  declarations: [
    DisableControlDirective,
    ShowPasswordInputDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DisableControlDirective,
    ShowPasswordInputDirective
  ],
  providers: [
    ShowHideService
  ],
})
export class DirectivesModule { }
