// WE CAN POSSIBLY REMOVE THIS NOW, ANGULAR CALENDAR HAS IT'S OWN ENUM THAT MAKES THINGS WORK BETTER
export enum CalView {
  Today,
  Tomorrow,
  Day,
  Week,
  Month
}

export enum CalendarCategory {
  None = 0,
  OfferCondition = 1,
  Delivery = 2,
  Possession = 3,
  Expiration = 4
}

export enum CalendarStatus {
  Active = 0,
  Completed = 1
}

export class EventColor {
  primary: string;
  secondary: string;
}

export class Calendar {
  CalendarId: string = null;
  ParentId: string = null;
  ParentNumber: number = null;
  ParentRev: number = null;
  CalendarRuleId: string = null;
  OrganizerId: string = null;
  AllDayEvent: boolean = false;
  DTStart: Date = null;
  DTEnd: Date = null;
  TZId: string = null;
  Summary: string = null;
  Location: string = null;
  Description: string = null;
  Priority: number = null;
  Category: CalendarCategory = null;
  Status: CalendarStatus = null;
  Created: Date = null;
  LastModified: Date = null;
  Completed: Date = null;
  constructor(init?: Partial<Calendar>) {
    Object.assign(this, init);
  }
}