import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PasswordRecoverySteps } from '../../classes/passwordrecovery';
import { SiteLayoutComponent } from '../../layout/site-layout/site-layout.component';
import { AuthService } from '../../services/auth.service';
import { RecoveryService } from '../../services/recovery.service';
import FormUtils from '../../utils/form/FormUtils';
import ValidatorUtils from '../../utils/form/ValidatorUtils';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html'
})
export class PasswordRecoveryComponent implements OnInit {

  frm: UntypedFormGroup;
  _steps = PasswordRecoverySteps;
  _emailAttempts = 0;
  _mobilePhoneAttempts = 0;
  _showErrors = false;
  _email: AbstractControl = null;
  _mobile: AbstractControl = null;
  _passcode: AbstractControl = null;
  _step: AbstractControl = null;
  _password: AbstractControl = null;
  _confirmPassword: AbstractControl = null;
  _fu = FormUtils;
  _captcha = null;
  _siteKey = null;

  _nextUrl = null;
  _autoPassword: string;

  constructor(private rsvc: RecoveryService, private site: SiteLayoutComponent, public fb: UntypedFormBuilder,
    private authService: AuthService, private route: ActivatedRoute) {
      this._siteKey = environment.SiteKey;
  }

  ngOnInit() {
    this._nextUrl = this.route.snapshot.queryParams['next'] || '/sellers';

    this.frm = this.fb.group({
      Email: [null, ValidatorUtils.ValidEmailSync()],
      Passcode: [null, ValidatorUtils.NumberOfDigitsRequired(6)],
      MobilePhone: [null, ValidatorUtils.NumberOfDigitsRequired(4)],
      Password: [null, ValidatorUtils.RequiredPassword()],
      ConfirmPassword: null,
      Step: PasswordRecoverySteps.EmailStep,
      ErrorMessage: null,
      Success: true,
      ProtectedMobilePhone: null,
      LastTwoDigits: null,
      UserId: null,
      IsMobileVerification: true,
      Captcha: new UntypedFormControl(null, Validators.required)
    });

    this._email = this.frm.get('Email');
    this._mobile = this.frm.get('MobilePhone');
    this._passcode = this.frm.get('Passcode');
    this._step = this.frm.get('Step');
    this._captcha = this.frm.get('Captcha');
    this._password = this.frm.get('Password');
    this._confirmPassword = this.frm.get('ConfirmPassword');
  }

  validate() {

    this._showErrors = true;
    switch (this._step.value) {
      case PasswordRecoverySteps.EmailStep:
        if (this._email.valid && this._captcha.valid) { this.postPasswordRecovery(); }
        break;
      case PasswordRecoverySteps.MobileNumberStep:
        if (this._mobile.valid) { this.postPasswordRecovery(); }
        break;
      case PasswordRecoverySteps.PasscodeStep:
        if (this._passcode.valid) { this.postPasswordRecovery(); }
        break;
      case PasswordRecoverySteps.PasswordStep:
        if (this._password.valid && (this._password.value === this._confirmPassword.value)) {
          this._autoPassword = this._password.value;
          this.postPasswordRecovery();
        }
        break;
    }
  }

  postPasswordRecovery(): void {
    // this.site.ShowSpinner();

    // HIDE FORM VALIDATION ERRORS
    this._showErrors = false;
    this.rsvc.post(this.frm.value)
      .subscribe(
        response => {
          this.frm.setValue(response);

          // HANDLE NEXT STEP
          switch (response.Step) {
            case PasswordRecoverySteps.InactiveUserStep:
            case PasswordRecoverySteps.NoUserStep:
              this.site.ShowErrorMessage('This email address is not registered. If this is your email, ' +
                'please click the <b>Register</b> link to register.', 10);
              this.site.router.navigate(['/']);
              return;
            case PasswordRecoverySteps.MobileNumberStep:
              this._mobilePhoneAttempts++;
              if (this._mobilePhoneAttempts > 3) {
                this.site.ShowErrorMessage('Too many invalid attempts were made. Please try resetting your password again later.');
                this.site.router.navigate(['/']);
                return;
              }
              break;
            case PasswordRecoverySteps.BackToLoginStep:
              this.loginUser();
              return;
            case PasswordRecoverySteps.TooManyAttempts:
              this.site.ShowErrorMessage('Too many invalid attempts were made. Please try resetting you password again later.');
              this.site.router.navigate(['/']);
              return;
            case PasswordRecoverySteps.TimeExpired:
              this.site.ShowErrorMessage('Time has expired. Please try resetting your password again later.');
              this.site.router.navigate(['/']);
              return;
            case PasswordRecoverySteps.PasscodeStep:
              if (response.ErrorMessage) {
                this.site.ShowErrorMessage(response.ErrorMessage);
              }
              return;
          }

          if (!response.Success) {
            this.site.ShowErrorMessage(response.ErrorMessage, 5);
          }
        },
        error => this.site.HideSpinner(),
        () => {
          if (this._step.value !== PasswordRecoverySteps.BackToLoginStep) {
            // this.site.HideSpinner();
          }
        }
      );
  }

  loginUser() {
    this.authService.Login(this.frm.get('Email').value, this.frm.get('Password').value)
      .pipe(first())
      .subscribe(
        () => this.site.router.navigate([this._nextUrl]),
        error => {
          this.site.ShowErrorMessage('Login failed, please try again. If you are not yet registered, ' +
            'please do so now by clicking on the Register button below.');
          // this.site.HideSpinner();
        });
  }
}
