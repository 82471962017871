import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowHideService } from 'ngx-show-hide-password';

@Directive({
  selector: 'input[appShowPasswordInput]'
})
export class ShowPasswordInputDirective implements OnInit, OnDestroy {
  private subscription: Subscription;
  private givenId: string;

  @Input() set appShowPasswordInput( id: string ) {
    this.givenId = id;
    this.renderer.setAttribute(this.el.nativeElement, 'id', this.givenId);

    this.service.setShow(this.givenId, this.el.nativeElement.type !== 'password');
  }

  constructor(private service: ShowHideService, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.subscription = this.service
      .getObservable(this.givenId)
      .subscribe(show => this.renderer.setAttribute(this.el.nativeElement, 'type', show ? 'text' : 'password'));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
