import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Contact } from '../classes/classes';
import ConfigUtils from '../utils/ConfigUtils';
import { Signer } from '../utils/data/SignerUtils';

@Injectable()
export class ContactService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/contact';
  }

  deleteContact (contactId: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/' + contactId);
  }

  postContact (contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(this.baseUrl, contact);
  }

  putContact (contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(this.baseUrl + '/' + contact.ContactId, contact);
  }

  putContactWithSigner(data: Signer): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/UpdateContactWithSigner`, data);
  }

  getContacts (): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.baseUrl);
  }

  getContact(contactId: string): Observable<Contact> {
    return this.http.get<Contact>(this.baseUrl + '/' + contactId);
  }

  QueryContacts(search: string, corp = false): Observable<Contact[]> {
    return this.http.post<Contact[]>(this.baseUrl + '/QueryContacts', JSON.stringify({
      SearchText: search,
      Corporation: corp
    }));
  }
}
