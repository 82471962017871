import { Contract, ContractType, SubContractType } from '../../classes/contract';
import DateUtils from '../DateUtils';
import GuidUtils from '../GuidUtils';
import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { ContractAgent, ContractAgentUtils } from './ContractAgentUtils';

//#region CompareContracts
export class CompareContracts {
  Contract: Contract = null;
  Previous: Contract = null;
  Valid = false;
}
//#endregion

//#region ContractUtils
export class ContractUtils {

  private _app: AppLayoutComponent;

  constructor(app: AppLayoutComponent) { this._app = app; }

  /**
   * Searches for a contract and its matching previous contract. Will also check to see if the user has access to contract.
   *
   * @param contractId used to search `contracts` and sets result to CompareContracts.Contract otherwise null.
   * @param contracts to be searched.
   */
  public GetContractAndPrevContracts(contractId: string, contracts: Contract[]): CompareContracts {

    const data = new CompareContracts();
    const index = contracts.findIndex(contract => contract.ContractId === contractId);
    if (index > -1) {
      data.Contract = contracts[index];
    } else { return data; }

    // WHEN RESCINDING, IT IS POSSIBLE A USER IS OPENING AN OLD LINK OR ON AN OLD PAGE. THIS CHECK
    // WILL NOT ALLOW USERS TO PROGRESS IF THE VISIBILITY VALUE IS FALSE.
    if (!ContractAgentUtils.DoesAgentHaveAccess(data.Contract, this._app.cu.User.UserId)) { return data; }

    // REV 0 === NO PREV VALUE
    if (data.Contract.Rev === 0) {
      // IF THE FIRST REV IS NOT CURRENT, THERE IS NEWER REVS THAT SHOULD BE ACCESSED
      data.Valid = data.Contract.IsCurrent;
      return data;
    }

    // TRY GETTING THE PREVIOUS CONTRACT
    const prevIndex = contracts.findIndex(contract => contract.ContractId === data.Contract.PrevContractId);
    if (prevIndex > -1) {
      data.Previous = contracts[prevIndex];
    } else {
      return data;
    }

    // EITHER THE CURRENT CONTRACT OR THE PREVIOUS SHOULD BE CURRENT
    data.Valid = (data.Contract.IsCurrent || data.Previous.IsCurrent);
    return data;
  }

  /**
   * Cleanup contract before posting.
   *
   * @param contract to be prepared before posting.
   * @param contractId new ContractId for this contract.
   */
  public NewContract(contract: Contract, contractId: string, sellerSigns = null): Contract {

    contract.AgentFullName = `${this._app.cu.User.FirstName} ${this._app.cu.User.LastName}`;
    contract.AgentIP = this._app.ipAddress;
    contract.BrokerageId = this._app.cu.User.Brokerage.BrokerageId;
    contract.BrokerageName = this._app.cu.User.Brokerage.Name;
    contract.ContractGroup = this._app.cu.User.Brokerage.ContractGroup;
    contract.ContractId = contractId;
    contract.IsCurrent = true;
    contract.SubContractType = SubContractType.Base;
    contract.UserId = this._app.cu.User.UserId;

    contract.ContractAddendums = [];

    // PERFORM SPECIFIC CONTRACT TYPE UPDATE
    switch (contract.ContractType) {
      case ContractType.Buyer:
        contract.AgreementStart = DateUtils.GetToday().toDate();
        contract.AgreementEnd = DateUtils.GetToday().add(6, 'month').toDate();
        contract.SellerSigns = false;
        break;
      case ContractType.Listing:
        contract.AgreementStart = DateUtils.GetToday().toDate();
        contract.AgreementEnd = DateUtils.GetToday().add(6, 'month').toDate();
        contract.SellerSigns = true;
        break;
      case ContractType.Offer:
        contract.SellerSigns = sellerSigns ?? false;
        break;
    }

    // ADD CURRENT USER AS CONTRACT AGENT
    if (contract.SellerSigns !== null) {
      const agent = new ContractAgent();
      agent.Id = GuidUtils.GenerateGuid();
      agent.ContractId = contractId;
      agent.UserId = this._app.cu.User.UserId;
      agent.BrokerageId = this._app.cu.User.Brokerage.BrokerageId;
      agent.SplitPercent = 100;
      agent.IsSellerAgent = contract.SellerSigns;
      agent.Visible = true;

      contract.ContractAgents = [];
      contract.ContractAgents.push(agent);
    }

    return contract;
  }
}
//#endregion
