import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppLayoutComponent } from '../app-layout/app-layout.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent {
  constructor(public router: Router, public app: AppLayoutComponent) { }
}
