import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Email, ReceiverType } from '../classes/classes';
import ConfigUtils from '../utils/ConfigUtils';

@Injectable()
export class EmailService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${ConfigUtils.GetBaseApiUrl()}/email`;
  }

  public GetEmails(parentId: string, signerId: string, type: ReceiverType): Observable<Email[]> {
    return this.http.get<Email[]>(`${this.baseUrl}/${parentId}/${signerId}/${type}`);
  }

  public GetEmailsByContractNumber(number: number, signerId: string, type: ReceiverType): Observable<Email[]> {
    return this.http.get<Email[]>(`${this.baseUrl}/bynumber/${number}/${signerId}/${type}`);
  }
}
