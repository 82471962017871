import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Condition } from '../../classes/classes';
import { Contract, ContractStatus, SubContractType } from '../../classes/contract';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { ContractAgentUtils } from '../../utils/data/ContractAgentUtils';
import { ContractAction } from '../../utils/form/ContractActionUtils';
import ContractFormUtils from '../../utils/form/ContractFormUtils';
import DynamicFormUtils from '../../utils/form/DynamicFormUtils';
import FormUtils from '../../utils/form/FormUtils';

@Component({
  selector: 'app-offer-tile',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './offer-tile.component.html'
})
export class OfferTileComponent {

  //#region Variables
  @Input() contract: Contract;
  @Input() df: DynamicFormUtils;
  @Input() largeTile = false;
  @Output() selected = new EventEmitter<Contract>();
  @Output() click = new EventEmitter<ContractAction>();

  cfu = ContractFormUtils;
  sct = SubContractType;
  status = ContractStatus;
  formUtils = FormUtils;
  _hasCondition = false;
  _conditionsOpen: number = 0;
  _conditionsWaived: number = 0;
  _conditions: Condition[] = null;
  //#endregion

  constructor(private app: AppLayoutComponent) { }

  //#region Public Functions
  public GetStatus(): string {
    const responsible = this.contract.SellerSigns ?
      ContractAgentUtils.IsContractAgent(this.contract.ContractAgents, this.app.cu.User.UserId, true) :
      ContractAgentUtils.IsContractAgent(this.contract.ContractAgents, this.app.cu.User.UserId, false);
    return responsible ? this.GetResponsibleAgentStatus() : this.GetNonResponsibleAgentStatus();
  }

  public RepresentedBy(isSellerAgent: boolean): string {
    // IF NO AGENT IS SPECIFIED FOR THAT SIDE
    if ((!ContractAgentUtils.SideHasAgent(this.contract.ContractAgents, true) && isSellerAgent) ||
      (!ContractAgentUtils.SideHasAgent(this.contract.ContractAgents, false) && !isSellerAgent)) {
      return null;
    }

    // CURRENT USER IS REPRESENTING
    if ((isSellerAgent && ContractAgentUtils.IsContractAgent(this.contract.ContractAgents, this.app.cu.User.UserId, true)) ||
      (!isSellerAgent && ContractAgentUtils.IsContractAgent(this.contract.ContractAgents, this.app.cu.User.UserId, false))) {
      return '(Representing)';
    }

    // SOMEONE ELSE IS REPRESENTING
    return `(${this.formUtils.ConcatAgentNames(this.contract.ContractAgents, isSellerAgent)})`;
  }

  public ShowOfferExpires(): boolean {

    // MUST BE A VALUE
    if (!this.contract.Prop.OfferExpires) {
      return false;
    }

    // OFFER EXPIRES CAN ONLY SHOW WHILE BASE OR COUNTER
    switch (this.contract.SubContractType) {
      case SubContractType.Base:
      case SubContractType.Counter:
        break;
      default:
        return false;
    }

    // LIMITED WHICH STATUSES WILL SHOW VALUE
    switch (this.contract.Status) {
      case ContractStatus.InProgress:
      case ContractStatus.AwaitingSignatures:
      case ContractStatus.AwaitingSubmission:
      case ContractStatus.Active:
      case ContractStatus.Submitted:
        return true;
    }

    return false;
  }

  public WaitingForSeller(value: boolean): string {

    // IF NULL, NEITHER SIDE RESPONSIBLE
    if (this.contract.SellerSigns === null) {
      return;
    }

    // RETURN WAITING TEXT IF WAITING ON THIS PARTY
    if (this.contract.SellerSigns === value) {
      return ' - Waiting';
    }
  }
  //#endregion

  //#region Private Functions
  private get AddBuyerCondition(): boolean { return this.contract.Prop.AddBuyerCondition; }
  private get AddBuyerConditionWaive(): boolean { return this.contract.Prop.AddBuyerConditionWaive; }
  // private get BackUpOffer(): boolean { return this.contract.Prop.BackUpOffer; }
  // private get BackUpOfferWaive(): boolean { return this.contract.Prop.BackUpOfferWaive; }
  private get BuyerPropertySale(): boolean { return this.contract.Prop.BuyerPropertySale; }
  private get BuyerPropertySaleWaive(): boolean { return this.contract.Prop.BuyerPropertySaleWaive; }
  private get CondoDocs(): boolean { return this.contract.Prop.CondoDocs; }
  private get CondoDocsWaive(): boolean { return this.contract.Prop.CondoDocsWaive; }
  private get CountryResPropSched(): boolean { return this.contract.Prop.CountryResPropSched; }
  private get CountryResPropSchedWaive(): boolean { return this.contract.Prop.CountryResPropSchedWaive; }
  private get Financing(): boolean { return this.contract.Prop.Financing; }
  private get FinancingWaive(): boolean { return this.contract.Prop.FinancingWaive; }
  private get PropInspection(): boolean { return this.contract.Prop.PropInspection; }
  private get PropInspectionWaive(): boolean { return this.contract.Prop.PropInspectionWaive; }
  private get SellerCondition(): boolean { return this.contract.Prop.SellerCondition; }
  private get SellerConditionWaive(): boolean { return this.contract.Prop.SellerConditionWaive; }
  private get SepticSystem(): boolean { return this.contract.Prop.SepticSystem; }
  private get SepticSystemWaive(): boolean { return this.contract.Prop.SepticSystemWaive; }
  private get SubdivisionPlan(): boolean { return this.contract.Prop.SubdivisionPlan; }
  private get SubdivisionPlanWaive(): boolean { return this.contract.Prop.SubdivisionPlanWaive; }
  private get WaterCondition(): boolean { return this.contract.Prop.WaterCondition; }
  private get WaterConditionWaive(): boolean { return this.contract.Prop.WaterConditionWaive; }

  public GetConditions(): Condition[] {
    if (!this._hasCondition) { return null; }

    const results = [];

    // THERE IS NO WAIVE FOR DOWER CONSENT? WOULD THIS EVEN SHOW?
    /* if (this.contract.Prop.DowerConsentDate)
      results.push(new Condition({
        Name: 'Dower Consert & Acknowledgement',
        DueDate: this.contract.Prop.DowerConsentDate,
        Waived: this.contract.Prop.???
      })); */

    if (this.Financing || this.FinancingWaive)
      results.push(new Condition({
        Name: 'Financing', DueDate: this.contract.Prop.FinancingDue, Waived: this.FinancingWaive
      }));

    if (this.CondoDocs || this.CondoDocsWaive)
      results.push(new Condition({
        Name: 'Condo Docs', DueDate: this.contract.Prop.CondoDocsReviewBeforeDay, Waived: this.CondoDocsWaive
      }));

    if (this.PropInspection || this.PropInspectionWaive)
      results.push(new Condition({
        Name: 'Property Inspection', DueDate: this.contract.Prop.PropInspectionDue, Waived: this.PropInspectionWaive
      }));

    if (this.BuyerPropertySale || this.BuyerPropertySaleWaive)
      results.push(new Condition({
        Name: 'Sale of Buyer\'s Property', DueDate: this.contract.Prop.BuyerPropertySaleDue, Waived: this.BuyerPropertySaleWaive
      }));

    if (this.AddBuyerCondition || this.AddBuyerConditionWaive)
      results.push(new Condition({
        Name: 'Additional Buyer Condition', DueDate: this.contract.Prop.AddBuyerConditionDue, Waived: this.AddBuyerConditionWaive
      }));

    if (this.SellerCondition || this.SellerConditionWaive)
      results.push(new Condition({
        Name: 'Seller Condition', DueDate: this.contract.Prop.SellerConditionDue, Waived: this.SellerConditionWaive
      }));

    if (this.WaterCondition || this.WaterConditionWaive)
      results.push(new Condition({
        Name: 'Water', DueDate: this.contract.Prop.WaterConditionDate, Waived: this.WaterConditionWaive
      }));

    if (this.SepticSystem || this.SepticSystemWaive)
      results.push(new Condition({
        Name: 'Septic System', DueDate: this.contract.Prop.SepticSystemDate, Waived: this.SepticSystemWaive
      }));

    if (this.CountryResPropSched || this.CountryResPropSchedWaive)
      results.push(new Condition({
        Name: 'Country Residential Purchase Contract Property Schedule', DueDate: this.contract.Prop.CountryResPropSchedApprovalDate, Waived: this.CountryResPropSchedWaive
      }));

    if (this.SubdivisionPlan || this.SubdivisionPlanWaive)
      results.push(new Condition({
        Name: 'Subdivision Plan', DueDate: this.contract.Prop.SubdivisionPlanDate, Waived: this.SubdivisionPlanWaive
      }));

    /* if (this.BackUpOffer || this.BackUpOfferWaive)
      results.push(new Condition({
        Name: 'Backup Offer', DueDate: this.contract.Prop.BackUpOfferDate, Waived: this.BackUpOfferWaive
      })); */

    return results;
  }

  private GetNonResponsibleAgentStatus(): string {
    switch (this.contract.Status) {
      case ContractStatus.Accepted:
        let result = 'ACCEPTED';
        if (this.HasCondition()) {
          result += ' [CONDITIONAL]';
        }

        if (this.contract.NextContractId) {
          result += ' - Changes Pending';
        }

        return result;
      case ContractStatus.Active:
        switch (this.contract.SubContractType) {
          case SubContractType.ARBBS:
            return 'ACTIVE AGREEMENT TO REPRESENT BOTH SELLER AND BUYER';
          case SubContractType.CA:
            return 'ACTIVE CUSTOMER ACKNOWLEDGMENT';
        }
        break;
      case ContractStatus.Rejected:
      case ContractStatus.Firm:
      case ContractStatus.Closed:
      case ContractStatus.ExpiryPending:
      case ContractStatus.Expired:
      case ContractStatus.ConditionsNonWaived:
      case ContractStatus.Terminated:
        return ContractFormUtils.GetContractStatus(this.contract.Status, this.contract.ContractType).toUpperCase();
      default:
        return 'AWAITING RESPONSE';
    }
  }

  private GetResponsibleAgentStatus(): string {
    switch (this.contract.Status) {
      case ContractStatus.Active:
        switch (this.contract.SubContractType) {
          case SubContractType.ARBBS:
            return 'ACTIVE AGREEMENT TO REPRESENT BOTH SELLER AND BUYER';
          case SubContractType.CA:
            return 'ACTIVE CUSTOMER ACKNOWLEDGMENT';
          default:
            return ContractFormUtils.GetContractStatusByContract(this.contract, this.HasCondition());
        }
      case ContractStatus.Accepted:
        return ContractFormUtils.GetContractStatusByContract(this.contract, this.HasCondition());
      default:
        return ContractFormUtils.GetContractStatusByContract(this.contract, this.HasCondition());
    }
  }

  private HasCondition(): boolean {

    // ONLY SHOW CONDITIONS IF OFFER IS ACCEPTED
    if (this.contract.Status != ContractStatus.Accepted) {
      this._hasCondition = false;
      this._conditions = null;
      this._conditionsOpen = this._conditionsWaived = 0;
      return false;
    }

    const value = this._hasCondition = this.Financing || this.FinancingWaive != null ||
      this.PropInspection || this.PropInspectionWaive != null ||
      this.BuyerPropertySale || this.BuyerPropertySaleWaive != null ||
      this.AddBuyerCondition || this.AddBuyerConditionWaive != null ||
      this.CondoDocs || this.CondoDocsWaive != null ||
      this.WaterCondition || this.WaterConditionWaive != null ||
      this.SepticSystem || this.SepticSystemWaive != null ||
      this.CountryResPropSched || this.CountryResPropSchedWaive != null ||
      this.SubdivisionPlan || this.SubdivisionPlanWaive != null ||
      this.SellerCondition || this.SellerConditionWaive != null;
      // this.BackUpOffer || this.BackUpOfferWaive != null;

    if (value) {
      this._conditions = this.GetConditions();
      this._conditionsOpen = this._conditions.filter(x => x.Waived === null).length;
      this._conditionsWaived = this._conditions.filter(x => x.Waived === true).length;
    } else {
      this._conditions = null;
      this._conditionsOpen = this._conditionsWaived = 0;
    }

    return value;
  }

  //#endregion
}
