import { FileRef } from './../forms/classes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contract } from '../classes/contract';
import { Envelope } from '../envelopes/classes';
import ConfigUtils from '../utils/ConfigUtils';
import { Signer } from '../utils/data/SignerUtils';

@Injectable()
export class SignerService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/signer';
  }

  DownloadFile(id: string = null): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };

    return id === null ?
      this.http.get<ArrayBuffer>(`${this.baseUrl}/download`, options) :
      this.http.get<ArrayBuffer>(`${this.baseUrl}/download/${id}`, options);
  }

  public GetSignerFiles(): Observable<Array<FileRef>> {
    return this.http.get<Array<FileRef>>(`${this.baseUrl}/files`);
  }

  getEmptyContactSigs(): Observable<Signer[]> {
    return this.http.get<Signer[]>(`${this.baseUrl}/GetEmptyContactSigs`);
  }

  getContract(): Observable<Contract> { return this.http.get<Contract>(this.baseUrl); }
  getEnvelope(): Observable<Envelope> { return this.http.get<Envelope>(this.baseUrl); }
}
