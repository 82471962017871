
<script src="https://js.stripe.com/v3/"></script>


<form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
  <div class="form-row">
    <label for="card-info">Credit Card Info</label>
    <div id="card-info" #cardInfo>

    </div>

    <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
  </div>

  <button *ngIf="!token" type="submit">{{buttonText}}</button>
</form>
<div class="alert alert-info" role="alert">
  Once you complete and submit this subscription form, you will be billed for the first period of your chosen plan. 
  By subscribing, you authorize us to automatically continue your Subscription by charging your account on a 
  monthly or yearly basis according to the payment plan chosen. To cancel your auto-renewal,
  simply go to your Profile found within DealSimple™, view your billing information, and cancel.
  See our Terms of Use and Privacy Policy for more information. 
</div>