<div class="card" (click)="selected.emit(data);" [ngClass]="clickable ? 'hover': ''"
  [ngStyle]="{'cursor': clickable ? 'pointer' : 'auto'}">
  <div class="card-body">
    <div class="row">
      <div class="col text-truncate">
        <div class="row">
          <h5 class="card-title mb-0 text-truncate">
            [{{ data.Board }}] {{ data.Name }}
          </h5>
        </div>
        <div class="row">
          <div class="col-12 text-truncate">
            {{ data.Address }}, {{ data.City }}, {{ data.ProvState }}
          </div>
          <div *ngIf="data.ContractGroup" class="col-12 text-truncate">
            {{ type.get(data.ContractGroup) }}
          </div>
          <div *ngIf="!data.ContractGroup" class="col-12 text-truncate">
            <span class="badge rounded-pill text-bg-danger">No Brokerage Type</span>
          </div>
          <div class="col-auto pe-0">
            <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
          </div>
          <div class="col ps-2 text-truncate">
            {{data.EmailAddress}}
          </div>
          <div class="col-auto pe-0">
            <fa-icon [icon]="['fas', 'phone']"></fa-icon>
          </div>
          <div class="col ps-2 text-truncate">
            {{data.PhoneNumber}}
          </div>
        </div>
      </div>

      <div *ngIf="showRemove" class="col-auto text-end">
        <button type="button" class="btn btn-outline-danger btn-xs" (click)="remove.emit(data)">
          <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>