import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brokerage } from '../../classes/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from '../../layout/site-layout/site-layout.component';
import { LookupService } from '../../services/lookup.service';
import { Alert, AlertType } from '../../shared/dismissible-alerts/classes';
import { DataUtils } from '../../utils/DataUtils';
import FormUtils from '../../utils/form/FormUtils';
import { RegistrationFormUtils } from '../../utils/form/RegistrationFormUtils';
import { RegistrationFlowType } from '../classes';
import { RegisterService } from '../service/register.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html'
})
export class DetailsComponent implements OnInit {

  @Input() fg: UntypedFormGroup;
  @Input() layout: AppLayoutComponent | SiteLayoutComponent;
  @Output() close = new EventEmitter();
  @Output() next = new EventEmitter();

  originalEmail = null;
  originalMobile = null;
  tempEmail = null;
  tempMobile = null;
  forceEmailVerification: boolean = false;
  _fu = FormUtils;

  data = DataUtils;
  _rft = RegistrationFlowType;
  _regFlowType: RegistrationFlowType;
  fgUser: UntypedFormGroup = null;
  _error = false;
  _disableDetails = false;
  _alerts: Alert[] = [];
  _brokerages: Observable<Brokerage[]>;
  _getMLSID: boolean = false;
  _MLSID: string = '';

  constructor(private svc: RegisterService, private lusvc: LookupService) { }

  ngOnInit() {
    this._brokerages = this.lusvc.getBrokerages('CA', 'AB');
    this.fgUser = this.fg.get('User') as UntypedFormGroup;

    this._regFlowType = this.fg.get('RegistrationFlowType').value;
    if (this._regFlowType !== RegistrationFlowType.Standard) {
      this._disableDetails = true;
      this.fgUser.get('BrokerageId').disable();
    } else {
      this._getMLSID = true;
    }
    RegistrationFormUtils.initDetailsStepValidators(this.fg, this.lusvc);

    this.originalEmail = this.fgUser.get('EmailAddress').value;
    this.originalMobile = this.fgUser.get('MobilePhoneNumber').value;
  }

  Close() {
    this.close.emit();
  }
  Next() {
    if (this._getMLSID) {
      this.svc.GetUserDataFromMLSID(this._MLSID.toUpperCase()).subscribe({
        next: (data) => {
          this.fgUser.get('FirstName').setValue(data.FirstName);
          this.fgUser.get('LastName').setValue(data.LastName);
          this.fgUser.get('EmailAddress').setValue(data.EmailAddress);
          this.fgUser.get('BrokerageId').setValue(data.BrokerageId);
          this.fgUser.get('MLSID').setValue(data.MLSID);
          this.fgUser.get('MLSNumericKey').setValue(data.MLSNumericKey);
          this.fgUser.get('BrokerageId').disable();
          this._disableDetails = true;
          this._getMLSID = false;
          this.forceEmailVerification = true;
        },
        error: error => {
          console.log(error);
          this._alerts = [];
          this._alerts.push(new Alert(AlertType.DANGER, error.error.Message));
          FormUtils.scrollToTop(true);
        }
      });
      return;
    }

    this._error = false;
    if (FormUtils.FormGroupHasError(this.fgUser, true) || FormUtils.FormGroupHasError(this.fg, true) ||
      this.fg.get('Password').value !== this.fg.get('ConfirmPassword').value) {
      FormUtils.scrollToTop(true);
      return;
    }

    this._brokerages.pipe(map(brokerages => brokerages.find(x => x.BrokerageId === this.fgUser.get('BrokerageId').value))).subscribe(x => {
      this.fgUser.get('Brokerage').setValue(x);

      if (this.forceEmailVerification || (this.tempEmail != null && this.tempEmail !== this.originalEmail)) { this.fg.get('IsEmailVerification').setValue(true); }
      if (this.tempMobile != null && this.tempMobile !== this.originalMobile) { this.fg.get('IsMobileVerification').setValue(true); }

      this.svc.PostRegistrationData(this.fg.getRawValue())
        .subscribe(
          response => {
            this.fg.setValue(response);
            if (!response.Success) {
              this._alerts = [];
              this._alerts.push(new Alert(AlertType.DANGER, this.fg.get('ErrorMessage').value));
              FormUtils.scrollToTop(true);
            } else {
              this.next.emit();
            }
          },
          error => {
            this._alerts = [];
            this._alerts.push(new Alert(AlertType.DANGER, error));
            FormUtils.scrollToTop(true);
          }
        );
    });
  }
}

