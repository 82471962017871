import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Email } from '../classes/classes';
import DateUtils from '../utils/DateUtils';

@Pipe({
  name: 'emailIndex'
})
export class EmailIndexPipe implements PipeTransform {

  transform(email: Email, emails: Email[]): string {
    if (emails.findIndex(x => x.MessageId === email.MessageId) === (emails.length - 1)) {
      return 'Email Sent';
    }
    return `Email Resent`;
  }
}

@Pipe({
  name: 'emailDate'
})
export class EmailDatePipe implements PipeTransform {

  transform(value: Date, format = 'medium', ordinal = false): string {
    if (!value) { return ''; }
    value = DateUtils.createDateAsUTC(new Date(value));
    const zone = new DatePipe('en-US').transform(new Date(), 'ZZZ');

    //We might be able to put the ordinal directly in the format string.
    if (ordinal) {
      format = this.insertOrdinal(value, format);
    }

    return new DatePipe('en-US').transform(value, format, zone);
  }

  insertOrdinal(date: Date, format: String): string {
    let split = format.split(' ');
    const dayIndex = split.findIndex(x => x.includes('d'));
    let dayFormat = split[dayIndex];
    let ordinal = '\'th\'';
    let day = date.getDate();

    if (day % 10 == 1 && day != 11)
      ordinal = '\'st\'';
    else if (day % 10 == 2 && day != 12)
      ordinal = '\'nd\'';
    else if (day % 10 == 3 && day != 13)
      ordinal = '\'rd\'';


    split[dayIndex] = `${dayFormat.substring(0, dayFormat.lastIndexOf('d') + 1)}${dayFormat.substring(dayFormat.lastIndexOf('d') + 1)}`;
    return split.join(' ');
  }
}
