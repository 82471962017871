import { ViewersModule } from './../../viewers/viewers.module';
import { SearchModule } from './../../search/search.module';
import { SelectContactComponent } from './select-contact/select-contact.component';
import { TilesModule } from './../../tiles/tiles.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2SearchPipeModule } from '@ngx-maintenance/ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PageLayoutModule } from './../../shared/page-layout/page-layout.module';
import { SharedModule } from './../../shared/shared.module';
import { PipesModule } from './../../pipes/pipes.module';
import { ContactEditComponent } from './../contact/contact-edit/contact-edit.component';
import { ContactComponent } from './contact.component';
import { DisplayContactComponent } from './display-contact/display-contact.component';
@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    PageLayoutModule,
    FormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    SharedModule,
    PipesModule,
    ReactiveFormsModule,
    UiSwitchModule,
    TilesModule,
    SearchModule,
    ViewersModule
  ],
  declarations: [
    ContactEditComponent,
    ContactComponent,
    SelectContactComponent,
    DisplayContactComponent
  ],
  exports: [ContactEditComponent, ContactComponent, SelectContactComponent, DisplayContactComponent]
})
export class ContactModule { }
