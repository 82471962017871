import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle, IdleExpiry, LocalStorageExpiry } from '@ng-idle/core';
import { Intercom } from 'ng-intercom';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../environments/environment';
import { ModalConfig } from '../../classes/classes';
import { Contract, ContractType } from '../../classes/contract';
import { AuthService, CurrentUser, RoleType, RoleUtils } from '../../services/auth.service';
import { ContractService } from '../../services/contract.service';
import { UserService } from '../../services/user.service';
import { Alert, AlertType } from '../../shared/dismissible-alerts/classes';
import { ContractAgentUtils } from '../../utils/data/ContractAgentUtils';
import { ContractUtils } from '../../utils/data/ContractUtils';
import ContractFormUtils from '../../utils/form/ContractFormUtils';
import GuidUtils from '../../utils/GuidUtils';
import { ContractPrompt } from '../../utils/messaging/ContractPrompt';
import { ModalUtils } from '../../utils/ModalUtils';

@Component({
  providers: [LocalStorageExpiry, { provide: IdleExpiry, useExisting: LocalStorageExpiry }, Idle],
  selector: 'app-layout',
  templateUrl: './app-layout.component.html'
})
export class AppLayoutComponent implements OnInit {

  //#region Variables
  cu: CurrentUser = null;
  prompt: ContractPrompt = null;
  modal: ModalUtils = null;
  smallScreen = false;
  mediumScreen = false;
  largeScreen = false;
  ipAddress: string;

  _newWindow: Window = null;
  _downloadName: string;
  _safeUrl: any;
  _alerts: Alert[] = [];
  //#endregion

  //#region Property
  private _contractNumber: string = null;

  get contractNumber(): string {
    return this._contractNumber;
  }
  set contractNumber(contractNumber: string) {
    this._contractNumber = contractNumber;
  }
  //#endregion


  constructor(
    private svc: AuthService,
    private spinner: NgxUiLoaderService,
    public router: Router,
    private intercom: Intercom,
    private csvc: ContractService,
    private usvc: UserService,
    private sanitizer: DomSanitizer,
    private maintenanceCheck: Idle
  ) {
    this.cu = this.svc.CurrentUser;
    this.intercom.shutdown();
    this.prompt = new ContractPrompt(this);
    this.modal = new ModalUtils(this);

    // ADMIN AND SIGNERS DO NOT REQUIRE INTERCOM LOADED
    if (!this.isAdmin() && !this.isSigner() && !this.isConveyancer() && environment.RunIntercom) { this.LoadIntercom(); }
  }

  ngOnInit() { this.OnResize(); this.SetLogoutCheck(); }

  //#region Message Functions
  public ShowErrorMessage(message: string): void {
    this._alerts.push(new Alert(AlertType.DANGER, message));
  }

  public ShowSuccessMessage(message: string): void {
    this._alerts.push(new Alert(AlertType.SUCCESS, message));
  }
  //#endregion

  public HideSpinner() { this.spinner.stop(); }
  public ShowSpinner() { this.spinner.start(); }
  public LoadCurrentUserIP(): void {
    this.usvc.GetCurrentUserIP().subscribe(
      response => this.ipAddress = response.ip.split('.').map(parseFloat).reduce((total, part) => total * 256 + part).toString()
    );
  }

  //#region Security Functions
  public isAdmin(): boolean { return this.cu.Role === RoleType.Admin; }
  public isConveyancer(): boolean { return this.cu.Role === RoleType.Conveyancer; }
  public isDashboardRoute(): boolean { return this.router.url.indexOf('home') > -1; }
  public isAgentResponsible(data: Contract, sellerSigns: boolean = false): boolean {
    if (data.SellerSigns === null) { return null; }
    return ContractAgentUtils.IsContractAgent(data.ContractAgents, this.cu.User.UserId, data.SellerSigns);
  }
  public isGuest(): boolean { return RoleUtils.HasRole(this.cu.Role, RoleType.Guest); }
  public isNonValidatedGuest(): boolean { return RoleUtils.HasRole(this.cu.Role, RoleType.Guest) && !this.cu.User.Validated; }
  public isPaidUser(): boolean {
    if (RoleUtils.HasSome(this.cu.Role, (RoleType.Agent | RoleType.ListingAgent)))
      return true;

    return false;
  }
  public isSigner(): boolean { return RoleUtils.HasSome(this.cu.Role, RoleType.EnvelopeSigner | RoleType.Signer); }
  public RefreshToken(redirectPath: string = '/') {
    this.svc.RefreshToken()
      .subscribe(
        () => this.router.navigate([redirectPath]),
        error => this.modal.ShowModal(error)
      );
  }
  //#endregion

  //#region New Tab Functions
  public CloseNewTab() {
    if (this._newWindow !== null) {
      this._newWindow.close();
      this._newWindow = null;
    }
    this.HideSpinner();
  }

  public OpenNewTab() {
    this.ShowSpinner();
    this._newWindow = window.open();
  }

  public OpenNewTabByUrl(url: string) {
    this._newWindow = window.open(url);
  }

  public SetNewTabUrl(url: string) {
    if (this._newWindow !== null) {
      this._newWindow.location.href = url;
      this._newWindow.focus();
      this._newWindow = null;
    }

    this.HideSpinner();
  }
  public StartNewContract(type: ContractType) {
    const id = GuidUtils.GenerateGuid();
    sessionStorage.setItem('new', id);
    this.router.navigate([ContractFormUtils.GetContractHomePath(type), id]);
  }

  public UploadContract(type: ContractType, base64: string) {
    const id = GuidUtils.GenerateGuid();
    const contract = new ContractUtils(this).NewContract(new Contract(type), id);

    this.ShowSpinner();
    this.csvc.PostContract(contract, base64)
      .subscribe(
        () => {
          this.router.navigate([ContractFormUtils.GetContractHomePath(type), id]);
          this.HideSpinner();
        },
        ex => this.modal.ShowError(ex)
      );
  }
  //#endregion

  //#region Private Functions
  private LoadIntercom() {
    if (this.isSigner()) {
      this.intercom.boot({
        name: this.cu.SignerName,
        user_id: this.cu.SignerId,
        widget: { 'activator': '#intercom' }
      });
      return;
    } else if (this.isGuest()) {
      this.intercom.boot({
        name: `${this.cu.User.FirstName} ${this.cu.User.LastName}`,
        user_id: this.cu.User.UserId,
        widget: { 'activator': '#intercom' }
      });
    }

    this.intercom.boot({
      name: `${this.cu.User.FirstName} ${this.cu.User.LastName}`,
      email: this.cu.User.EmailAddress,
      phone: this.cu.User.MobilePhoneNumber,
      user_id: this.cu.User.UserId,
      unsubscribed_from_emails: !this.cu.User.GANotifiy,
      company: {
        id: this.cu.User.Brokerage.BrokerageId,
        name: this.cu.User.Brokerage.Name,
      },
      widget: { 'activator': '#intercom' },
      'User Role': RoleType[this.cu.Role],
      'Subscription End Date': this.cu.Subscription?.EndDate ?
        this.cu.Subscription?.EndDate : 'Not Set',
      'Subscription Renewal Date': this.cu.Subscription?.RenewalDate
    });
  }

  public OnResize() {
    this.smallScreen = window.innerWidth < 768;
    this.mediumScreen = window.innerWidth < 992;
    this.largeScreen = window.innerWidth < 1200;
  }

  //#endregion

  //#region Download Functions
  public DownloadFileByUrl(url: string, filename: string) {
    if (url !== null) {
      this.ShowSpinner();
      this._downloadName = filename;
      this._safeUrl = this.sanitizer.bypassSecurityTrustUrl(url) as string;
      setTimeout(() => {
        this.HideSpinner();
        document.getElementById('__hiddenLauncher').click();
      }, 100);
    }
  }

  public DownloadFile(data: ArrayBuffer, filename = 'Contract.pdf', mimeType = 'application/pdf') {

    this.ShowSpinner();

    if (data == null) {
      this.HideSpinner();
      return null;
    }

    const blob = new Blob([new Uint8Array(data)], { type: mimeType });

    // // IE
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob, filename);
    //   this.HideSpinner();
    //   return null;
    // }

    // ALL OTHER BROWSERS
    const url = window.URL.createObjectURL(blob);
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 5000);

    return this.DownloadFileByUrl(url, filename);
  }

  public PrintFile(data: ArrayBuffer) {
    if (data !== null && data != undefined) {
      const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.onload = () => {
        setTimeout(() => {
          this.HideSpinner();
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };

      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 5000);
    }
  }
  //#endregion

  //#region MAINTENANCE LOGOUT CHECK
  private SetLogoutCheck() {
    // CLEAR IDLE FIRST
    localStorage.removeItem('ng2Idle.maintenanceCheck.expiry');
    localStorage.removeItem('ng2Idle.maintenanceCheck.idling');

    this.maintenanceCheck.setIdleName('maintenanceCheck');
    // 1 MINUTE IDLE TIMER
    this.maintenanceCheck.setIdle(60);
    // DIRTY HACK IS TO SET TIMEOUT WINDOW TO 1 HOUR
    this.maintenanceCheck.setTimeout(60 * 60);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.maintenanceCheck.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.maintenanceCheck.onIdleStart.subscribe(() => {
      if (sessionStorage.length < 1) { return; }
      if (!this.cu.MaintenanceWindow || !this.cu.MaintenanceWindow.Item1 || !this.cu.MaintenanceWindow.Item2) { return; }
      if (this.cu.MaintenanceWindow.Item1.toString() < new Date().toISOString()) {
        sessionStorage.clear();
        location.reload();
      }
    });

    this.maintenanceCheck.watch();
  }
  //#endregion
}
