import { FileRef } from './../forms/classes';
import { ContractActionType, ContractAction } from '../utils/form/ContractActionUtils';
import { Contract, ContractType, ContractStatus } from '../classes/contract';
import { FileRefType } from '../forms/classes';

export class AttachmentAction {
  ActionType: ContractActionType = null;
  FileData: string = null;
  HasQR = false;
}

export enum AttachmentMode {
  Ready = 0,
  Rename = 1,
  Delete = 2
}

export enum AttachmentWizardStep {
  FileError,
  OtherFileStep,
  PDFStep1,
  PDFStep2
}

export class AttachmentError {
  file: File;
  type: string;
}

export class AttachmentForm {
  FileRefId: string = null;
  FileExt: string = null;
  Filename: string = null;
  IsAppendix = false;
  IsPrivate = true;
  FileRefType: FileRefType = null;
  ParentId: string = null;
  ParentNumber: number = null;
  ParentRev: number = null;
  Mode: AttachmentMode = AttachmentMode.Ready;
  UserId: string = null;
}

export class QRData {
  Contract: Contract;
  Contracts: Contract[];
  FileRefs: FileRef[];
  FileRefId: FileRef;
  ContractId: string;
  ContractNumber: number;
  CurrentPage: number;
  TaskInstId: string;
  TotalPages: number;
  TotalPagesProcessed: number;
  ErrorMessage: string;
}

export class LightContractData {
  Type: ContractType;
  Number: string;
  Address: string;
  Buyers: string;
  Sellers: string;
  SearchText: string;
  Price: string;
  ContractId: string;
  PaperActions: ContractAction[];
  SellerAgent: boolean;
}

export class AttachmentData {
  FileData: string = null;
  FileName: string = null;
  QR: QRData = null;

  constructor(fields?: {
    FileData?: string,
    FileName?: string,
    QR?: QRData,
  }) {
    if (fields) { Object.assign(this, fields); }
  }
}

export class UploadAction {
  FileNames: string[] = null;
  NextStep: AttachmentWizardStep = null;
  QR: QRData = null;
  ContractId: string = null;
  ContractType: ContractType = null;

  constructor(fields?: {
    FileNames?: string[],
    NextStep?: AttachmentWizardStep,
    QR?: QRData,
    ContractId?: string,
    ContractType?: ContractType;
  }) {
    if (fields) { Object.assign(this, fields); }
  }
}

