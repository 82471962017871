<div class="card" [ngClass]="{'hover': !largeTile}" (click)="selected.emit(contract); $event.stopPropagation();">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="row">
          <h5 class="card-title mb-0">
            <div *ngIf="!contract.Prop.AddrLine1">
              No Address
            </div>
            <div *ngIf="contract.Prop.AddrLine1">
              {{ contract.Prop.AddrLine1 }}, {{contract.Prop.AddrCity}}
            </div>
          </h5>
        </div>
        <div class="row">
          <div class="col">
            <b id="color" [ngClass]="cfu.getContractSignClass(contract.Status)">
              {{ GetStatus() }}
            </b>
            |
            {{ contract.Prop.Price ? '$' + (contract.Prop.Price | newDecimal:0) : 'No Price' }}
            {{ contract.Prop.ListingPrice ? '($' + (contract.Prop.ListingPrice | newDecimal:0) + ' Listing)': '' }}
          </div>
        </div>
        <div *ngIf="formUtils.ConcatOwnerNames(contract.ContractOwners, true) !== 'No Seller'" class="row">
          <div class="col">
            <b>Seller: </b>
            <span>
              {{ formUtils.ConcatOwnerNames(contract.ContractOwners, true) }}
              <b>{{ RepresentedBy(true) }}</b>
              {{ WaitingForSeller(true) }}
            </span>
          </div>
        </div>
        <div *ngIf="formUtils.ConcatOwnerNames(contract.ContractOwners, false) !== 'No Buyer'" class="row">
          <div class="col">
            <b>Buyer: </b>
            <span>
              {{ formUtils.ConcatOwnerNames(contract.ContractOwners, false) }}
              <b>{{ RepresentedBy(false) }}</b>
              {{ WaitingForSeller(false) }}
            </span>
          </div>
        </div>
        <div *ngIf="ShowOfferExpires()" class="row">
          <div class="col">
            <b>Offer Expires: </b>
            <span>
              {{ contract.Prop.OfferExpires | newDate:'MMM d' }} at {{ contract.Prop.OfferExpires | newDate:'h:mm a' }}
            </span>
          </div>
        </div>
        <div
          *ngIf="(contract.Status === status.Accepted || contract.Status === status.Firm || contract.Status === status.Closed) && contract.Prop.CompletionDate"
          class="row">
          <div class="col">
            <b>Possession Date: </b>
            <span>
              {{ contract.Prop.CompletionDate | newDate:'MMM d, yyyy' }}
            </span>
          </div>
        </div>
        <div *ngIf="_hasCondition" class="row">
          <div class="col-12 fw-bold">
            Conditions:
          </div>
          <div *ngFor="let condition of GetConditions()" class="col-lg-4 col-md-6 col-12">
            <i [ngClass]="condition.Waived ? 'fal fa-check-square' : 'fal fa-square'"></i>
            {{ condition.Name }} ({{ condition.DueDate | newDate:'MMM d' }})
          </div>
        </div>
      </div>

      <div *ngIf="df && df.GetActions().length > 0" class="col-auto text-end">
        <div class="d-sm-block d-none">
          <div ngbDropdown placement="bottom-right">
            <button class="btn btn-primary" ngbDropdownToggle>Actions</button>
            <div ngbDropdownMenu>
              <button *ngFor="let action of df.GetActions()" ngbDropdownItem (click)="click.emit(action)">{{
                action.Name ? action.Name : action.ActionType.Name }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-sm-none">
          <div ngbDropdown placement="bottom-right">
            <button class="btn btn-dropdown" ngbDropdownToggle>
              <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
            </button>
            <div ngbDropdownMenu>
              <button *ngFor="let action of df.GetActions()" class="dropdown-item pointerCursor"
                (click)="click.emit(action)">{{ action.Name ? action.Name : action.ActionType.Name }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>