<div class="container-fluid col-10">
    <p align="center">
        <strong>TERMS OF USE</strong>
    </p>
    <p align="center">
        <a name="OLE_LINK4"></a>
        <a name="OLE_LINK3"></a>
    </p>
    <p align="center">
        <strong>
            CONSULT A LAWYER BEFORE BUYING OR SELLING REAL PROPERTY – WE DO NOT
            PROVIDE LEGAL ADVICE
        </strong>
    </p>
    <p align="center">
        <strong></strong>
    </p>
    <p align="center">
        THIS SITE AND SERVICES ARE INTENDED FOR USE ONLY BY LICENSED REAL ESTATE
        AGENTS AND OTHER QUALIFIED REAL ESTATE PROFESSIONALS. IT IS YOUR
        RESPONSIBILITY TO REVIEW ALL DOCUMENTS WITH YOUR CLIENTS AND TO ENSURE
        CLIENT CONTACT INFORMATION IS ACCURATE.
    </p>
    <p align="center">
        <br />
        YOUR SUBSCRIPTION TO THIS SERVICE WILL AUTOMATICALLY RENEW UNLESS YOU ELECT
        TO TERMINATE IT AS DESCRIBED BELOW.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        <strong>
            PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT
            INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS.
            THESE INCLUDE VARIOUS LIABILITY LIMITATIONS AND EXCLUSIONS, AND A
            DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
        </strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        This site is owned and operated by Real Deal Transaction Management Inc. (" <strong>RDTM</strong>",
        "<strong>we</strong>" and "<strong>us</strong>").
        Access to and use of this website (the "<strong>Site</strong>") and its
        related services (the "<strong>Services</strong>") are provided by RDTM.
        These Terms of Use are an agreement entered into between RDTM and the
        individual or organization identified on the subscription form (the " <strong>User</strong>",
        "<strong>you</strong>", or "<strong>your</strong>
        "), and are entered into as of the date on which you 'Subscribe' through
        the DealSimple™ ‘register’ page (the "<strong>Effective Date</strong>").
        Access to the Site and Services are provided by us on the condition that
        you accept these Terms of Use, and <u>by using the Site or the Services, you agree to these Terms of Use</u>.
        If you do not agree to accept and abide by these Terms of Use you may not
        access or use this Site or the Services.
    </p>
    <p>
        These Terms of Use govern the relationship between you and RDTM with
        respect to your use of the Site and its related Services. You agree that
        the agreement formed by these Terms of Use is like any written negotiated
        agreement signed by you, and you agree to be bound by, and fully comply
        with, its terms. You represent and warrant that you have all necessary
        right, power and authority to enter into this agreement and to perform and
        otherwise discharge all of your obligations hereunder.
    </p>
    <p>
        These Terms of Use hereby incorporate by reference our <a target="_blank" routerLink="/privacy">Privacy
            Policy</a>,
        which can be accessed <a target="_blank" routerLink="/privacy">here</a>
        (the "<strong>Privacy Policy</strong>").
        You acknowledge and agree that you have read and understood both documents
        prior to subscribing, and agree to their terms. In the event of any
        conflict or inconsistency between the terms of these Terms of Use and the
        <a target="_blank" routerLink="/privacy">Privacy Policy</a>, the Terms of Use will prevail.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        ALL INFORMATION AND DOCUMENTATION CONTAINED ON THE SITE IS FOR
        INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE RELIED ON AS DEFINITIVE OR
        ACCURATE. WE DO NOT WARRANT THE LEGAL OR FINANCIAL ABILITY OF ANY USER TO
        ENTER INTO OR CONSUMMATE ANY TRANSACTION FOR REAL PROPERTY. WE DO NOT
        WARRANT THE ACCURACY OF THE IDENTITY OF ANY USER OF THE SITE OR SERVICES.
        WE DO NOT PROVIDE LEGAL ADVICE. WE DO NOT REPRESENT OR WARRANT THAT THE
        DOCUMENTS PROVIDED THROUGH THE SITE OR THE SERVICES HEREIN WILL HAVE A
        PARTICULAR LEGAL EFFECT IF EXECUTED. WE DO NOT REPRESENT OR WARRANT THAT A
        TRANSACTION YOU ATTEMPT TO CARRY OUT USING THE DOCUMENTS PROVIDED THROUGH
        THE SITE OR SERVICES WILL BE ENFORCEABLE. THE DOCUMENTS PROVIDED THROUGH
        THE SITE AND SERVICES HAVE BEEN PREPARED BY AND/OR LICENSED FROM THE
        ALBERTA REAL ESTATE ASSOCIATION (“<strong>AREA</strong>”) AND NOT RDTM.
        RDTM MAKES NO REPRESENTATION OR WARRANTY AS TO THEIR CONTENTS. CONSULT A
        LAWYER BEFORE BUYING OR SELLING REAL PROPERTY.
    </p>
    <p>
        <strong>1. </strong>
        <strong>JURISDICTION </strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        You understand that aspects of this Site and the Services may not be
        available in all jurisdictions and that you are responsible for ensuring
        that it is lawful for you to use this Site and receive the Services where
        these activities occur.
    </p>
    <p>
        If you reside in a jurisdiction which restricts the use of Internet-based
        applications according to age, or which restricts the ability to enter into
        agreements such as set out in these Terms of Use according to age and you
        are under such a jurisdiction and under such age limit, you may not enter
        into this agreement or use this Site or the Services. <strong> </strong>
    </p>
    <p>
        Furthermore, if you reside in a jurisdiction where it is forbidden by law
        to participate in the activities offered by this Site or the Services, you
        may not enter into this agreement or use this Site or the Services. By
        using this Site you are explicitly representing that you have verified that
        your use of this Site and the Services is allowed in your own jurisdiction.
    </p>
    <p>
        The Site and Services may only be used by individuals over the age of 18.
        If you are under the age of 18, you may not use the Site or the Services.
    </p>
    <p>
        Your Account (as defined below) may be deleted and these Terms of Use may
        be suspended or terminated without warning, and without any liability to
        RDTM, if we believe that you are under age or your use of this Site or the
        Services is not allowed (among other reasons set out below).
    </p>
    <p>
        <strong>2. </strong>
        <strong>PRIVACY</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        RDTM is committed to respecting the privacy of the personal information of
        the individuals with whom we interact. We have developed a <a target="_blank" routerLink="/privacy">Privacy
            Policy</a>
        to describe our privacy policies and practices and how we collect, use and
        disclose the personal information of those individuals who visit this Site
        or use the Services. Please see our <a target="_blank" routerLink="/privacy">Privacy Policy</a> for further
        details and
        contact information for our Privacy Officer. <strong> </strong>
    </p>
    <p>
        You acknowledge and agree that access to and use of this Site and the
        Services is provided via the Internet and that your information, including
        personal information, may be transferred across national borders and stored
        or processed in any country in the world.
    </p>
    <p>
        <strong>3. </strong>
        <strong>ACCOUNTS </strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        In order to access and use the Services available on this Site, you will
        need to sign up for, open and maintain an account (“ <strong>Account</strong>”) with us. <strong> </strong>Prior
        to completing
        the signup process for your Account, you will be required to indicate your
        acceptance of all of the terms and conditions of these Terms of Use and
        <a target="_blank" routerLink="/privacy">Privacy Policy</a>.
        If you do not agree to these Terms of Use and <a target="_blank" routerLink="/privacy">Privacy Policy</a>,
        you may not sign up for an Account. When signing up for your
        Account, you will need to elect the term for which you wish to subscribe,
        and will be informed of the fees RDTM will charge you for that term.
        <s>
        </s>
    </p>
    <p>
        <s>
        </s>
    </p>
    <p>
        <strong>
            YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW UNLESS YOU CANCEL IT IN ADVANCE OF THE AUTO-RENEWAL DATE.
        </strong>
    </p>
    <p>
        The Site and Services are intended to be used only by licensed real estate
        agents which, in Alberta, Canada are certified by the Real Estate Council
        of Alberta (“<strong>RECA</strong>”). Use of the Site for Alberta real
        estate transactions is dependent upon remaining a member in good standing
        with RECA as well as the Alberta Real Estate Association (“ <strong>AREA</strong>”). You will be asked to verify
        your status by
        providing your AREA membership information. By providing your membership
        information, you consent to that information being shared with our third
        party authenticator for the purpose of membership authentication, as
        required by AREA. Each time you log into your Account, your membership will
        be authenticated. If your membership status changes, your Account and use
        of the Site or Services may be subject to suspension or termination
        pursuant to these Terms of Use.
    </p>
    <p>
        You may terminate your Account using the functionality provided by RDTM
        from within the Site by going into your DealSimple profile and choosing
        “View Billing”, then “Manage Subscription”, then “Cancel Subscription”. If
        you cancel your Account, you will, subject to these Terms of Use, have
        access until the end of your current subscription period (as subscription
        Fees are prepaid), after which time you may no longer use your Account or
        the Site and Services. In the event you elect to terminate your Account,
        RDTM will not refund you for any fees you have paid hereunder to use your
        Account or the Site or Services. RDTM may, in its sole and absolute
        discretion, delete or destroy any and all User Materials (as defined below)
        upon termination of your Account. If you wish to reinstate your Account,
        you may contact RDTM at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        to inquire about doing so. RDTM may require payment of a reinstatement fee
        in order to reactivate your Account. However, this does not guarantee
        access to User Materials maintained in your Account prior to its
        termination, which may be deleted at any time as described above.
    </p>
    <p>
        Should you choose to terminate your Account, for reasons of privacy and
        security, we ask that you do so using the process described above, or by
        contacting us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        . Alternatively, we may send an email to the electronic address used to
        subscribe to DealSimple to verify the User’s identity and confirm that they
        wish to cancel the Subscription assigned to that electronic address.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        During the registration process we may collect your name, birth date, email
        address, phone number, username, password, IP address, computer’s hardware
        and operating system specifications. We may also collect and verify
        credentials from you in order to determine your eligibility to use the Site
        or the Services. We will also collect your payment related information
        (such as your credit card number and billing address). Your information may
        be used by us:
    </p>
    <ul>
        <li>
            to verify your eligibility to create and to maintain your Account;
        </li>
        <li>
            to ensure that your Account, including your username, is unique;
        </li>
        <li>
            to enable us to provide certain security and privacy safeguards;
        </li>
        <li>
            to deal with security, debugging and technical support issues;
        </li>
        <li>
            for payment-related issues;
        </li>
        <li>
            to protect ourselves and others from abuse;
        </li>
        <li>
            to conduct a criminal or other background check in relation to you; and
        </li>
        <li>
            to comply with law.
        </li>
    </ul>
<br>
    <p>
        All of the information you provide to us or that we collect from you in
        connection with your use of the Site or the Services will be governed by
        these Terms of Use and <a target="_blank" routerLink="/privacy">Privacy Policy</a>.
        You agree at all times to: (i)
        provide accurate, current and complete information about yourself as
        prompted by our registration form or otherwise; and (ii) maintain and
        update your information (including your e-mail address) to keep it
        accurate, current and complete. You acknowledge that, if any information
        provided by you is untrue, inaccurate, not current or incomplete, we may
        suspend or terminate your Account and your ability to use this Site or the
        Services and, in our sole discretion, to report you to appropriate
        authorities.
    </p>
    <p>
        We hereby disclaim any and all responsibility or liability for any
        unauthorized use of your Account.
    </p>
    <p>
        You must not choose a username that infringes the rights of any third
        party, impersonates any RDTM employee, other user, celebrity or any famous
        person or entity, which is deliberately confusing or which is offensive,
        racist, obscene, hurtful, unlawful or otherwise inappropriate. You agree
        that you will not use misspellings or alternative spellings or take any
        other actions for the purpose of circumventing the foregoing restrictions.
        You understand and agree that, in addition to the foregoing restrictions,
        we reserve the right to change, remove, alter or delete any username at any
        time and for any reason in our sole discretion.
    </p>
    <p>
        YOU ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR
        USERNAME AND PASSWORD AND FOR ANY AND ALL ACTIVITIES (INCLUDING PURCHASES
        AND CHARGES, AS APPLICABLE) THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
    </p>
    <p>
        You cannot transfer your Account to any other person, or in the case of the
        Team Bundle, permit anyone not registered under your Account to use it.
    </p>
    <p>
        You agree to notify us immediately of any unauthorized use, theft or
        misappropriation of your Account, username, or password. We shall not
        be liable for any loss that you incur as a result of someone else using
        your username or password, either with or without your knowledge.

    </p>

    <p>
        In the event that you accept an invitation issued from DealSimple to
        register for an Account at no charge for the purpose of viewing
        transaction documents created by, or otherwise interacting with, a
        current User, we will provide you with limited access to the Services
        offered by the Site, provided that your use of the Site and Services is
        subject to these Terms of Use and <a target="_blank" routerLink="/privacy">Privacy Policy</a>.
    </p>

    <p>
        <strong>4. </strong>
        <strong>LAWFUL USE ONLY</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        You may not use this Site or the Services in any way that is unlawful, or
        harms us or any other person or entity, as determined in our sole
        discretion.
    </p>
    <p>
        <strong>5. </strong>
        <strong>CODE OF CONDUCT</strong>
        <strong></strong>
    </p>
    <p>
        Use of this Site and Services does not replace your function or alter your
        responsibilities as a licensed real estate agent or broker. You must
        continue to represent your client in a manner consistent with the
        requirements of your real estate license and any agreements between you and
        your clients. While using this Site, you agree to review User Materials for
        completeness and accuracy. This includes data that may be populated for you
        from other sources including the representative for another party or an MLS
        System.
    </p>
    <p>
        You are solely responsible for the content of any communications by you
        with third parties, including any communications about, concerning or
        through this Site or the Services. RDTM does not condone or allow
        unsolicited commercial electronic messages or communications (spam). You
        agree that you will not use this Site or the Services to send unsolicited
        commercial or other e-mail or electronic messages of any kind except in
        compliance with the legislation known as " <strong>Canada's Anti-Spam Law</strong>" or "<strong>CASL</strong>".
    </p>
    <p>
        You are also solely responsible for ensuring the accuracy of the contact
        information you enter for the purpose of contacting parties to your real
        estate transaction through the Site and Services in order to facilitate a
        transaction. IT IS IMPORTANT THAT YOU ENSURE THIS INFORMATION IS CORRECT TO
        AVOID DISCLOSURE OF CONFIDENTIAL INFORMATION TO OUTSIDE PARTIES.
    </p>
    <p>
        You may not interfere with the security of, or otherwise abuse this Site,
        the Services or any system resources, services or networks connected to or
        accessible through this Site or the Services. You may only use this Site
        and the Services for lawful purposes. You agree that you will not attempt
        to, nor permit any third party to, enter restricted areas of RDTM's
        computer systems or software, or perform functions that you are not
        authorized to perform.
    </p>
    <p>
        RDTM may, without notice, temporarily or permanently suspend your, or any
        other parties, access to this Site or the Services by deactivating any
        Account, password(s) or links to the Internet if we reasonably suspect that
        you, or any other parties, are obtaining unauthorized access to our other
        systems or information, or are using otherwise valid user identifications
        or passwords in any unauthorized manner. These suspensions will be for such
        periods of time as RDTM may reasonably determine is necessary to permit the
        thorough investigation and mitigation of such suspended activity.
    </p>
    <p>
        While using this Site or the Services you agree to comply with all
        applicable laws, rules and regulations, including without limitation any
        laws governing the transmission of commercial electronic messages or
        similar. In particular, you agree not to use the Site or the Services to
        send unlawful commercial electronic messages contrary to CASL.
    </p>
    <p>
        We reserve the right, in our sole discretion, to take any actions we deem
        necessary and appropriate to preserve the integrity of this Site and the
        Services.
    </p>
    <p>
        You agree not to post anything on the Site that:
    </p>
    <ul>
        <li>
            is defamatory, abusive, harassing, threatening, or an invasion of a right
            of privacy of another person;
        </li>
        <li>
            is bigoted, hateful, or racially or otherwise offensive;
        </li>
        <li>
            is violent, vulgar, obscene, pornographic or otherwise sexually explicit,
            or otherwise harms or can reasonably be expected to harm any person or
            entity;
        </li>
        <li>
            is misleading, untruthful or inaccurate;
        </li>
        <li>
            contains a virus or other harmful component, or otherwise tampers with,
            impairs or damages the Service or any connected network, or otherwise
            interferes with any person or entity's use or enjoyment of the Service;
        </li>
        <li>
            advertises or offers to sell any products, services or otherwise (whether
            or not for profit), or solicits others (including solicitations for
            contributions or donations);
        </li>
        <li>
            is illegal or encourages or advocates illegal activity or the discussion
            of illegal activities with the intent to commit them, including without
            limitation a submission that is, or represents an attempt to engage in,
            child pornography, stalking, sexual assault, fraud, trafficking in obscene
            or stolen material, drug dealing and/or drug abuse, harassment, theft, or
            conspiracy to commit any criminal activity; or
        </li>
        <li>
            infringes or violates any right of a third party including: (i)
            copyright, patent, trademark, trade secret or other proprietary or
            contractual rights; (ii) rights of privacy (specifically, you must not
            distribute another person's personal information of any kind without their
            express permission) personality or publicity; or (iii) any confidentiality
            obligation.
        </li>
    </ul>
<br>
    <p>
        In addition, you agree not to:
    </p>
    <ul>
        <li>
            download, copy, reproduce, republish, upload, post, transmit, modify,
            distribute or publicly display any of the content or information contained
            in this Site or the Services except as permitted through this Site and
            Services or as expressly authorized by us in writing;
        </li>
        <li>
            use tools which hack or alter this Site, the Services or that allow you
            to connect to this Site's or the Services' private binary interface or
            utilize user or other interfaces other than those provided by us to you;
        </li>
        <li>
            attempt to obtain a password or other private account information from
            any other person or user of this Site or the Services;
        </li>
        <li>
            create multiple Accounts for any purpose, except as expressly permitted
            under the Team Bundle Account; or
        </li>
        <li>
            transmit any software or other materials that contain any viruses, worms,
            trojan horses, defects, date bombs, time bombs or other items of a
            destructive nature.
        </li>
    </ul>
<br>
    <p>
        You acknowledge that RDTM may remove, delete or edit any post, information
        or content provided by you for any reason whatsoever, including
        non-compliance with the foregoing.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        WE CANNOT AND DO NOT ASSURE THAT OTHER USERS ARE OR WILL BE COMPLYING WITH
        THE FOREGOING CODE OF CONDUCT OR ANY OTHER PROVISIONS OF THESE TERMS OF
        USE, AND, AS BETWEEN YOU AND US, YOU HEREBY ASSUME ALL RISK OF HARM OR
        INJURY RESULTING FROM ANY SUCH LACK OF COMPLIANCE.
    </p>
    <p>
        <strong>6. </strong>
        <strong>AVAILABILITY</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        While we endeavour to keep downtime to a minimum, we can't promise or
        warrant that this Site or the Services will be uninterrupted, secure or
        error-free. We reserve the right to interrupt or suspend this Site or the
        Services, or any part thereof, with or without prior notice for any reason.
    </p>
    <p>
        <strong>7. </strong>
        <strong>ACCESS TO THE INTERNET</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        You are solely responsible for obtaining and maintaining all Internet,
        computer hardware and other equipment needed to access and use this Site
        and the Services and you agree that you shall be solely responsible for all
        charges and fee related thereto.
    </p>
    <h3>
    </h3>
    <p>
        <strong>8. </strong>
        <strong>CHANGES TO SITE AND CONTENT</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        RDTM may revise, supplement or delete information, services and/or the
        resources contained in this Site and the Services and reserves the right to
        make such changes without prior notification to past, current or
        prospective visitors. For the avoidance of doubt, we reserve the right at
        any time to change: (i) the terms and conditions of these Terms of Use;
        (ii) this Site or the Services, including terminating, eliminating,
        supplementing, modifying, adding or discontinuing any content or feature or
        data or service on or available through this Site or the Services or the
        hours that they are available; (iii) any fees or charges, if any, related
        to the use of this Site or the Services; and (iv) the equipment, hardware
        or software required to use and access this Site or the Services.
    </p>
    <p>
        Any changes we make to these Terms of Use will be effective immediately
        upon notice, which we may provide by any means including, without
        limitation, posting on this website. Your continued use of this Site or the
        Services after such notice will be deemed acceptance of such changes. Be
        sure to return to this Site periodically to ensure you are familiar with
        the most current version of these Terms of Use.
    </p>
    <p>
        <strong>9. </strong>
        <strong>LINKED WEBSITES</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        This Site or the Services may provide links to third party websites for
        your convenience only. In addition, the Site or Services may interact with
        third party sites for the purpose of verifying or authenticating you as a
        licensed real estate agent in good standing with the appropriate real
        estate board. The inclusion of these links does not imply that RDTM
        monitors or endorses these websites. RDTM does not accept any
        responsibility for such websites. RDTM shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be
        caused by or in connection with the use of or the reliance upon any
        information, content, goods or services available on or through any third
        party websites or linked resources.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        <strong>10. </strong>
        <strong>INTERNET SOFTWARE OR COMPUTER VIRUSES</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        Due to technical difficulties with the Internet, Internet software or
        transmission problems could produce inaccurate or incomplete copies of
        information contained on this Site or the Services. Due to the ability to
        share certain content and materials, computer viruses or other destructive
        programs may also be inadvertently downloaded from this Site or the
        Services.
    </p>
    <p>
        RDTM shall not be responsible or liable for any software, computer viruses
        or other destructive, harmful or disruptive files or programs that may
        infect or otherwise impact your use of your computer equipment or other
        property on account of your access to, use of, or browsing on this Site or
        the Services or other content from this Site. RDTM suggests that you take
        prudent action to protect yourself, such as installing appropriate
        anti-virus or other protective software.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        <strong>11. </strong>
        <strong>INTELLECTUAL PROPERTY </strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        As between you and RDTM, this Site and the Services are owned and operated
        by RDTM (or its licensors) and may be protected by intellectual property
        laws including laws relating to copyrights, trade-marks, trade-names,
        Internet domain names, notice and notice rights, and other similar rights
        under the laws of Alberta, Canada and elsewhere.
    </p>
    <p>
        RDTM may make certain content, software or other electronic materials
        (including all files, text, URLs, video, audio and images contained in or
        generated by such materials, and accompanying data) (collectively “ <strong>RDTM Materials</strong>”) available
        to you from this Site or the
        Services, from time to time. If you stream or use RDTM Materials, you agree
        that such materials shall only be used in compliance with these Terms of
        Use and any additional license terms accompanying such materials. RDTM does
        not transfer either the title or the intellectual property rights to the
        RDTM Materials, and retains any and all intellectual property rights that
        it may have therein. You agree not to sell, share, redistribute, or
        reproduce the RDTM Materials. You further agree not to decompile,
        reverse-engineer, disassemble, or otherwise convert any of the RDTM
        Materials comprising software into a human-perceivable form. RDTM or its
        licensors own all related trademarks and logos, and you agree not to copy
        or use them in any manner.
    </p>
    <p>
        By any posting, uploading, displaying, performing, transmitting, sharing,
        making available or otherwise distributing information or other content (“ <strong>User Materials</strong>”) to
        this Site or the Services by you or at
        your direction, you are granting, and hereby grant, RDTM, its affiliates,
        partners, officers, directors, employees, consultants, agents, and
        representatives a worldwide, irrevocable, non-exclusive, royalty-free,
        sublicensable license to use such User Materials in connection with the
        operation of this Site and the Services, including, a right to copy,
        distribute, transmit, publicly display, publicly perform, reproduce, edit,
        translate, make available, make derivative works from, and reformat such
        User Materials. You will not be compensated for any User Materials. By
        posting User Materials on this Site or the Services, you warrant and
        represent that you own title to the User Materials and any work or content
        therein that may be subject to protection by intellectual property laws, or
        are otherwise authorized by the owner of such title to post, distribute,
        display, perform, transmit, make available, or otherwise distribute User
        Materials, in compliance with the license granted herein to RDTM, its
        affiliates, partners, officers, directors, employees, consultants, agents,
        and representatives and that such User Materials, and their use, do not
        violate any applicable laws, including, without limitation, any
        intellectual property laws. You agree that the burden of proving that any
        User Materials do not violate any laws or third party rights rests solely
        with you.
    </p>
    <p>
        By posting, uploading, displaying, performing, transmitting, sharing or
        otherwise distributing any User Materials to this Site or the Services, you
        agree that RDTM may, with or without notice to you, and without any
        liability of any kind whatsoever, remove or render inaccessible such User
        Materials from the Site or the Services at any time and for any reason,
        including, without limitation, account inactivity or compliance with law.
    </p>
    <p>
        If you choose to transmit, share or otherwise distribute your User
        Materials to third parties through the Site or the Services, you represent
        and warrant that you will do so in compliance with all laws, including,
        without limitation, CASL and any other laws governing the transmission of
        commercial electronic messages or similar.
    </p>
    <p>
        You acknowledge and agree that the Service is not intended for document
        retention purposes, that any documents stored on the Site are stored solely
        for the purpose of facilitating provision of the Services and they are not
        intended to be compliant with RECA or AREA storage system regulations or
        by-laws, and that RDTM has no responsibility to retain any information you
        provide it. You are solely responsible for your User Materials. You must
        comply with statutory requirements for document storage as may change from time to time. You are
        solely responsible for making and keeping backup copies of your User
        Materials and for applying the appropriate level of access rights to same.
        RDTM shall not be responsible or liable for the loss, deletion or accuracy
        of any User Materials, the failure to store, transmit, encrypt (or
        otherwise secure) or receive any User Materials, or the storage,
        transmission, encryption (or other security) or receipt of any other
        communication involving the use of this Site or the Services. We may, in
        our sole discretion, limit the data volume of User Materials that you post
        or upload to the Site or Services, and may charge a fee for the storage of
        greater volumes of data, as discussed above. Further, we may, in case of
        Account inactivity, delete your User Materials. <strong></strong>
    </p>
    <p>
        You are solely responsible for obtaining and maintaining any necessary
        consents or permissions from any third party whose information may be
        collected, recorded, processed, stored, used, disclosed, transferred,
        exchanged or otherwise handled as a result of, or as part of, any User
        Materials or any communications involving the use of this Site or the
        Services including without limitation that you have the consent to include
        images of each identifiable person included in the User Materials and agree
        to obtain and maintain all such consents or permissions throughout the term
        of this agreement.
    </p>
    <p>
        You agree that RDTM may disclose your User Materials where compelled or
        permitted to do so by applicable laws. To ensure that RDTM provides a high
        quality experience for you and for other users of this Site and the
        Services, you agree that RDTM or its representatives may access your
        Account and all information and any records related thereto (including User
        Materials) on a case-by-case basis to investigate complaints or allegations
        of abuse, infringement of third party rights, or other unauthorized uses of
        this Site or the Services, and you agree to give RDTM full access to your
        Account and all information and records related thereto without the need
        for additional authorization and without any claim to privacy or other
        rights to such Account and related information and records.
    </p>
    <p>
        RDTM may not disclose the existence or occurrence of such an investigation
        unless required by law or if requested by law enforcement officials, but
        RDTM reserves the right to suspend or terminate your Account or your access
        to this Site and the Services immediately, with or without notice to you,
        and without liability to you, if RDTM believes that you have violated any
        of these Terms of Use, furnished RDTM with false or misleading information,
        or interfered with use of this Site or the Services by others.
    </p>
    <p>
        <strong>12. </strong>
        <strong>FEES FOR SERVICES</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        We provide access to the Services through this Site on a fee basis. Where
        you subscribe for an Account to use the Site and Services, you agree to
        pay, through the payment mechanism selected by you and approved by us, all
        amounts due and owing for such Services on the basis elected by you at the
        time that you subscribed. We may amend this Site, the Services and/or the
        amounts that we charge for same at any time, without prior notice. Fees do
        not include any applicable sales, use, excise, value- added or other taxes
        or governmental charges, and you shall be responsible for, and shall
        promptly pay, same. If we terminate your access to the Site or Services
        because of your breach of these Terms of Use, you shall not be entitled to
        the refund of any fees you have already paid. You agree that we may collect
        interest at the lesser of 1.5% per month (18% per annum) or the highest
        amount permitted by law on any amounts not paid when due, where relevant.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        <strong>13. </strong>
        <strong> SITE AND SERVICES PROVIDED “AS IS” </strong>
    </p>
    <p>
        THIS SITE (INCLUDING, WITHOUT LIMITATION, THE INFORMATION PUBLISHED
        THEREON) AND THE SERVICES ARE PROVIDED "AS IS" WITHOUT REPRESENTATION,
        WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
        BUT NOT LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. RDTM DOES NOT
        REPRESENT OR WARRANT THAT THIS SITE OR THE SERVICES WILL MEET YOUR
        REQUIREMENTS OR THAT THEIR USE WILL BE UNINTERRUPTED OR ERROR-FREE.
    </p>
    <p>
        <strong>14. </strong>
        <strong>LIMITATION OF LIABILITY</strong>
    </p>
    <p>
        ALL INFORMATION AND DOCUMENTATION CONTAINED ON THE SITE IS FOR
        INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE RELIED ON AS DEFINITIVE OR
        ACCURATE. WE DO NOT WARRANT THE LEGAL OR FINANCIAL ABILITY OF ANY USER TO
        ENTER INTO OR CONSUMMATE ANY TRANSACTION FOR REAL PROPERTY. WE DO NOT
        WARRANT THE ACCURACY OF THE IDENTITY OF ANY USER OF THE SITE OR SERVICES.
        WE DO NOT PROVIDE LEGAL ADVICE. WE DO NOT REPRESENT OR WARRANT THAT THE
        DOCUMENTS PROVIDED THROUGH THE SITE OR THE SERVICES HEREIN WILL HAVE A
        PARTICULAR LEGAL EFFECT IF EXECUTED. WE DO NOT REPRESENT OR WARRANT THAT A
        TRANSACTION YOU ATTEMPT TO CARRY OUT USING THE DOCUMENTS PROVIDED THROUGH
        THE SITE OR SERVICES WILL BE ENFORCEABLE. THE DOCUMENTS PROVIDED THROUGH
        THE SITE AND SERVICES HAVE BEEN PREPARED BY AND/OR LICENSED FROM AREA AND
        NOT RDTM. RDTM MAKES NO REPRESENTATION OR WARRANTY AS TO THEIR CONTENTS.
        CONSULT A LAWYER BEFORE BUYING OR SELLING REAL PROPERTY.
    </p>
    <p>
        RDTM DOES NOT TAKE RESPONSIBILITY FOR ANY INFORMATION OR CLAIM MADE BY A
        USER OF THE SITE OR THE SERVICES.
    </p>
    <p>
        YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS SITE AND THE
        SERVICES, INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION OR DOCUMENTS
        CONTAINED THEREIN
        <strong>
            . IN PARTICULAR, YOU ASSUME ALL LIABILITY AND RISK FOR ANY LOSS OF ANY
            KIND WHATSOEVER SUSTAINED BY YOU IN CONNECTION WITH THE USAGE OF THE
            SITE AND THE SERVICES, INCLUDING ANY ACTIVITIES OR TRANSACTIONS PLANNED
            OR CARRIED OUT THROUGH THE SITE OR SERVICES. FURTHER, YOU ASSUME ALL
            RESPONSIBILITY AND RISK FOR THE OUTCOME OF ANY TRANSACTION BETWEEN OR
            AMONG YOU AND ANY OTHER USER OF THE SITE OR SERVICES. YOU REPRESENT AND
            WARRANT THAT YOU WILL OBTAIN LEGAL ADVICE FROM A LAYWER BEFORE USING
            ANY DOCUMENTS PROVIDED THROUGH THE SITE OR SERVICES. YOU ACKNOWLEDGE
            THAT RDTM DOES NOT PROVIDE LEGAL ADVICE. YOU ACKNOWLEDGE THAT RDTM HAS
            NOT PREPARED THE DOCUMENTS PROVIDED THROUGH THE SITE OR SERVICES, AND
            THAT IT LICENSES THEM FROM AREA. IN THE EVENT OF ANY LOSS OR HARM
            CAUSED BY USE OF ANY DOCUMENTS PROVIDED BY THE SITE OR THE SERVICES,
            YOU AGREE TO HOLD INDEMNIFY AND HOLD HARMLESS RDTM.
        </strong>
    </p>
    <p>
        IN NO EVENT SHALL RDTM OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES,
        PARTNERS, OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE,
        EXEMPLARY, CONSEQUENTIAL OR OTHER SIMILAR DAMAGES WHATSOEVER (OR FOR ANY
        DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR
        ANY OTHER PECUNIARY LOSS IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE,
        ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE
        OR <a target="_blank" routerLink="/privacy">PRIVACY POLICY</a>, INCLUDING WITHOUT LIMITATION YOUR USE OF,
        RELIANCE UPON,
        ACCESS TO, OR EXPLOITATION OF THIS SITE, THE SERVICES, OR ANY PART THEREOF
        OR DOCUMENT PROVIDED THEREBY, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN
        IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE
        ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF
        INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE.
    </p>
    <p>
        IN EVERY EVENT, RDTM’S TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS
        OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PART OF THIS SITE AND/OR
        THE SERVICES IN ANY MANNER WHATSOEVER SHALL BE LIMITED IN THE AGGREGATE TO
        ONE HUNDRED THE VALUE OF FEES PAID BY YOU HEREUNDER DURING THE TWELVE (12)
        MONTH PERIOD PRECEDING THE DATE ON WHICH A CLAIM AROSE.
    </p>
    <p>
        THE CONSIDERATION BEING PAID HEREUNDER DOES NOT INCLUDE ANY CONSIDERATION
        FOR RDTM TO ASSUME ANY RISKS BEYOND THOSE EXPRESSLY ASSUMED HEREIN AND IF
        ANY SUCH RISKS WERE TO BE ASSUMED BY RDTM, RDTM WOULD NOT HAVE ENTERED INTO
        THIS AGREEMENT WITHOUT CHARGING SUBSTANTIALLY HIGHER FEES. YOU ACKNOWLEDGE
        AND AGREE THAT RDTM WOULD NOT PROVIDE YOU WITH ACCESS TO OR USE OF THE SITE
        OR THE SERVICES IN THE EVENT THAT YOU WERE TO RELY ON ANYTHING PROVIDED
        THEREIN AS CONSTITUTING LEGAL ADVICE. RDTM DOES NOT PROVIDE REAL ESTATE
        TRANSACTION OR LEGAL ADVICE AND YOU ACKNOWLEDGE AND AGREE THAT FEES PAID BY
        YOU TO RDTM ARE NOT FEES PAID UNDER A SOLICITOR-CLIENT OR AGENCY
        RELATIONSHIP.
    </p>
    <p>
        <strong>15. </strong>
        <strong>INDEMNIFICATION </strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        IN ADDITION TO ANY OTHER INDEMNITY SET OUT HEREIN, YOU AGREE TO, UPON
        REQUEST, INDEMNIFY AND HOLD US HARMLESS FROM ANY AND ALL LIABILITIES,
        CLAIMS, LOSSES AND EXPENSES, INCLUDING ATTORNEYS' AND OTHER FEES AND COSTS,
        WHICH ARISE DIRECTLY OR INDIRECTLY FROM ANY BREACH OF THESE TERMS OF USE
        FOR WHICH YOU ARE RESPONSIBLE.
    </p>
    <p>
        <a name="OLE_LINK2"></a>
        <a name="OLE_LINK1">
            <strong>16. </strong>
            <strong>TERM &amp; TERMINATION </strong>
        </a>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        You acknowledge and agree that access to this Site and the Services may
        not be available from time to time, may be amended, revised, replaced,
        suspended or terminated in whole or in part at any time and without
        notice, and that RDTM shall not, in any event, be responsible to you in
        any way should you be unable to access this Site and the Service at any
        time or from time to time.
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        RDTM may, at its sole discretion, at any time and from time to time,
        without notice, suspend your right to use this Site and the Services
        and/or terminate these Terms of Use (including any of the licenses
        granted hereunder). Without limiting the foregoing, these Terms of Use
        and the licenses granted herein shall automatically terminate, without
        notice, if you materially fail to perform or comply with these Terms of
        Use or any provision hereof. Upon termination of these Terms of Use,
        you shall immediately cease and desist from all use of this Site and
        the Services.

    </p>

    <p>
        Sections 11 to 20 will survive any termination or expiry of these Terms
        of Use.
    </p>
    <p>
        <strong>17. </strong>
        <strong>NOTICE</strong>
    </p>
    <p>
        <strong></strong>
    </p>
    <p>
        If you need to contact us regarding this Site, the Services or these Terms
        of Use, please e-mail us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        .<strong> </strong>You can also contact us via mail addressed to:
    </p>
    <p>
        <strong>REAL DEAL TRANSACTION MANAGEMENT INC.</strong><br>
        2126 – 7 Avenue South<br>
        Lethbridge, Alberta, Canada<br>
        T1J 1M7<br>
        Attention: Legal Department
    </p>

<p>
    You acknowledge that RDTM may remove, delete or edit any post, information
    or content provided by you for any reason whatsoever, including
    non-compliance with the foregoing.
</p>
<p>
    <strong></strong>
</p>
<p>
    WE CANNOT AND DO NOT ASSURE THAT OTHER USERS ARE OR WILL BE COMPLYING WITH
    THE FOREGOING CODE OF CONDUCT OR ANY OTHER PROVISIONS OF THESE TERMS OF
    USE, AND, AS BETWEEN YOU AND US, YOU HEREBY ASSUME ALL RISK OF HARM OR
    INJURY RESULTING FROM ANY SUCH LACK OF COMPLIANCE.
</p>
<p>
    <strong>6. </strong>
    <strong>AVAILABILITY</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    While we endeavour to keep downtime to a minimum, we can't promise or
    warrant that this Site or the Services will be uninterrupted, secure or
    error-free. We reserve the right to interrupt or suspend this Site or the
    Services, or any part thereof, with or without prior notice for any reason.
</p>
<p>
    <strong>7. </strong>
    <strong>ACCESS TO THE INTERNET</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    You are solely responsible for obtaining and maintaining all Internet,
    computer hardware and other equipment needed to access and use this Site
    and the Services and you agree that you shall be solely responsible for all
    charges and fee related thereto.
</p>
<h3>
</h3>
<p>
    <strong>8. </strong>
    <strong>CHANGES TO SITE AND CONTENT</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    RDTM may revise, supplement or delete information, services and/or the
    resources contained in this Site and the Services and reserves the right to
    make such changes without prior notification to past, current or
    prospective visitors. For the avoidance of doubt, we reserve the right at
    any time to change: (i) the terms and conditions of these Terms of Use;
    (ii) this Site or the Services, including terminating, eliminating,
    supplementing, modifying, adding or discontinuing any content or feature or
    data or service on or available through this Site or the Services or the
    hours that they are available; (iii) any fees or charges, if any, related
    to the use of this Site or the Services; and (iv) the equipment, hardware
    or software required to use and access this Site or the Services.
</p>
<p>
    Any changes we make to these Terms of Use will be effective immediately
    upon notice, which we may provide by any means including, without
    limitation, posting on this website. Your continued use of this Site or the
    Services after such notice will be deemed acceptance of such changes. Be
    sure to return to this Site periodically to ensure you are familiar with
    the most current version of these Terms of Use.
</p>
<p>
    <strong>9. </strong>
    <strong>LINKED WEBSITES</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    This Site or the Services may provide links to third party websites for
    your convenience only. In addition, the Site or Services may interact with
    third party sites for the purpose of verifying or authenticating you as a
    licensed real estate agent in good standing with the appropriate real
    estate board. The inclusion of these links does not imply that RDTM
    monitors or endorses these websites. RDTM does not accept any
    responsibility for such websites. RDTM shall not be responsible or liable,
    directly or indirectly, for any damage or loss caused or alleged to be
    caused by or in connection with the use of or the reliance upon any
    information, content, goods or services available on or through any third
    party websites or linked resources.
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>10. </strong>
    <strong>INTERNET SOFTWARE OR COMPUTER VIRUSES</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    Due to technical difficulties with the Internet, Internet software or
    transmission problems could produce inaccurate or incomplete copies of
    information contained on this Site or the Services. Due to the ability to
    share certain content and materials, computer viruses or other destructive
    programs may also be inadvertently downloaded from this Site or the
    Services.
</p>
<p>
    RDTM shall not be responsible or liable for any software, computer viruses
    or other destructive, harmful or disruptive files or programs that may
    infect or otherwise impact your use of your computer equipment or other
    property on account of your access to, use of, or browsing on this Site or
    the Services or other content from this Site. RDTM suggests that you take
    prudent action to protect yourself, such as installing appropriate
    anti-virus or other protective software.
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>11. </strong>
    <strong>INTELLECTUAL PROPERTY </strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    As between you and RDTM, this Site and the Services are owned and operated
    by RDTM (or its licensors) and may be protected by intellectual property
    laws including laws relating to copyrights, trade-marks, trade-names,
    Internet domain names, notice and notice rights, and other similar rights
    under the laws of Alberta, Canada and elsewhere.
</p>
<p>
    RDTM may make certain content, software or other electronic materials
    (including all files, text, URLs, video, audio and images contained in or
generated by such materials, and accompanying data) (collectively “    <strong>RDTM Materials</strong>”) available to you from this Site or the
    Services, from time to time. If you stream or use RDTM Materials, you agree
    that such materials shall only be used in compliance with these Terms of
    Use and any additional license terms accompanying such materials. RDTM does
    not transfer either the title or the intellectual property rights to the
    RDTM Materials, and retains any and all intellectual property rights that
    it may have therein. You agree not to sell, share, redistribute, or
    reproduce the RDTM Materials. You further agree not to decompile,
    reverse-engineer, disassemble, or otherwise convert any of the RDTM
    Materials comprising software into a human-perceivable form. RDTM or its
    licensors own all related trademarks and logos, and you agree not to copy
    or use them in any manner.
</p>
<p>
    By any posting, uploading, displaying, performing, transmitting, sharing,
making available or otherwise distributing information or other content (“    <strong>User Materials</strong>”) to this Site or the Services by you or at
    your direction, you are granting, and hereby grant, RDTM, its affiliates,
    partners, officers, directors, employees, consultants, agents, and
    representatives a worldwide, irrevocable, non-exclusive, royalty-free,
    sublicensable license to use such User Materials in connection with the
    operation of this Site and the Services, including, a right to copy,
    distribute, transmit, publicly display, publicly perform, reproduce, edit,
    translate, make available, make derivative works from, and reformat such
    User Materials. You will not be compensated for any User Materials. By
    posting User Materials on this Site or the Services, you warrant and
    represent that you own title to the User Materials and any work or content
    therein that may be subject to protection by intellectual property laws, or
    are otherwise authorized by the owner of such title to post, distribute,
    display, perform, transmit, make available, or otherwise distribute User
    Materials, in compliance with the license granted herein to RDTM, its
    affiliates, partners, officers, directors, employees, consultants, agents,
    and representatives and that such User Materials, and their use, do not
    violate any applicable laws, including, without limitation, any
    intellectual property laws. You agree that the burden of proving that any
    User Materials do not violate any laws or third party rights rests solely
    with you.
</p>
<p>
    By posting, uploading, displaying, performing, transmitting, sharing or
    otherwise distributing any User Materials to this Site or the Services, you
    agree that RDTM may, with or without notice to you, and without any
    liability of any kind whatsoever, remove or render inaccessible such User
    Materials from the Site or the Services at any time and for any reason,
    including, without limitation, account inactivity or compliance with law.
</p>
<p>
    If you choose to transmit, share or otherwise distribute your User
    Materials to third parties through the Site or the Services, you represent
    and warrant that you will do so in compliance with all laws, including,
    without limitation, CASL and any other laws governing the transmission of
    commercial electronic messages or similar.
</p>
<p>
    You acknowledge and agree that the Service is not intended for document
    retention purposes, that any documents stored on the Site are stored solely
    for the purpose of facilitating provision of the Services and they are not
    intended to be compliant with RECA or AREA storage system regulations or
    by-laws, and that RDTM has no responsibility to retain any information you
    provide it. You are solely responsible for your User Materials. You must 
    comply with statutory requirements for document storage as may change from time to time. You are
    solely responsible for making and keeping backup copies of your User
    Materials and for applying the appropriate level of access rights to same.
    RDTM shall not be responsible or liable for the loss, deletion or accuracy
    of any User Materials, the failure to store, transmit, encrypt (or
    otherwise secure) or receive any User Materials, or the storage,
    transmission, encryption (or other security) or receipt of any other
    communication involving the use of this Site or the Services. We may, in
    our sole discretion, limit the data volume of User Materials that you post
    or upload to the Site or Services, and may charge a fee for the storage of
    greater volumes of data, as discussed above. Further, we may, in case of
    Account inactivity, delete your User Materials. <strong></strong>
</p>
<p>
    You are solely responsible for obtaining and maintaining any necessary
    consents or permissions from any third party whose information may be
    collected, recorded, processed, stored, used, disclosed, transferred,
    exchanged or otherwise handled as a result of, or as part of, any User
    Materials or any communications involving the use of this Site or the
    Services including without limitation that you have the consent to include
    images of each identifiable person included in the User Materials and agree
    to obtain and maintain all such consents or permissions throughout the term
    of this agreement.
</p>
<p>
    You agree that RDTM may disclose your User Materials where compelled or
    permitted to do so by applicable laws. To ensure that RDTM provides a high
    quality experience for you and for other users of this Site and the
    Services, you agree that RDTM or its representatives may access your
    Account and all information and any records related thereto (including User
    Materials) on a case-by-case basis to investigate complaints or allegations
    of abuse, infringement of third party rights, or other unauthorized uses of
    this Site or the Services, and you agree to give RDTM full access to your
    Account and all information and records related thereto without the need
    for additional authorization and without any claim to privacy or other
    rights to such Account and related information and records.
</p>
<p>
    RDTM may not disclose the existence or occurrence of such an investigation
    unless required by law or if requested by law enforcement officials, but
    RDTM reserves the right to suspend or terminate your Account or your access
    to this Site and the Services immediately, with or without notice to you,
    and without liability to you, if RDTM believes that you have violated any
    of these Terms of Use, furnished RDTM with false or misleading information,
    or interfered with use of this Site or the Services by others.
</p>
<p>
    <strong>12. </strong>
    <strong>FEES FOR SERVICES</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    We provide access to the Services through this Site on a fee basis. Where
    you subscribe for an Account to use the Site and Services, you agree to
    pay, through the payment mechanism selected by you and approved by us, all
    amounts due and owing for such Services on the basis elected by you at the
    time that you subscribed. We may amend this Site, the Services and/or the
    amounts that we charge for same at any time, without prior notice. Fees do
    not include any applicable sales, use, excise, value- added or other taxes
    or governmental charges, and you shall be responsible for, and shall
    promptly pay, same. If we terminate your access to the Site or Services
    because of your breach of these Terms of Use, you shall not be entitled to
    the refund of any fees you have already paid. You agree that we may collect
    interest at the lesser of 1.5% per month (18% per annum) or the highest
    amount permitted by law on any amounts not paid when due, where relevant.
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>13. </strong>
    <strong> SITE AND SERVICES PROVIDED “AS IS” </strong>
</p>
<p>
    THIS SITE (INCLUDING, WITHOUT LIMITATION, THE INFORMATION PUBLISHED
    THEREON) AND THE SERVICES ARE PROVIDED "AS IS" WITHOUT REPRESENTATION,
    WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
    BUT NOT LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF
    MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. RDTM DOES NOT
    REPRESENT OR WARRANT THAT THIS SITE OR THE SERVICES WILL MEET YOUR
    REQUIREMENTS OR THAT THEIR USE WILL BE UNINTERRUPTED OR ERROR-FREE.
</p>
<p>
    <strong>14. </strong>
    <strong>LIMITATION OF LIABILITY</strong>
</p>
<p>
    ALL INFORMATION AND DOCUMENTATION CONTAINED ON THE SITE IS FOR
    INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE RELIED ON AS DEFINITIVE OR
    ACCURATE. WE DO NOT WARRANT THE LEGAL OR FINANCIAL ABILITY OF ANY USER TO
    ENTER INTO OR CONSUMMATE ANY TRANSACTION FOR REAL PROPERTY. WE DO NOT
    WARRANT THE ACCURACY OF THE IDENTITY OF ANY USER OF THE SITE OR SERVICES.
    WE DO NOT PROVIDE LEGAL ADVICE. WE DO NOT REPRESENT OR WARRANT THAT THE
    DOCUMENTS PROVIDED THROUGH THE SITE OR THE SERVICES HEREIN WILL HAVE A
    PARTICULAR LEGAL EFFECT IF EXECUTED. WE DO NOT REPRESENT OR WARRANT THAT A
    TRANSACTION YOU ATTEMPT TO CARRY OUT USING THE DOCUMENTS PROVIDED THROUGH
    THE SITE OR SERVICES WILL BE ENFORCEABLE. THE DOCUMENTS PROVIDED THROUGH
    THE SITE AND SERVICES HAVE BEEN PREPARED BY AND/OR LICENSED FROM AREA AND
    NOT RDTM. RDTM MAKES NO REPRESENTATION OR WARRANTY AS TO THEIR CONTENTS.
    CONSULT A LAWYER BEFORE BUYING OR SELLING REAL PROPERTY.
</p>
<p>
    RDTM DOES NOT TAKE RESPONSIBILITY FOR ANY INFORMATION OR CLAIM MADE BY A
    USER OF THE SITE OR THE SERVICES.
</p>
<p>
    YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS SITE AND THE
    SERVICES, INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION OR DOCUMENTS
    CONTAINED THEREIN
    <strong>
        . IN PARTICULAR, YOU ASSUME ALL LIABILITY AND RISK FOR ANY LOSS OF ANY
        KIND WHATSOEVER SUSTAINED BY YOU IN CONNECTION WITH THE USAGE OF THE
        SITE AND THE SERVICES, INCLUDING ANY ACTIVITIES OR TRANSACTIONS PLANNED
        OR CARRIED OUT THROUGH THE SITE OR SERVICES. FURTHER, YOU ASSUME ALL
        RESPONSIBILITY AND RISK FOR THE OUTCOME OF ANY TRANSACTION BETWEEN OR
        AMONG YOU AND ANY OTHER USER OF THE SITE OR SERVICES. YOU REPRESENT AND
        WARRANT THAT YOU WILL OBTAIN LEGAL ADVICE FROM A LAWYER BEFORE USING
        ANY DOCUMENTS PROVIDED THROUGH THE SITE OR SERVICES. YOU ACKNOWLEDGE
        THAT RDTM DOES NOT PROVIDE LEGAL ADVICE. YOU ACKNOWLEDGE THAT RDTM HAS
        NOT PREPARED THE DOCUMENTS PROVIDED THROUGH THE SITE OR SERVICES, AND
        THAT IT LICENSES THEM FROM AREA. IN THE EVENT OF ANY LOSS OR HARM
        CAUSED BY USE OF ANY DOCUMENTS PROVIDED BY THE SITE OR THE SERVICES,
        YOU AGREE TO HOLD INDEMNIFY AND HOLD HARMLESS RDTM.
    </strong>
</p>
<p>
    IN NO EVENT SHALL RDTM OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES,
    PARTNERS, OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE,
    EXEMPLARY, CONSEQUENTIAL OR OTHER SIMILAR DAMAGES WHATSOEVER (OR FOR ANY
    DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR
    ANY OTHER PECUNIARY LOSS IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE,
    ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE
    OR <a target="_blank" routerLink="/privacy">PRIVACY POLICY</a>, INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON,
    ACCESS TO, OR EXPLOITATION OF THIS SITE, THE SERVICES, OR ANY PART THEREOF
    OR DOCUMENT PROVIDED THEREBY, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN
    IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE
    ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF
    INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE.
</p>
<p>
    IN EVERY EVENT, RDTM’S TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS
    OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PART OF THIS SITE AND/OR
    THE SERVICES IN ANY MANNER WHATSOEVER SHALL BE LIMITED IN THE AGGREGATE TO
    ONE HUNDRED THE VALUE OF FEES PAID BY YOU HEREUNDER DURING THE TWELVE (12)
    MONTH PERIOD PRECEDING THE DATE ON WHICH A CLAIM AROSE.
</p>
<p>
    THE CONSIDERATION BEING PAID HEREUNDER DOES NOT INCLUDE ANY CONSIDERATION
    FOR RDTM TO ASSUME ANY RISKS BEYOND THOSE EXPRESSLY ASSUMED HEREIN AND IF
    ANY SUCH RISKS WERE TO BE ASSUMED BY RDTM, RDTM WOULD NOT HAVE ENTERED INTO
    THIS AGREEMENT WITHOUT CHARGING SUBSTANTIALLY HIGHER FEES. YOU ACKNOWLEDGE
    AND AGREE THAT RDTM WOULD NOT PROVIDE YOU WITH ACCESS TO OR USE OF THE SITE
    OR THE SERVICES IN THE EVENT THAT YOU WERE TO RELY ON ANYTHING PROVIDED
    THEREIN AS CONSTITUTING LEGAL ADVICE. RDTM DOES NOT PROVIDE REAL ESTATE
    TRANSACTION OR LEGAL ADVICE AND YOU ACKNOWLEDGE AND AGREE THAT FEES PAID BY
    YOU TO RDTM ARE NOT FEES PAID UNDER A SOLICITOR-CLIENT OR AGENCY
    RELATIONSHIP.
</p>
<p>
    <strong>15. </strong>
    <strong>INDEMNIFICATION </strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    IN ADDITION TO ANY OTHER INDEMNITY SET OUT HEREIN, YOU AGREE TO, UPON
    REQUEST, INDEMNIFY AND HOLD US HARMLESS FROM ANY AND ALL LIABILITIES,
    CLAIMS, LOSSES AND EXPENSES, INCLUDING ATTORNEYS' AND OTHER FEES AND COSTS,
    WHICH ARISE DIRECTLY OR INDIRECTLY FROM ANY BREACH OF THESE TERMS OF USE
    FOR WHICH YOU ARE RESPONSIBLE.
</p>
<p>
    <a name="OLE_LINK2"></a>
    <a name="OLE_LINK1">
        <strong>16. </strong>
        <strong>TERM &amp; TERMINATION </strong>
    </a>
</p>
<p>
    <strong></strong>
</p>
<p>
  You acknowledge and agree that access to this Site and the Services may
  not be available from time to time, may be amended, revised, replaced,
  suspended or terminated in whole or in part at any time and without
  notice, and that RDTM shall not, in any event, be responsible to you in
  any way should you be unable to access this Site and the Service at any
  time or from time to time.
</p>
<p>
    <strong></strong>
</p>
<p>
  RDTM may, at its sole discretion, at any time and from time to time,
  without notice, suspend your right to use this Site and the Services
  and/or terminate these Terms of Use (including any of the licenses
  granted hereunder). Without limiting the foregoing, these Terms of Use
  and the licenses granted herein shall automatically terminate, without
  notice, if you materially fail to perform or comply with these Terms of
  Use or any provision hereof. Upon termination of these Terms of Use,
  you shall immediately cease and desist from all use of this Site and
  the Services.

</p>

<p>
  Sections 11 to 20 will survive any termination or expiry of these Terms
  of Use.
</p>
<p>
    <strong>17. </strong>
    <strong>NOTICE</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    If you need to contact us regarding this Site, the Services or these Terms
    of Use, please e-mail us at
    <a href="mailto:support@dealsimple.ca" target="_blank">
        support@dealsimple.ca
    </a>
    .<strong> </strong>You can also contact us via mail addressed to:
</p>
<p>
    <strong>REAL DEAL TRANSACTION MANAGEMENT INC.</strong><br>
    2126 – 7 Avenue South<br>
    Lethbridge, Alberta, Canada<br>
    T1J 1M7<br>
    Attention: Legal Department
</p>

<p>
    <strong>18. </strong>
    <strong>YOUR COMMENTS </strong>
</p>
<p>
    <strong></strong>
</p>
<p>
Feel free to email your comments, suggestions and feedback ("    <strong>Comments</strong>") to RDTM at the e-mail addresses provided in
    this Site or the Services.
</p>
<p>
    You may also contact us through the DealSimple messaging service/icon
    located at the bottom of the Site once you are logged in. This feature is
    delivered to you as part of the Site and Services under your Account in
    order to provide you with assistance from our support team.
</p>
<p>
    However, the Internet is not a fully secure medium and any communication
    may be lost, intercepted or altered. RDTM is not liable for any damages
    related to communications to or from this Site or the Services. You agree
    with respect to any Comments provided by you to us, via e-mail or
    otherwise, that: (i) RDTM has no obligation concerning such Comments; (ii)
    such Comments are non-confidential; (iii) RDTM may use, disclose,
    distribute or copy such Comments and use any ideas, concepts or know-how
    contained in such Comments for any purpose and without restriction or
    obligation to you (or any third party); and (iv) such Comments are
    truthful, do not violate the legal rights of others, and are not the
    property of others. <strong></strong>
</p>
<p>
    <strong>19. </strong>
    <strong>EXPORT CONTROL AND JURISDICTION</strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    This Site, the Services, and RDTM Materials are subject to Canadian and
    United States (U.S.) export controls. No materials may be downloaded or
    otherwise exported or re-exported (i) into any country to which the Canada
    or the U.S. has embargoed goods; or (ii) to anyone on the U.S. Treasury
    Department's list of Specially Designated Nationals or the U.S. Commerce
    Department's Table of Deny Orders. By downloading or using any materials,
    you represent and warrant that you are not located in, under the control
    of, or a national or resident of any such country or on any such list.
</p>
<p>
    If you choose to access the Site from locations other than Canada and the
    United States, you do so at your own initiative and at your own risk.
    Access to or use of the Site is not permitted where such access or use is
    prohibited by law. You represent and warrant that you will comply with
    local laws in your jurisdiction to the extent such laws are applicable. You
    agree that you are solely responsible for such compliance. YOU AGREE TO
    INDEMNIFY AND HOLD HARMLESS APMC FOR ANY LIABILITY, CLAIM, LOSS OR EXPENSE,
    INCLUDING ATTORNEY FEES, ARISING DIRECTLY OR INDIRECTLY AS A RESULT OF ANY
    BREACH OF THE PROVISIONS OF THIS SECTION.
</p>
<p>
    We reserve the right to limit the availability of the Site and/or the
    provision of any of the Services, materials, or other products described or
    offered thereon to any person, geographic area, or jurisdiction we so
    desire, at any time and in our sole discretion, and to limit the quantities
of any such Services, materials, or other product provided.    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>20. COMMERCIAL ELECTRONIC MESSAGES</strong>
</p>
<p>
    Under CASL, we may require your consent to receive "Commercial Electronic
Messages" (including email) from us. A “<a
        id="_anchor_1"
        href="https://laws-lois.justice.gc.ca/eng/acts/e-1.6/page-1.html"
        name="_msoanchor_1"
        target="_blank"
    >Commercial Electronic Message</a>”
     is defined to include “an electronic message that, having regard to the
    content of the message, the hyperlinks in the message to content on a
    website or other database, or the contact information contained in the
    message, it would be reasonable to conclude has as its purpose, or one of
    its purposes, to encourage participation in a commercial activity
</p>
<p>
    By consenting to receive emails from DealSimple by opting-in on the
    DealSimple ‘register’ page, you consent to receive communications about
    marketing and promotional emails through the email address you provided for
    your DealSimple Account. You may unsubscribe from the DealSimple
    promotional email list at any time by clicking the “unsubscribe” link at
    the bottom of the email received or by contacting us at
    <a href="mailto:support@dealsimple.ca" target="_blank">
        support@dealsimple.ca
    </a>
    . Unsubscribing from this email list does not unsubscribe you from the
    Service, nor does it delete your Account. Even if you unsubscribe, we may
    continue to send you electronic communications in compliance with the
    applicable legislation. Should you choose to unsubscribe from factual
    notice emails, you may no longer have access to the transactional real
    estate communications that form the core Services of this Site and you may
    not be able to communicate with your clients. Additionally, you may not
    receive important notifications about the Service.
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>21. MISCELLANEOUS </strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    These Terms of Use is governed by and construed in accordance with the laws
    of the Province of Alberta, Canada, without regards to its principles of
    conflicts of law. Subject to the obligation to arbitrate below, you agree
    to the exclusive personal jurisdiction of the courts located in Calgary,
    Alberta, Canada, and waive any jurisdictional, venue, or inconvenient forum
    objections to such courts.<strong> </strong>
</p>
<p>
    Except where prohibited by applicable law, any controversy, claim or
    dispute arising out of or relating to these Terms of Use, the Site or the
    Services or the relationship which results from these Terms of Use,
    including without limitation, the performance, breach, enforcement,
    existence or validity of the matters provided for in these Terms of Use or
    your receipt and use of the Site or Services, which cannot be amicably
    resolved, (collectively, a "<strong>Claim</strong>"), will be referred to
    and finally settled (to the exclusion of the courts) by private and
    confidential binding arbitration before a single arbitrator held in
    Calgary, Alberta in English and in accordance with the UNCITRAL Arbitration
    Rules, except as such rules are modified or waived herein. Although the
    appointing authority shall be a Justice of the Alberta Court of Queen's
    Bench, the arbitrator will be a person who is legally trained and who has
    experience in the information technology field in Canada or the United
    States of America and is independent of either party. Any such Claim will
    be arbitrated on an individual basis, and will not be consolidated in any
    arbitration with any claim, controversy or dispute of any other party.
    Except where prohibited by applicable law, you agree to: (i) waive any
    right you may have to commence or participate in any class action against
    RDTM relating to any Claim; (ii) opt out of any class proceedings against
    RDTM; and (iii) waive, to the fullest extent permitted by law, any right of
    appeal. Notwithstanding the foregoing, RDTM reserves the right to pursue
    the protection of intellectual property rights and confidential information
    through injunctive or other equitable relief through the courts.
</p>
<p>
    If any provision of these Terms of Use is found to be unlawful, void, or
    for any reason unenforceable, then that provision shall be deemed severable
    from these Terms of Use and shall not affect the validity and
    enforceability of any remaining provisions.
</p>
<p>
These Terms of Use (which incorporate by reference our    <em><a target="_blank" routerLink="/privacy">Privacy Policy</a></em> and the terms of any Subscription Agreement) set
    out the entire agreement between us relating to the subject matter herein
    and supersede any all prior or contemporaneous written or oral agreements
    between us. These Terms of Use are not assignable, transferable or
    sub-licensable by you except with <em>RDTM’s</em> prior written consent. No
    waiver by either party of any breach or default hereunder shall be deemed
    to be a waiver of any preceding or subsequent breach or default. Any
    heading, caption or section title contained in these Terms of Use is
    inserted only as a matter of convenience and in no way defines or explains
    any section or provision hereof.
</p>
<p>
    These Terms of Use were last updated on July 16, 2019.
</p>
