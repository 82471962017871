import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';

import { PipesModule } from '../pipes/pipes.module';
import { PageLayoutModule } from '../shared/page-layout/page-layout.module';
import { PaymentComponent } from './payment.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    PageLayoutModule,
    PipesModule,
    ReactiveFormsModule,
    UiSwitchModule
  ],
  declarations: [
    PaymentComponent
  ],
  exports: [
    PaymentComponent
  ]
})
export class PaymentModule { }
