import { FileRef } from './../forms/classes';
export const NEW_OFFER_STARTED: string = '__NEW__OFFER__FILE__NAME__';

export enum DealFileSection {
  Contracts = 0,
  Attachments,
  CRGs,
  Fintracs,
}

export class ConveyancingFile {
  File: FileRef;
  Section: DealFileSection;
  constructor(init?: Partial<ConveyancingFile>) {
    Object.assign(this, init);
  }
}