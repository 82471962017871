import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ShowHideService } from 'ngx-show-hide-password';

@Component({
  selector: 'app-password-form-input',
  templateUrl: './password-form-input.component.html'
})
export class PasswordFormInputComponent implements OnInit {

  @Input() ParentForm: UntypedFormGroup;

  @Input() GivenFormControlName: string;

  @Input() GivenId: string;

  numOfCharsNewConfirm: number;
  allowShowPassword: boolean;

  constructor(private showHideService: ShowHideService) { }

  ngOnInit() {
    this.allowShowPassword = true;
    this.numOfCharsNewConfirm = 0;
  }

  checkForAutofill(value: string, id: string) {

    if (value.length === 0) {
      this.allowShowPassword = true;
    } else if (value.length !== this.numOfCharsNewConfirm + 1 &&
      value.length !== this.numOfCharsNewConfirm - 1 &&
      value.length !== this.numOfCharsNewConfirm) {
      this.allowShowPassword = false;
      this.showHideService.setShow(id, false);
    }

    this.numOfCharsNewConfirm = value.length;
  }

}
