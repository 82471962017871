import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { StripeService } from '../../services/stripe.service';
import ArrayUtils from '../../utils/ArrayUtils';

@Component({
  selector: 'app-extend-free-trial',
  templateUrl: './extend-free-trial.component.html'
})
export class ExtendFreeTrialComponent implements OnInit {
  @ViewChild('extension') extensionDD: ElementRef;
  @Input() userId: string;
  trialEndDate: Date = undefined;
  arrNumbers = ArrayUtils.generateNumberArray(730);
  showForm = false;
  number: number;

  constructor(private svc: StripeService, private app: AppLayoutComponent) { }

  ngOnInit(): void {
    this.svc.FreeTrialCheck(this.userId)
      .subscribe(
        response => {
          if (!response) { return; }
          this.trialEndDate = response.EndDate;
          this.arrNumbers = ArrayUtils.generateNumberArray(response.MaxNumDays);
          this.showForm = true;
        },
        error => this.app.ShowErrorMessage(error)
      );
  }
  UpdateTrial(value: number) {
    if (this.arrNumbers.length === 0) {
      this.app.ShowErrorMessage('Sorry, this subscription has reached the maximum trial extension...');
      return;
    }
    this.svc.ExtendFreeTrial(this.userId, value)
      .subscribe(
        response => {
          this.trialEndDate = response.EndDate;
          this.arrNumbers = ArrayUtils.generateNumberArray(response.MaxNumDays);
        },
        error => this.app.ShowErrorMessage(error),
        () => this.extensionDD.nativeElement.value = 1
      );
  }
}
