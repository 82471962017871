import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserPaymentData } from '../../classes/classes';
import { StripeService } from '../../services/stripe.service';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { ActivatedRoute } from '@angular/router';
import GuidUtils from '../../utils/GuidUtils';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html'
})
export class PaymentMethodsComponent implements OnInit {

  @Input()
  paymentMethods: UserPaymentData[];

  @Input()
  app: AppLayoutComponent;

  @Output()
  reloadData = new EventEmitter<boolean>();

  userId: string = null;

  constructor(private svc: StripeService, private route: ActivatedRoute) {
    this.userId = this.route.snapshot.params['id'] || GuidUtils.EmptyGuid();
  }

  ngOnInit() {
  }
  RemovePaymentMethod(card: UserPaymentData) {
    this.svc.RemovePaymentMethod(this.userId, card.CardId)
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error removing payment method... Try again later');
          } else {
            this.app.ShowSuccessMessage('Successfully removed payment method');
            this.reloadData.emit(true);
          }
        },
        error => {
          console.log(error);
          this.app.ShowErrorMessage('Error removing payment method... Try again later');
        }
      );
  }
  acceptDelete(card: any, db: any, dcb: any, dd: any) {
    this.toggleDelete(db, dcb, dd);
    this.RemovePaymentMethod(card);
  }
  toggleDelete(db: any, dcb: any, dd: any) {
    db.hidden = !db.hidden;
    dcb.hidden = !dcb.hidden;
    dd.hidden = !dd.hidden;
  }
  UpdateDefaultPaymentMethod(card: UserPaymentData) {
    this.svc.UpdateDefaultPaymentMethod(this.userId, card.CardId)
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error updating default payment method... Try again later');
          } else {
            this.app.ShowSuccessMessage('Successfully updated default payment method');
            this.reloadData.emit(true);
          }
        },
        error => {
          console.log(error);
          this.app.ShowErrorMessage('Error updating default payment method... Try again later');
        }
      );
  }
  EditPaymentMethod(cardId: string) {
    this.app.router.navigate([this.app.router.url, 'paymethod', cardId]);
  }
  Route(route: string) {
    this.app.router.navigate([route]);
  }
}
