export enum PasswordRecoverySteps {
    EmailStep = 0,
    NoUserStep = 1,
    InactiveUserStep = 2,
    MobileNumberStep = 3,
    PasscodeStep = 4,
    PasswordStep = 5,
    BackToLoginStep = 6,
    TooManyAttempts = 7,
    TimeExpired = 8
}
export class PasswordRecoveryData {
    MobilePhone: string;
    Email: string;
    Passcode: string;
    Password: string;
    ConfirmPassword: string;
    Step: PasswordRecoverySteps = PasswordRecoverySteps.EmailStep;
    ErrorMessage: string;
    Success: boolean;
    ProtectedMobilePhone: string;
    UserId: string;
    IsMobileVerification: boolean;
}
