import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyValuePair } from '../../classes/form';
import GuidUtils from '../../utils/GuidUtils';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonGroupComponent
    }
  ]
})

export class RadioButtonGroupComponent implements ControlValueAccessor {

  @Output() valueChange = new EventEmitter<any>();
  @Input() options: KeyValuePair[] = [{ "key": "Yes", "value": 1 }, { "key": "No", "value": 0 }]; // default as most are yes and no
  @Input() useBooleanOptions: boolean = false;

  value: string = null;
  id: string = GuidUtils.GenerateGuid();

  onChange = (value: string) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;

  ngOnInit() {
    if (this.useBooleanOptions) {
      this.options = [{ "key": "Yes", "value": true }, { "key": "No", "value": false }];
    }
  }

  public writeValue(value: string): void {
    this.value = value;
  }
  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public valueUpdated(event: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = event;
      this.valueChange.emit(this.value);
      this.onChange(this.value);
    }
  }
}
