import { UntypedFormControl } from '@angular/forms';
import { Contract, ContractType, SubContractType } from './../../classes/contract';
import { AttachmentMode } from './../../attachment/classes';
import { DealFileSection, NEW_OFFER_STARTED } from './../classes';
import { FileRef, FileRefType } from './../../forms/classes';
import { Component, OnInit, Input } from '@angular/core';
import { ContractDealFilesComponent } from './contract-deal-files.component';
import ContractFormUtils from '../../utils/form/ContractFormUtils';
import DynamicFormUtils from '../../utils/form/DynamicFormUtils';

@Component({
  selector: 'app-deal-file-section',
  templateUrl: './deal-file-section.component.html',
  styles: [`
    .contract {
      transition: all 200ms ease;
    }

    .contract:hover {
      margin-top: -0.25rem;
      margin-bottom: 0.25rem;
    }

    .status-indicator {
      height: 30px;
      width: 100%;
    }
    
    .status-indicator>div {
      height: 50%;
      border-bottom: 2px dashed #00afef;
    }
  `]
})
export class DealFileSectionComponent implements OnInit {
  @Input() deal: ContractDealFilesComponent;
  @Input() section: DealFileSection;
  @Input() df: DynamicFormUtils;

  string: string;
  _dfs = DealFileSection;
  _mode = AttachmentMode;
  _ct = ContractType;
  _fr = FileRefType;
  _pg = 1;
  _cfu = ContractFormUtils;
  _statusIndicatorName = NEW_OFFER_STARTED;

  files: FileRef[] = [];
  filenames: Map<string, UntypedFormControl> = new Map<string, UntypedFormControl>();

  constructor() { }

  ngOnInit(): void {
    // Don't show the trade reports in the attachments section
    if (this.section == this._dfs.Attachments) {
      this.files = this.deal.dealFiles.get(this.section).filter(x => x.FileRefType != FileRefType.TradeReport);
    } else {
      this.files = this.deal.dealFiles.get(this.section);
    }
  }

  public GetFilenameFormControl(file: FileRef): UntypedFormControl {
    if (!this.filenames.has(file.FileRefId))
      this.filenames.set(file.FileRefId, new UntypedFormControl());
    return this.filenames.get(file.FileRefId);
  }

  public RemoveFilename(file: FileRef) {
    this.filenames.delete(file.FileRefId);
  }

  public GetFilename(file: FileRef): string {
    return this.filenames.get(file.FileRefId)?.value;
  }

  public OpenFile(file: FileRef) {
    if (this.deal.GetMode(file) === AttachmentMode.Ready)
      this.deal.OpenFile(file);
  }

  public GetFileName(file: FileRef): string {
    if (this.section != this._dfs.Contracts)
      return file.Filename;
    const summary = this.GetContractSummaryText(this.GetContractForFileRef(file));
    return summary;
  }

  private GetContractSummaryText(c: Contract): string {
    if (c === null) { return ''; }
    let value = c.Rev === 0 ?
      `Original - ${ContractFormUtils.GetContractName(c)}` :
      `${c.Rev} - ${ContractFormUtils.GetContractName(c)}`;
    if (c.IsCurrent) { value += ' (Current)'; }

    // IF SOMETHING IN STATUS RESPONSE, BE CAREFUL NOT TO SHOW THE STATUS IN THE HISTORY LIST
    if (c.StatusResponse !== null) {
      if (!this.df.app.isAgentResponsible(c)) {
        return value + ' - Awaiting Response';
      } else {
        return `${value} - ${ContractFormUtils.GetContractStatus(c.StatusResponse, c.ContractType)}
          ${ContractFormUtils.GetContractStatus(c.Status, c.ContractType)}`;
      }
    } else if (c.IsCurrent && c.SellerSigns !== null) {
      if (!c.NextContractId && !this.df.app.isAgentResponsible(c)) { return value + ' - Awaiting Response'; }
    }

    // IF PRE-OFFER CONTRACT DO NOT SHOW STATUS
    if (!c.IsCurrent && c.Rev === 0 && c.SubContractType !== SubContractType.Base) {
      return value;
    }

    return `${value} - ${ContractFormUtils.GetContractStatus(c.Status, c.ContractType)}`;
  }

  private GetContractForFileRef(file: FileRef): Contract {
    if (this.df.contracts === undefined) { return null; }
    return this.df.contracts.find(x => x.ContractId == file.ParentId);
  }
}
