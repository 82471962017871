export class Alert {

    Type: string;
    Message: string;
    constructor(type: string, message: string) {
        this.Type = type;
        this.Message = message;
    }
}

export class AlertType {
    public static SUCCESS = 'success';
    public static INFO = 'info';
    public static WARNING = 'warning';
    public static DANGER = 'danger';
    public static PRIMARY = 'primary';
    public static SECONDARY = 'secondary';
    public static LIGHT = 'light';
    public static DARK = 'dark';
}
