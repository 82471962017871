<div class="row mb-2">
  <div class="col-12">
    <input [(ngModel)]="search" name="search" type="text" class="form-control" placeholder="Search for a brokerage..."
      autocomplete="off" (keyup)="searchText.next($any($event).target.value)" maxlength="30" />
  </div>
</div>

<div class="row mb-3" *ngIf="count > 0">
  <div class="col-12">
      <span *ngIf="count === 1" class="badge text-bg-dark">{{ count }} brokerage found</span>
      <span *ngIf="count > 1" class="badge text-bg-dark">{{ count }} brokerages found</span>
      <virtual-scroller #scroll [items]="brokerages">
          <div *ngFor="let brokerage of scroll.viewPortItems" class="col-lg-4 col-md-6 col-12">
            <app-brokerage-tile [data]="brokerage" [group]="groupAnimations" (selected)="brokerage_Selected($event)">
            </app-brokerage-tile>
          </div>
      </virtual-scroller>
  </div>
</div>

<div class="alert alert-warning" role="alert" *ngIf="count === 0">
  <div class="row">
      <div class="col-12">
        The brokerage you were looking for was not found.
      </div>
  </div>
</div>

<div class="row mb-3" *ngIf="search?.length > 0">
  <div class="col">
      <button type="button" class="btn btn-outline-secondary" (click)="ResetSearch()">Reset
      </button>
  </div>
</div>