import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Pipe, PipeTransform } from '@angular/core';
import { StatusGroup } from '../../classes/classes';
import { Contract, ContractStatus } from '../../classes/contract';
import DateUtils from '../../utils/DateUtils';
import { ContractActionType } from '../../utils/form/ContractActionUtils';
import ContractFormUtils from '../../utils/form/ContractFormUtils';
import DynamicFormUtils from '../../utils/form/DynamicFormUtils';
import FormUtils from '../../utils/form/FormUtils';

@Component({
  selector: 'app-listing-tile',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './listing-tile.component.html'
})
export class ListingTileComponent {

  @Input() contract: Contract;
  @Input() df: DynamicFormUtils;
  @Input() hover = true;
  @Input() statusGroup = StatusGroup.Active;
  @Output() selected = new EventEmitter<Contract>();
  @Output() click = new EventEmitter<ContractActionType>();

  _cfu = ContractFormUtils;
  _fu = FormUtils;
  _du = DateUtils;
  _sg = StatusGroup;

  public IsTileDisabled(): boolean {
    if (+this.statusGroup === StatusGroup.Active) { return false; }
    return !DateUtils.IsUTCDateGTNow(this.contract.AgreementStartUtc);
  }
}

@Pipe({
  name: 'sellerType'
})
export class SellerTypePipe implements PipeTransform {

  transform(prop: any): string {

    if (prop.ExcSellerRep) {
      return 'ESRA';
    } else if (prop.CustomerAckSellerRep) {
      return 'SCAFA';
    }

    return null;
   }
}
