import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Contract, ContractStatus, ContractType, PaperType, SubContractType } from '../../classes/contract';
import DateUtils from '../DateUtils';
import { ContractTemplate } from './../../classes/contract';

export default class ContractFormUtils {

  // return 'Reject Waiting for Submission';
  public static GetContractStatusByContract(c: Contract, hasConditions = false): string {
    if (c.StatusResponse) {
      const response = `${this.GetContractStatus(c.StatusResponse, c.ContractType, hasConditions)}`.toUpperCase();
      return `${response} - ${this.GetContractStatus(c.Status, c.ContractType)}`;
    }

    if (c.NextContractId) {
      const status = `${this.GetContractStatus(c.Status, c.ContractType, hasConditions)}`.toUpperCase();
      return `${status} - Changes Pending`;
    }

    // PENDING TERMINATION STATUS HANDLING
    if (([SubContractType.Termination, SubContractType.BuyerVoid, SubContractType.SellerVoid].includes(c.SubContractType)) &&
      c.Status !== ContractStatus.Terminated) {
      const status = `${this.GetContractStatus(c.Status, c.ContractType, hasConditions)}`.toUpperCase();
      return `${status} - ${this.GetSubContractText(c.SubContractType)} Pending`;
    }

    return this.GetContractStatus(c.Status, c.ContractType, hasConditions).toUpperCase();
  }

  private static GetSubContractText(subContractType: SubContractType): string {
    switch (subContractType) {
      case SubContractType.Amendment:
      case SubContractType.Counter:
      case SubContractType.Waive:
      case SubContractType.Termination:
        return SubContractType[subContractType];
      case SubContractType.BuyerVoid:
        return 'Buyer Void';
      case SubContractType.SellerVoid:
        return 'Seller Void';
      case SubContractType.SellerNotice:
        return 'Seller Notice';
      case SubContractType.CondoNotice:
        return 'Condo Notice';
    }

    return '';
  }

  public static GetContractStatus(status: ContractStatus, contractType: ContractType, hasConditions: boolean = false): string {
    switch (status) {
      case ContractStatus.InProgress:
        return 'In Progress';
      case ContractStatus.AwaitingSignatures:
        return 'Waiting for Signatures';
      case ContractStatus.Active:
        return 'Active';
      case ContractStatus.AwaitingSubmission:
        return 'Awaiting Submission';
      case ContractStatus.Submitted:
        return 'New';
      case ContractStatus.Amended:
        return 'Amended';
      case ContractStatus.Accepted:
        return hasConditions ? 'Accepted [Conditional]' : 'Accepted';
      case ContractStatus.Firm:
        return 'Firm Offer';
      case ContractStatus.Closed:
        return 'Closed Offer';
      case ContractStatus.Rejected:
        return 'Rejected';
      case ContractStatus.Countered:
        return 'Countered';
      case ContractStatus.ConditionsWaived:
        return 'Conditions Waived';
      case ContractStatus.ConditionsNonWaived:
        return 'Fallen Through';
      case ContractStatus.Terminated:
        return contractType === ContractType.Offer ? 'Fallen Through' : 'Terminated';
      case ContractStatus.ExpiryPending:
        return 'Expiry Pending';
      case ContractStatus.Expired:
        return 'Expired';
    }
  }

  public static GetContractHomePath(type: ContractType): string {
    switch (type) {
      case ContractType.Buyer:
        return '/buyers';
      case ContractType.Listing:
        return '/sellers';
      case ContractType.Offer:
        return '/offers';
      default:
        return null;
    }
  }

  static getContractSignClass(status: ContractStatus): string {
    switch (status) {
      case ContractStatus.Active:
      case ContractStatus.Accepted:
      case ContractStatus.Firm:
      case ContractStatus.Closed:
        return 'text-success';
      case ContractStatus.Terminated:
      case ContractStatus.Rejected:
      case ContractStatus.Expired:
      case ContractStatus.ConditionsNonWaived:
        return 'text-danger';
      default:
        return 'text-warning';
    }
  }

  static getSubStatus(status: ContractStatus): string {
    switch (status) {
      case ContractStatus.InPlay:
        return 'Offer In Play';
      case ContractStatus.Accepted:
        return 'Accepted Offer';
      case ContractStatus.Firm:
        return 'Firm Offer';
      default:
        return '';
    }
  }

  static CleanupContractDates(data: Contract, dataFromAPI = false): Contract {
    data.AgreementStart = DateUtils.FixDate(data.AgreementStart, dataFromAPI);
    data.AgreementEnd = DateUtils.FixDate(data.AgreementEnd, dataFromAPI);
    data.TermEffectiveDate = DateUtils.FixDate(data.TermEffectiveDate, dataFromAPI);
    data.AmendEffectiveDate = DateUtils.FixDate(data.AmendEffectiveDate, dataFromAPI);

    return data;
  }

  static GetContractName(contract: Contract | ContractTemplate): string {
    if (contract == null) { return null; }

    let value = ContractType[contract.ContractType];

    if (contract.SubContractType !== SubContractType.Base) {
      switch (contract.SubContractType) {
        case SubContractType.ARBBS:
          value = 'Agreement to Represent Both Seller and Buyer';
          break;
        case SubContractType.CA:
          value = 'Customer Acknowledgment';
          break;
        case SubContractType.BuyerVoid:
          value = 'Buyer Void';
          break;
        case SubContractType.SellerVoid:
          value = 'Seller Void';
          break;
        case SubContractType.SellerNotice:
          value = 'Seller Notice';
          break;
        case SubContractType.CondoNotice:
          value = 'Condo Notice';
          break;
        case SubContractType.CRG:
          value = 'CRG Schedule';
          break;
        default:
          value += ` ${SubContractType[contract.SubContractType]}`;
          break;
      }
    }

    if ((contract as Contract).PaperType != undefined) {
      return (contract as Contract).PaperType === PaperType.DSDigital ? value : `Paper ${value}`;
    } else {
      return value;
    }
  }

  static GetContractFileName(contract: Contract): string {
    return `[${this.GetContractNumber(contract)}-${contract.Rev}] ${this.GetContractName(contract)}.pdf`;
  }

  static GetContractNumber(contract: Contract): string {
    if (contract.PaperType === PaperType.NonDS && !contract.PaperNumber) {
      return 'Paper Number';
    }
    return contract.PaperNumber ? contract.PaperNumber : contract.Number.toString();
  }

  static initContract(fb: UntypedFormBuilder, c: Contract): UntypedFormGroup {
    const fg = fb.group(c);
    fg.addControl('Prop', fb.group({}));
    fg.addControl('FieldDatas', fb.array([]));
    fg.addControl('ContractAddendums', fb.array([]));
    fg.addControl('ContractAgents', fb.array([]));
    fg.addControl('ContractOwners', fb.array([]));
    fg.addControl('Signers', fb.array([]));
    fg.addControl('SourceAssociations', fb.array([]));
    fg.addControl('TargetAssociations', fb.array([]));
    return fg;
  }
}
