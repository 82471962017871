<div class="form-row">
  <div class="col">
    <input [(ngModel)]="code" [ngStyle]="{'border-color': couponError || customerError ? 'red' : '#ced4da'}" type="text"
      class="form-control" placeholder="Discount code" autocomplete="off" (keydown)="onKey();">
  </div>
  <div class="col-auto">
    <button type="button" class="btn btn-primary btn-block" (click)="verifyCoupon(code)">Apply</button>
  </div>
</div>
<div class="form-row">
  <div *ngIf="couponError" class="col" style="font-size: 0.75rem;">
    <span class="text-danger">Enter a valid discount code</span>
  </div>
  <div *ngIf="customerError" class="col" style="font-size: 0.75rem;">
    <span class="text-danger">Oops! You have already used this coupon...</span>
  </div>
</div>