<div class="input-group" [formGroup]="ParentForm">
  <input type="password" class="form-control" minlength="8" maxlength="40" placeholder="Password"
    autocomplete="new-password" [formControlName]="GivenFormControlName"
    (input)="checkForAutofill($any($event).target.value, $any($event).target.id)" [appShowPasswordInput]="GivenId">
  <ng-content></ng-content>
  <div *ngIf="allowShowPassword" class="input-group-append ngx-show-hide-password">

    <button class="btn btn-primary" type="button" [showHideTrigger]="GivenId">
      <i class="fas" [showHideStatus]="{show: 'fa-eye', hide: 'fa-eye-slash', id: GivenId}"></i>
    </button>
  </div>
</div>