import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import ConfigUtils from '../utils/ConfigUtils';
import { Observable } from 'rxjs';
import { TaskInstance, TileType } from '../dashboard/classes';
import { StatusData } from '../classes/classes';

@Injectable()
export class DashboardService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${ConfigUtils.GetBaseApiUrl()}/dashboard`;
  }

  public GetTasks(): Observable<TaskInstance[]> {
    return this.http.get<TaskInstance[]>(`${this.baseUrl}`);
  }

  public GetContractTasks(contractNumber: number): Observable<TaskInstance[]> {
    return this.http.get<TaskInstance[]>(`${this.baseUrl}/${contractNumber}`);
  }

  public DownloadTemplate(taskId: string, contractNumber: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/download/template/${taskId}/${contractNumber}`, options);
  }

  public DownloadCompleted(taskId: string, contractNumber: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/download/completed/${taskId}/${contractNumber}`, options);
  }

  public GetStatusData(type: TileType): Observable<StatusData[]> {
    return this.http.post<StatusData[]>(`${this.baseUrl}/getstatusdata`, type);
  }
}
