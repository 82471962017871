import { Brokerage } from './../../classes/classes';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-brokerage-tile',
  templateUrl: './brokerage-tile.component.html'
})
export class BrokerageTileComponent implements OnInit {
  @Input() data: Brokerage;
  @Input() clickable: boolean = true;
  @Input() showRemove: boolean = false;
  @Input() group: boolean = true;
  @Output() selected = new EventEmitter<Brokerage>();
  @Output() remove = new EventEmitter<Brokerage>();

  type = new Map<string, string>([
    ['CA_AB_DA', 'Designated Agency'],
    ['CA_AB_CL', 'Common Law'],
  ]);

  hover: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
