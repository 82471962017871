import { RoleType, RoleUtils } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuardConveyancer implements CanActivate {

  constructor(
    private router: Router,
    private svc: AuthService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.svc.CurrentUser) {
      if (!RoleUtils.HasRole(this.svc.CurrentUser.Role, RoleType.Conveyancer)) {
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
      }

      return true;
    }

    this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
