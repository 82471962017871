import { ModalConfig } from '../classes/classes';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from '../layout/site-layout/site-layout.component';

export class ModalUtils {

  //#region Global Vars
  public config: ModalConfig = null;
  //#endregion

  //#region Constructor
  constructor(public base: AppLayoutComponent | SiteLayoutComponent) { }

  public GetErrorMessage(error: any): string {
    if (error.error && error.error.error_description) {
      return error.error.error_description;
    } else if (error.error && error.error.Message) {
      return error.error.Message;
    } else if (error.statusText) {
      return error.statusText;
    } else {
      return error;
    }
  }

  public OnSelected(value: boolean) {
    switch (value) {
      case false:
        if (this.config.CloseFunction) { this.config.CloseFunction(); }
        break;
      case true:
        if (this.config.OkFunction) { this.config.OkFunction(); }
        break;
      default:
        if (this.config.CloseFunction) { this.config.CloseFunction(); }
        break;
    }
  }

  public ShowError(error: any, okFunc: any = null) {
    this.base.HideSpinner();
    this.ShowModal(this.GetErrorMessage(error), 'Error', okFunc);
  }

  public ShowModal(message: string, title = 'Confirmation', okFunction: any = null, closeFunction: any = null) {
    this.base.HideSpinner();
    this.base.modal.Show(new ModalConfig({
      Title: title,
      Message: message,
      OkFunction: () => {
        this.config = null;
        if (okFunction !== null) { okFunction(); }
      },
      CloseFunction: () => {
        this.config = null;
        if (closeFunction !== null) { closeFunction(); }
      }
    }));
  }

  public Show(config: ModalConfig) {
    const okFunction = config.OkFunction;
    const closeFunction = config.CloseFunction;

    this.config = config;
    this.config.OkFunction = () => {
      this.config = null;
      if (okFunction !== null) { okFunction(); }
    };
    this.config.CloseFunction = () => {
      this.config = null;
      if (closeFunction !== null) { closeFunction(); }
    };
  }
  //#endregion
}
