import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PasswordRecoveryData } from '../classes/passwordrecovery';
import ConfigUtils from '../utils/ConfigUtils';

@Injectable()
export class RecoveryService {
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = ConfigUtils.GetBaseApiUrl() + '/recovery';
  }

  post (data: PasswordRecoveryData): Observable<PasswordRecoveryData> {
    return this.http.post<PasswordRecoveryData>(this.apiUrl, data);
  }

}
