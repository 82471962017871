<div class="alert alert-secondary" role="alert">
  <div class="form-row">
    <div class="col">
      {{headerText}}
    </div>
  </div>
</div>

<div class="form-group">
  <div class="form-row pb-1 col-12">
    <div *ngFor="let file of files"
      [ngClass]="{'selected': selectedFiles.get(file.FileRefId), 'card col-12 file hover': file.Filename != _statusIndicatorName, 'status-indicator': file.Filename == _statusIndicatorName}"
      [ngStyle]="{'cursor: pointer;': file.Filename != _statusIndicatorName}">
      <div *ngIf="file.Filename != _statusIndicatorName" class="card-body p-2">
        <div class="form-row align-items-center">
          <mat-checkbox class="mt-2" (change)="selected.emit([file, $event.checked])"></mat-checkbox>
          <div class="col" (click)="viewFile.emit(file); $event.stopPropagation()">
            <span class="card-title">

              <button value="{{file.FileRefId}}" type="button" class="btn btn-link p-0">
                {{ GetFileName(file) }}
              </button>
            </span>
          </div>
          <div class="col-auto">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button ngbDropdownToggle class="btn btn-dropdown dropdown-toggle dropdownButton">
                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdown">
                <button class="dropdown-item pointerCursor" (click)="downloadFile.emit(file)">Download</button>
                <button class="dropdown-item pointerCursor" (click)="printFile.emit(file)">Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="file.Filename == _statusIndicatorName">
        <div></div>
        <p class="badge badge-primary">
          NEW OFFER STARTED
        </p>
      </div>
    </div>
  </div>
</div>