<div id="divYesNoPrompt">
    <app-yes-no-prompt *ngIf="config" [modal]="true" [config]="config" (selected)="prompt_OnValueSelected($event);">
    </app-yes-no-prompt>
</div>
<div *ngIf="selectedUser">
    <div class="row mb-3 g-3">
        <div class="col-12">
            <app-user-tile [data]="selectedUser" [clickable]="false"></app-user-tile>
        </div>
    </div>
    <div class="alert alert-secondary" role="alert">
        <div class="row">
            <div class="col">
                RESET USER PASSWORD
            </div>
        </div>
    </div>
    <div class="row mb-3 g-3">
        <div class="col-auto">
            <button type="button" class="btn btn-primary" (click)="generate_OnClick()">Generate</button>
        </div>
        <div [formGroup]="frm" class="col input-group">
            <input #newPassTB type="text" formControlName="NewPass" class="form-control" readonly>
            <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" (click)="copy_OnClick()">Copy</button>
            </div>
        </div>
    </div>
    <div id="divExtendTrial">
        <app-extend-free-trial [userId]="selectedUser.UserId"></app-extend-free-trial>
    </div>
    <div *ngIf="selectedUser.CustomerId">
        <app-billing [userId]="selectedUser.UserId"></app-billing>
    </div>
    <div *ngIf="!selectedUser.CustomerId" class="row mb-3 g-3">
        <div class="col-12">
            <i>This is a guest user... They do not have billing details, please instruct them through
                registration if you would like to manage their billling details.</i>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-auto">
        <button type="button" class="btn btn-outline-secondary" (click)="back_OnClick()">Back
        </button>
    </div>
    <div class="col">
    </div>
</div>