<ng-template #template>
    <div class="modal-header bg-primary p-0">
        <div class="col p-2">
            <h6 class="text-light m-0">Copy File Wizard</h6>
        </div>
        <div class="col-auto p-0">
            <span class="align-middle">
                <button type="button" class="btn btn-danger btn-block" (click)="close()">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                </button>
            </span>
        </div>
    </div>
    <div class="modal-body">
        <app-upload-no-qr-wizard [pdf]="false" [exp]="true" (contractSelected)="selectedContract = $event">
        </app-upload-no-qr-wizard>
        <div *ngIf="selectedContract?.ContractType === _ct.Offer" class="row pb-3">
            <div class="col">
                Would you like to <b>share</b> this file with the other party?
            </div>
            <div class="col-auto">
                <app-radio-button-group [useBooleanOptions]="true"
                    (valueChange)="public = $event">
                </app-radio-button-group>
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-12">
                <button type="button" class="btn btn-primary btn-block" (click)="confirm()">Continue</button>
            </div>
        </div>
    </div>
</ng-template>