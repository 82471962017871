import { ActivatedRoute } from '@angular/router';
import { AdminService } from './../service/admin.service';
import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { Brokerage } from './../../classes/classes';
import { Component, OnInit, NgZone } from '@angular/core';
import GuidUtils from '../../utils/GuidUtils';
import { ConfigBuilder, Graphable, GraphBrokerageBoard, GraphConfig } from '../classes';


@Component({
  selector: 'app-brokerage-selected',
  templateUrl: './brokerage-selected.component.html'
})
export class BrokerageSelectedComponent implements OnInit {
  brokerageType: Array<[string, string]> = [
    ['Designated Agency', 'CA_AB_DA'],
    ['Common Law', 'CA_AB_CL'],
  ];

  brokerageId: string = null;
  selectedBrokerage: Brokerage = null;
  brokerages: Array<Brokerage> = [];
  type: string = null;
  showSave: boolean = false;
  configbuilder = new ConfigBuilder();
  config: GraphConfig = null;

  constructor(private app: AppLayoutComponent, private svc: AdminService, private route: ActivatedRoute, public ngZone: NgZone) {
    this.brokerageId = this.route.snapshot.params['id'] || GuidUtils.EmptyGuid();
  }

  ngOnInit(): void {
    this.configbuilder
      .withRelationship({ fromField: 'BrokerageId', fromType: 'Brokerage', fromId: 'BrokerageId', toField: 'ParentId', toType: 'GraphBrokerageBoard', toId: 'Id' })
      .withRelationship({ fromField: 'Name', fromType: 'GraphBrokerageBoard', fromId: 'Id', toField: 'Board', toType: 'Brokerage', toId: 'BrokerageId' })
      .withDisplayFieldFor('Brokerage', 'Name')
      .withDisplayFieldFor('Brokerage', 'City', null, ' - ')
      .withDisplayFieldFor('Brokerage', 'MLSID')
      .withDisplayFieldFor('Brokerage', 'ContractGroup')
      .withDisplayFieldFor('GraphBrokerageBoard', 'Name')
      .withCallbackFor('Brokerage', 'Brokerage_onClick', this.Brokerage_onClick.bind(this))
      .withTooltipFor('Brokerage', 'Navigate to Brokerage');

    this.app.ShowSpinner();
    if (!this.app.cu.User.EmailAddress.includes('@rdtminc.com')) {
      this.app.router.navigate(['/']);
      return;
    }
    this.GetBrokerage(this.brokerageId);
  }

  public Brokerage_onClick(key: string) {
    this.ngZone.run(() => {
      this.app.ShowSpinner();
      this.app.router.navigate(['admin/brokerages', key]);
      this.GetBrokerage(key);
    });
  }

  public GetBrokerage(brokerageId: string): void {
    this.brokerageId = brokerageId;
    this.svc.GetBrokerage(this.brokerageId).subscribe({
      next: (response) => {
        this.selectedBrokerage = response.find(x => x.BrokerageId == this.brokerageId);
        this.brokerages = response.map(x => new Brokerage(x));
        this.type = this.selectedBrokerage.ContractGroup;
        this.configbuilder.withData(this.GetGraphData());
        this.app.HideSpinner();
      },
      error: (error) => {
        this.app.ShowErrorMessage(error.error.Message);
        this.app.router.navigate(['admin']);
        this.app.HideSpinner();
      }
    });
  }

  public type_OnChange() {
    this.showSave = this.type != this.selectedBrokerage.ContractGroup;
  }

  public saveBrokerage(brokerage: Brokerage = this.selectedBrokerage): void {
    this.app.ShowSpinner();
    this.selectedBrokerage.ContractGroup = this.type;
    this.svc.UpdateBrokerage(brokerage).subscribe({
      complete: () => {
        this.selectedBrokerage = null;
        this.GetBrokerage(this.brokerageId);
        this.showSave = false;
      },
      error: (error) => {
        this.app.ShowErrorMessage(error.error.Message);
        this.app.HideSpinner();
      }
    });
  }

  public GetGraphConfig(): GraphConfig {
    if (this.selectedBrokerage == null || this.brokerages.length == 0)
      return null;
    return this.configbuilder.build();
  }

  private GetGraphData(): Array<Graphable> {
    if (this.brokerages.length == 1)
      return this.brokerages;
    const parentId = this.selectedBrokerage.ParentBrokerageId ?? this.selectedBrokerage.BrokerageId;
    const boards = [...new Set(this.brokerages.map(x => x.Board))].map((x, i) => new GraphBrokerageBoard({ Name: x, ParentId: parentId, Id: `Board${i}` }));
    return [...this.brokerages, ...boards];
  }

}
