<div class="alert alert-danger" role="alert" *ngIf="fu.FormGroupHasError(fgForm)">
  <li *ngIf="fu.ShowControlError(_fileData)">
    You need to <b>attach a file</b>.
  </li>
  <li *ngIf="fu.ShowControlError(_country)">
    You need to <b>choose a country</b>.
  </li>
  <li *ngIf="fu.ShowControlError(_prov)">
    You need to <b>choose a province</b>.
  </li>
</div>
<div [formGroup]="fgForm">

  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col text-truncate">
        1. ATTACH MEMBER LIST SPREADSHEET
      </div>
    </div>
  </div>
  <div class="row mb-2 g-2">
    <div *ngIf="!_fileData.value" class="col-12">
      <app-file-attach [fgForm]="fgForm"></app-file-attach>
    </div>
    <div *ngIf="_fileData.value" class="col-12">
      Thank you for attaching CSV file. Please fill out the rest of the form and submit.
    </div>
  </div>

  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col text-truncate">
        2. SELECT THE COUNTRY, PROVINCE, AND BOARD OF THE DATA FILE YOU ARE UPLOADING
      </div>
    </div>
  </div>

  <!-- TODO: REMOVE FILTERS ON DROPDOWNS TO ALLOW FOR OTHER COUNTRIES/PROVS -->
  <div [formGroup]="fgForm" class="row mb-3 g-3">
    <div class="col-lg-4 col-md-6 col-12">
      Country*
      <select name="selCountry" class="form-select" formControlName="Country" autocomplete="new-password">
        <option value=""></option>
        <option *ngFor="let country of data.Countries | filter: 'Canada'" value="{{country.Country}}">
          {{ country.Country }}
        </option>
      </select>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      Province*
      <select name="selProv" class="form-select" formControlName="Province" autocomplete="new-password">
        <option value=""></option>
        <option *ngFor="let prov of data.Provinces | filter: 'Alberta'" value="{{prov.Abbrev}}">
          {{ prov.Province }}
        </option>
      </select>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      Board (if applicable)
      <input class="form-control" formControlName="Board" autocomplete="new-password" type="text">
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      Upload Type*
      <select name="selCountry" class="form-select" formControlName="UploadType" autocomplete="new-password">
        <option value=""></option>
        <option *ngFor="let type of _uploadTypes" value="{{type}}">
          {{ _dfut[type] }}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-outline-secondary" (click)="Reset()">
        Reset
      </button>
    </div>
    <div class="col-auto text-end">
      <button type="button" class="btn btn-primary" (click)="Submit_Click()">
        Submit
      </button>
    </div>
  </div>
</div>