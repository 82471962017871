import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import DateUtils from '../utils/DateUtils';

@Pipe({
  name: 'newDate'
})
export class NewDatePipe implements PipeTransform {

  transform(value: any, format = 'longDate', ordinal = false): any {

    if (!value) { return 'Not Specified'; }
    if (value.startDate) { 
      return new DatePipe('en-US').transform(value.startDate.toDate(), format);
    }
    value = DateUtils.FixDate(value);
    return new DatePipe('en-US').transform(value, format);
  }
}
