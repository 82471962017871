import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OrderPipe } from 'ngx-order-pipe';
import { Contact, Email } from '../../classes/classes';
import { PaperType, SignerType } from '../../classes/contract';
import { Signature, SignatureMode } from '../../classes/signature';
import { FileRefSignerSig } from '../../forms/classes';
import FormUtils from '../form/FormUtils';

//#region Signer
export class Signer {
  Id: string = null;
  ParentId: string = null;
  ContactId: string = null;
  IsSeller: boolean = null;
  SignerType: SignerType = SignerType.Normal;
  FirstName: string = null;
  MiddleName: string = null;
  LastName: string = null;
  FullName: string = null;
  Company: string = null;
  EmailAddress: string = null;
  SignatureRequired = true;
  IsOwner = false;
  IsSpouse: boolean = null;
  SignatureId: string = null;
  SignatureMode: SignatureMode = SignatureMode.Email;
  Contact: Contact = null;
  Signature: Signature = null;
  EmailSent: number = null;
  FileRefSignerSigs: FileRefSignerSig[] = null;
  SendEmail: boolean = true;
  Remove: boolean = false;
  NewRecord: boolean = true;

  constructor(init?: Partial<Signer>) {
    Object.assign(this, init);
  }
}
//#endregion

//#region SignerUtils
export class SignerUtils {
  //#region Public Functions
  static Create(signer: Signer, parentId: string, pt: PaperType = null): UntypedFormGroup {

    signer.ParentId = parentId;

    switch (pt) {
      case PaperType.DSDigital:
        signer.SignatureMode = SignatureMode.Email;
        break;
      case PaperType.DSPaper:
      case PaperType.NonDS:
        signer.SignatureMode = SignatureMode.Paper;
        break;
      default:
        signer.SignatureMode = SignatureMode.EmailSignPDF;
        break;
    }

    return new UntypedFormBuilder().group(signer);
  }

  static Load(fa: UntypedFormArray, signers: Signer[], parentId: string, pt: PaperType = null, isSeller: boolean = null) {

    switch (isSeller) {
      case null:
        FormUtils.clearFormArray(fa);
        break;
      case true:
      case false:
        this.RemoveBySide(fa, isSeller);
        break;
    }

    signers.forEach(signer => { fa.push(this.Create(signer, parentId, pt)); });
  }

  static RemoveBySide(fa: UntypedFormArray, isSeller = false) {
    for (let i = fa.controls.length - 1; i >= 0; i--) {
      if (fa.controls[i].get('IsSeller').value === isSeller) {
        fa.removeAt(i);
      }
    }
    fa.markAsDirty();
  }

  static GetSignersBySide(fa: UntypedFormArray, isSeller = true): AbstractControl[] {
    return fa.controls.filter(grp => grp.get('IsSeller').value === isSeller);
  }

  static GetRequiredSignersBySide(fa: UntypedFormArray, isSeller = true): AbstractControl[] {
    return fa.controls.filter(grp => 
        grp.get('IsSeller').value === isSeller && 
        grp.get('SignatureRequired').value === true);
  }

  static GetSignersByType(fa: UntypedFormArray, type: SignerType): UntypedFormArray {
    return new UntypedFormArray(fa.controls.filter(x => x.get('SignerType').value === type));
  }

  static Sort(signers: Signer[]): Signer[] {

    const owners = signers.filter(c => c.IsOwner);
    const nonOwners = new OrderPipe().transform(signers.filter(c => !c.IsOwner), ['FirstName', 'LastName'], false, true);

    nonOwners.forEach((signer: any) => {
      owners.push(signer);
    });

    return owners;
  }
  //#endregion
}
//#endregion
