<ng-template #template>
    <div id="modalTop"></div>
    <div class="modal-header">
        <h5 class="modal-title">Registration</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div [ngSwitch]="_step">
            <!-- STEP 1 - REGISTRATION DETAILS: FN, LN, EM, MO, PW, TOS, PROMO -->
            <div *ngSwitchCase="_steps.UserDetailsStep">
                <app-details [fg]="_fg" [layout]="app ? app : site" (close)="Close()" (next)="Next()"></app-details>
            </div>
            <!-- STEP 2 - CONF EMAIL -->
            <div *ngSwitchCase="_steps.EmailPasscodeStep">
                <app-passcode [fg]="_fg" (back)="Back()" (next)="Next()"></app-passcode>
            </div>
            <!-- STEP 3 - CONF MOBILE -->
            <div *ngSwitchCase="_steps.MobilePasscodeStep">
                <app-passcode [fg]="_fg" (back)="Back()" (next)="Next()"></app-passcode>
            </div>
            <!-- STEP 4 - PLAN SELECTION (GUEST ONLY) AND PAYMENT -->
            <div *ngSwitchCase="_steps.CartStep">
                <app-cart [fg]="_fg" (back)="Back()" (close)="Close()" (next)="Next()"></app-cart>
            </div>
        </div>
        <!-- NOTE: STEP 2/3 ARE OPTIONAL BASED ON MODIFICATION OF EM/MO IN STEP 1 -->
        <!-- NOTE: PLAN SELECTION SHOULD BE OPTIONAL BASED ON NO PLAN AS INPUT -->
    </div>
</ng-template>