import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PaymentModule } from '../payment/payment.module';
import { PipesModule } from '../pipes/pipes.module';
import { RegisterModule } from '../register/register.module';
import { PageLayoutModule } from '../shared/page-layout/page-layout.module';
import { SharedModule } from '../shared/shared.module';
import { BillingComponent } from './billing/billing.component';
import { EditPaymentMethodInfoComponent } from './edit-payment-method-info/edit-payment-method-info.component';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { AddPaymentMethodComponent } from './add-payment-method/add-payment-method.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbDropdownModule,
    PageLayoutModule,
    PaymentModule,
    PipesModule,
    ReactiveFormsModule,
    SharedModule,
    UiSwitchModule,
    ShowHidePasswordModule,
    RegisterModule,
  ],
  declarations: [
    AddPaymentMethodComponent,
    BillingComponent,
    EditPaymentMethodInfoComponent,
    ManageSubscriptionComponent,
    PaymentMethodsComponent,
  ],
  exports: [
    AddPaymentMethodComponent,
    BillingComponent,
    EditPaymentMethodInfoComponent,
    ManageSubscriptionComponent,
    PaymentMethodsComponent,
  ]
})
export class UserBillingModule { }
