import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import FormUtils from '../../utils/form/FormUtils';
import { RegistrationFormUtils } from '../../utils/form/RegistrationFormUtils';
import { RegistrationSteps } from '../classes';
import { RegisterService } from '../service/register.service';

@Component({
  selector: 'app-passcode',
  templateUrl: './passcode.component.html'
})
export class PasscodeComponent implements OnInit {

  @Input() fg: UntypedFormGroup;
  @Output() back = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();

  _fu = FormUtils;
  _steps = RegistrationSteps;
  _step: RegistrationSteps;
  _error = false;
  _resend = false;

  constructor(private svc: RegisterService) { }

  ngOnInit() {
    this._step = this.fg.get('Step').value;
    RegistrationFormUtils.initPasscodeStepValidators(this.fg);
  }
  formatMobile() {
    FormUtils.formatPhoneNumberCtrl(this.fg.get('User').get('MobilePhoneNumber'));
    return this.fg.get('User').get('MobilePhoneNumber').value;
  }
  ResendCode() {
    this._error = false;
    this.svc.ResendCode(this.fg.getRawValue())
      .subscribe(
        response => {
          this.fg.setValue(response);
          if (!response.Success) {
            this._error = true;
          } else {
            this._resend = true;
            setTimeout(() => {
              this._resend = false;
            }, 5000);
          }
        },
        error => console.log(error)
      );
  }
  Next() {
    this._error = false;
    if (FormUtils.FormGroupHasError(this.fg, true)) { return; }
    this.svc.PostRegistrationData(this.fg.getRawValue())
    .subscribe(
      response => {
        this.fg.setValue(response);
        this._error = !response.Success;
      },
      error => console.log(error),
      () => {
        if (!this._error) {
          this.next.emit();
        } else {
          setTimeout(() => {
            this._error = false;
          }, 5000);
        }
      }
    );
  }
  Back() {
    this.back.emit();
  }
}
