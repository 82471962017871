import { RoleType } from './../../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { User } from '../../classes/classes';
import ValidatorUtils from './ValidatorUtils';
import { LookupService } from '../../services/lookup.service';
import { RegistrationSteps, RegistrationFlowType } from '../../register/classes';
import FormUtils from './FormUtils';

export class RegistrationFormUtils {

  // tslint:disable-next-line: max-line-length
  static initRegistrationData(fb: UntypedFormBuilder, user: UntypedFormGroup, step: RegistrationSteps, rft: RegistrationFlowType, url: string, planId: string = null): UntypedFormGroup {
    return fb.group({
      Brokerage: user != null && user.get('Brokerage') != null ? user.get('Brokerage').value : null,
      ConfirmPassword: null,
      Coupon: null,
      ErrorMessage: null,
      IsEmailVerification: false,
      IsMobileVerification: false,
      Mapped: false,
      Passcode: null,
      Password: null,
      PlanId: planId,
      RegistrationFlowType: rft,
      Step: step,
      Success: false,
      Token: null,
      TOS: false,
      UrlReferrer: url,
      User: user == null ? fb.group(new User()) : user,
      Lookup: null
    });
  }
  static initDetailsStepValidators(frm: UntypedFormGroup, lusvc: LookupService): UntypedFormGroup {
    FormUtils.resetFormGroupValidators(frm);
    frm.get('User').get('BrokerageId').setValidators(ValidatorUtils.Required());
    frm.get('User').get('FirstName').setValidators(ValidatorUtils.Required());
    frm.get('User').get('LastName').setValidators(ValidatorUtils.Required());
    frm.get('User').get('MobilePhoneNumber').setValidators(ValidatorUtils.Required());
    frm.get('User').get('EmailAddress').setValidators(ValidatorUtils.ValidEmailRequiredSync());
    frm.get('User').get('EmailAddress').setAsyncValidators(ValidatorUtils.ValidEmailAsync(lusvc));
    frm.get('Password').setValidators(ValidatorUtils.RequiredPassword());
    frm.get('ConfirmPassword').setValidators(ValidatorUtils.RequiredPassword());
    frm.get('TOS').setValidators(Validators.requiredTrue);
    return frm;
  }
  static initPasscodeStepValidators(frm: UntypedFormGroup): UntypedFormGroup {
    FormUtils.resetFormGroupValidators(frm);
    frm.markAsUntouched();
    frm.markAsPristine();
    frm.get('Passcode').setValidators(ValidatorUtils.NumberOfDigitsRequired(6));
    return frm;
  }
  static initCartStepValidation(frm: UntypedFormGroup): UntypedFormGroup {
    FormUtils.resetFormGroupValidators(frm);
    frm.markAsUntouched();
    frm.markAsPristine();
    frm.get('PlanId').setValidators(ValidatorUtils.Required());
    return frm;
  }
  static InitUser(fb: UntypedFormBuilder, user: User = null): UntypedFormGroup {
    if (user) {

      const fg = new UntypedFormGroup({
        'FirstName': new UntypedFormControl(user.FirstName, [Validators.required])
      });
      Object.keys(user).forEach(x => fg.addControl(x, new UntypedFormControl(user[x])));
      FormUtils.formatPhoneNumberCtrl(fg.get('MobilePhoneNumber'));
      return fg;
    } else {
      const fg = fb.group({
        Brokerage: null,
        BrokerageId: null,
        UseBusAddr: false,
        BusName: null,
        BusAddrCity: null,
        BusAddrCountry: 'Canada',
        BusAddrLine1: null,
        BusAddrLine2: null,
        BusAddrPostalCode: null,
        BusAddrProv: null,
        CustomerId: null,
        CustomerIdDefault: null,
        DateCreated: null,
        EmailAddress: null,
        EmailAlert: true,
        FaxNumber: null,
        FirstName: null,
        ForceUpdatePaymentMethod: null,
        ForceChangePassword: null,
        GANotifiy: false,
        InvitedBy: null,
        LastModified: null,
        LastName: null,
        MobilePhoneNumber: null,
        SMSAlert: true,
        QuietTimeStart: '21:00:00',
        QuietTimeEnd: '09:00:00',
        SearchText: null,
        UserId: null,
        Validated: null,
        WorkPhoneNumber: null,
        DateCancelled: null,
        DealAssistEmail: null,
        DealAssistMobile: null,
        Associations: [],
        UserType: RoleType.ListingAgent,
        MLSID: null,
        MLSNumericKey: null,
      });

      return fg;
    }
  }
}
