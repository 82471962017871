import { UserLookup } from './../../classes/classes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ConfigUtils from '../../utils/ConfigUtils';
import { RegistrationData } from '../classes';

@Injectable()
export class RegisterService {

  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = ConfigUtils.GetBaseApiUrl() + '/register';
  }

  PostRegistrationData(data: RegistrationData) {
    return this.http.post<RegistrationData>(this.apiUrl, data);
  }

  GetUserDataFromMLSID(MLSID: string) {
    return this.http.get<UserLookup>(this.apiUrl + '/' + MLSID);
  }

  ResendCode(data: RegistrationData) {
    return this.http.post<RegistrationData>(this.apiUrl + '/resendcode', data);
  }
}
