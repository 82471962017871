import { ContractStatus, SubContractType } from './../../classes/contract';
import { Pipe, PipeTransform } from '@angular/core';
import { Contract, ContractType } from '../../classes/contract';
import { FieldName } from '../../classes/form';
import { IfNullCurrencyPipe } from '../../pipes/if-null-currency.pipe';
import { ContractOwner } from '../../utils/data/ContractOwnerUtils';
import ContractFormUtils from '../../utils/form/ContractFormUtils';
import { LowerCasePipe, UpperCasePipe } from '@angular/common';

@Pipe({
    name: 'owners'
})
export class OwnersPipe implements PipeTransform {

    transform(owners: ContractOwner[], isSeller: boolean = true): string {
        return this.CreateOwnerString(owners.filter(x => x.IsSeller === isSeller));
    }

    private CreateOwnerString(owners: ContractOwner[]): string {
        let owner = '';

        owners.forEach(x => {
            owner += `${x.FirstName} ${x.LastName},`;
        });

        if (owner.length > 2) { owner = owner.substring(0, owner.length - 1); }
        return owner;
    }
}

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {

    transform(prop: any): string {
        const address = `${prop[FieldName[FieldName.AddrLine1]]}, ${prop[FieldName[FieldName.AddrCity]]}`;
        return address.length === 2 ? '' : address;
    }
}

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {
    transform(prop: any): string {
        const price = `${prop[FieldName[FieldName.ListingPrice]]}`;
        return price.length === 0 ? '' : new IfNullCurrencyPipe().transform(price);
    }
}

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(contract: Contract): string {
        let result = '';
        contract.ContractOwners.forEach(x =>
            result += (x.FirstName + x.MiddleName + x.LastName + x.CorporationName).replace(/null/g, '').toLowerCase());
        contract.ContractAgents.forEach(x =>
            result += (x.User.FirstName + x.User.LastName + x.Brokerage.Name).replace(/null/g, '').toLowerCase());
        result += JSON.stringify(contract).toLowerCase();

        return result;
    }
}

@Pipe({
    name: 'status'
})
export class StatusPipe implements PipeTransform {
    contract: Contract;
    private BuyerHasConditions(): boolean {
        return this.contract.Prop.Financing || this.contract.Prop.FinancingWaive != null ||
            this.contract.Prop.PropInspection || this.contract.Prop.PropInspectionWaive != null ||
            this.contract.Prop.BuyerPropertySale || this.contract.Prop.BuyerPropertySaleWaive != null ||
            this.contract.Prop.AddBuyerCondition || this.contract.Prop.AddBuyerConditionWaive != null ||
            this.contract.Prop.CondoDocs || this.contract.Prop.CondoDocsWaive != null ||
            this.contract.Prop.WaterCondition || this.contract.Prop.WaterConditionWaive != null ||
            this.contract.Prop.SepticSystem || this.contract.Prop.SepticSystemWaive != null ||
            this.contract.Prop.CountryResPropSched || this.contract.Prop.CountryResPropSchedWaive != null ||
            this.contract.Prop.SubdivisionPlan || this.contract.Prop.SubdivisionPlanWaive != null;
    }

    private SellerHasConditions(): boolean {
        return this.contract.Prop.SellerCondition || this.contract.Prop.SellerConditionWaive != null;
         // || this.contract.Prop.BackUpOffer || this.contract.Prop.BackUpOfferWaive != null;
    }
    transform(contract: Contract): string {
        this.contract = contract;
        let result = '';
        switch (contract.ContractType) {
            case ContractType.Buyer:
            case ContractType.Listing:
                result = ContractFormUtils.GetContractStatusByContract(contract).replace('-', '|');
                if (contract.SubStatus != null)
                    result += ` | ${ContractFormUtils.getSubStatus(contract.SubStatus)}`;
                break;
            case ContractType.Offer:
                switch (contract.Status) {
                    case ContractStatus.Accepted:
                        result = 'ACCEPTED';

                        if (this.BuyerHasConditions() || this.SellerHasConditions()) {
                            result += ' [CONDITIONAL]';
                        }

                        if (contract.NextContractId) {
                            result += ' | Changes Pending';
                        }

                        break;
                    case ContractStatus.Active:
                        switch (contract.SubContractType) {
                            case SubContractType.ARBBS:
                                result = 'ACTIVE AGREEMENT TO REPRESENT BOTH SELLER AND BUYER';
                            case SubContractType.CA:
                                result = 'ACTIVE CUSTOMER ACKNOWLEDGMENT';
                        }
                        break;
                    case ContractStatus.Rejected:
                    case ContractStatus.Firm:
                    case ContractStatus.Closed:
                    case ContractStatus.ExpiryPending:
                    case ContractStatus.Expired:
                    case ContractStatus.ConditionsNonWaived:
                    case ContractStatus.Terminated:
                        result = ContractFormUtils.GetContractStatus(contract.Status, contract.ContractType).replace('-', '|');
                        break;
                    default:
                        result = 'AWAITING RESPONSE';
                }
                break;
        }
        return new LowerCasePipe().transform(result);
    }
}