import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { SiteLayoutComponent } from '../../layout/site-layout/site-layout.component';
import { NewDecimalPipe } from '../../pipes/new-decimal.pipe';
import { StripeService } from '../../services/stripe.service';
import ArrayUtils from '../../utils/ArrayUtils';

@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  providers: [NgbDropdownConfig]
})

export class PlanSelectionComponent implements OnInit {
  @Output() selectedPlan = new EventEmitter<any>();

  plans: any[] = [];
  quantityArr = ArrayUtils.generateNumberArray(15, 2);
  quantity = 2;

  constructor(private svc: StripeService, private site: SiteLayoutComponent, config: NgbDropdownConfig) {
    config.placement = 'top-right';
  }

  ngOnInit() {
    this.site.ShowSpinner();
    this.svc.GetSubscriptionPlans()
      .subscribe(
        response => {
          this.plans = response;
          // this.FixTileSizes();
          this.site.HideSpinner();
        },
        error => this.site.modal.ShowError('Error retrieving subscription plans...')
      );
  }
  /* FixTileSizes() {
    setTimeout(() => {
      const height = $('.card-Monthly').outerHeight();
      $('.button-guest').css('margin-top', height - $('.card-guest').outerHeight(true) + 'px');
      this.plans.forEach(x => {
        if (x.tiers) {
          $('.button-' + x.nickname).css('margin-top', height - 8 - $('.card-' + x.nickname).outerHeight(true) + 'px');
        } else {
          $('.button-' + x.nickname).css('margin-top', height - $('.card-' + x.nickname).outerHeight(true) + 'px');
        }
      });
    }, 10);
  }*/
  public SelectPlan(plan: any) {
    plan.metadata['Quantity'] = plan.tiers ? this.quantity : 1;
    this.selectedPlan.emit(plan);
  }
  public GetPrice(plan: any) {
    if (!plan.tiers) { return new NewDecimalPipe().transform((plan.amount / 100).toString(), 0); }

    let price = 0;
    for (let i = 0; i < plan.tiers.length; i++) {
      const prev = i > 0 ? i - 1 : 0;
      if (this.quantity <= plan.tiers[i].up_to && this.quantity > plan.tiers[prev].up_to) {
        price = this.quantity * (plan.tiers[i].unit_amount / 100);
      } else if (plan.tiers[i].up_to == null && this.quantity > plan.tiers[prev].up_to) {
        price = this.quantity * (plan.tiers[i].unit_amount / 100);
      }
    }

    return new NewDecimalPipe().transform(price.toString(), 0);
  }
  public GetSavings(plan: any) {
    return plan.metadata['Saving'] == null ? 'Cancel Anytime' : plan.metadata['Saving'];
  }
}
