import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserPaymentData } from '../../classes/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { StripeService } from '../../services/stripe.service';
import ValidatorUtils from '../../utils/form/ValidatorUtils';
import GuidUtils from '../../utils/GuidUtils';

@Component({
  selector: 'app-edit-payment-method-info',
  templateUrl: './edit-payment-method-info.component.html'
})
export class EditPaymentMethodInfoComponent implements OnInit {

  userId: string = null;
  card: UserPaymentData;
  cardId: string;
  frm: UntypedFormGroup;
  _ExpMonth: AbstractControl = null;
  _ExpYear: AbstractControl = null;
  _PostalZip: AbstractControl = null;
  _showErrors = false;
  _arrYears = [];
  _arrMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(private svc: StripeService, private app: AppLayoutComponent, public fb: UntypedFormBuilder, private route: ActivatedRoute) {
    this.userId = this.route.snapshot.params['id'] || GuidUtils.EmptyGuid();
  }

  ngOnInit() {

    this.cardId = this.getCardId();
    this.GetPaymentMethod();
    this.populateYears();
  }
  populateYears() {
    const currYear = new Date().getFullYear();
    for (let i = currYear; i < currYear + 30; i++) {
      this._arrYears.push(i);
    }
  }
  getCardId() {
    const parts = this.app.router.url.split('/');
    return parts[parts.length - 1];
  }
  initForm() {
    this.frm = this.fb.group({
      ExpMonth: this.card.ExpiryMonth,
      ExpYear: this.card.ExpiryYear,
      PostalZip: this.card.PostalZip
    });
    this._ExpMonth = this.frm.get('ExpMonth');
    this._ExpYear = this.frm.get('ExpYear');
    this._PostalZip = this.frm.get('PostalZip');

    if (this.card.Country === 'CA') {
      this._PostalZip.setValidators(ValidatorUtils.PostalCodeRequired());
    } else if (this.card.Country === 'US') {
      this._PostalZip.setValidators(ValidatorUtils.ZipCodeRequired());
    }
  }
  validateForm(): void {
    this._showErrors = true;
    if (this._PostalZip.valid) {
      this._showErrors = false;
      this.UpdatePaymentMethodInfo();
    }
  }
  GetPaymentMethod() {
    this.svc.GetPaymentMethod(this.userId, this.cardId)
      .subscribe(
        response => {
          this.card = response;
          this.initForm();
        },
        error => {
          console.log(error);
        }
      );
  }
  Back_OnClick() {
    const route = this.app.router.url.split('/');
    route.pop();
    route.pop();
    this.app.router.navigate(route);
  }
  UpdatePaymentMethodInfo() {
    this.card.PostalZip = this._PostalZip.value;
    this.card.ExpiryMonth = this._ExpMonth.value;
    this.card.ExpiryYear = this._ExpYear.value;

    this.svc.UpdatePaymentMethod(this.userId, this.card)
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error updating payment method... Try again later');
          } else {
            this.app.ShowSuccessMessage('Successfully updated payment method');
            this.Back_OnClick();
          }
        },
        error => {
          console.log(error);
          this.app.ShowErrorMessage('Error updating payment method... Try again later');
        }
      );
  }
}
