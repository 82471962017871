<div class="d-none d-md-flex py-2">
    <a [routerLink]="app.isAdmin() ? '/admin' : '/home'" role="button">
        <img src="/assets/images/deal-simple-logo_480.png" alt="" style="width: 300px;" />
    </a>
    <div class="ms-auto text-end">
        <span>Hello
            <a *ngIf="!app.isSigner()" class="nav-link p-0" routerLink="/profile" style="display: inherit">
                {{app.cu.User.FirstName}} {{app.cu.User.LastName}}
            </a><span *ngIf="app.isSigner()">{{ app.cu.SignerName }}</span> |
            <a class="nav-link alt-link p-0" style="display: inherit" routerLink="/">
                Sign out
            </a>
        </span>
    </div>
</div>

<div class="d-md-none py-1">
    <div class="row">
        <div class="col">
            <a [routerLink]="app.isAdmin() ? '/admin' : '/home'" role="button">
                <img src="/assets/images/deal-simple-logo_transparent.png" alt="" style="width: 140px;" />
            </a>
        </div>
        <div class="col-auto">
            <span>
                <a *ngIf="!app.isSigner()" class="nav-link p-0" routerLink="/profile" style="display: inherit">
                    {{app.cu.User.FirstName}} {{app.cu.User.LastName}}
                </a><span *ngIf="app.isSigner()">{{ app.cu.SignerName }}</span> |
                <a class="nav-link alt-link p-0" style="display: inherit" routerLink="/">
                    Sign out
                </a>
            </span>
        </div>
    </div>

</div>