import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'newDecimal'
})
export class NewDecimalPipe implements PipeTransform {

  transform(value: string, decimals = 2, returnRawNumber = false, allowZero = false): any {

    if (value === null || value === undefined) { return null; }
    // REMOVE ANYTHING THAT ISN'T A NUMBER OR DECIMAL
    value = value.toString().replace(/[^\d\.]+/g, '');

    const d = Math.pow(10, decimals);

    let n = 0;

    if (isFinite(value as any)) {
      n = Math.round(value as any * d) / d;
    }

    if (returnRawNumber) { return n; } 
    else if (n === 0 && !allowZero) { return null; } 
    else { return new DecimalPipe('en-US').transform(n.toString(), '1.' + decimals + '-' + decimals); }
    // return returnRawNumber ? n : new DecimalPipe('en-US').transform(n.toString(), '1.' + decimals + '-' + decimals);
  }
}
