import { Brokerage } from './../../classes/classes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../classes/classes';
import ConfigUtils from '../../utils/ConfigUtils';
import { DataFileUploadType } from '../classes';

@Injectable()
export class AdminService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/admin';
  }
  GetUser(userId: string): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/User/${userId}`);
  }
  GetBrokerage(brokerageId: string): Observable<Brokerage[]> {
    return this.http.get<Brokerage[]>(`${this.baseUrl}/Brokerage/${brokerageId}`);
  }
  UpdateBrokerage(brokerage: Brokerage): Observable<boolean> {
    return this.http.put<boolean>(`${this.baseUrl}/UpdateBrokerage`, brokerage);
  }
  QueryUsers(searchText: string): Observable<User[]> {
    return this.http.post<User[]>(`${this.baseUrl}/QueryUsers`, JSON.stringify(searchText));
  }
  QueryBrokerages(searchText: string, getGroupless: boolean = false): Observable<Brokerage[]> {
    return this.http.post<Brokerage[]>(`${this.baseUrl}/QueryBrokerages/${getGroupless}`, JSON.stringify(searchText));
  }
  ResetDemo(): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/ResetDemo`, null);
  }
  ResetUserPassword(userId: string): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/ResetUserPassword/${userId}`);
  }

  UploadDataFile(country: string, prov: string, board: string, uploadType: DataFileUploadType, fileData: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/UploadDataFile/${country.toLowerCase()}_${prov.toLowerCase()}${board != null ? '_' + board.toLowerCase() : ''}/${uploadType}`, JSON.stringify(fileData));
  }
}
