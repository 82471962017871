import { YesNoPromptConfig } from '../../classes/classes';
import { Contract, ContractStatus, ContractType, SubContractType } from '../../classes/contract';
import { FieldName } from '../../classes/form';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { ContractAgentUtils } from '../data/ContractAgentUtils';
import { ContractActionType } from '../form/ContractActionUtils';
import FormUtils from '../form/FormUtils';

export class ContractPrompt {

  public _contractType: ContractType = null;
  private _contract: Contract = null;
  private _isSellerAgent: boolean = null;
  private _otherAgentNames: string = null;
  private _responsibleAgentNames: string = null;
  private _paper = false;
  private _hasQR = false;
  private _backupWarning = false;

  private _editWarning = false;
  private readonly _br = '<br><br>';
  private readonly _ds = 'DealSimple™';
  private readonly _noMessage = `${this._br}Otherwise, select <b>No</b> to go back.`;

  constructor(public app: AppLayoutComponent) { }

  public GetContractPrompt(action: ContractActionType, yesFunction: Function = null, paper = false, backupWarning = false, isSellerAgent: boolean = null, hasQR: boolean = false, editWarning: boolean = false): YesNoPromptConfig {

    const prompt = new YesNoPromptConfig();
    this._paper = paper;
    this._hasQR = hasQR;
    this._backupWarning = backupWarning;
    this._editWarning = editWarning;

    if (isSellerAgent != null) { this._isSellerAgent = isSellerAgent; }

    switch (+action) {
      case ContractActionType.Delete:
      case ContractActionType.Reject:
      case ContractActionType.Terminate:
      case ContractActionType.BuyerVoid:
      case ContractActionType.SellerVoid:
      case ContractActionType.ExpireNow:
      case ContractActionType.FallenThrough:
      case ContractActionType.Sold:
        prompt.YesButtonCss = 'btn-danger';
        break;
      case ContractActionType.SendOffer:
        if (this._contract.StatusResponse === ContractStatus.Rejected) { prompt.YesButtonCss = 'btn-danger'; }
        break;
    }

    prompt.Message = this.GetContractMessage(action);
    prompt.YesFunction = yesFunction;

    return prompt;
  }
  private GetContractMessage(action: ContractActionType): string {
    switch (+action) {
      case ContractActionType.Amend:
        return this.AmendContract();
      case ContractActionType.Copy:
        return this.CopyContract();
      case ContractActionType.Delete:
        return this.DeleteContract();
      case ContractActionType.Edit:
        return this.EditContract();
      case ContractActionType.Terminate:
        return this.TerminateContract();

      // OFFER ONLY CONTRACT ACTIONS:
      case ContractActionType.Accept:
        return this.AcceptContract();
      case ContractActionType.AcceptCounter:
        return this.AcceptCounterContract();
      case ContractActionType.Conditions:
        return this.ConditionRemoval();
      case ContractActionType.Counter:
        return this.CounterContract();
      case ContractActionType.Finalize:
        return this.FinalizeContract();
      case ContractActionType.Reject:
        return this.RejectContract();
      case ContractActionType.BuyerVoid:
        return this.BuyerVoidContract();
      case ContractActionType.SellerVoid:
        return this.SellerVoidContract();
      case ContractActionType.Notice:
        return this.SellerNotice();
      case ContractActionType.Waive:
        if (this._contract.SubContractType == SubContractType.SellerNotice)
          return this.SellerNoticeWaive();
        else
          return this.ConditionRemoval();
      case ContractActionType.NonWaive:
        if (this._contract.SubContractType == SubContractType.SellerNotice)
          return this.SellerNoticeNonWaive();
        else
          return this.ConditionRemoval();
      case ContractActionType.CondoNotice:
        return this.CondoNotice();
      case ContractActionType.Rescind:
        return this.RescindContract();
      case ContractActionType.Resend:
        return this.ResendContract();
      case ContractActionType.SendOffer:
        return this.SendContract();
      case ContractActionType.Continue:
        return this.ContinueOffer();
      case ContractActionType.ExpireNow:
        return this.ExpireNow();
      case ContractActionType.FallenThrough:
        return this.FallenThrough();
      case ContractActionType.Sold:
        return this.Sold();
      case ContractActionType.StartNewOffer:
        return this.StartNewOffer();
      default:
        return null;
    }
  }
  public LoadContract(contract: Contract) {
    this._contract = contract;
    this._isSellerAgent = ContractAgentUtils.IsContractAgent(contract.ContractAgents, this.app.cu.User.UserId, true);
    this._otherAgentNames = FormUtils.ConcatAgentNames(contract.ContractAgents, !contract.SellerSigns);
    this._responsibleAgentNames = FormUtils.ConcatAgentNames(contract.ContractAgents, contract.SellerSigns);
  }

  //#region Contract Messages
  private AmendContract(): string {
    if (!this._paper) {
      return `Are you sure you would like to <b>amend</b> this contract?${this._br}
        Selecting <b>Yes</b> will take an exact copy of the current contract to allow for additional
        changes to be made that require signatures from all parties. The current signed contract will
        remain during this time and will only be replaced by the new amendment once the contract is fully signed.
        ${this._noMessage}`;
    } else {
      return `Are you sure you would like to <b>amend</b> this contract with a <b>non-${this._ds}</b> amendment?${this._br}
        By selecting <b>Yes</b> you agree that:
        <ul><li>This is a valid amendment form</li>
        <li>This amendment is signed by all necessary parties that pertain to this contract</li>
        <li>${this._ds} is not responsible for the validity of this form</li>
        <li>You will mirror the changes made in the amendment to ${this._ds} on the following screens</li></ul><br>
        If you agree, click <b>Yes</b> followed by making the changes in ${this._ds}. Once the changes are made,
        please go to the <b>Sign Tab</b> to indicate the changes are finalized. Please note that <b>no signatures will be
        captured by ${this._ds}</b> because this is a <b>non-${this._ds}</b> amendment.${this._noMessage}`;
    }
  }
  private CopyContract(): string {
    return `Are you sure you would like to copy this contract?${this._br}
      Selecting <b>Yes</b> will take an exact copy of the current contract to allow for use of an expired or
      terminated contract. All dates will require updating and you should review the contract for completeness.
      When complete the contract will require signatures from all parties. The current contract will remain
      expired or terminated.
      ${this._noMessage}`;
  }
  private DeleteContract(): string {
    return `Are you sure you would like to <b>delete</b> this incomplete version of the contract?${this._br}
      Selecting <b>Yes</b> will delete this version of the contract. However, any previously fully executed
      version of the contract will remain.
      ${this._noMessage}`;
  }
  private EditContract(): string {
    return `Are you sure you would like to edit?${this._br}
    ${this._editWarning ? `This contract is currently involved in an active offer, and as such will need to be re-selected before that offer can be continued. ${this._br}` : ''}
    Selecting <b>Yes</b> will delete any signatures previously captured for this version of the contract.
    All will be required to sign again.
    ${this._noMessage}`;
  }
  private TerminateContract(): string {
    if (!this._paper) {
      return `Are you sure you would like to <b>terminate</b> this contract?${this._br}
        Selecting <b>Yes</b> will begin the termination process. All parties must sign off on the termination
        before the contract is officially terminated. Until then, the last signed version of the contract is
        valid.
        ${this._noMessage}`;
    } else {
      // TODO@G40: WORDING TO BE UPDATED...
      return `Are you sure you would like to terminate this contract with a non-controlled PDF?`;
    }
  }

  private FallenThrough(): string {
    return `Are you sure you would like to mark this deal as <b>fallen through</b>?${this._br}
      Selecting <b>Yes</b> will mark this deal as fallen through. This will allow you to use your listing
      in other offers, but the existing offer will be moved to inactive.
      ${this._noMessage}`;
  }

  private Sold(): string {
    return `Are you sure you would like to mark this property as <b>sold</b>?${this._br}
      Selecting <b>Yes</b> will mark both this property and the attached offer as closed, and you will
      not be able to use the listing in any other offers.
      ${this._noMessage}`;
  }
  //#endregion

  //#region Offer-Only Contract Messages
  private BuyerVoidContract() {
    if (!this._paper) {
      return `Are you sure you would like to initiate a <b>buyer void</b> for this contract?${this._br}
    Selecting <b>Yes</b> will begin the buyer void process.
    All buyers must sign off on the buyer void
    before the contract is officially voided. Until then, the last signed version of the contract is
    valid.
    ${this._noMessage}`;
    } else {
      return `Are you sure you would like to <b>Void</b> this contract in response to a <b>non-${this._ds}</b> Buyer Void?${this._br}
        By selecting <b>Yes</b> you agree that:
        <ul><li>This is a valid Buyer Void</li>
        <li>This Buyer Void is signed by all necessary parties that pertain to this contract</li>
        <li>${this._ds} is not responsible for the validity of this form</li></ul><br>
        If you agree, click <b>Yes</b> Proceed to the <b>Sign Tab</b> to indicate the form is finalized. Please note that <b>no signatures will be
        captured by ${this._ds}</b> because this is a <b>non-${this._ds}</b> Buyer Void.${this._noMessage}`;
    }
  }
  private SellerVoidContract() {
    if (!this._paper) {
      return `Are you sure you would like to initiate a <b>seller void</b> for this contract?${this._br}
    Selecting <b>Yes</b> will begin the seller void process.
    All sellers must sign off on the seller void
    before the contract is officially voided. Until then, the last signed version of the contract is
    valid.
    ${this._noMessage}`;
    } else {
      return `Are you sure you would like to <b>Void</b> this contract response to a <b>non-${this._ds}</b> Seller Void?${this._br}
        By selecting <b>Yes</b> you agree that:
        <ul><li>This is a valid Seller Void</li>
        <li>This Seller Void is signed by all necessary parties that pertain to this contract</li>
        <li>${this._ds} is not responsible for the validity of this form</li></ul><br>
        If you agree, click <b>Yes</b> Proceed to the <b>Sign Tab</b> to indicate the form is finalized. Please note that <b>no signatures will be
        captured by ${this._ds}</b> because this is a <b>non-${this._ds}</b> Seller Void.${this._noMessage}`;
    }
  }
  private SellerNotice(): string {
    return `Are you sure you would like to initiate a <b>Seller Notice for Sale of Buyer Property</b> for this contract?${this._br}
    Selecting <b>Yes</b> will begin the Notice for Sale of Buyer Property process. This means that, once all sellers have signed, 
    the notice will be sent to ${this._otherAgentNames} to be Waived/Non-Waived. Until all buyers have signed, the last signed version
    of the contract is valid.
    ${this._noMessage}`;
  }
  private SellerNoticeWaive(): string {
    if (!this._paper) {
      return `Are you sure you would like to <b>Waive</b> all Buyer conditions in response to the <b>Seller's Notice for Sale of Buyer Property</b>?
      Selecting <b>Yes</b> will waive all Buyer conditions and set the contract to <b>accepted/firm</b>.
      ${this._noMessage}`;
    } else {
      if (!this._hasQR) {
        return `Are you sure you would like to <b>Waive</b> all Buyer conditions in response to a <b>non-${this._ds}</b> Seller's Notice for Sale of Buyer Property?${this._br}
          By selecting <b>Yes</b> you agree that:
          <ul><li>This is a valid Seller's Notice</li>
          <li>This Seller's Notice is signed by all necessary parties that pertain to this contract</li>
          <li>${this._ds} is not responsible for the validity of this form</li></ul><br>
          If you agree, click <b>Yes</b> Proceed to the <b>Sign Tab</b> to indicate the form is finalized. Please note that <b>no signatures will be
          captured by ${this._ds}</b> because this is a <b>non-${this._ds}</b> Seller's Notice.${this._noMessage}`;
      } else {
        return `Are you sure that the seller notice is <b>fully signed</b> and the buyer conditions have been <b>waived</b>?${this._br}
          Select <b>Yes</b> if you agree to the above. The seller notice will automatically be emailed to all parties.
          ${this._noMessage}`;
      }
    }
  }
  private SellerNoticeNonWaive(): string {
    if (!this._paper) {
      return `Are you sure you would like to <b>Non-Waive</b> all Buyer conditions in response to the <b>Seller's Notice for Sale of Buyer Property</b>?
      Selecting <b>Yes</b> will non-waive all Buyer conditions and cause the contract to <b>fall through</b>.
      ${this._noMessage}`;
    } else {
      if (!this._hasQR) {
        return `Are you sure you would like to <b>Non-Waive</b> all Buyer conditions in response to a <b>non-${this._ds}</b> Seller's Notice for Sale of Buyer Property?${this._br}
          By selecting <b>Yes</b> you agree that:
          <ul><li>This is a valid Seller's Notice</li>
          <li>This Seller's Notice is signed by all necessary parties that pertain to this contract</li>
          <li>${this._ds} is not responsible for the validity of this form</li></ul><br>
          If you agree, click <b>Yes</b> Proceed to the <b>Sign Tab</b> to indicate the form is finalized. Please note that <b>no signatures will be
          captured by ${this._ds}</b> because this is a <b>non-${this._ds}</b> Seller's Notice.${this._noMessage}`;
      } else {
        return `Are you sure that the seller notice is <b>fully signed</b> and the buyer conditions have been <b>non-waived</b>?${this._br}
          Select <b>Yes</b> if you agree to the above. The seller notice will automatically be emailed to all parties.
          ${this._noMessage}`;
      }
    }
  }
  private CondoNotice(): string {
    if (!this._paper) {
      return `Are you sure you would like to send a General Notice of the buyer's intent to obtain the Condominium Documents? Selecting <b>Yes</b> will extend the Condition 
      Day to the Condo Documents Delivery Extension Day (if you have set one), else it will stay the same.
      ${this._noMessage}`;
    } else {
      return `Are you sure you would like to upload a <b>non-${this._ds} Condo Notice</b> to this contract?${this._br}
        By selecting <b>Yes</b> you agree that:
        <ul><li>This is a valid General Notice</li>
        <li>This amendment is signed by all necessary parties that pertain to this contract</li>
        <li>${this._ds} is not responsible for the validity of this form</li>
        If you agree, click <b>Yes</b> Proceed to the <b>Sign Tab</b> to indicate the form is finalized. Please note that <b>no signatures will be
        captured by ${this._ds}</b> because this is a <b>non-${this._ds}</b> Condo Notice.${this._noMessage}`;
    }
  }
  private AcceptContract(): string {
    switch (this._contract.SubContractType) {
      case SubContractType.Amendment:
        if (!this._paper) {
          return `Are you sure you would like to <b>accept</b> this amendment?${this._br}
          Selecting <b>Yes</b> will require signatures from the ${(this._isSellerAgent ? 'sellers' : 'buyers')}.
          Once all signatures are received and the amendment has been sent to the other agent,
          the amendment is officially accepted and appended to the previously agreed to offer.
          The amended offer will automatically be emailed to all parties.
          ${this._noMessage}`;
        } else {
          return `Are you sure that the amendment is <b>fully signed</b> and there are <b>no additional markups or
          strike throughs</b>?${this._br}
          Select <b>Yes</b> if you agree to the above. The amended offer will automatically be emailed to all parties.
          ${this._noMessage}`;
        }
      case SubContractType.ARBBS:
        if (!this._paper) {
          return `Are you sure you would like to <b>accept</b> this Agreement to Represent Both Seller and Buyer?${this._br}
        Selecting <b>Yes</b> will require signatures from the ${(this._isSellerAgent ? 'sellers' : 'buyers')}.
        Once all signatures are received and the Agreement to Represent Both Seller and Buyer has been sent to the other agent,
        the Agreement to Represent Both Seller and Buyer is officially accepted. The Agreement to represent both Seller and
        Buyer will automatically be emailed to all parties. At that point the initiating agent may continue with the offer.
        ${this._noMessage}`;
        } else {
          return `Are you sure that the Agreement to Represent Both Seller and Buyer is <b>fully signed</b> and there are <b>no additional markups or
          strike throughs</b> on the offer?${this._br}
          Select <b>Yes</b> if you agree to the above. The Agreement to Represent Both Seller and Buyer is not officially accepted until
          it is sent to the other agent from ${this._ds}. <b>Once the Agreement to Represent Both Seller and Buyer has been sent to the other agent,
          the Agreement to Represent Both Seller and Buyer is officially accepted</b>. The final accepted Agreement to represent both Seller and
          Buyer will automatically be emailed to all parties. At that point the initiating agent may continue with the offer.
          ${this._noMessage}`;
        }
      default:
        if (!this._paper) {
          return `Are you sure you would like to <b>accept</b> this offer?${this._br}
            ${(this._backupWarning ? `It appears <b>you already have an accepted offer</b> on this property.
            We recommend that instead of accepting this offer, you write a <b>counter with the Backup Condition</b> added.${this._br}` : '')}
            Selecting <b>Yes</b> will require signatures from the ${(this._isSellerAgent ? 'sellers' : 'buyers')}.
            Once all signatures are received and the offer has been sent to the other agent,
            the offer is officially accepted. The final accepted offer will automatically
            be emailed to all parties.
            ${this._noMessage}`;
        } else {
          return `Are you sure that the offer is <b>fully signed</b> and there are <b>no additional markups or
          strike throughs</b> on the offer?${this._br}
          ${(this._backupWarning ? `It appears <b>you already have an accepted offer</b> on this property.
          We recommend that instead of accepting this offer, you write a <b>counter with the Backup Condition</b> added.${this._br}` : '')}
          Select <b>Yes</b> if you agree to the above. The accepted offer will automatically be set to the correct
          accepted status and emailed to all parties.
          ${this._noMessage}`;
        }
    }
  }
  private AcceptCounterContract(): string {
    return `Are you sure you would like to <b>accept</b> this <b>paper offer</b> with changes?${this._br}
        Selecting <b>Yes</b> will allow to <b>capture any changes</b> that were made to the contract.
        The changes made in ${this._ds} do not need to be signed by any other parties and are purely
        to assist with managing <b>future transactions</b> pertaining to this offer.
        The uploaded <b>paper offer</b> will become accepted when it is sent to the other agent.
        The final accepted offer will automatically be emailed to all parties.
        ${this._noMessage}`;
  }
  private ConditionRemoval(): string {
    return `Are you sure you would like to manage conditions for this contract?${this._br}
      Selecting <b>Yes</b> will take an exact copy of the current contract to allow condition management that require
      signatures. The current signed contract will remain during this time and will only be replaced
      by the new amendment once the contract is fully signed.
      ${this._noMessage}`;
  }
  private CounterContract(): string {
    if (!this._paper) {
      return `Are you sure you would like to counter this offer?${this._br}
        Selecting <b>Yes</b> will allow for additional changes to be made that
        require signatures from all parties. The current offer will remain active during this time and
        will only be replaced by the new version once the contract is signed and sent to the other agent.
        ${this._noMessage}`;
    } else {
      return `Are you sure you would like to counter this offer?${this._br}
        Selecting <b>Yes</b> will allow for additional changes to be made that
        require signatures from all parties. The current uploaded <b>paper offer</b> will remain
        active during this time and will only be replaced by the new version once the contract is
        signed and sent to the other agent.
        ${this._noMessage}`;
    }
  }
  private FinalizeContract(): string {
    return `Are you sure you would like to finalize this offer?${this._br}
      Selecting <b>Yes</b> will automatically set the offer status.${this._noMessage}`;
  }
  private RejectContract(): string {
    if (this._contract.SubContractType === SubContractType.Amendment) {
      if (!this._paper) {
        return `Are you sure you would like to <b>reject</b> this amendment?${this._br}
        Selecting <b>Yes</b> will not require any further signatures. Once in reject mode,
        you must submit the rejection to the other agent to notify the other agent that the amendment has
        been rejected. This will not impact the original accepted offer.
        ${this._noMessage}`;
      } else {
        // TODO@G40: WORDING TO BE UPDATED...
        return `Are you sure you would like to <b>reject</b> this paper amendment?${this._br}
        Selecting <b>Yes</b> will not require any further signatures. Once in reject mode,
        you must submit the rejection to the other agent to notify the other agent that the amendment has
        been rejected. This will not impact the original accepted offer.
        ${this._noMessage}`;
      }
    } else {
      return `Are you sure you would like to <b>reject</b> this offer?${this._br}
        Selecting <b>Yes</b> will not require any further signatures. Once in reject mode,
        you must submit the rejection to the other agent to finalize the rejection.
        ${this._noMessage}`;
    }
  }
  private RescindContract(): string {
    return `Are you sure you would like to <b>rescind</b> (or take back) this contract? Please note that this
      can only be done if the other agent is not already in the process of responding.${this._br}
      Selecting <b>Yes</b> will reset the contract back to you and removing your latest response in ${this._ds}
      from the other agent.
      ${this._noMessage}`;
  }
  private ResendContract(): string {
    return `Are you sure you would like to resend the Access Link to ${this._responsibleAgentNames}?${this._br}
      Selecting <b>Yes</b> will automatically alert the agent and they will be able to respond
      to the offer directly through the ${this._ds} link.
      ${this._noMessage}`;
  }
  private SendContract(): string {

    if (this._contract.SubContractType === SubContractType.Amendment) {
      switch (this._contract.StatusResponse) {
        case null:
          return `All signatures have been collected and this amendment is ready to be sent to ${this._otherAgentNames}.
            Are you sure you would like to send this amendment to the other agent at this time?${this._br}
            Selecting <b>Yes</b> will automatically alert the agent of the new amendment and they will be able
            to respond to the amendment directly through ${this._ds}.
            ${this._noMessage}`;
        case ContractStatus.Accepted:
          return `All signatures have been collected and this amendment is ready to be sent to ${this._otherAgentNames}.
            Are you sure you would like to send this <b>accepted</b> amendment to the other agent at this time?${this._br}
            Selecting <b>Yes</b> will notify the other agent and append the amendment to the official contract.
            ${this._noMessage}`;
        case ContractStatus.Rejected:
          return `The amendment has been <b>rejected</b> and is ready to be sent to ${this._otherAgentNames}.${this._br}
            Are you sure you would like to send this amendment as <b>rejected</b> to the other agent at this time?${this._br}
            Selecting <b>Yes</b> will automatically alert the agent of the <b>rejected amendment</b>.
            <b>This will not impact the original accepted offer</b>.
            ${this._noMessage}`;
      }
    } else if (this._contract.StatusResponse === ContractStatus.Rejected) {
      return `The offer has been <b>rejected</b> and is ready to be sent to ${this._otherAgentNames}.${this._br}
        Are you sure you would like to send this offer as <b>rejected</b> to the other agent at this time? Selecting
        <b>Yes</b> will automatically alert the agent of the <b>rejected offer</b> and this transaction
        will be <b>terminated</b>.
        ${this._noMessage}`;
    } else if (this._contract.SubContractType === SubContractType.SellerNotice) {
      if (this._contract.StatusResponse !== ContractStatus.ConditionsNonWaived && this._contract.StatusResponse !== ContractStatus.ConditionsWaived) {
        return `All signatures have been collected and this Seller Notice for Sale of Buyer Property is ready to be sent to ${this._otherAgentNames}.
        Are you sure you would like to send this notice to the other agent at this time? Selecting 
        <b>Yes</b> will automatically alert the agent of this notice and require them to Waive/Non-Waive all buyer conditions
        within the next <b>${this._contract.Prop[FieldName.BPSHoursToWaive]} hours</b> to continue the transaction, else this transaction will <b>fall through</b>.
        ${this._noMessage}`;
      } else {
        return `All signatures have been collected for this ${this._contract.StatusResponse === ContractStatus.ConditionsNonWaived ? 'Non-Waive' : 'Waive'} 
          of Sale of Buyer Property. Are you sure you would like to finalize this decision at this time? Selecting
          <b>Yes</b> will cause this transaction to be marked as <b>${this._contract.StatusResponse === ContractStatus.ConditionsNonWaived ? 'fallen through'
            : 'accepted/firm'}</b>.
          ${this._noMessage}`;
      }
    } else {
      return `All signatures have been collected and this offer is ready to be sent to ${this._otherAgentNames}.
        Are you sure you would like to send this offer to the other agent at this time? Selecting
        <b>Yes</b> will automatically alert the agent of the new offer and they will be able
        to respond to the offer directly through ${this._ds}.
        ${this._noMessage}`;
    }
  }
  private ContinueOffer(): string {
    switch (+this._contract.SubContractType) {
      case SubContractType.ARBBS:
        return `Your Agreement to Represent Both Seller and Buyer is fully signed and you are ready to continue writing your offer.${this._br}
          Selecting <b>Yes</b> will allow you to continue writing your offer.
          ${this._noMessage}`;
      case SubContractType.CA:
        return `Your Customer Acknowledgment is fully signed and you are ready to continue writing your offer.${this._br}
        Selecting <b>Yes</b> will allow you to continue writing your offer.
        ${this._noMessage}`;
    }
  }
  private ExpireNow(): string {
    return `Are you sure you would like to <b>Expire</b> this contract? Please note that this transaction is currently <b>Pending Expiration</b>
      because an agent working with this contract has downloaded/printed a copy in order to obtain signatures. They have either not sent you the required documents in time, or the required documents
      have yet to be uploaded.${this._br}
      Selecting <b>Yes</b> will cause this contract <b>to fully expire</b> in ${this._ds}. Please only do this if you are certain this contract is dead.
      ${this._noMessage}`;
  }

  private StartNewOffer(): string {
    return `Would you like to create a new offer contract?${this._br}
    Selecting <b>Yes</b> will create an <b>exact duplicate</b> of the previous offer written. Double-check that all 
    your dates have been updated prior to signing. ${this._noMessage}`;
  }
  //#endregion
}
