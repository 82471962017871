import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../../classes/classes';
import { AdminService } from '../service/admin.service';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html'
})
export class SearchUserComponent {

  @Output() selected = new EventEmitter<User>();

  searchText = new Subject<string>();
  search: string;
  users: User[] = [];
  count = -1;

  constructor(private svc: AdminService) {
    this.InitSearchDelay();
  }

  InitSearchDelay() {
    this.searchText.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(text => this.Search(text));
  }

  ResetSearch(searchText: string = ''): boolean {

    if (searchText.trim().length === 0) {
      this.users = [];
      this.count = -1;
      this.search = null;

      return true;
    }

    return false;
  }

  Search(searchText: string) {

    // IF BLANK, DO NOT CONTINUE
    if (this.ResetSearch(searchText)) {
      return;
    }

    // SEARCH FOR USERS
    this.svc.QueryUsers(searchText)
      .subscribe(
        response => {
          this.users = response;
          this.count = this.users.length;
        },
        error => {
          console.log(error);
        }
      );
  }

  user_Selected(user: User) {
    this.selected.emit(user);
    this.ResetSearch();
  }
}
