import { UserLookup } from './../classes/classes';
import { Brokerage, User } from '../classes/classes';

export enum RegistrationSteps {
  UserDetailsStep,
  EmailPasscodeStep,
  MobilePasscodeStep,
  CartStep,
  Finished
}

export enum RegistrationFlowType {
  Standard = 0,
  GuestValidation = 10,
  PaymentOnly = 20,
  GuestValidationWithPayment = 30
}

export class RegistrationData {
  Brokerage: Brokerage;
  ConfirmPassword: string;
  Coupon: string;
  ErrorMessage: string;
  IsEmailVerification: boolean;
  IsMobileVerification: boolean;
  Mapped: boolean;
  Passcode: number;
  Password: string;
  PlanId: string;
  RegistrationFlowType: RegistrationFlowType;
  Step: RegistrationSteps;
  Success: boolean;
  Token: any;
  TOS: boolean;
  UrlReferrer: string;
  User: User;
  Lookup: UserLookup;
}
