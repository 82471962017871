import { Pipe, PipeTransform } from '@angular/core';
import ArrayUtils from '../utils/ArrayUtils';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, contains: boolean = true, formatDate: string = ''): any[] {

    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();

    return items.filter(item => {

      if (typeof item === 'object') {
        if (contains) {
          return ArrayUtils.GetObjectText(item, formatDate).join(' ').toLowerCase().includes(searchText);
        } else {
          return ArrayUtils.GetObjectText(item, formatDate).join(' ').toLowerCase().startsWith(searchText);
        }
      }

      if (contains) {
        return JSON.stringify(item).toLowerCase().includes(searchText);
      } else {
        return JSON.stringify(item).toLowerCase().startsWith(searchText);
      }
    });
  }
}
