
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Signature, SignatureConfig } from '../../classes/signature';
import { SignatureFieldComponent } from '../signature/signature.component';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html'
})
export class SignatureModalComponent implements OnDestroy {

  @ViewChild('signatureModal', { static: true }) signatureModal: any;
  @ViewChild('sigComponent', { static: true }) sigComponent: SignatureFieldComponent;

  @Input() set showModal(show: boolean) { show ? this.ShowModal() : this._modal?.hide(); }
  @Input() parentId: string;
  @Input() fileRefId: string;
  @Input() signerId: string;
  @Input() fullName: string;
  @Output() signature = new EventEmitter<Signature>();

  _signatureConfig: SignatureConfig = null;
  _modal: BsModalRef = null;

  constructor(private svcModal: BsModalService) { }
  ngOnDestroy() { if (this._modal) { this._modal.hide(); } }

  public Cancel() {
    this._modal.hide();
    this.signature.emit(null);
  }

  public ShowModal() {
    const config: ModalOptions = {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-dialog-centered modal-lg'
    };

    this._modal = this.svcModal.show(this.signatureModal, config);
  }
}
