import { Component, NgZone, OnInit } from '@angular/core';
import { SiteLayoutComponent } from '../layout/site-layout/site-layout.component';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  _planId = null;
  _showModal = false;
  _nextUrl = null;

  constructor(public site: SiteLayoutComponent, private route: ActivatedRoute, private authService: AuthService, private ngZone: NgZone) { }

  ngOnInit() {
    this._nextUrl = this.route.snapshot.queryParams['next'] || '/home';
  }
  SelectedPlan(value: any) {
    this._planId = value.id;
    this._showModal = true;
  }
  RegistrationComplete(value: any) {
    this._showModal = false;
    this._planId = null;
    if (value.Success) {
      this.LoginUser(value.Email, value.Password);
    } else {
      console.log('Registration Aborted...');
    }
  }
  LoginUser(email: string, password: string) {
    this.authService.Login(email, password)
      .subscribe(
        response => this.ngZone.run(() =>  this.site.router.navigate([this._nextUrl])),
        error => {
          this.site.ShowErrorMessage('Login failed, please try again. If you are not yet registered, ' +
            'please do so now by clicking on the Register button below.');
        });
  }
}
