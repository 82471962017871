<div class="alert alert-secondary" role="alert">
  <div class="row">
    <div class="col">
      DOCUMENTS
    </div>
  </div>
</div>
<div *ngIf="_show">
  <div *ngFor="let section of sections; let i = index">
    <h5 class="btn text-secondary btn-link" (click)="collapse.toggle()" [attr.aria-expanded]="!collapsed[i]">
      {{_dfs[section]}} <div class="fal fa-caret-right ps-1 icon text-secondary"
        [ngClass]="collapsed[i] ? 'collapsed' : ''"></div>
      <!-- Chevron right? -->
    </h5>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[i]">
      <!-- FROM HERE -->
      <app-deal-file-section [deal]="this" [section]="section" [df]="df"></app-deal-file-section>
      <!-- TO HERE -->
    </div>
  </div>

  <ng-template #pdfModal>
    <div class="modal-header bg-primary p-0">
      <div class="col p-2">
        <h6 class="text-light m-0">{{ _filename }}</h6>
      </div>
      <div class="col-auto p-0">
        <span class="align-middle">
          <button type="button" class="btn btn-danger btn-block" (click)="_modal.hide()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </button>
        </span>
      </div>
    </div>
    <app-pdf-lite-viewer [pdf]="_pdf | async" [filename]="_filename" (afterLoadComplete)="df.app.HideSpinner()">
    </app-pdf-lite-viewer>
  </ng-template>

  <ng-template #imageModal>
    <div class="modal-header bg-primary p-0">
      <div class="col p-2">
        <h6 class="text-light m-0">{{ _filename }}</h6>
      </div>
      <div class="col-auto p-0">
        <span class="align-middle">
          <button type="button" class="btn btn-danger btn-block" (click)="CloseImageViewer()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </button>
        </span>
      </div>
    </div>
    <angular-image-viewer [src]="_imageUrls" [(index)]="_imageIndex" [config]="_imageConfig">
    </angular-image-viewer>
  </ng-template>

  <app-copy-file-modal [fileRef]="_fileRefToCopy" (cancel)="_fileRefToCopy = null">
  </app-copy-file-modal>

</div>