import { RoleType } from './../services/auth.service';
import { FileRef } from './../forms/classes';
import { Association } from './../utils/data/AssociationUtils';
import { ContractType } from './contract';
import { Graphable } from '../admin/classes';

export class InviteUserClass {
  BrokerageId: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  MLSID: string;
  MLSNumericKey: number;
}

export class Brokerage extends Graphable {
  BrokerageId: string;
  ParentBrokerageId: string;
  MLSNumberickey: number;
  MLSID: string;
  Board: string;
  Name: string;
  Address: string;
  City: string;
  ProvState: string;
  Country: string;
  PostalZipCode: string;
  Active: boolean;
  EmailAddress: string;
  PhoneNumber: string;
  FaxNumber: string;
  ContractGroup: string;
  DateCreated: string;
  LastModified: string;
  SearchText: string;
  TimezoneName: string;


  constructor(init?: Partial<Brokerage>) {
    super('Brokerage', 'BrokerageId');
    Object.assign(this, init);
  }
}

export enum ApiCall {
  UserChangeBusinessAddr = 'UserChangeBusinessAddr',
  UserChangeEmail = 'UserChangeEmail',
  UserChangeMobile = 'UserChangeMobile',
  UserChangePassword = 'UserChangePassword',
  UserForgotPassword = 'UserForgotPassword',
  UserUpdateDefaultCommunicationMethod = 'UserUpdateDefaultCommunicationMethod'
}

export class ApiRequest {
  Method: ApiCall;
  Data: string;
}

export class ApiResponse {
  Message: string;
  Success: boolean;
}

export class Contact {
  ContactId: string = null;
  UserId: string = null;
  FirstName: string = null;
  MiddleName: string = null;
  LastName: string = null;
  FullName: string = null;
  Company: string = null;
  Occupation: string = null;
  SearchText: string = null;
  HomePhone: string = null;
  MobilePhone: string = null;
  WorkPhone: string = null;
  FaxNumber: string = null;
  EmailAddress: string = null;
  AddrLine1: string = null;
  AddrLine2: string = null;
  AddrCity: string = null;
  AddrProv: string = null;
  AddrCountry: string = null;
  AddrPostalCode: string = null;
  Active = true;
  DateCreated: Date = null;
  LastModified: Date = null;
  Associations: Association[] = [];

  constructor(init?: Partial<Contact>) {
    Object.assign(this, init);
  }
}

export class ContactAction {
  ActionType: ContactActionType;
  Contact: Contact;

  constructor(init?: Partial<ContactAction>) {
    Object.assign(this, init);
  }
}

export enum ContactActionType {
  Delete,
  ViewFintrac,
  EditFintrac,
  ViewCRG
}

export class Condition {
  Name: string = null;
  DueDate: Date = null;
  Waived: boolean = null;
  constructor(init?: Partial<Condition>) {
    Object.assign(this, init);
  }
}

export class NameValuePair {
  Name: string = null;
  Value: string = null;
  constructor(init?: Partial<NameValuePair>) {
    Object.assign(this, init);
  }
}
export class AddressResult {
  Address: string;
  City: string;
  Province: string;
  PostalCode: string;
  Country: string;
  FullAddress: string;
}

export enum ReportType {
  Listings = 100,
  Buyers = 200,
  Offers = 300,
  PropertyPool = 400,
  Envelopes = 500
}
export class User {
  UserId: string;
  BrokerageId: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  Validated: boolean;
  CustomerId: string;
  CustomerIdDefault: string;
  MobilePhoneNumber: string;
  WorkPhoneNumber: string;
  UseBusAddr: boolean;
  BusName: string;
  BusAddrLine1: string;
  BusAddrLine2: string;
  BusAddrCity: string;
  BusAddrProv: string;
  BusAddrCountry: string;
  BusAddrPostalCode: string;
  FaxNumber: string;
  EmailAlert: boolean;
  SMSAlert: boolean;
  QuietTimeStart: string;
  QuietTimeEnd: string;
  GANotifiy: boolean;
  InvitedBy: string;
  ForceUpdatePaymentMethod: boolean;
  ForceChangePassword: boolean;
  SearchText: string;
  DateCreated: Date;
  LastModified: Date;
  DateCancelled: Date;
  Brokerage: Brokerage;
  Associations: Association[];
  UserType: RoleType;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

export class UserAuthResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

export class UserChangePassword {
  CurrentPassword: string;
  NewPassword: string;
}

export class UserUpdateDefaultCommunicationMethod {
  SMSAlert: boolean;
  MarketingAlert: boolean;
  EmailAlert: boolean;
  QuietTimeStart: string;
  QuietTimeEnd: string;
}

export class YesNoPromptConfig {
  Message = 'Are you sure you would like to do this?';
  NoButtonCss = 'btn-outline-secondary';
  NoButtonText = 'No';
  NoFunction = null;
  YesButtonCss = 'btn-primary';
  YesButtonText = 'Yes';
  YesFunction = null;
  Textbox = false;
  TextboxLabel = null;
  TextboxData = null;
  constructor(init?: Partial<YesNoPromptConfig>) {
    Object.assign(this, init);
  }
}

export class ModalConfig {
  AllowClickOff = false;
  Title = 'Confirmation';
  Message = null;
  OkButtonCss = 'btn-primary';
  OkButtonText = 'Done';
  OkFunction = null;
  CloseButtonCss = 'btn-secondary';
  CloseButtonText = 'Cancel';
  CloseFunction = null;
  CloseButtonVisible = false;
  constructor(init?: Partial<ModalConfig>) {
    Object.assign(this, init);
  }
}

export class UserInvoiceData {
  InvoiceId: string;
  InvoiceHostedUrl: string;
  InvoicePaid: boolean;
  InvoiceStatus: string;
  InvoicePaidOn: Date;
}
export class UserPaymentData {
  CardId: string;
  CardBrand: string;
  Last4: string;
  ExpiryMonth: Number;
  ExpiryYear: Number;
  Default: boolean;
  PostalZip: string;
  Country: string;
}
export class UserSubscriptionData {
  SubscriptionId: string;
  Quantity: number;
  CurrentCoupon: SubscriptionCouponData;
  NextCoupon: SubscriptionCouponData;
  Plan: UserPlanData;
  NextPlan: UserPlanData;
}
export class UserPlanData {
  PlanId: string;
  PlanPrice: number;
  PlanInterval: string;
  PlanIntervalCount: Number;
  PlanName: string;
  CanCancel: string;
  StartDate: Date;
  RenewalDate: Date;
  EndDate: Date;
  BillingCycleStart: Date;
  BillingCycleEnd: Date;
}
export class SubscriptionCouponData {
  CouponId: string;
  Status: string;
  PercentOff: number;
  ValueOff: number;
}
export class UserBillingData {
  Invoices: UserInvoiceData[];
  PaymentMethods: UserPaymentData[];
  Subscription: UserSubscriptionData;
}

export class Subscription {
  SubscriptionId: string;
  UserId: string;
  ProductId: string;
  PlanId: string;
  MinQty: number;
  StartDate: Date;
  RenewalDate: Date;
  EndDate: Date;
  NextProductId: string;
  NextPlanId: string;
  NextStartDate: Date;
  NextRenewalDate: Date;
  NextEndDate: Date;
  FailedPayment: boolean;
  LastModified: Date;
}

export class UserLookup {
  UserId: string;
  MLSID: string;
  MLSNumericKey: number;
  Board: string;
  EmailAddress: string;
  ProvState: string;
  Country: string;
  BrokerageId: string;
  Brokerage: Brokerage;
  FirstName: string;
  LastName: string;
  ModificationTimestamp: Date;
  BrokerageMLSID: string;
  BrokerageMLSNumericKey: number;
  SearchText: string;
  FaxNumber: string;
  WorkPhoneNumber: string;
}

export class UserSearchData {
  User: User;
  Lookup: UserLookup;
}

export enum RegFlowType {
  Invite,
  Regular
}

export class StatusData {
  ContractType: ContractType;
  StatusGroup: StatusGroup;
  Count: number;
  Icon: string[];
  Name: string;
}

export enum StatusGroup {
  Active,
  ActiveOnly,
  ActiveAndAwatingSigs,
  All,
  Inactive
}

export enum TaskStatusEnum {
  Ready = 0,
  Complete = 1,
  Inactive = 2,
  Canceled = 99
}

export class ConveyancingPackage {
  Files: FileRef[];
  Message: string;

  constructor(init?: Partial<ConveyancingPackage>) {
    Object.assign(this, init);
  }
}

export class Email {
  MessageId: string;
  ParentId: string;
  SignerId: string;
  ReceiverType: ReceiverType;
  ToAddress: string;
  InvalidatedOn: Date;
  InvalidatedReason: number;
  Sent: boolean;
  SendTime: Date;
  Delivered: boolean;
  DeliveredTime: Date;
  FirstOpenedTime: Date;
  OpenedCount: number;
  LastOpenedTime: Date;
  FirstClickedTime: Date;
  ClickedCount: number;
  LastClickedTime: Date;
  ClickedUrl: string;
  Subject: string;
  AttachmentNames: string;
  Expiration: Date;
}

export enum ReceiverType {
  ContractAgent = 10,
  Signer = 20,
  Conveyancer = 30
}
