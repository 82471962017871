import { Email } from '../classes/classes';
import { FileRef } from '../forms/classes';
import { Signer } from '../utils/data/SignerUtils';

export enum EnvelopeStatus {
  InProgress = 1,
  AwaitingSignatures = 2,
  Signed = 3,
  Expired = 999
}

export enum EnvelopeWizardSteps {
  Files,
  Signers,
  SignerDetails,
  AdditionalDetails
}

export class Envelope {
  EnvelopeId: string = null;
  Number: number = null;
  UserId: string = null;
  EmailMessage: string = null;
  DateExpires: Date = null;
  DateExpiresUtc: Date = null;
  LastStep = 0;
  Status = EnvelopeStatus.InProgress;
  Archived: boolean = null;
  TZId: string = null;
  DateSent: Date = null;
  DateCompleted: Date = null;
  DateCreated: Date = null;
  LastModified: Date = null;
  AgentIP: string = null;
  FileRefs: FileRef[];
  Signers: Signer[];
  constructor(init?: Partial<Envelope>) {
    Object.assign(this, init);
  }
}

export class EnvelopeEvent {
  Envelope: Envelope = null;
  Type: string = null;
  Id: any;
  constructor(init?: Partial<EnvelopeEvent>) {
    Object.assign(this, init);
  }
}
