
export default class ConfigUtils {
  public static GetBaseApiUrl(): string { return this.GetBaseUrl() + '/api'; }
  public static GetBaseUrl(): string { return window.location.origin.replace('4200', '8080').toLowerCase(); }
  public static isDemoEnv(): boolean { return this.GetBaseUrl().indexOf('demo.dealsimple') >= 0; }
  public static isDevEnv(): boolean { return this.GetBaseUrl().indexOf('localhost') >= 0; }
  public static isProdEnv(): boolean { return this.GetBaseUrl().indexOf('app.dealsimple') >= 0; }
  public static isSbxEnv(): boolean { return this.GetBaseUrl().indexOf('sbx.dealsimple') >= 0; }
  public static isTestEnv(): boolean { return this.GetBaseUrl().indexOf('test.dealsimple') >= 0; }
}
