import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OrderPipe } from 'ngx-order-pipe';
import { Brokerage, Email, User } from '../../classes/classes';
import { Contract, ContractType } from '../../classes/contract';
import { FieldName } from '../../classes/form';
import { Signature, SignatureMode } from '../../classes/signature';
import FormUtils from '../form/FormUtils';

//#region ContractAgent
export class ContractAgent {
  Id: string = null;
  ContractId: string = null;
  UserId: string = null;
  BrokerageId: string = null;
  BrokerageUnique = true;
  SignatureId: string = null;
  SplitPercent = 0;
  IsSellerAgent = false;
  SignatureRequired = false;
  SignatureMode: SignatureMode = SignatureMode.InApp;
  Visible = false;
  Brokerage: Brokerage = null;
  Signature: Signature = null;
  User: User = null;
  SendEmail = true;
  Remove = false;
  NewRecord = true;
}
//#endregion

//#region ContractAgentUtils
export class ContractAgentUtils {
  //#region Public Functions
  static AddUser(fa: UntypedFormArray, user: User, isSellerAgent: boolean, visible = true, contractId: string = null) {

    const agents = this.GetContractAgentsBySide(fa, isSellerAgent);

    const agent = new ContractAgent();
    agent.ContractId = contractId;
    agent.UserId = user.UserId;
    agent.BrokerageId = user.Brokerage.BrokerageId;
    agent.SplitPercent = agents.length === 0 ? 100 : 0;
    agent.IsSellerAgent = isSellerAgent;
    agent.Visible = visible;
    agent.Brokerage = user.Brokerage;
    agent.User = user;

    fa.push(new UntypedFormBuilder().group(agent));
    fa.markAsDirty();
  }

  /**
      * Returns `true` if the current user is a `ContractAgent` found in `data` that equals `userId`.
      * If the result for the `ContractAgent.Visble` is false then a `false` value will return. Anything else is `false`.
      *
      * @param contract Contract to search.
      */
  static DoesAgentHaveAccess(contract: Contract, userId: string): boolean {
    if (userId === null) { return null; }

    // IN CASE OF A DOUBLE ENDS AGENT ALWAYS HAS ACCESS
    if (contract.ContractType === ContractType.Offer && contract.Prop[FieldName[FieldName.BuyerRep]] && contract.Prop[FieldName[FieldName.SellerRep]]) { return true; }

    const agent = this.GetContractAgent(contract.ContractAgents, userId);
    if (agent === null) {
      return false;
    } else {
      return agent.Visible;
    }
  }

  /**
     * Returns the first `ContractAgent` found in `data` that equals `userId` and `isSellerAgent`; `null`.
     *
     * @param data FormArray or ContractAgent[] to search
     * @param userId used to find.
     * @param isSellerAgent used to find.
     */
  static GetContractAgent(data: any, userId: string, isSellerAgent: boolean = null): ContractAgent {
    if (userId === null || data === null) { return null; }
    if (data instanceof UntypedFormArray) { data = data.value; }

    let result: ContractAgent = null;

    if (isSellerAgent !== null) {
      result = data.find((x: { UserId: string; IsSellerAgent: boolean; }) =>
        x.UserId === userId &&
        x.IsSellerAgent === isSellerAgent);
    } else {
      result = data.find((x: { UserId: string; }) => x.UserId === userId);
    }

    return result === undefined ? null : result;
  }

  static RemoveBySide(fa: UntypedFormArray, isSellerAgent = false) {
    for (let i = fa.controls.length - 1; i >= 0; i--) {
      if (fa.controls[i].get('IsSellerAgent').value === isSellerAgent) {
        fa.removeAt(i);
      }
    }
    fa.markAsDirty();
  }

  /**
        * Returns `true` if lead 'ContractAgent' found in `data` on current side has same CL brokerage as attempted other side.
        * Anything else is `false`.
        *
        * @param buyerAgents FormArray or ContractAgent[] to search
        * @param sellerAgents FormArray or ContractAgent[] to search
        */
  static IsCommonLawDoubleEnd(buyerAgents: any, sellerAgents: any): boolean {
    // DATA CHECK
    if (buyerAgents == null) { return false; }
    if (sellerAgents == null) { return false; }
    if (buyerAgents instanceof UntypedFormArray) { buyerAgents = buyerAgents.value; }
    if (sellerAgents instanceof UntypedFormArray) { sellerAgents = sellerAgents.value; }

    // GET AGENTS WITH CL BROKERAGES
    let clBuyerAgentBrokerages: string[] = buyerAgents[0] instanceof UntypedFormGroup ?
      buyerAgents.filter(x => x.value?.Brokerage?.ContractGroup?.indexOf('_CL') > -1).map(x => x.value?.BrokerageId) :
      buyerAgents.filter(x => x?.Brokerage?.ContractGroup?.indexOf('_CL') > -1).map(x => x.BrokerageId);
    let clSellerAgentBrokerages: string[] = sellerAgents[0] instanceof UntypedFormGroup ?
      sellerAgents.filter(x => x.value?.Brokerage?.ContractGroup?.indexOf('_CL') > -1).map(x => x.value?.BrokerageId) :
      sellerAgents.filter(x => x?.Brokerage?.ContractGroup?.indexOf('_CL') > -1).map(x => x.BrokerageId);

    if (clBuyerAgentBrokerages.length === 0 || clSellerAgentBrokerages.length === 0) { return false; }

    // CHECK FOR MATCHING BROKERAGES
    return clBuyerAgentBrokerages.filter(x => clSellerAgentBrokerages.includes(x)).length > 0;
  }

  /**
          * Returns `true` if any 'ContractAgent' found in `data` on current side exists on attempted other side.
          * Anything else is `false`.
          *
          * @param buyerAgents FormArray or ContractAgent[] to search
          * @param sellerAgents FormArray or ContractAgent[] to search
          */
  static IsDesignatedAgencyDoubleEnd(buyerAgents: any, sellerAgents: any): boolean {
    // DATA CHECK
    if (buyerAgents == null) { return false; }
    if (sellerAgents == null) { return false; }
    if (buyerAgents instanceof UntypedFormArray) { buyerAgents = buyerAgents.value; }
    if (sellerAgents instanceof UntypedFormArray) { sellerAgents = sellerAgents.value; }

    const buyerAgentIDs = buyerAgents[0] instanceof UntypedFormGroup ? buyerAgents.map(x => x.value.UserId) : buyerAgents.map(x => x.UserId);
    const sellerAgentIDs = sellerAgents[0] instanceof UntypedFormGroup ? sellerAgents.map(x => x.value.UserId) : sellerAgents.map(x => x.UserId);

    if (buyerAgentIDs == null) { return false; }
    if (sellerAgentIDs == null) { return false; }

    // ANY OF MY AGENTS EXIST ON OTHER SIDE OF DEAL WE HAVE A DA DOUBLE END
    if (buyerAgentIDs.filter(id => sellerAgentIDs.includes(id)).length > 0) {
      return true;
    }

    return false;
  }

  /**
            * Returns `true` curretn user id exists in seller and buyer agents.
            * Anything else is `false`.
            *
            * @param buyerAgents FormArray or ContractAgent[] to search
            * @param sellerAgents FormArray or ContractAgent[] to search
            */
  static IsAgentOnBothSides(buyerAgents: any, sellerAgents: any, currentUserId: any): boolean {
    // DATA CHECK
    if (buyerAgents == null) { return false; }
    if (sellerAgents == null) { return false; }
    if (buyerAgents instanceof UntypedFormArray) { buyerAgents = buyerAgents.value; }
    if (sellerAgents instanceof UntypedFormArray) { sellerAgents = sellerAgents.value; }

    const buyerAgentIDs = buyerAgents[0] instanceof UntypedFormGroup ? buyerAgents.map(x => x.value.UserId) : buyerAgents.map(x => x.UserId);
    const sellerAgentIDs = sellerAgents[0] instanceof UntypedFormGroup ? sellerAgents.map(x => x.value.UserId) : sellerAgents.map(x => x.UserId);

    if (buyerAgentIDs == null) { return false; }
    if (sellerAgentIDs == null) { return false; }

    if (buyerAgentIDs.includes(currentUserId) && sellerAgentIDs.includes(currentUserId)) { return true; }

    return false;
  }

  static GetContractAgentsBySide(fa: UntypedFormArray, isSellerAgent = true): AbstractControl[] {
    return fa.controls.filter(grp => grp.get('IsSellerAgent').value === isSellerAgent);
  }

  static SideHasAgentSigner(fa: UntypedFormArray, isSellerAgent = true): boolean {
    const results = fa.controls.filter(grp => grp.get('IsSellerAgent').value === isSellerAgent && grp.get('SignatureRequired').value === true);
    return results.length > 0;
  }

  /**
     * Returns true if `ContractAgent` found in `data` that equals `userId` and `isSellerAgent`; `false`.
     *
     * @param data FormArray or ContractAgent[] to search
     * @param userId used to find.
     * @param isSellerAgent used to find.
     */
  static IsContractAgent(data: any, userId: string, isSellerAgent: boolean = null): boolean {
    const result = this.GetContractAgent(data, userId, isSellerAgent);
    return result === null ? false : true;
  }

  static SideHasAgent(data: any, isSellerAgent: boolean = null): boolean {
    if (data === null) { return false; }
    if (data instanceof UntypedFormArray) { data = data.value; }
    return data.find(x => x.IsSellerAgent == isSellerAgent) === undefined ? false : true;
  }

  static Load(fa: UntypedFormArray, agents: ContractAgent[],
    replaceContractId: string = null, replaceVisible: boolean = null,
    isSellerAgent: boolean = null) {

    switch (isSellerAgent) {
      case null:
        FormUtils.clearFormArray(fa);
        break;
      case true:
      case false:
        this.RemoveContractAgentSide(fa, isSellerAgent);
        break;
    }

    agents.forEach(agent => {
      fa.push(this.Create(agent, replaceContractId, replaceVisible));
    });
  }

  static Sort(agents: ContractAgent[]): ContractAgent[] {

    const result: ContractAgent[] = [];

    // SORT AGENTS
    if (agents.length > 0 && agents[0].User != null) {
      agents = new OrderPipe().transform(agents,
        ['User.FirstName', 'User.LastName'], false, true);
    }

    agents.forEach(agent => {
      result.push(agent);
    });

    return result;
  }
  //#endregion

  //#region Private Functions
  private static Create(ca: ContractAgent, replaceContractId: string = null, replaceVisible: boolean = null): UntypedFormGroup {

    const agent = new ContractAgent();
    agent.ContractId = replaceContractId ? replaceContractId : ca.ContractId;
    agent.UserId = ca.UserId;
    agent.BrokerageId = ca.BrokerageId;
    agent.BrokerageUnique = ca.BrokerageUnique;
    agent.SplitPercent = ca.SplitPercent;
    agent.IsSellerAgent = ca.IsSellerAgent;
    agent.Visible = replaceVisible ? replaceVisible : false;
    agent.Remove = ca.Remove;

    return new UntypedFormBuilder().group(agent);
  }

  private static RemoveContractAgentSide(fa: UntypedFormArray, isSellerAgent = false) {
    for (let i = fa.controls.length - 1; i >= 0; i--) {
      if (fa.controls[i].get('IsSellerAgent').value === isSellerAgent) {
        fa.removeAt(i);
      }
    }
    fa.markAsDirty();
  }
  //#endregion
}
//#endregion
