import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AttachmentWizardStep, QRData, UploadAction } from '../attachment/classes';
import { FileDataEvent, FileRef, FileRefType } from '../forms/classes';
import ConfigUtils from '../utils/ConfigUtils';

@Injectable()
export class FileService {

  private baseUrl: string;

  //#region BEHAVIOR SUBJECT FOR CHANGE DETECTION BETWEEN ATTACHMENT HANDLER AND WIZARD
  private cdProgress = new BehaviorSubject<number>(0);
  private cdShowProgress = new BehaviorSubject<boolean>(false);
  private cdUploadSource = new BehaviorSubject<UploadAction>(null);
  private cdWizardStep = new BehaviorSubject<AttachmentWizardStep>(null);

  currentProgressCD = this.cdProgress.asObservable();
  currentShowProgressCD = this.cdShowProgress.asObservable();
  currentUploadCD = this.cdUploadSource.asObservable();
  currentWizardStepCD = this.cdWizardStep.asObservable();

  public progressCD(data: number) { this.cdProgress.next(data); }
  public showProgressCD(data: boolean) { this.cdShowProgress.next(data); }
  public uploadCD(data: UploadAction) { this.cdUploadSource.next(data); }
  public wizardStepCD(data: AttachmentWizardStep) { this.cdWizardStep.next(data); }
  //#endregion


  constructor(private http: HttpClient) {
    this.baseUrl = `${ConfigUtils.GetBaseApiUrl()}/files`;
  }

  public DeleteFile(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  public DownloadFile(id: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/${id}/download`, options);
  }

  public GetFile(id: string): Observable<FileRef> {
    return this.http.get<FileRef>(`${this.baseUrl}/${id}`);
  }

  public GetFiles(number: number, id: string): Observable<FileRef[]> {
    return this.http.get<FileRef[]>(`${this.baseUrl}/contract/${number}/${id}`);
  }

  public GetConveyancerFiles(number: number): Observable<FileRef[]> {
    return this.http.get<FileRef[]>(`${this.baseUrl}/conveyancer/${number}`);
  }

  public DownloadZipArchive(files: FileRef[]): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.post<ArrayBuffer>(`${this.baseUrl}/zip`, files, options);
  }

  public GetFilesByType(type: FileRefType = null): Observable<FileRef[]> {
    return this.http.get<FileRef[]>(`${this.baseUrl}/filterbytype/${type}`);
  }

  public GetFolders(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/folders`);
  }

  public PostFile(data: FileDataEvent): Observable<FileRef> {
    return this.http.post<FileRef>(this.baseUrl, data);
  }

  public UpdateFile(value: FileRef): Observable<FileRef> {
    return this.http.put<FileRef>(this.baseUrl, value);
  }

  public CopyFileToContract(cid: string, frid: string, isprivate = true): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${frid}/copyto/${cid}/${isprivate}`);
  }

  public GetQRCode(filedata: string): Observable<QRData> {
    const data = new FileDataEvent({
      FileData: filedata
    });
    return this.http.post<QRData>(`${this.baseUrl}/qr`, data);
  }
}
