import { NIL, v4 as uuidv4 } from 'uuid';

export default class GuidUtils {

  static GenerateGuid(): string {
    return uuidv4().toString();
  }

  static EmptyGuid(): string {
    return NIL.toString();
  }
}
