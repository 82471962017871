import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PipesModule } from '../pipes/pipes.module';
import { PageLayoutComponent } from '../shared/page-layout/page-layout.component';
import { SharedModule } from '../shared/shared.module';
import { CartComponent } from './cart/cart.component';
import { PlanComponent } from './cart/plan/plan.component';
import { CouponComponent } from './coupon/coupon.component';
import { DetailsComponent } from './details/details.component';
import { PasscodeComponent } from './passcode/passcode.component';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { RegisterModalComponent } from './register-modal/register-modal.component';
import { RegisterComponent } from './register.component';
import { RegisterService } from './service/register.service';

const routes: Routes = [
  {
    path: 'pricing',
    component: PageLayoutComponent,
    children: [
      { path: '', component: RegisterComponent }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    NgSelectModule,
    NgOptionHighlightModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    UiSwitchModule,
    ShowHidePasswordModule
  ],
  declarations: [
    RegisterModalComponent,
    DetailsComponent,
    PasscodeComponent,
    PlanComponent,
    PlanSelectionComponent,
    CartComponent,
    RegisterComponent,
    CouponComponent
  ],
  exports: [
    RegisterModalComponent,
    CouponComponent
  ],
  providers: [RegisterService]
})
export class RegisterModule { }
