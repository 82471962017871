export enum FieldDataType {
  Array = 0,
  String = 1,
  Int = 2,
  Numeric = 3,
  Boolean = 4,
  Date = 5,
  DateTime = 6,
  Currency = 7,
  Percent = 8,
  PhoneNumber = 9,
  EmailAddress = 10
}

export enum FieldControlType {
  Array = 0,
  Input = 1,
  Switch = 2,
  TextArea = 3,
  Date = 4,
  DropDown = 5,
  RadioButtonGroup = 6,
  MultiButtonGroup = 7
}

export class KeyValuePair {
  key: string;
  value: any;
}

export enum MultiOptionEnum {
  NotApplicable = -1,
  No = 0,
  Yes = 1
}

export class Form {
  FormId: string;
  ContractGroup: string;
  ContractType: string;
  Description: string;
  Fields: Field[];
}

export class Field {
  FieldId: string;
  FieldName: string;
  ParentFieldId: string;
  ParentFieldId2: string;
  Label: string;
  SummaryLabel: string;
  SummaryHeader: SummaryHeader;
  SummaryType: SummaryType;
  SummarySubHeader: string;
  SummaryConditional: string;
  Options: string;
  DefaultValue: any;
  Disabled: boolean;
  DataType: FieldDataType;
  ControlType: FieldControlType;
  TrackChange: boolean;
  Searchable: boolean;
  Synchronize: boolean;
  Remarks: string;
  MaxLength: number;
  SortOrder: number;
  DisplayOrder: number;
  Fields: Field[];

  constructor(init?: Partial<Field>) {
    Object.assign(this, init);
  }
}

export class LookupList {
  LookupListId: string;
  Text: string;
  Value: string;
  DisplayOrder: number;
}

export enum SummaryHeader {
  SearchCriteria,
  AgreementDetails,
  FeesAndLimits,
  BuyerTerms,
  AdditionalTerms,
  PropertyInfo,
  Condo,
  Inclusions,
  Tenant,
  Mobile,
  PropertySched,
  BuyerConditions,
  SellerConditions,
  PurchasePrice,
  DepositDetails,
  IncomeSchedule
}

export class SummaryField {
  Conditionals: string[] = [];
  Type: SummaryType;
  ObjectName: string[] = [];
  ReplacementNames: string[] = [];
  FieldNames: string[] = [];
  Labels: string[] = [];
  ParentIndex: number;
  SortOrder: number;
  ParentFieldId: string;
  Options: KeyValuePair[] = [];

  constructor(init?: Partial<SummaryField>) {
    Object.assign(this, init);
  }
}

export enum SummaryType {
  Break,
  Bool,
  BoolGroup,
  Currency,
  MultiChoice,
  Number,
  String,
  TextArea,
  Date,
  DateTime,
  Header,
  SectionHeader,
  Interpolation,
  Fee,
  Array,
  Condition,
  SubCondition,
  Int,
  Custom
}

export enum FieldName {
  AccessToProperty,     // for some reason, this does not work in the top position... pay attention to this.
  AddBuyerCondition,
  AddBuyerConditionComment,
  AddBuyerConditionDesc,
  AddBuyerConditionDue,
  AddBuyerConditionWaive,
  AddDeposit,
  AddDepositAmount,
  AddDepositDueDate,
  AddDepositPayMethod,
  Addendums,
  AdditionalTerms,
  AddTitleArray,
  AddTitleCondoPlan,
  AddTitleCondoUnit,
  AddTitleCondoUnitFactor,
  AddTitleType,
  AddTitleTypeOther,
  AddTitledCondo,
  AddrCity,
  AddrCountry,
  AddrLine1,
  AddrLine2,
  AddrPostalCode,
  AddrProv,
  AgentFinanciallyTied,
  AgreementEndDays,
  Agricultural,
  BPSAddress,
  BPSBoard,
  BPSBrokerage,
  BPSHoursToWaive,
  BPSIsListed,
  BPSListedWithin,
  BPSOther,
  BPSPrice,
  BPSWillBeListed,
  BackUpOffer,
  BackUpOfferComment,
  BackUpOfferContractNumber,
  BackUpOfferDate,
  BackUpOfferDetails,
  BackUpOfferExternalContractNumber,
  BackUpOfferPosition,
  BackUpOfferWaive,
  BacterialAnalysis,
  BacterialAnalysisDate,
  BuyerLawyerEmail,
  BuyerLawyerFirm,
  BuyerLawyerName,
  BuyerLawyerPhone,
  BuyerPropertySale,
  BuyerPropertySaleComment,
  BuyerPropertySaleDue,
  BuyerPropertySaleWaive,
  BuyerRep,
  CRISAdditionalInfo,
  CRISIncomeAdjustment,
  CSANumber,
  CableAvailable,
  CableFeeCR,         // was CableSatelliteFee
  CableFeeM,          // was CableFee
  CellInternetCoverageAvailable,
  ChemicalAnalysis,
  ChemicalAnalysisDate,
  Commercial,
  CompletionDate,
  Condo,
  CondoDocs,
  CondoDocsComment,
  CondoDocsDeliveryDay,
  CondoDocsDeliveryExtDay,
  CondoDocsOther,
  CondoDocsReviewBeforeDay,
  CondoDocsWaive,
  CondominiumType,
  ContractsBuyerAssumes,
  CountryResPropSched,
  CountryResPropSchedApprovalDate,
  CountryResPropSchedComment,
  CountryResPropSchedDeliveryDate,
  CountryResPropSchedWaive,
  CountryResidential,
  CustomerAckBuyerRep,
  CustomerAckSellerRep,
  DaysAfterAgrOfferCopies,
  DaysAfterAgreement,
  DepositAmount,
  DepositDueDate,
  DepositPayMethod,
  DepositTrustee,
  DowerConsentDate,
  DrillersReport,
  DrillersReportDate,
  DrivewayAccessExists,
  DrivewayAccessType,
  EBRAIfFeeLessPayment,
  EBRAIfFeeMoreRefund,
  EBRANewAgreement,
  EBRARetainer,
  EBRARetainerAmt,
  EBRARetainerOther,
  EBRARetainerPayable,
  EBRARetainerRefund,
  EBRAWholeFee,
  EasementAgainstTitle, // was EasementsAgainstTitleExist
  EasementGasLine,      // was GasLineEasementExists
  EasementOther,        // was OtherEasementExists
  EasementOtherDetails, // was OtherEasementDetails
  EasementPipeline,     // was PipelineEasementExists
  EasementPowerLine,    // was PowerLineEasementExists
  EasementWell,         // was WellEasementExists
  ElectricalAvailable,
  ElectricityServiced,
  ExcBuyerRep,
  ExcSellerRep,
  Financing,
  FinancingComment,
  FinancingDue,
  FinancingNotExceed,
  FinancingWaive,
  FlowTest,
  FlowTestDate,
  Foundation,
  FoundationConstruction,
  FuelSupplyIsElectric,
  FuelSupplyIsNaturalGas,
  FuelSupplyIsNone,
  FuelSupplyIsOther,
  FuelSupplyIsOtherDetails,
  FuelSupplyIsPropane,
  GasFeeCR,             // was GasFee
  GasFeeM,              // was GasFee
  HeatFeeCR,            // was HeatFee
  HeatFeeM,             // was HeatFee
  Id,
  Include,
  InclusionItem,
  Inclusions,
  IncomeScheduleAcres,
  IncomeScheduleExpiryDate,
  IncomeScheduleIncome,
  IncomeScheduleLeaseType,
  IncomeScheduleMeridian,
  IncomeSchedulePart,
  IncomeScheduleRange,
  IncomeScheduleRenewalDate,
  IncomeScheduleSection,
  IncomeScheduleTownship,
  IncomeSchedules,
  LandLeased,
  LandLineServiced,
  LeaseAgreementCR,     // was LeaseAgreementsExist
  LeaseAgreementM,      // was LeaseAgreementExists
  LegalHandover,
  ListingPrice,
  Make,
  ManagementCompany,
  ManagementCompanyEmail,
  ManagementCompanyPhone,
  ManagementCompanyWebsite,
  MarketArea,
  MfgYear,
  MLSNumber,          // was MLSId
  Mobile,
  Model,
  MonthlyCondoFee,
  MonthlyLeasedFee,
  MonthlyRent,
  MunicipalRoadAccess,
  NaturalGasAvailable,
  NaturalGasServiced,
  NewBuild,
  NewRecord,
  NonExcBuyerRep,
  NumDwellings,
  OCDollarAmt,
  OCDollarPctAmt,
  OCDollarPctPct,
  OCPctFixedPct,
  OCPctSplitFirstAmt,
  OCPctSplitFirstAmtPct,
  OCPctSplitPctRem,
  OCTypeDollar,
  OCTypeDollarPct,
  OCTypePctFixed,
  OCTypePctSplit,
  OccRestrictionCR,     // was OccupancyRestrictions
  OccRestrictionM,      // was OccRestriction
  OccRestrictionNotes,
  OfferExpires,
  OtherCRDetails,
  OtherCondoDetails,
  OtherMobileDetails,
  OtherPropertyInformation,
  OtherProvidedToProperty,
  OtherReport,
  OtherReportDetails,
  OtherServices,
  PNPExpensiveDefects,
  PNPGovNotices,
  PNPMaterialDefects,
  PNPMaterialDisclosure,
  PNPMissingPermits,
  PNPOtherDefects,
  ParkingAssigned,
  ParkingAssignedStall,
  ParkingLeased,
  ParkingLeasedStall,
  ParkingNonTitled,
  PayBalanceDays,
  PhoneLineBalance,
  PhoneLinePaid,
  Price,
  ProjectName,
  PropInspection,
  PropInspectionComment,
  PropInspectionDue,
  PropInspectionWaive,
  PropertyType,
  ProposedPossession,
  ReasonableExpense,
  Referral,
  ReferralAddClientInfo,
  ReferralBrokerAddress,
  ReferralBrokerCity,
  ReferralBrokerEmailAddress,
  ReferralBrokerName,
  ReferralBrokerPhoneNumber,
  ReferralBrokerPostalCode,
  ReferralBrokerProvState,
  ReferralBrokerageId,
  ReferralEmailAddress,
  ReferralFeeDollar,
  ReferralFeeDollarAmt,
  ReferralFeePctFixed,
  ReferralFeePctFixedPct,
  ReferralFirstName,
  ReferralLastName,
  ReferralManualEntry,
  ReferralPhoneNumber,
  ReferralUserId,
  Remove,
  Residential,
  S1LegallyMarried,
  S1OrSpouseResided,
  S1SpouseName,
  SecurityDepositReq,
  SellerCondition,
  SellerConditionComment,
  SellerConditionDesc,
  SellerConditionDue,
  SellerConditionWaive,
  SellerLawyerEmail,
  SellerLawyerFirm,
  SellerLawyerName,
  SellerLawyerPhone,
  SellerRep,
  SepticSystem,
  SepticSystemComment,
  SepticSystemDate,
  SepticSystemHoldingTankSize,
  SepticSystemIsHoldingTank,
  SepticSystemIsNone,
  SepticSystemIsOther,
  SepticSystemIsOtherDetails,
  SepticSystemIsTankField,
  SepticSystemWaive,
  SerialNumber,
  ServicesAdditional,
  ServicesLockbox,
  ServicesSign,
  Splits,
  SplitFullName,
  SplitPercent,
  StorageAssigned,
  StorageAssignedStall,
  StorageLeased,
  StorageLeasedStall,
  StorageNonTitled,
  StrikeRPR,
  SubdivisionPlan,
  SubdivisionPlanComment,
  SubdivisionPlanDate,
  SubdivisionPlanWaive,
  SurfaceContracts,
  TCDollarAmt,
  TCDollarPctAmt,
  TCDollarPctPct,
  TCPctFixedPct,
  TCPctSplitFirstAmt,
  TCPctSplitFirstAmtPct,
  TCPctSplitPctRem,
  TCTypeDollar,
  TCTypeDollarPct,
  TCTypePctFixed,
  TCTypePctSplit,
  Tenant,
  TenantAgreementInDefault,     // Tenant added to front
  TenantAgreementProvidedBy,    // Tenant added to front
  TenantDamageDeposit,          // Tenant added to front
  TenantDamageDepositPaidOn,    // Tenant added to front
  TenantInPoss,
  TenantIsDamageDeposit,        // Tenant added to front
  TenantLeaseExpiry,            // Tenant added to front
  TenantLeaseStart,             // Tenant added to front
  TenantMoveInInspectionExists, // Tenant added to front
  TenantMoveInReportProvidedBy, // Tenant added to front
  TenantName,
  TenantOther,                  // Was OtherDetails
  TenantOtherDetails,           // Was Details
  TenantPropMgmtContract,       // Tenant added to front
  TenantRenewalRights,
  TenantRenewalRightsDetails,
  TenantRent,                   // Tenant added to front
  TenantWrittenAgreementExists, // Tenant added to front
  Tenants,
  Titled,
  TitledAcres,
  TitledBlock,
  TitledCondoPlan,
  TitledCondoUnit,
  TitledCondoUnitFactor,
  TitledLINC,
  TitledLot,
  TitledMeridian,
  TitledMetsBoundsOther,
  TitledPart,
  TitledPlan,
  TitledQuarter,
  TitledRge,
  TitledRuralAddressId,
  TitledSec,
  TitledSubdivName,
  TitledSubdivPlan,
  TitledSubdivUnit,
  TitledTwp,
  UnpaidFeesBuyerAssumes,
  UtilityContractsAssumed,
  UtilityPaid,
  WaterCondition,
  WaterConditionComment,
  WaterConditionDate,
  WaterConditionWaive,
  WaterFeeCR,
  WaterFeeM,
  WaterRightsIncluded,
  WaterRightsPriorityNo,
  WaterRightsRegistered,
  WaterSupplyCisternSize,
  WaterSupplyIsCistern,
  WaterSupplyIsCoop,
  WaterSupplyIsDrilledWell,
  WaterSupplyIsMunicipal,
  WaterSupplyIsOther,
  WaterSupplyIsOtherDetails,
  WellWaterReportsFee
}

export namespace FieldName {
  export function toString(value: FieldName): string { return FieldName[value]; }
  export function parse(value: string): FieldName { return FieldName[value]; }
}