export class Signature {
  Id: string;
  SignerId: string;
  SignatureData: string;
  InitialData: string;
  TimeStamp: Date;
  TimeStampLocal: Date;
  SignatureType: number;
  SignedInCity: string;
  SignatureMode: SignatureMode;
  IPAddress: string;

  constructor(init?: Partial<Signature>) {
    Object.assign(this, init);
  }
}

export enum SignatureType {
  Initial = 100,
  Signature = 200
}
export class SignatureConfig {
  RequiresInitials: boolean;
  Questions: string[];
  Signature: string;
  Initial: string;
  SignaturePreview: string;
  InitialPreview: string;
}

export enum SigningStep {
  RedFlag = 0,
  Location = 1,
  Initial = 2,
  Signature = 3,
  Preview = 4
}

export enum SignatureMode {
  InApp = 1,
  Email = 2,
  EmailSignPDF = 20,
  Paper = 3,
  PaperSignature = 100
}

export class SigningData {
  Initial: string;
  SignedInCity: string;
  Signature: string;
  OfferWetSignature: boolean;
  IPAddress: string;
}

export class SignatureArtifacts {
  SignatureBorder: string;
  InitialBorder: string;
  TypedSignature: string;
  TypedInitial: string;
}
