import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractType } from '../classes/contract';
import { Form } from '../classes/form';
import ConfigUtils from '../utils/ConfigUtils';

@Injectable()
export class FormService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${ConfigUtils.GetBaseApiUrl()}/forms`;
  }

  public GetForm(contractId: string, ct: ContractType): Observable<Form> {
    return this.http.get<Form>(`${this.baseUrl}/${contractId}/${ct}`);
  }
}
