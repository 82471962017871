import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contract, ContractTemplate } from '../classes/contract';
import ConfigUtils from '../utils/ConfigUtils';
import { Signature, SignatureArtifacts, SignatureConfig, SigningData } from '../classes/signature';
import { Envelope } from '../envelopes/classes';
import { environment } from '../../environments/environment';
import GuidUtils from '../utils/GuidUtils';

@Injectable()
export class SignatureService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/signature';
  }

  getSignatureConfig(parentId: string, signerId: string): Observable<SignatureConfig> {
    return this.http.get<SignatureConfig>(`${this.baseUrl}/GetSignatureConfig/${parentId}/${signerId}`);
  }

  getSignatureArtifacts(parentId: string): Observable<SignatureArtifacts> {
    return this.http.get<SignatureArtifacts>(`${this.baseUrl}/GetSignatureArtifacts/${parentId}`);
  }

  UpdatePreview(data: SignatureConfig): Observable<SignatureConfig> {
    return this.http.post<SignatureConfig>(`${this.baseUrl}/UpdatePreview`, data);
  }

  getTemplateSigners(templateId: string): Observable<ContractTemplate> {
    return this.http.get<ContractTemplate>(`${this.baseUrl}/GetTemplateSigners/${templateId ?? GuidUtils.EmptyGuid()}`);
  }
  postMasterSig(parentId: string, fileRefId: string, signerId: string, data: SigningData): Observable<Signature> {
    return this.http.post<Signature>(`${this.baseUrl}/master/${parentId}/${fileRefId}/${signerId}`, data);
  }
  post(contractId: string, data: Signature): Observable<Contract> {
    return this.http.post<Contract>(`${this.baseUrl}/${contractId}`, data);
  }

  postEnvelopeSig(envelopeId: string, fileRefId: string, signerId: string, signatureId: string, singature: Signature): Observable<Envelope> {
    return this.http.post<Envelope>(`${this.baseUrl}/sign/${envelopeId}/${fileRefId}/${signerId}/${signatureId}`, singature);
  }

  postRemoteSig(data: Signature): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/PostRemoteSig`, data);
  }
}
