import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { SiteLayoutComponent } from '../layout/site-layout/site-layout.component';
import { AuthService, RoleType, RoleUtils } from '../services/auth.service';
import FormUtils from '../utils/form/FormUtils';
import ValidatorUtils from '../utils/form/ValidatorUtils';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  frm: UntypedFormGroup;
  success = true;
  qsId: string = null;

  constructor(private svc: AuthService,
    public site: SiteLayoutComponent,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    // ALWAYS LOGOUT WHEN ON LOGIN PAGE
    this.svc.Logout();
    this.success = true;
    this.qsId = this.route.snapshot.queryParams['id'];

    if (this.frm) {
      FormUtils.resetFormGroupValidators(this.frm);
    }

    // NO ID or INVITE, ASSUME THAT IT IS A STANDARD USER LOGIN; ELSE IT WILL BE A SIGNER/INVITE LOGIN
    if (!this.qsId) {
      this.frm = this.fb.group({
        EmailAddress: [null, ValidatorUtils.ValidEmailRequiredSync()],
        Password: [null, [Validators.required, Validators.minLength(8)]],
        PassCode: null
      });
    } else {
      this.frm = this.fb.group({
        EmailAddress: null,
        Password: null,
        PassCode: null
      });

      // TRY TO LOGING USING ENCRYPTED ID
      this.site.ShowSpinner();
      this.svc.Post(this.qsId)
        .subscribe(
          () => {
            this.success = true;
            this.LoginAsOther();
          },
          ex => {
            this.success = false;
            this.site.modal.ShowError(ex);
            this.site.router.navigate(['/']);
          }
        );
    }

    if (this.route.snapshot.queryParams['m'] === 'sigscomplete') {
      setTimeout(() => {
        this.site.modal.ShowModal(`Thank you for using DealSimple! You will automatically
        receive a copy of the files once all signatures have been
        collected.`, 'Signatures Completed');
      }, 100);
    }
  }

  public Login(): void {

    // HACK: GOOGLE CHROME ON iOS ONLY HAS A WEIRD ISSUE WITH AUTOFILL.
    // SO I END UP GETTING TEXT VIA JQUERY IF IT IS A PROBLEM AND SETTING
    // THINGS MANUALLY TO GET PAST THE ISSUE.
    const pwdHack: string = $('#Password').val();
    const emailHack: string = $('#EmailAddress').val();
    const acEmail: AbstractControl = this.frm.get('EmailAddress');
    const acPassword: AbstractControl = this.frm.get('Password');

    if (acEmail.value !== emailHack) { acEmail.setValue(emailHack); }
    if (acPassword.value !== pwdHack) { acPassword.setValue(pwdHack); }

    this.success = this.frm.status === 'INVALID' ? false : true;
    if (!this.success) { return; }

    this.site.ShowSpinner();

    this.svc.Login(this.frm.get('EmailAddress').value, this.frm.get('Password').value)
      .pipe(first())
      .subscribe(
        () => this.Login_OnSuccess(),
        error => {
          console.log(error);
          this.site.modal.ShowError(error);
        }
      );
  }

  private LoginAsOther(): void {

    this.success = this.frm.status === 'INVALID' ? false : true;
    if (!this.success) {
      this.site.HideSpinner();
      return;
    }

    // TRY LOGGING IN
    this.svc.LoginOther(this.qsId)
      .pipe(first())
      .subscribe(
        () => {
          switch (this.svc.CurrentUser.Role) {
            case RoleType.Signer:
              this.site.router.navigate(['/contract']);
              break;
            case RoleType.EnvelopeSigner:
              this.site.router.navigate(['/sign']);
              break;
            default:
              if (RoleUtils.HasSome(this.svc.CurrentUser.Role, RoleType.Guest | RoleType.Conveyancer | RoleType.Agent)) {
                this.Login_OnSuccess();
              }
              break;
          }
        },
        error => this.site.modal.ShowError(error)
      );
  }

  private Login_OnSuccess() {

    // ADMIN USERS ALWAYS LOG INTO THE ADMIN ROUTE
    if (this.svc.CurrentUser.Role === RoleType.Admin) {
      this.NavigateTo('/admin');
      return;
    } else if (this.svc.CurrentUser.User.ForceChangePassword) {
      this.NavigateTo('/profile/password');
      return;
    } else if (this.svc.CurrentUser.User.ForceUpdatePaymentMethod) {
      // IF FORCE UPDATE PAYMENT METHOD USER CANNOT ACCESS ANY OTHER ROUTES
      this.NavigateTo('/profile/billing/paymethod');
      return;
    }

    // ACCOUNT EXISTS LOGIC RUNS HERE
    const returnUrl = this.site.route.snapshot.queryParams['returnUrl'] || '/home';
    this.NavigateTo(returnUrl);
  }

  private NavigateTo(path: string = '/') {
    this.site.router.navigate([path]);
    this.site.HideSpinner();
  }
}


@Pipe({
  name: 'maintenance'
})
export class MaintenancePipe implements PipeTransform {

  transform(maintenanceWindow: { start: Date, end: Date; }): boolean {
    if (!maintenanceWindow || !maintenanceWindow.start || !maintenanceWindow.end) { return true; }
    return maintenanceWindow.start.toString() >= new Date().toISOString();
  }
}
