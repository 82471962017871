import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { TilesModule } from './../tiles/tiles.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewersModule } from './../viewers/viewers.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthGuardConveyancer } from './../guards/auth.guard.conveyancer';
import { PageLayoutComponent } from './../shared/page-layout/page-layout.component';
import { Routes, RouterModule } from '@angular/router';
import { ConveyancerViewComponent } from './conveyancer-view/conveyancer-view.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFilesComponent } from './list-files/list-files.component';

const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuardConveyancer],
    children: [
      { path: ':number', component: ConveyancerViewComponent, canActivate: [AuthGuardConveyancer] }
    ]
  }
];

@NgModule({
  declarations: [
    ConveyancerViewComponent,
    ListFilesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FontAwesomeModule,
    ViewersModule,
    NgbDropdownModule,
    MatCheckboxModule,
    TilesModule,
    AngularImageViewerModule,
  ]
})
export class ConveyancerModule { }
