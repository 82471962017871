<section class="pricing py-1">
  <div class="container">
    <div class="row">
      <div class="col-xl-2 col-lg-1"></div>
      <div *ngFor="let plan of plans" class="col-xl-4 col-lg-5 col-md-6">
        <div class="card mb-5 mb-lg-0">
          <div class="card-body card-{{plan.nickname}}">
            <h5 class="card-title text-uppercase text-center">{{plan.tiers ? ('Team ' + plan.nickname) : plan.nickname}}
            </h5>
            <h5 class="card-subtitle text-muted text-uppercase text-center">{{GetSavings(plan)}}</h5>
            <h6 class="card-price text-center">${{GetPrice(plan)}}<span
                class="period text-muted">/{{plan.interval}}</span></h6>
            <div class="pricing text-muted text-center tax-text"><i>*Tax not included</i></div>
            <div class="card-banner">
              Introductory pricing available for RASCA
            </div>
            <ul *ngIf="!plan.tiers" class="fa-ul">
              <li><span class="fa-li"><i class="fas fa-check"></i></span><b>Billed {{plan.nickname}}</b></li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>
                <b>AREA and Industry Forms</b>
                <ul>
                  <li>All forms, always up to date</li>
                  <li>The right forms at the right time</li>
                  <li>Smart form selection</li>
                </ul>
              </li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>
                <b>Digital Signatures</b>
                <ul>
                  <li>Smart tagging, never miss an initial</li>
                  <li>Unlimited Signatures</li>
                  <li>Signing Certificates</li>
                </ul>
              </li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>
                <b>Unlimited file storage</b>
                <ul>
                  <li>File storage for <em>ALL</em> your documents</li>
                </ul>
              </li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>
                <b>Mobile Friendly</b>
                <ul>
                  <li>Available on all mobile devices</li>
                  <li>Web app</li>
                </ul>
              </li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>
                <b>Auto populating in-app Calendar</b>
                <ul>
                  <li>Your calendar changes as your contracts change</li>
                </ul>
              </li>
            </ul>
            <ul *ngIf="plan.tiers" class="fa-ul">
              <li><span class="fa-li"><i class="fas fa-check"></i></span><b>Billed Monthly on a Yearly Contract</b></li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Everything from Single Agent Subscriptions</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Manage Team Member Access (<a
                  href="http://help.dealsimple.ca/en/articles/3162955-my-brokerage-is-interested-in-your-product-can-we-buy-licenses-for-all-of-our-agents">Learn
                  More</a>)</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Single Invoice for Multiple Users</li>
            </ul>
            <div *ngIf="!plan.tiers" class="d-grid col-12">
              <button type="button" class="btn btn-primary text-uppercase button-{{plan.nickname}}"
                (click)="SelectPlan(plan)">Buy
                Plan</button>
            </div>

            <div *ngIf="plan.tiers" class="btn-group button-{{plan.nickname}}" class="col-12">
              <button type="button" class="btn btn-primary text-uppercase" (click)="SelectPlan(plan)">Buy
                {{quantity}} agents</button>
              <div class="btn-group" ngbDropdown role="group">
                <button id="dropdownConfig" class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button *ngFor="let num of quantityArr;" ngbDropdownItem (click)="quantity = num;">{{num}}</button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>