import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ControlContainer } from '@angular/forms';
import { Observable } from 'rxjs';
import { SignatureMode } from '../../classes/signature';
import { NewDecimalAllowZeroPipe } from '../../pipes/new-decimal-allow-zero.pipe';
import { NewDecimalPipe } from '../../pipes/new-decimal.pipe';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';
import { Signer } from '../data/SignerUtils';
import { ContractAgent } from '../data/ContractAgentUtils';
import { ContractOwner } from '../data/ContractOwnerUtils';
import DateUtils from '../DateUtils';

export default class FormUtils {

  static getObjectValue(obj: any, propertyPath: string, dateValue: boolean = false): any {
    let value: any = null;

    propertyPath.split('.').some(property => {
      if (value === null) {
        if (obj[property] === null || obj[property] === undefined) {
          return true;
        } else {
          value = obj[property];
          if (dateValue) {
            // ADDED LOGIC TO DEAL WITH NESTED DATES.
            if (propertyPath.split('.').length > 1) {
              value = obj;
              propertyPath.split('.').forEach(x => value = value[x]);
            } else {
              value = obj[propertyPath];
            }
            value = DateUtils.FixDate(value);
            return value;
          }
        }
      } else {
        if (value[property] === null || value[property] === undefined) {
          value = null;
          return true;
        } else {
          value = value[property];
        }
      }
    });
    return value;
  }

  static boolToYesNo(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  static boolToWaiveNonWaive(value: boolean): string {
    return value === null ? '' : value === true ? 'Waived' : 'Non-Waived';
  }

  static RemoveControl(fg: UntypedFormGroup, ctrlName: string, setToNull = false) {
    if (fg.get(ctrlName)) {
      fg.removeControl(ctrlName);
    }

    if (setToNull) {
      fg.addControl(ctrlName, new UntypedFormControl(null));
    }
  }

  static ConcatContactNames(data: Signer[], isSeller = false): string {
    let result = '';
    const filteredContacts = data.filter(contact => contact.IsSeller === isSeller);
    filteredContacts.forEach((item, i) => {
      result += item.FirstName + ' ' + item.LastName;
      if (filteredContacts.length !== (i + 1)) {
        result += ', ';
      }
    });

    // SET TO NOT SPECIFIED BY DEFAULT
    if (result === '') {
      result = 'Not Specified';
    }

    return result;
  }

  static ConcatOwnerNames(data: ContractOwner[], isSeller = false): string {
    let result = '';
    const filteredOwners = data.filter(owner => owner.IsSeller === isSeller && !owner.Remove);
    filteredOwners.forEach((item, i) => {
      result += item.Name;
      if (filteredOwners.length !== (i + 1)) {
        result += ', ';
      }
    });

    // SET TO NOT SPECIFIED BY DEFAULT
    if (result === '') {
      result = isSeller ? 'No Seller' : 'No Buyer';
    }

    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  static ConcatAgentNames(data: ContractAgent[], isSellerAgent = false): string {
    let result = '';
    const filteredAgents = data.filter(agent => agent.IsSellerAgent === isSellerAgent && !agent.Remove);
    filteredAgents.forEach((item, i) => {
      result += item.User.FirstName + ' ' + item.User.LastName;
      if (filteredAgents.length !== (i + 1)) {
        result += ', ';
      }
    });

    // SET TO NOT SPECIFIED BY DEFAULT
    if (result === '') {
      result = 'No Agent';
    }

    return result;
  }

  static concatUserNames(data: any[]): string {
    let result = '';
    data.forEach((item, i) => {
      if (item.User !== null) {
        result += item.User.FirstName + ' ' + item.User.LastName;
        if (data.length !== (i + 1)) {
          result += ', ';
        }
      }
    });

    return result;
  }

  static clearFormArray(fa: UntypedFormArray) {
    while (fa.length !== 0) {
      fa.removeAt(0);
    }
  }

  static formatDecimalCtrl(control: AbstractControl, isPercent = false, decimals = 2, allowZero = false): void {
    if (allowZero) {
      if (isPercent) {
        const n: number = new NewDecimalAllowZeroPipe().transform(control.value, decimals, true);
        if (n > 100) {
          control.setValue('100');
        }
      }
      const result = new NewDecimalAllowZeroPipe().transform(control.value);
      control.setValue(result);
    } else {
      if (isPercent) {
        const n: number = new NewDecimalPipe().transform(control.value, decimals, true);
        if (n > 100) {
          control.setValue('100');
        }
      }
      const result = new NewDecimalPipe().transform(control.value);
      control.setValue(result);
    }
  }

  static formatPhoneNumberCtrl(control: AbstractControl): void {
    if (control.value === null) {
      return;
    }

    control.setValue(new PhoneNumberPipe().transform(control.value));
  }

  static initContactSignatureValidation(fa: UntypedFormArray) {
    fa.controls.forEach(grp => {
      if (grp.get('SignatureMode').value === SignatureMode.InApp) {
        this.addRequiredValidation(grp.get('Signature'));
      }
    });
  }

  static initSignatureValidation(fa: UntypedFormArray) {
    fa.controls.forEach(grp => {
      this.addRequiredValidation(grp.get('Signature'));
    });
  }

  static addRequiredNoWhiteSpaceValidation(control: AbstractControl): AbstractControl {
    control.setValidators([Validators.required, this.noWhitespaceValidator]);
    control.updateValueAndValidity();
    return control;
  }

  static addRequiredValidation(control: AbstractControl): AbstractControl {
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
    return control;
  }

  static addMinLengthValidator(control: AbstractControl, minLength = 10) {
    control.setValidators([Validators.minLength(minLength)]);
    control.updateValueAndValidity();
    return control;
  }

  static addRequiredTrueValidation(control: AbstractControl): AbstractControl {
    control.setValidators([Validators.requiredTrue]);
    control.updateValueAndValidity();
    return control;
  }

  static addRequiredFalseValidation(control: AbstractControl): AbstractControl {
    control.setValidators([this.requiredFalse]);
    control.updateValueAndValidity();
    return control;
  }

  static addRequiredNotOtherValidation(control: AbstractControl): AbstractControl {
    control.setValidators([Validators.required, this.requiredNotOther]);
    control.updateValueAndValidity();
    return control;
  }

  static addRequiredPatternValidation(control: AbstractControl, pattern: string): AbstractControl {
    control.setValidators([Validators.required, Validators.pattern(pattern)]);
    control.updateValueAndValidity();
    return control;
  }

  static noWhitespaceValidator(control: AbstractControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  static resetControlValidators(ctrl: AbstractControl) {
    ctrl.clearValidators();
    ctrl.clearAsyncValidators();
    ctrl.updateValueAndValidity();
  }

  static resetFormGroupValidators(fg: UntypedFormGroup) {
    Object.keys(fg.controls).forEach(key => {
      if (fg.get(key).errors != null) {
        this.resetControlValidators(fg.get(key));
      }
    });
  }

  static resetFormArrayValidators(fa: UntypedFormArray) {

    // IF ARRAY HAS VALIDATORS ATTACHED TO IT, CLEAR IT
    if (fa.errors !== null) {
      fa.clearValidators();
      fa.updateValueAndValidity();
    }

    // RESET VALIDATORS INSIDE ARRAY
    fa.controls.forEach(fg => {
      this.resetFormGroupValidators(fg as UntypedFormGroup);
    });
  }

  static ResetValidators(controls: AbstractControl[]): AbstractControl[] {
    controls.forEach(ctrl => { this.resetControlValidators(ctrl); });
    return [];
  }

  static scrollToTop(modal: boolean = false): void {
    modal ? document.getElementById('modalTop').scrollIntoView() : window.scrollTo(0, 0);
  }

  static scrollToBottom(delayed = false): void {
    if (!delayed) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      setTimeout(function () {
        window.scrollTo(0, document.body.scrollHeight);
      }, 400);
    }
  }

  static ToggleOtherField(sourceChecked: boolean, sourceCtrl: string, toggleCtrl: string, fg: UntypedFormGroup) {
    fg.get(sourceCtrl).setValue(sourceChecked);
    if (sourceChecked) {
      fg.get(toggleCtrl).setValue(false);
    }
  }

  static ShowControlError(ctrl: AbstractControl): boolean {
    if (ctrl.errors && (ctrl.dirty || ctrl.touched)) {
      return true;
    }
    return false;
  }

  static ShowFormArrayError(fa: UntypedFormArray): boolean {
    return fa.controls.some(grp => grp.errors && (grp.dirty || grp.touched));
  }

  static FormGroupHasError(fg: UntypedFormGroup, touch = false): boolean {

    // TOUCH CONTROLS IF NEEDED
    if (touch) {
      Object.keys(fg.controls).forEach(key => {
        fg.get(key).markAsTouched();
      });
    }

    // LOOP THROUGH EACH CONTROL TO DETERMINE IF THERE IS AN ERROR
    let err = false;
    Object.keys(fg.controls).forEach(key => {
      const ctrl = fg.get(key);
      if (ctrl.errors && (ctrl.dirty || ctrl.touched)) { err = true; }
    });

    return err;
  }

  static isControlEmpty(ctrl: AbstractControl): boolean {
    if (ctrl.value === null) {
      return true;
    }

    const ctrlLen: number = String(ctrl.value).trim().length;
    if (ctrlLen === 0) {
      ctrl.setValue(null);
      return true;
    }

    return false;
  }

  static requiredNotOther(control: AbstractControl): {[key: string]: boolean} | null {
    if (control.value !== undefined && control.value === 'Other') {
      return {'requiredNotOther': true};
    }
    return null;
  }

  static requiredFalse(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && (control.value)) {
      return { 'requiredFalse': true };
    }
    return null;
  }

  /**
     * Append `any[]` to `FormArray`.
     *
     * @param fa FormArray to append to
     * @param data any[] to append to FormArray
     */
  public static AppendToFormArray(fa: UntypedFormArray, data: any[]) {
    const fb = new UntypedFormBuilder();
    data.forEach(item => { fa.push(fb.group(item)); });
    fa.markAsDirty();
  }
  /**
     * Append `any[]` to `FormArray`.
     *
     * @param fa FormArray to append to
     * @param fg FormGroup to append to FormArray
     */
   public static AppendFormGroupToFormArray(fg: UntypedFormGroup, fa: UntypedFormArray, fgReset = true) {
    const fb = new UntypedFormBuilder();
    fa.push(fb.group(fg.getRawValue()));
    fa.markAsDirty();

    if (fgReset) { fg.reset(); }
  }
  /**
     * Clears `FormArray` and loads `any[]`.
     *
     * @param fa FormArray to load
     * @param data any[] to add to FormArray
     */
  public static LoadFormArray(fa: UntypedFormArray, data: any[]) {
    const fb = new UntypedFormBuilder();
    fa.clear();
    data.forEach(item => { fa.push(fb.group(item)); });
  }

  /**
   * Adds or removes a child `FormGroup` and populates the data if necessary.
   *
   * @param parent Parent FormGroup that will have a child FormGroup added or removed.
   * @param fgName Name of child FormGroup.
   * @param data Data will determine whether the FormGroup is created or not.
   */
  public static LoadSubFormGroup(parent: UntypedFormGroup, fgName: string, data: any) {

    // CHECK IF SUB FORMGROUP ALREADY EXISTS
    const fg = parent.get(fgName);
    if (fg instanceof UntypedFormGroup) {
      // IF DATA NULL, RESET THE FORM GROUP TO NULL
      if (data === null) {
        parent.removeControl(fgName);
        parent.addControl(fgName, new UntypedFormControl(null));
      }
    } else {
      parent.removeControl(fgName);
      parent.addControl(fgName, data !== null ?
        new UntypedFormBuilder().group(data) :
        new UntypedFormControl(null));
    }
  }

  /**
     * If data is null, return a blank `FormContrl` otherwise a `FormGroup`.
     *
     * @param data any object used to create FormGroup.
     */
  public static CreateFormGroupIfNotNull(data: any): any {
    return data === null ? new UntypedFormControl(null) : new UntypedFormBuilder().group(data);
  }

  /**
   * Safely creates a new FormArray that is attached for another FormGroup.
   *
   * @param fg FormGroup that the FormArray will belong to.
   * @param faName Name of FormArray to create.
   */
  public static CreateFormArrayIfNull(fg: UntypedFormGroup, faName: string): UntypedFormArray {

    if (fg.get(faName) instanceof UntypedFormArray) {
      console.log('exists!!!');
      return fg.get(faName) as UntypedFormArray;
    } else {
      console.log('created!!!');
      fg.removeControl(faName);
      fg.addControl(faName, new UntypedFormArray([new UntypedFormControl()]));
    }

    return fg.get(faName) as UntypedFormArray;
  }

  public static ReadFile(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Observable(observer => {
      reader.onloadend = () => {
        observer.next(reader.result.toString());
        observer.complete();
      };
    });
  }
}
