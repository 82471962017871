import { ContractTemplate } from './../classes/contract';
import { ContractAddendum, SubContractType } from '../classes/contract';
import { Signature } from '../classes/signature';

export enum FileRefType {
  RealtorFile = 1,
  MyFile = 2,
  ContractFile = 3,
  EnvelopeFile = 4,
  AddendumFile = 5,
  PrepFile = 10,
  PartiallySignedFile = 11,
  SignedFile = 12,
  MergedFile = 13,
  MasterFINTRAC = 20,
  FinalFINTRAC = 21,
  SignCert = 90,
  TradeReport = 100
}

export class FileRef {
  FileRefId: string = null;
  FileRefType: FileRefType = null;
  ParentId: string = null;
  ParentNumber: number = null;
  ParentRev: number = null;
  ContractGroup: string = null;
  FolderNameTop: string = null;
  FolderName: string = null;
  SFolderName: string = null;
  Filename: string = null;
  FileExt: string = null;
  NumPages: number = null;
  SortOrder: number = null;
  SearchText: string = null;
  Description: string = null;
  IsAppendix = false;
  SubContractType: SubContractType = null;
  TemplateId: string = null;
  UserId: string = null;
  EffectiveDate: Date = null;
  EndDate: Date = null;
  DateCreated: Date = null;
  LastModified: Date = null;
  XFDFData: string = null;
  Finalized: boolean = null;
  FileRefSignerSigs: FileRefSignerSig[] = null;
  Version: number = null;
  IsPrivate = true;
  IsSecondaryFile: boolean = null;
  ContractTemplate: ContractTemplate = null;
  constructor(init?: Partial<FileRef>) {
    Object.assign(this, init);
  }
}

export class FileRefSignerSig {
  FileRefId: string = null;
  SignerId: string = null;
  SignatureId: string = null;
  Signature: Signature = null;
  constructor(init?: Partial<FileRefSignerSig>) {
    Object.assign(this, init);
  }
}

export class FileDataEvent {
  FileData: string | ArrayBuffer = null;
  FileRef: FileRef = null;
  ContractAddendum: ContractAddendum = null;
  Type: string = null;
  constructor(init?: Partial<FileDataEvent>) {
    Object.assign(this, init);
  }
}

export class SearchFileData {
  FolderName: string = null;
  FileDataEvents: FileDataEvent[] = [];
  constructor(init?: Partial<SearchFileData>) {
    Object.assign(this, init);
  }
}

export class FintracData {
  FileData: string = null;
  ContractId: string = null;
  ContactId: string = null;
  Finalize: boolean = true;
  constructor(init?: Partial<FintracData>) {
    Object.assign(this, init);
  }
}
