import { Association } from '../utils/data/AssociationUtils';
import { ContractAgent } from '../utils/data/ContractAgentUtils';
import { ContractOwner } from '../utils/data/ContractOwnerUtils';
import { Signer } from '../utils/data/SignerUtils';

export enum ContractType {
  Buyer = 100,
  Listing = 200,
  Offer = 300,
  Schedule = 400
}

export enum SubContractType {
  Base = 0,
  Counter = 1,
  ARBBS = 2,
  CA = 3,
  Amendment = 10,
  Waive = 20,
  SellerNotice = 21,
  CondoNotice = 22,
  NonWaive = 29,
  Termination = 30,
  BuyerVoid = 31,
  SellerVoid = 32,
  CRG = 10008
}

export enum ContractStatus {
  InProgress = 10,
  AwaitingSignatures = 11,
  AwaitingSubmission = 12,
  Active = 13,
  Submitted = 14,
  Amended = 23,
  Terminated = 33,
  Accepted = 103,
  Rejected = 113,
  Countered = 123,
  ConditionsWaived = 133,
  ConditionsNonWaived = 134,
  Firm = 163,
  Closed = 173,
  ExpiryPending = 998,
  Expired = 999,
  InPlay = 2000
}

export enum PaperType {
  DSDigital = 0,
  DSPaper = 1,
  NonDS = 2
}

export enum SignerType {
  Normal = 10,
  Broker = 20
}

export enum ContractViewStep {
  SelectSigner = 0,
  ViewContract,
  Sign,
  ViewPackage
}

export class Contract {
  AgentFullName: string = null;
  AgentIP: string = null;
  AgreementEnd: Date = null;
  AgreementEndTime: string = null;
  AgreementEndUtc: string = null;
  AgreementStart: Date = null;
  AgreementStartTime: string = null;
  AgreementStartUtc: string = null;
  AutoSend: boolean = null;
  BrokerageId: string = null;
  BrokerageName: string = null;
  BuyerAutoSendToConveyancing: boolean = null;
  BuyerConveyancingMessage: string = null;
  ContractGroup: string = null;
  ContractId: string = null;
  ContractType: ContractType = null;
  DateCreated: Date = null;
  FileArchived = false;
  FormId: string = null;
  IsCurrent = false;
  LastModified: Date = null;
  LastStep = 0;
  NextContractId: string = null;
  NextContractStatus: number = null;
  NextSubContractType: SubContractType = null;
  Number: number = null;
  PaperDownloaded = false;
  PaperFormId: string = null;
  PaperNumber: string = null;
  PaperType: PaperType = PaperType.DSDigital;
  PrevContractId: string = null;
  PrevRev: number = null;
  Rev: number = null;
  SubStatus: ContractStatus = null;
  SearchText: string = null;
  SellerAutoSendToConveyancing: boolean = null;
  SellerConveyancingMessage: string = null;
  SellerSigns: boolean | null = false;
  SellerSignsOriginal: boolean | null = false;
  SignatureMode = false;
  Signed = false;
  Status: ContractStatus = ContractStatus.InProgress;
  StatusOriginal: ContractStatus = null;
  StatusResponse: ContractStatus = null;
  SubContractType: SubContractType = SubContractType.Base;
  TemplateId: string = null;
  TermAddTerms: string = null;
  TermConditional = false;
  TermEffectiveDate: Date = null;
  TermEffectiveTime: string = null;
  TermUnconditional = false;
  AmendEffectiveDate: Date = null;
  AmendEffectiveTime: string = null;
  AmendEffectiveUtc: string = null;
  TimezoneName: string = null;
  UserId: string = null;
  DelayExpireTill: Date = null;
  ContractAddendums: ContractAddendum[];
  ContractAgents: ContractAgent[];
  Signers: Signer[];
  ContractOwners: ContractOwner[];
  FieldDatas: any[];
  SourceAssociations: Association[];
  TargetAssociations: Association[];
  PreviewTemplate: ContractTemplate = null;
  PreviewTemplateId: string = null;
  Prop: any;

  constructor(ct: ContractType = null, sct: SubContractType = null) {
    if (ct) { this.ContractType = ct; }
    if (sct) { this.SubContractType = sct; }
  }
}

export class ContractChange {
  ObjectName: string;
  ArrayName: string;
  Description: string;
  OldValue: string;
  NewValue: string;
  constructor(init?: Partial<ContractChange>) {
    Object.assign(this, init);
  }
}

export class ContractAddendum {
  Id: string = null;
  ContractId: string = null;
  Letter: string = null;
  Contents: string = null;
  Include = true;
  NewRecord = true;
  Remove: boolean = null;
  Filename: string = null;
  FileRefId: string = null;
}

export class ContractTemplate {
  TemplateId: string;
  ContractGroup: string;
  ContractType: ContractType;
  SubContractType: SubContractType;
  TemplateName: string;
  TemplateRule: string;
  TriggerRule: string;
  TriggerStatus: string;
  InstanceName: string;
  Filepath: string;
  EffectiveDate: Date;
  EndDate: Date;
  PDFMergeSetting: number;
  RequiresInitials: boolean;
  BuyerAgentSigns: boolean;
  SellerAgentSigns: boolean;
  BuyerSignerSigns: boolean;
  SellerSignerSigns: boolean;
  IsSellerDoc: boolean;
  BuyerBrokerSigns: boolean;
  SellerBrokerSigns: boolean;
  CanHaveMultiple: boolean;
  PreviewTemplates: ContractTemplate[];
}

export class ReloadContract {
  ContractId: string;
  Contracts: Contract[];
  Contract: Contract;
  Prev: Contract;
  Step: number;
}
