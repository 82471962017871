import { PipesModule } from './../pipes/pipes.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ContactModule } from './../contacts/contact/contact.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RegisterModule } from '../register/register.module';
import { SharedModule } from '../shared/shared.module';
import { ViewersModule } from '../viewers/viewers.module';
import { ContractReportComponent } from './contract-report/contract-report.component';
import { ContractSignerReportComponent, UnsignedPipe } from './contract-signer-report/contract-signer-report.component';
import { EnvelopeSignerReportComponent } from './envelope-signer-report/envelope-signer-report.component';
import { SigningReportComponent } from './signing-report/signing-report.component';
import { IsLastAgentSignerPipe } from './signing-report/signer.pipe';
import { EmailModule } from '../email/email.module';

@NgModule({
  imports: [
    CommonModule,
    ContactModule,
    EmailModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SharedModule,
    UiSwitchModule,
    RegisterModule,
    ViewersModule,
    TabsModule.forRoot(),
    PipesModule
  ],
  declarations: [
    ContractReportComponent,
    ContractSignerReportComponent,
    EnvelopeSignerReportComponent,
    UnsignedPipe,
    SigningReportComponent,
    IsLastAgentSignerPipe
  ],
  exports: [
    ContractReportComponent,
    ContractSignerReportComponent,
    EnvelopeSignerReportComponent
  ]
})
export class ContractReportsModule { }
