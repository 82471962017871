<div class="alert alert-danger" role="alert" *ngIf="_fu.FormGroupHasError(fg) || _error"> 
    <div *ngIf="_fu.ShowControlError(fg.get('Passcode'))">
        Code must be <b>6 digits</b> long.
    </div>
    <div *ngIf="_error">
       {{this.fg.get('ErrorMessage').value}}
    </div>
</div>
<div class="alert alert-success" role="alert" *ngIf="_resend">
    A new code has been sent.
</div>
<div class="form-row" [formGroup]="fg">
    <div *ngIf="fg.get('IsMobileVerification').value && _step === _steps.MobilePasscodeStep" class="form-group col-12">
        A code has been sent to {{ formatMobile() }}
    </div>
    <div *ngIf="fg.get('IsEmailVerification').value && _step === _steps.EmailPasscodeStep" class="form-group col-12">
        A code has been sent to {{ fg.get('User').get('EmailAddress').value }}.
    </div>
    <div class="form-group col-12">
        Code *
        <input autocomplete="new-password" class="form-control" formControlName="Passcode" minlength="6" maxlength="6">
        <a class="btn alt-link text-muted ps-0" style="font-size: smaller;" (click)="ResendCode()">Resend Code?</a>
    </div>
    <!-- <div class="form-group col-12">
        <button type="button" class="btn btn-primary float-right" (click)="ResendCode()">Resend</button>
    </div> -->
</div>
<hr class="mt-0">
<div class="form-row">
    <div class="col p-0">
        <button type="button" class="btn btn-outline-secondary"(click)="Back()">Back</button>
    </div>
    <div class="col-auto">
        <button type="button" class="btn btn-primary" (click)="Next()">Next</button>
    </div>
</div>