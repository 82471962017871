import { Component, EventEmitter, Input, Output, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { YesNoPromptConfig } from '../../classes/classes';
import FormUtils from '../../utils/form/FormUtils';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-yes-no-prompt',
  templateUrl: './yes-no-prompt.component.html'
})

export class YesNoPromptComponent implements OnInit {
  @ViewChild('template', { static: true }) template: any;

  @Input() message = 'Are you sure you would like to do this?';
  @Input() config: YesNoPromptConfig;
  @Input() modal = false;
  @Output() selected = new EventEmitter<boolean>();

  modalRef: BsModalRef;
  modalConfig: ModalOptions = new ModalOptions;
  constructor(private modalService: BsModalService) { FormUtils.scrollToTop(); }
  ngOnInit() {
    // console.log('I am in!!!');
    if (this.modal) {
      this.modalConfig = {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-dialog-centered'
      };
      this.openModal(this.template);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  yes(): void {
    this.selected.emit(true);
    this.modalRef.hide();
  }

  no(): void {
    this.selected.emit(false);
    this.modalRef.hide();
  }
}
