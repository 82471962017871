import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppLayoutComponent } from '../../../layout/app-layout/app-layout.component';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-file-attach',
  templateUrl: './file-attach.component.html'
})
export class FileAttachComponent implements OnInit {

  @Input()
  fgForm: UntypedFormGroup;

  toUpload: File[] = [];
  filesToUpload: File[] = [];
  invalidFiles: { file: File, type: string; }[] = [];
  maxSize = environment.MaxFileSizeInMB;
  validComboDrag = false;
  invalidComboDrag = false;
  _showErrors = false;
  // ACCEPTS .XLSX ONLY
  accept = 'text/csv';

  constructor(public app: AppLayoutComponent) { }

  ngOnInit() {
  }

  lastInvalidsChange(value: { file: File, type: string; }[]) {
    if (!value) {
      return;
    }
    value.forEach(x => this.invalidFiles.push(x));
    this._showErrors = true;

    setTimeout(() => {
      this._showErrors = false;
      this.invalidFiles = [];
    }, 10 * 1000);

  }

  upload(data: File[]) {

    const files = this.updateFilesToUpload(data);

    // Browser fix for back-to-back same file upload
    $('input[type="file"]').val(null);
    this.uploadAttachments(files, 0);
  }

  uploadAttachments(data: File[], index: number) {

    const acFileData = this.fgForm.get('FileData');

    const x = data[index];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(x);

    fileReader.onload = () => {
      const base64File = fileReader.result.toString();
      acFileData.setValue(base64File);
    };
  }

  updateFilesToUpload(data: File[]): File[] {

    this.filesToUpload = [];
    this.filesToUpload = data.slice(this.toUpload.length, data.length);

    this.toUpload = [];
    data.forEach(x => this.toUpload.push(x));

    return this.filesToUpload;
  }

}
