import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Envelope } from '../../envelopes/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { SignerService } from '../../services/signer.service';

@Component({
  selector: 'app-envelope-signer-report',
  templateUrl: './envelope-signer-report.component.html'
})
export class EnvelopeSignerReportComponent implements OnInit {

  pdf: Observable<ArrayBuffer>;
  envelope: Envelope;
  constructor(public app: AppLayoutComponent, private svc: SignerService) { }

  ngOnInit() {
    this.app.HideSpinner();
    this.svc.getEnvelope()
      .subscribe(
        response => this.envelope = response,
        error => this.app.ShowErrorMessage(error)
      );
  }

  sigsRemaining(env: Envelope = null) {
    if (!this.envelope) {
      return;
    }

    if (!env) {
      env = this.envelope;
    } else {
      this.envelope = env;
    }

    const sigsRemaining = env.FileRefs.some(fr =>
      fr.FileRefSignerSigs.some(frss =>
        frss.Signature.SignatureData == null && frss.Signature.InitialData == null &&
        frss.SignerId === this.app.cu.SignerId));

    if (!sigsRemaining) {
      window.location.href = '/?m=sigscomplete';
    }
  }
}
