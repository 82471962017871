<div *ngIf="!SessionComplete()">
  <div>
    <div class="alert alert-info" role="alert">
      Please take the time to review the following document(s). There are <b>{{GetRemainingFileCount()}}</b> document(s)
      that
      require your signature, which are the following:
      <ul>
        <li *ngFor="let file of signerFiles | unsigned : signerId">
          {{_cfu.GetContractName(file.ContractTemplate)}}
        </li>
      </ul>
      You will find the signature section at the end of each document that still requires your signature,
      please follow the signing instructions located there.<br>
      If you do not agree to all terms of the contract, please discuss that with your real estate agent.
    </div>
    <div class="mt-3">
      <tabset type="pills" [justified]="true" #tabset [vertical]="app.smallScreen">
        <tab *ngFor="let file of signerFiles; let last = last" [disabled]="last && !AllOtherFilesSigned()"
          (selectTab)="TabChange_OnClick(file)">
          <ng-template tabHeading>
            {{_cfu.GetContractName(file.ContractTemplate)}}
            <fa-icon *ngIf="FileSignedByCurrentSigner(file)" class="text-success" [icon]="['fas', 'check-square']">
            </fa-icon>
          </ng-template>
        </tab>
      </tabset>
    </div>
    <app-pdf-lite-viewer [pdf]="pdf" [showDownloadButton]="false" [infiniteScroll]="true"
      (afterLoadComplete)="OnPdfLoad()">
    </app-pdf-lite-viewer>

    <div class="row" *ngFor="let grp of faSigners.controls; let i = index" [formGroup]="grp | as : form">
      <div *ngIf="!FileSignedByCurrentSigner(currentFileRef)" class="d-grid col-md-6 col-12">
        <button type="button" class="btn btn-primary" (click)="Sign($any(grp));">
          <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
          Sign
        </button>
        <hr class="signature" /> {{ grp.get('FirstName').value }} {{ grp.get('LastName').value }}
        {{ grp.get('IsSpouse').value ? '(Dower)' : '' }}
      </div>
    </div>
  </div>
  <app-signature-modal [showModal]="selectedGroup != null" [fileRefId]="currentFileRef?.FileRefId" [parentId]="parentId"
    [signerId]="signerId" [fullName]="signFor" (signature)="SignatureComplete($event);"></app-signature-modal>
</div>

<div class="alert alert-success" role="alert" *ngIf="SessionComplete()">
  Thank you for using DealSimple! You will automatically receive a copy of the contract once all signatures have been
  collected.
</div>