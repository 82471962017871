import { FileRef } from './../../forms/classes';
import { FileService } from './../../services/file.service';
import { Component, OnInit, Pipe, ViewChild, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Signature } from '../../classes/signature';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { SignatureService } from '../../services/signature.service';
import { SignerService } from '../../services/signer.service';
import { Signer, SignerUtils } from '../../utils/data/SignerUtils';
import FormUtils from '../../utils/form/FormUtils';
import ContractFormUtils from '../../utils/form/ContractFormUtils';

@Component({
  selector: 'app-contract-signer-report',
  templateUrl: './contract-signer-report.component.html'
})
export class ContractSignerReportComponent implements OnInit {
  @ViewChild('tabset') tabset: any;
  pdf: ArrayBuffer;
  data: Signer[];
  selectedGroup: UntypedFormGroup;
  signFor: string;
  formUtils: FormUtils;
  parentId: string = null;
  signerId: string;
  _cfu = ContractFormUtils;
  currentFileRef: FileRef = null;
  currentIndex = 0;
  form = this.fb.group({
    Signers: this.fb.array([])
  });
  faSigners = this.form.get('Signers') as UntypedFormArray;
  signerFiles: Array<FileRef> = [];

  constructor(public app: AppLayoutComponent,
    private svc: SignerService,
    private fb: UntypedFormBuilder,
    private ssvc: SignatureService,
    private fsvc: FileService) { }

  ngOnInit() {
    this.svc.getEmptyContactSigs()
      .subscribe(
        response => {
          this.LoadFormData(response);
        },
        ex => {
          this.app.HideSpinner();
          this.app.ShowErrorMessage(ex.statusText);
        },
        () => {
          if (this.signerId != null) {
            this.svc.GetSignerFiles().subscribe({
              next: response => {
                this.signerFiles = response;
                this.currentFileRef = this.signerFiles.filter(x => x.FileRefSignerSigs.some(x => x.SignerId == this.signerId && x.Signature.SignatureData == null))[0];
                this.currentIndex = this.signerFiles.findIndex(x => x.FileRefId == this.currentFileRef.FileRefId);
                this.GetNextFile(true);
              },
              error: err => {
                console.log(err);
              }
            });
          }
        }
      );
  }

  GetNextFile(init = false) {
    this.pdf = null;

    // IF NO SIGS REMAINING DO NOT SHOW REPORT
    if (this.SessionComplete() || this.currentFileRef == null || this.currentFileRef == undefined) {
      this.app.HideSpinner();
      return;
    }

    const response = response => {
      this.pdf = response;
    };
    const error = err => {
      this.app.HideSpinner();
      this.app.ShowErrorMessage(err.statusText);
      this.app.router.navigate(['/']);
    };
    const complete = () => {
      //   if (init && this.signatureCountRemaining() > 0) {
      //     this.app.modal.ShowModal(`Please take the time to review the contract in detail. You will find the signature section at the
      // end of the contract, please follow the signing instructions located there.
      // If you do not agree to all terms of the contract, please discuss with your real estate agent.`);
      //   }
    };

    if (this.currentFileRef.ParentId == this.parentId) {
      this.svc.DownloadFile()
        .subscribe(response, error, complete);
    } else {
      this.fsvc.DownloadFile(this.currentFileRef.FileRefId).subscribe(response, error, complete);
    }

  }

  public OnPdfLoad() {
    this.app.HideSpinner();
    this.tabset.tabs[this.currentIndex].active = true;
  }

  LoadFormData(signers: Signer[]) {
    if (signers.length > 0) {
      this.parentId = signers[0].ParentId;
      this.signerId = signers[0].Id;
    }
    SignerUtils.Load(this.faSigners, signers, this.parentId);
    this.faSigners.setValue(signers);
    this.data = signers;
  }

  Sign(group: UntypedFormGroup) {
    this.signFor = `${group.get('FirstName').value} ${group.get('MiddleName').value ? group.get('MiddleName').value + ' ' : ''}${group.get('LastName').value}`;
    this.signerId = group.get('Id').value;
    this.parentId = group.get('ParentId').value;
    this.selectedGroup = group;
  }

  SignatureComplete(value: Signature) {

    if (!value) {
      return this.hideSignatureCanvas();
    }
    this.app.ShowSpinner();
    this.ssvc.postRemoteSig(value)
      .subscribe(
        response => {
          this.selectedGroup?.get('Signature').setValue(value);
          this.hideSignatureCanvas();
        },
        ex => {
          this.app.ShowErrorMessage(ex.error.Message);
          this.app.router.navigate(['/']);
        },
        () => this.AfterSigPost()
      );
  }

  private AfterSigPost() {
    this.signerFiles = null;
    this.svc.GetSignerFiles().subscribe({
      next: response => {
        this.signerFiles = response;
        this.currentFileRef = null;
        this.currentFileRef = this.signerFiles.filter(x => x.FileRefSignerSigs.some(x => x.SignerId == this.signerId && x.Signature.SignatureData == null))[0];
        this.currentIndex = this.signerFiles.findIndex(x => x.FileRefId == this.currentFileRef?.FileRefId);
        this.GetNextFile();
      }
    });
  }

  hideSignatureCanvas() {
    this.selectedGroup = null;
    this.signFor = '';
  }

  SessionComplete(): boolean {
    return !this.signerFiles?.some(x => x.FileRefSignerSigs.some(x => x.SignerId == this.signerId && x.Signature.SignatureData == null));
  }

  AllOtherFilesSigned() {
    return this.signerFiles.filter(x => x.FileRefSignerSigs.some(x => x.SignerId == this.signerId && x.Signature.SignatureData == null)).length == 1;
  }

  public FileSignedByCurrentSigner(file: FileRef): boolean {
    return file?.FileRefSignerSigs.filter(x => x.SignerId == this.signerId && x.Signature.SignatureData == null).length === 0;
  }

  public GetRemainingFileCount(): number {
    return this.signerFiles.filter(x => !this.FileSignedByCurrentSigner(x)).length;
  }

  public TabChange_OnClick(file: FileRef) {
    if (this.currentFileRef != file) {
      this.app.ShowSpinner();
      this.currentFileRef = file;
      this.currentIndex = this.signerFiles.findIndex(x => x.FileRefId == this.currentFileRef.FileRefId);
      this.GetNextFile();
    }
  }
}

@Pipe({
  name: 'unsigned'
})
export class UnsignedPipe implements PipeTransform {
  transform(refs: FileRef[], signerId: string) {
    return refs.filter(x => !(x.FileRefSignerSigs.filter(x => x.SignerId == signerId && x.Signature.SignatureData == null).length === 0));
  }
}
