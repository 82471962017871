import { Component, OnInit } from '@angular/core';
import { ModalConfig } from '../../classes/classes';
import { ModalUtils } from '../../utils/ModalUtils';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html'
})
export class AppFooterComponent implements OnInit {

  smallScreen = false;
  _modalConfig: ModalConfig = null;
  modal: ModalUtils = null;

  constructor() {
    this.modal = new ModalUtils(null);
  }

  ngOnInit() {
    this.OnResize();
  }

  public OnResize(): boolean { return this.smallScreen = window.innerWidth <= 767; }

  legal_OnClick() {
    const msg = `<p class="text-center" style="font-size: 60%">DealSimple™ is owned and trademarked by Real Deal Transaction
      Management Inc. Please read our <a target="_blank" href="/terms">Terms of Use</a> / <a target="_blank" href="/privacy">Privacy Policy</a>
      for information about how we protect and restrict use of your personal
      information.<br><br> CONSULT A LAWYER BEFORE BUYING OR SELLING REAL PROPERTY – WE DO NOT PROVIDE LEGAL ADVICE. THIS SITE
      AND SERVICES ARE INTENDED FOR USE ONLY BY LICENSED REAL ESTATE AGENTS AND OTHER QUALIFIED REAL ESTATE
      PROFESSIONALS. IT IS YOUR RESPONSIBILITY TO REVIEW ALL DOCUMENTS WITH YOUR CLIENTS AND TO ENSURE CLIENT CONTACT
      INFORMATION IS ACCURATE.</p><br>
    <p class="text-center" style="font-size: 60%">&#169; 2020 Real Deal Transaction Management Inc. All Rights Reserved.
    </p>`;
    this._modalConfig = new ModalConfig({ Message: msg });
    this.modal.Show(this._modalConfig);
  }
}
