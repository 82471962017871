<div *ngIf="!modal">
    <div [innerHTML]="config.Message"></div>

    <div *ngIf="config.Textbox" class="row mb-3 g-3">
        <div class="col-12">
            {{config.TextboxLabel}}
            <textarea [(ngModel)]="config.TextboxData" class="form-control" autocomplete="off" maxlength="500" rows="3">
            </textarea>
        </div>
        <div class="col">
            <button type="button" class="btn {{ config.NoButtonCss }}" (click)="selected.emit(false)">
                {{ config.NoButtonText }}
            </button>
        </div>
        <div class="col-auto text-end">
            <button type="button" class="btn {{ config.YesButtonCss }}" (click)="selected.emit(true)">
                {{ config.YesButtonText }}
            </button>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="card">
        <div class="modal-header bg-primary p-0">
            <div class="col p-2">
                <h6 class="text-light m-0">Confirmation Prompt</h6>
            </div>
            <div class="col-auto p-0">
                <span class="align-middle">
                    <button type="button" class="btn btn-danger btn-block" (click)="no()">
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                    </button>
                </span>
            </div>
        </div>
        <div class="card-body">
            <div [innerHTML]="config.Message"></div>
            <div *ngIf="config.Textbox" class="row">
                <div class="col-12">
                    {{config.TextboxLabel}}
                    <textarea [(ngModel)]="config.TextboxData" class="form-control" autocomplete="off" maxlength="500"
                        rows="3">
                    </textarea>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn {{ config.NoButtonCss }}" (click)="no()">
                        {{ config.NoButtonText }}
                    </button>
                </div>
                <div class="col-auto text-end">
                    <button type="button" class="btn {{ config.YesButtonCss }}" (click)="yes()">
                        {{ config.YesButtonText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>