import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpInterceptUtils implements HttpInterceptor {
  constructor(private svc: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // DO NOTHING FOR THE ADDRESS LOOKUP
    if (request.url.indexOf(environment.AddrLookupUrl) >= 0 ||
        request.url.indexOf(environment.IPLookupUrl) >= 0) {
      return next.handle(request);
    }

    const cu = this.svc.CurrentUser;
    if (cu && cu.Token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `bearer ${cu.Token}`,
          'Content-Type': 'application/json'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(request);
  }
}
