import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ContractAction, ContractActionType } from '../../utils/form/ContractActionUtils';

@Component({
  selector: 'app-attachment-select-paper-action',
  templateUrl: './attachment-select-paper-action.component.html',
  styleUrls: ['../attachment.css']
})

export class AttachmentSelectPaperActionComponent {

  @Input() contractActions: ContractAction[] = [];
  @Output() action = new EventEmitter<[ContractActionType, boolean]>();

  cat = ContractActionType;
  form = this.fb.group({
    SelectedAction: null
  });

  constructor(private fb: UntypedFormBuilder) { }

  public OptionSelected(id: string) {
    const value = this.contractActions.find(x => x.Id === id);
    let isSellerAction: boolean = null;
    if (value?.Name) {
      //reversed because paper action re: Heather
      if (value.Name.toLowerCase().includes('seller')) {
        isSellerAction = false;
      } else if (value.Name.toLowerCase().includes('buyer')) {
        isSellerAction = true;
      }
    }

    this.action.emit([value.ActionType.Id, isSellerAction]);
  }
}
