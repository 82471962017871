import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import ConfigUtils from '../../utils/ConfigUtils';
import { Signer } from '../../utils/data/SignerUtils';
import DateUtils from '../../utils/DateUtils';
import { Envelope } from '../classes';

@Injectable()
export class EnvelopeService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${ConfigUtils.GetBaseApiUrl()}/envelopes`;
  }

  public CopyFileToEnvelope(id: string, fileRefId: string): Observable<Envelope> {
    return this.http.get<Envelope>(`${this.baseUrl}/${id}/copyfile/${fileRefId}`);
  }

  public GetEnvelope(id: string): Observable<Envelope> {
    return this.http.get<Envelope>(`${this.baseUrl}/${id}`);
  }

  public GetEnvelopes(): Observable<Envelope[]> {
    return this.http.get<Envelope[]>(`${this.baseUrl}`);
  }

  public DeleteEnvelope(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/${id}`);
  }

  public PostEnvelope(value: Envelope): Observable<Envelope> {
    return this.http.post<Envelope>(this.baseUrl, value);
  }

  public SendEnvelope(id: string): Observable<Envelope> {
    return this.http.get<Envelope>(`${this.baseUrl}/${id}/send`);
  }

  public UpdateEnvelope(value: Envelope): Observable<Envelope> {
    return this.http.put<Envelope>(this.baseUrl, this.FixEnvelopeDates(value));
  }

  public UpdateEnvelopeToEdit(id: string): Observable<Envelope> {
    return this.http.put<Envelope>(`${this.baseUrl}/${id}/edit`, null);
  }

  public SendSignerEmail(data: Signer): Observable<Envelope> {
    return this.http.post<Envelope>(`${this.baseUrl}/SendSignerEmail`, data);
  }

  private FixEnvelopeDates(e: Envelope): Envelope {
    e.DateExpires = DateUtils.FixDate(e.DateExpires);
    return e;
  }
}
