import { HeaderGroupHasChangedPipe, ConditionHeaderGroupHasChangedPipe } from './header-group-has-changed.pipe';
import { NgModule } from '@angular/core';
import { FilterFormArrayPipe } from './filter-fa.pipe';
import { FilterPipe } from './filter.pipe';
import { HasChangedPipe } from './has-changed.pipe';
import { IfNullCurrencyPipe } from './if-null-currency.pipe';
import { IfNullPipe } from './if-null.pipe';
import { NewDatePipe } from './new-date.pipe';
import { NewDecimalAllowZeroPipe } from './new-decimal-allow-zero.pipe';
import { NewDecimalPipe } from './new-decimal.pipe';
import { ObjectChangedPipe } from './object-changed.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { SummaryPipe } from './summary-pipe.pipe';
import { UTCToLocalPipe } from './utc-to-local.pipe';
import { PairPipe } from './pair.pipe';
import { SummaryFormatPipe } from './summary-format.pipe';
import { InterpolateFeePipe } from './interpolate-fee.pipe';
import { DistinctElementsPipe } from './distinct-elements.pipe';
import { AsPipe } from './as.pipe';

@NgModule({
  imports: [],
  declarations: [
    FilterPipe,
    FilterFormArrayPipe,
    IfNullPipe,
    NewDatePipe,
    NewDecimalPipe,
    NewDecimalAllowZeroPipe,
    PhoneNumberPipe,
    IfNullCurrencyPipe,
    SummaryPipe,
    HasChangedPipe,
    ObjectChangedPipe,
    UTCToLocalPipe,
    PairPipe,
    HeaderGroupHasChangedPipe,
    ConditionHeaderGroupHasChangedPipe,
    SummaryFormatPipe,
    InterpolateFeePipe,
    DistinctElementsPipe,
    AsPipe,
  ],
  exports: [
    FilterPipe,
    FilterFormArrayPipe,
    IfNullPipe,
    IfNullCurrencyPipe,
    NewDatePipe,
    NewDecimalPipe,
    NewDecimalAllowZeroPipe,
    PhoneNumberPipe,
    SummaryPipe,
    HasChangedPipe,
    ObjectChangedPipe,
    UTCToLocalPipe,
    PairPipe,
    HeaderGroupHasChangedPipe,
    SummaryFormatPipe,
    DistinctElementsPipe,
    ConditionHeaderGroupHasChangedPipe,
    AsPipe
  ]
})
export class PipesModule { }
