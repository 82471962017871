<div class="alert alert-danger" role="alert" *ngIf="_fu.FormGroupHasError(fg) || _error"> 
    <div *ngIf="_fu.ShowControlError(fg.get('PlanId'))">
        A subscription selection is <b>required</b> to continue.
    </div>
    <div *ngIf="_error">
        <span [innerHTML]="fg.get('ErrorMessage').value"></span>
     </div>
</div>
<div *ngIf="couponApplied" class="alert alert-success" role="alert">
    <span style="font-size: smaller;" [innerHTML]="couponApplied"></span>
</div>
<div #planComponent *ngIf="_plans.length > 0 && _showPlan">
    <app-plan [plans]="_plans" [fg]="fg" (selected)="Plan_OnSelected()"></app-plan>
</div>
<div id="divCoupon" class="form-group">
    <app-coupon (couponData)="assignCoupon($event)"></app-coupon>
    
</div>
<div #payComponent>

    <div *ngIf="_plan" class="form-row">
        <div class="form-group col-12">
            <span [innerHtml]="_paymentNotice"></span>
        </div>
    </div>

    <script src="https://js.stripe.com/v3/"></script>


    <form #checkout="ngForm" class="checkout">
        <div class="form-row">
            <label for="card-info">Credit Card Info</label>
            <div id="card-info" #cardInfo>

            </div>

            <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
        </div>
    </form>
</div>
<hr>
<div class="form-row">
    <div class="col p-0">
        <button *ngIf="this.fg.get('RegistrationFlowType').value === this._rft.GuestValidationWithPayment || this.fg.get('RegistrationFlowType').value === this._rft.Standard"
                type="button" class="btn btn-outline-secondary" (click)="Back()">Back
        </button>
        <button *ngIf="this.fg.get('RegistrationFlowType').value !== this._rft.GuestValidationWithPayment && this.fg.get('RegistrationFlowType').value !== this._rft.Standard"
                type="button" class="btn btn-outline-secondary" (click)="Close()">Cancel
        </button>
    </div>
    <div class="col-auto">
        <button *ngIf="!token" class="btn btn-primary" type="button" (click)="onSubmit(checkout)">Confirm</button>
    </div>
</div>