import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService, RoleType } from '../services/auth.service';

@Injectable()
export class AuthGuardSigner implements CanActivate {

  constructor(
    private router: Router,
    private svc: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.svc.CurrentUser) {

      // ONLY SIGNERS CAN ACCESS WITH THIS GUARD
      if (this.svc.CurrentUser.Role !== RoleType.Signer) {
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
