
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentMode } from './../../attachment/classes';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { FileService } from './../../services/file.service';
import { FileRef, FileRefType } from './../../forms/classes';
import { Contract, ContractStatus, ContractType, SubContractType } from './../../classes/contract';
import { DealFileSection, NEW_OFFER_STARTED } from '../classes';
import { ContractService } from './../../services/contract.service';
import { Component, Input, ViewChild, OnInit } from '@angular/core';
import DynamicFormUtils from '../../utils/form/DynamicFormUtils';

@Component({
  selector: 'app-contract-deal-files',
  templateUrl: './contract-deal-files.component.html',
  styles: [`
    .icon {
      transform: rotate(90deg);
      transition: all 0.5s ease;
    }

    .collapsed {
      transform: rotate(0deg);
    }
  `]
})
export class ContractDealFilesComponent {
  @ViewChild('pdfModal') pdfModal: any;
  @ViewChild('imageModal') imageModal: any;

  @Input() df: DynamicFormUtils;
  @Input() set fileMap(value: Map<DealFileSection, FileRef[]>) {
    if (value) {
      this.sections = [];
      this.collapsed = [];

      this.dealFiles = value;
      [...this.dealFiles.keys()].forEach((x, i) => {
        this.sections.push(x);
        this.collapsed.push(i == 0 ? false : true);
      });
      [].concat(...[...this.dealFiles.values()]).forEach(x => this.modes.set(x.FileRefId, AttachmentMode.Ready));
      let deadStatuses = [ContractStatus.Expired, ContractStatus.ConditionsNonWaived, ContractStatus.Rejected];
      if (this.df.contracts.some((contract, i, arr) => {
        return deadStatuses.includes(contract.Status) &&
          arr[i - 1] != undefined &&
          arr[i - 1] != null &&
          arr[i - 1]?.SubContractType == SubContractType.Base;
      })) {
        let contracts: FileRef[] = [];
        this.dealFiles.get(DealFileSection.Contracts).map(value => { return [value, this.df.contracts.find(x => x.Rev == value.ParentRev)] as [FileRef, Contract]; }).forEach(([contractFile, contract], i, arr) => {
          if (deadStatuses.includes(contract.Status) && arr[i - 1] != undefined && arr[i - 1] != null && arr[i - 1][1].SubContractType == SubContractType.Base) {
            contracts.push(new FileRef({ Filename: NEW_OFFER_STARTED }));
          }
          contracts.push(contractFile);
        });
        this.dealFiles.set(DealFileSection.Contracts, contracts);
      }
      this._show = true;
    }
  }

  dealFiles: Map<DealFileSection, FileRef[]> = null;
  collapsed: boolean[] = [];
  sections: DealFileSection[] = [];
  modes: Map<string, AttachmentMode> = new Map<string, AttachmentMode>();

  _dfs = DealFileSection;
  _filename: string = "";
  _pdf: Observable<ArrayBuffer> = null;
  _image: Blob = null;
  _imageUrls: string[] = [];
  _imageIndex: number = 0;
  _modal: BsModalRef;
  _fileRefToCopy: FileRef = null;
  _mode = AttachmentMode;
  _ct = ContractType;
  _fr = FileRefType;
  _pg: number = 1;
  _imageConfig = {
    allowFullscreen: false
  };
  _show: boolean = false;

  constructor(private svc: ContractService,
    private fileSvc: FileService,
    private svcModal: BsModalService,
    private sanitizer: DomSanitizer) { }
  public OpenFile(file: FileRef) {
    this.df.app.ShowSpinner();
    if (file.FileExt == '.pdf') {
      this._filename = file.Filename.includes('.pdf') ? file.Filename.slice(0, file.Filename.length - 4) : file.Filename;
      this._pdf = this.fileSvc.DownloadFile(file.FileRefId);
      this.LaunchViewer();
    } else if (['jpg', 'jpeg', 'png', 'tif', 'tiff'].includes(file.FileExt.replace('.', ''))) {
      this._filename = file.Filename.includes(file.FileExt) ? file.Filename.slice(0, file.Filename.length - file.FileExt.length) : file.Filename;
      this.fileSvc.DownloadFile(file.FileRefId).subscribe({
        next: image => {
          this._image = new Blob([new Uint8Array(image)], { type: this.GetMimeType(file.FileExt) });
          const url = window.URL.createObjectURL(this._image);
          this._imageUrls = [this.sanitizer.bypassSecurityTrustUrl(url) as string];
          this.LaunchViewer(this.imageModal);
        }
      });
    } else {
      //show error message
    }
  }

  public CloseImageViewer() {
    this._modal.hide();
    window.URL.revokeObjectURL(this._imageUrls[0]);
  }

  //#region Action Functions
  public DownloadFile(file: FileRef) {
    const mimeType = this.GetMimeType(file.FileExt);
    this.df.app.ShowSpinner();
    this.fileSvc.DownloadFile(file.FileRefId)
      .subscribe({
        next: response => this.df.app.DownloadFile(response, file.Filename, mimeType),
        error: error => this.df.app.modal.ShowError(error)
      });
  }

  public Toggle_Permissions(file: FileRef) {
    file.IsPrivate = !file.IsPrivate;
    this.df.app.ShowSpinner();

    this.fileSvc.UpdateFile(file)
      .subscribe({
        next: response => {
          this.UpdateFile(file);
          this.SetMode(file, AttachmentMode.Ready);
          this.df.app.HideSpinner();
        },
        error: error => {
          this.SetMode(file, AttachmentMode.Ready);
          this.df.app.modal.ShowError(error);
        }
      }
      );
  }

  public RenameFile_OnClick(file: FileRef) {
    this.SetMode(file, AttachmentMode.Rename);
  }

  public DeleteFile_OnClick(file: FileRef) {
    this.SetMode(file, AttachmentMode.Delete);
  }

  public CopyToContract_OnClick(file: FileRef) {
    this._fileRefToCopy = file;
  }

  public Rename_OnAccept(file: FileRef, newFilename: string) {
    // NEW FILENAME CANNOT BE BLANK
    if (newFilename == null || newFilename.trim() === '') {
      this.SetMode(file, AttachmentMode.Ready);
      return;
    }

    this.df.app.ShowSpinner();
    file.Filename = newFilename;

    this.fileSvc.UpdateFile(file)
      .subscribe({
        next: response => {
          this.UpdateFile(response);
          this.SetMode(response, AttachmentMode.Ready);
          this.df.app.HideSpinner();
        },
        error: error => {
          this.SetMode(file, AttachmentMode.Ready);
          this.df.app.modal.ShowError(error);
        }
      });
  }

  public Rename_OnCancel(file: FileRef) {
    this.SetMode(file, AttachmentMode.Ready);
  }

  public Delete_OnAccept(file: FileRef) {
    this.df.app.ShowSpinner();
    this.fileSvc.DeleteFile(file.FileRefId).subscribe({
      complete: () => {
        const section = [...this.dealFiles.entries()].map(x => {
          if (x[1].some(y => y.FileRefId == file.FileRefId))
            return x[0];
        }).find(x => x != undefined);
        const newArray = this.dealFiles.get(section).filter(x => x.FileRefId != file.FileRefId);
        this.dealFiles.set(section, newArray);
        this.modes.delete(file.FileRefId);
        this.df.app.HideSpinner();
      },
      error: error => {
        this.SetMode(file, AttachmentMode.Ready);
        this.df.app.modal.ShowError(error);
      }
    });
  }

  public ViewSignCert(file: FileRef) {
    this.df.app.ShowSpinner();
    this._filename = `[${file.ParentNumber}-${file.ParentRev}] Signing Certificate.pdf`;
    this._pdf = this.df.svcContract.GetSigningCertificate(file.ParentId);
    this.LaunchViewer();
  }

  //#endregion

  //#region Mode
  public GetMode(file: FileRef): AttachmentMode {
    return this.modes.get(file.FileRefId);
  }

  public SetMode(file: FileRef, mode: AttachmentMode) {
    this.modes.set(file.FileRefId, mode);
  }

  public GetModesForSection(section: DealFileSection): Map<string, AttachmentMode> {
    const ids = this.dealFiles.get(section).map(x => x.FileRefId);
    return new Map<string, AttachmentMode>([...this.modes.entries()].filter(x => ids.includes(x[0])));
  };
  //#endregion

  //#region Private functions
  private GetMimeType(ext: string): string {
    ext = ext[0] === '.' ? ext.slice(1) : ext;
    switch (ext) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'tif':
      case 'tiff':
        return 'image/tiff';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'application/pdf';
    }
  }

  private UpdateFile(file: FileRef) {
    let section: DealFileSection = null;
    let sectionFiles: FileRef[] = null;
    [...this.dealFiles.entries()].forEach(x => {
      if (x[1].some(y => y.FileRefId == file.FileRefId)) {
        //TODO@D-bag: can we update the file here?
        section = x[0];
        sectionFiles = x[1];
      }
    });

    if (section != null && sectionFiles != null) {
      sectionFiles[sectionFiles.findIndex(x => x.FileRefId == file.FileRefId)] = file;
      this.dealFiles.set(section, sectionFiles);
    }
  }

  private LaunchViewer(viewer: any = this.pdfModal) {
    const config = new ModalOptions();
    config.ignoreBackdropClick = true;
    config.keyboard = false;
    config.class = 'modal-dialog-centered modal-lg';
    config.animated = true;
    this._modal = this.svcModal.show(viewer, config);
    this.df.app.HideSpinner();
  }
  //#endregion

}


