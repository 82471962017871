<div *ngIf="selectedBrokerage">
  <div class="row mb-2">
    <div class="col-12">
      <app-brokerage-tile [data]="selectedBrokerage" [clickable]="false" [group]="false">
      </app-brokerage-tile>
    </div>
  </div>

  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col">
        CHANGE BROKERAGE TYPE
      </div>
    </div>
  </div>
  <div class="row mb-3 g-3">
    <div class="col-lg-4 col-md-6 col-12">
      <select class="form-select" (change)="type_OnChange()" [(ngModel)]="type">
        <option *ngFor="let type of brokerageType" value="{{type[1]}}">
          {{type[0]}}
        </option>
      </select>
    </div>
    <div *ngIf="showSave" class="col-auto">
      <button type="button" class="btn btn-primary" (click)="saveBrokerage()">Save
      </button>
    </div>
  </div>

  <div class="row mb-3 g-3">
    <div class="col-12">
      <app-manage-brokerage-relations [currentBrokerageId]="brokerageId" [brokerages]="brokerages"
        (updateBrokerage)="saveBrokerage($event)">
      </app-manage-brokerage-relations>
    </div>
  </div>

  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col">
        BROKERAGE GRAPH
      </div>
    </div>
  </div>

  <app-graph [currentRecordId]="brokerageId" [config]="GetGraphConfig()">
  </app-graph>

</div>