import { Contract, ContractStatus, ContractType, PaperType, SubContractType } from '../../classes/contract';
import { SignatureMode } from '../../classes/signature';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { ContractAgentUtils } from '../data/ContractAgentUtils';

//#region ContractAction
export class ContractAction {
  Id: string;
  ContractGroup: string;
  ContractType: ContractType;
  SubContractType: SubContractType;
  Name: string;
  ActionRule: string;
  Responsible: ResponsibilityEnum;
  ActionType: ActionType;
}

export class ActionType {
  Id: ContractActionType;
  Name: string;
  Description: string;
  Icon: string;
  SortOrder: number;
}

export enum ResponsibilityEnum {
  Buyer = 0,
  Seller = 1,
  Both = 2
}

//#endregion
//#region ContractActionType
export enum ContractActionType {
  Amend = 1,
  Copy = 2,
  Delete = 3,
  Edit = 4,
  ActiveContract = 6,
  NextContract = 7,
  Terminate = 8,
  Accept = 100,
  AcceptCounter = 101,
  Counter = 110,
  Reject = 120,
  SendForReview = 130,
  Conditions = 11,
  SendOffer = 12,
  Rescind = 14,
  Resend = 15,
  Continue = 16,
  StartNewOffer = 17,
  Finalize = 18,
  BuyerVoid = 88,
  SellerVoid = 89,
  Notice = 90,
  Waive = 91,
  NonWaive = 92,
  CondoNotice = 93,
  FallenThrough = 140,
  Sold = 141,
  GuestDownload = 200,
  // UPLOADS
  PaperContractUpload = 5,
  AcceptedPaperOfferUpload = 6,
  PaperOfferUpload = 20,
  PaperNonOfferMarkupUpload = 30,
  NewBuyerUpload = 199,
  NewSellerUpload = 299,
  NewOfferUpload = 399,
  AttachmentUpload = 999,
  ExpireNow = 9999
}
//#endregion
//#region QRActionType
export enum QRActionType {
  AcceptedPaperOffer,
  ContractTemplate,
  Invalid,
  NextContract,
  NoActionAvailable,
  NoQRFound,
  PaperSignature,
  PaperOffer,
  PrevContract,
  SelectPaperAction
}
//#endregion

export class ContractActionUtils {

  //#region Global Vars
  private _actions: ContractAction[] = [];
  private _nextContract: Contract = null;
  private _prevExpiryPending = false;
  private _responsible: boolean = null;
  private _uid: string = null;
  //#endregion

  //#region Constructor
  /**
     * Logic for determining which actionscan be performed to a contract by the current user.
     *
     * @param contract
     * @param contracts
     * @param app
     */
  constructor(contract: Contract, contracts: Contract[], app: AppLayoutComponent) {
    this._uid = app.cu.User.UserId;

    // IF PREV CONTRACT IS CURRENT ASSIGN
    if (contract.PrevContractId) {
      const prev = contracts.find(x => x.ContractId === contract.PrevContractId);
      if (prev.IsCurrent && prev.Status === ContractStatus.ExpiryPending) {
        this._prevExpiryPending = true;
      }
    }

    // DETERMINE IF THE AGENT IS RESPONSIBLE FOR THE CONTRACT
    if (contract.SellerSigns !== null) {
      this._responsible = ContractAgentUtils.IsContractAgent(contract.ContractAgents, this._uid, contract.SellerSigns);

      // THERE CAN BE A NEXT CONTRACT WHERE THE CURRENT USER IS NOT RESPONSIBLE
      if (contract.NextContractId) {
        this._nextContract = contracts.find(x => x.ContractId === contract.NextContractId);
        if (this._nextContract) {
          this._responsible = ContractAgentUtils.IsContractAgent(this._nextContract.ContractAgents, this._uid, this._nextContract.SellerSigns);
        }
      }
    }

    if (contract.Status === ContractStatus.ExpiryPending && contract.ContractAgents.find(x => x.UserId === this._uid)?.User.CustomerId != null) {
      this.AddAction(ContractActionType.ExpireNow, 'Expire Now');
    }

    // LOAD ACTIONS
    contract.ContractType !== ContractType.Offer ?
      this.LoadNonOfferContractActions(contract) :
      this.LoadOfferContractActions(contract);
  }
  //#endregion

  public GetActions(): ContractAction[] { return this._actions; }
  public HasAction(action: ContractActionType): boolean {
    return this._actions.findIndex(x => x.ActionType.Id === action) > -1;
  }

  //#region Private Functions
  private AddAction(action: ContractActionType, buttonText: string, description: string = null) {
    this._actions.push({
      ActionType: {
        Description: description,
        Icon: this.GetButtonIcon(action),
        Id: action,
        Name: buttonText,
        SortOrder: action
      },
      ActionRule: null,
      ContractGroup: null,
      ContractType: null,
      Id: null,
      Name: null,
      Responsible: null,
      SubContractType: null
    });
  }
  private AddPaperActions(c: Contract) {
    // THIS WAS C.SIGNATUREMODE... IF ALL OF OFFER SIDE IS WET ONLY... NO AGENT SIG SO NOT IN SIGMODE
    if (!c.Signed) {
      const sideAgents = c.ContractAgents.filter(x =>
        x.IsSellerAgent === c.SellerSigns &&
        x.SignatureRequired);
      const remAgentSigs = sideAgents.filter(x => !x.SignatureId);
      const wetAgentSigs = sideAgents.filter(x => x.SignatureMode === SignatureMode.Paper);
      const sideSigners = c.Signers.filter(x =>
        x.IsSeller === c.SellerSigns &&
        x.SignatureRequired);
      const remSignerSigs = sideSigners.filter(x => !x.SignatureId);
      const wetSignerSigs = sideSigners.filter(x => x.SignatureMode === SignatureMode.Paper);
      // PERFORM CHECK FOR WET CONTRACT UPLOAD
      if (remSignerSigs.length === wetSignerSigs.length || remAgentSigs.length === wetAgentSigs.length) {
        this.AddAction(ContractActionType.PaperContractUpload, null, 'Final Signed Contract');
      }
    }
  }
  private GetButtonIcon(action: ContractActionType): string {
    switch (action) {
      case ContractActionType.Amend:
      case ContractActionType.Edit:
      case ContractActionType.Conditions:
      case ContractActionType.Continue:
      case ContractActionType.Notice:
      case ContractActionType.CondoNotice:
        return 'pencil';
      case ContractActionType.Copy:
        return 'copy';
      case ContractActionType.GuestDownload:
        return 'file-download';
      case ContractActionType.NextContract:
      case ContractActionType.ActiveContract:
        return 'eye';
      case ContractActionType.Delete:
      case ContractActionType.Terminate:
      case ContractActionType.BuyerVoid:
      case ContractActionType.SellerVoid:
        return 'trash-alt';
      case ContractActionType.Accept:
      case ContractActionType.Waive:
        return 'thumbs-up';
      case ContractActionType.Counter:
        return 'edit';
      case ContractActionType.Reject:
      case ContractActionType.NonWaive:
        return 'thumbs-down';
      case ContractActionType.Resend:
      case ContractActionType.SendOffer:
        return 'paper-plane';
      case ContractActionType.Rescind:
        return 'undo';
      case ContractActionType.PaperOfferUpload:
        return 'file-alt';
      default:
        return 'eye';
    }
  }
  private LoadNonOfferContractActions(c: Contract) {
    const showEdit = !this._prevExpiryPending;

    if (!c.Signed && c.SignatureMode && showEdit) { this.AddAction(ContractActionType.Edit, 'Edit'); }
    // if (c.NextSubContractType === SubContractType.Amendment) { this.AddAction(ContractActionType.NextContract, 'View Amendment'); }
    // if (c.NextSubContractType === SubContractType.Termination) { this.AddAction(ContractActionType.NextContract, 'View Termination'); }

    // GO TO PARENT
    if (!c.IsCurrent && !c.NextContractId &&
      (c.SubContractType === SubContractType.Amendment || c.SubContractType === SubContractType.Termination) &&
      (c.Status === ContractStatus.InProgress || c.Status === ContractStatus.AwaitingSignatures || c.Status === ContractStatus.AwaitingSubmission)) {
      this.AddAction(ContractActionType.ActiveContract, 'View Active');
    }

    if (!c.Signed && c.ContractId && (c.PaperType != PaperType.DSDigital || !c.FileArchived)) {
      this.AddAction(ContractActionType.Delete, 'Delete');
    }
    this.AddPaperActions(c);
  }
  private LoadOfferContractActions(c: Contract) {
    // OFFER STATUS' WITHIN A SWITCH BASED ON RESPONSIBLE AGENT
    switch (this._responsible) {
      case true:
        const paperActionHelper = (c.PaperType === PaperType.NonDS ||
          ((c.SubContractType === SubContractType.Amendment || c.SubContractType === SubContractType.Waive || c.SubContractType == SubContractType.Counter) &&
            c.PaperType === PaperType.DSPaper)) && c.Status === ContractStatus.AwaitingSubmission;

        const editConditions = (c.Status === ContractStatus.AwaitingSignatures || c.Status === ContractStatus.AwaitingSubmission);
        if (editConditions && !paperActionHelper && !this._prevExpiryPending) {
          this.AddAction(ContractActionType.Edit, 'Edit');
        }

        if (c.Signed && c.Status === ContractStatus.AwaitingSubmission) { this.AddAction(ContractActionType.SendOffer, 'Send'); }
        // if (c.NextSubContractType === SubContractType.Counter) { this.AddAction(ContractActionType.NextContract, 'View Counter'); }
        // if (c.NextSubContractType === SubContractType.Amendment) { this.AddAction(ContractActionType.NextContract, 'View Amendment'); }
        // if (c.NextSubContractType === SubContractType.Waive) { this.AddAction(ContractActionType.NextContract, 'View Conditions'); }
        // if (c.NextSubContractType === SubContractType.Base) { this.AddAction(ContractActionType.NextContract, 'View Offer'); }
        // if (c.NextSubContractType === SubContractType.BuyerVoid) { this.AddAction(ContractActionType.NextContract, 'View Buyer Void'); }
        // if (c.NextSubContractType === SubContractType.SellerVoid) { this.AddAction(ContractActionType.NextContract, 'View Seller Void'); }
        // if (c.NextSubContractType === SubContractType.SellerNotice) { this.AddAction(ContractActionType.NextContract, 'View Notice'); }
        // if (c.NextSubContractType === SubContractType.CondoNotice) { this.AddAction(ContractActionType.NextContract, 'View Condo Notice'); }

        // GO TO PARENT
        if (!c.IsCurrent && !c.NextContractId &&
          ([SubContractType.Amendment,
          SubContractType.Waive,
          SubContractType.Base,
          SubContractType.Counter,
          SubContractType.SellerVoid,
          SubContractType.BuyerVoid,
          SubContractType.SellerNotice,
          SubContractType.CondoNotice].includes(c.SubContractType)) &&
          ([ContractStatus.InProgress,
          ContractStatus.Submitted,
          ContractStatus.AwaitingSignatures,
          ContractStatus.AwaitingSubmission].includes(c.Status))) {
          this.AddAction(ContractActionType.ActiveContract, 'View Active');
        }

        // DELETE
        if ((!c.StatusResponse && c.ContractId) || paperActionHelper) {
          if ((c.PaperType != PaperType.DSDigital || !c.FileArchived) &&
            (c.Status == ContractStatus.InProgress || c.Status == ContractStatus.AwaitingSubmission)) {
            this.AddAction(ContractActionType.Delete, 'Delete');
          }
        }
        this.AddPaperActions(c);
        break;
      case false:
        // ONLY ALLOWED DURING THE COUNTER PROCESS
        if (!c.NextContractId && !c.StatusResponse &&
          (c.Status === ContractStatus.Submitted || c.Status === ContractStatus.ExpiryPending) &&
          (c.SubContractType === SubContractType.Base || c.SubContractType === SubContractType.Counter ||
            c.SubContractType === SubContractType.Amendment || c.SubContractType === SubContractType.ARBBS ||
            c.SubContractType === SubContractType.SellerNotice)) {

          if (c.SubContractType !== SubContractType.ARBBS) {
            this.AddAction(ContractActionType.Rescind, 'Rescind');
          }

          this.AddAction(ContractActionType.Resend, 'Resend');

          // OTHER AGENT NEEDS TO HAVE DOWNLOADED OR RESPONDED
          this.AddAction(ContractActionType.PaperOfferUpload, null, 'Paper Offer Upload');
        }

        // NEED TO BE ABLE TO VIEW AMENDMENT FOR RESCIND
        if (c.NextSubContractType === SubContractType.Amendment) {
          this.AddAction(ContractActionType.NextContract, 'View Amendment');
        }

        break;
      default:
        if (!c.NextContractId && c.Status === ContractStatus.Active) {
          const sellerSide = c.ContractAgents.find(x => x.UserId === c.UserId).IsSellerAgent;
          const initiatingSide = c.ContractAgents
            .filter(x => x.IsSellerAgent === sellerSide)
            .map(x => x.UserId)
            .includes(this._uid);
          if (initiatingSide) {
            this.AddAction(ContractActionType.Continue, 'Continue Offer');
          }
        }

        if (c.Status === ContractStatus.Expired) {
          this.AddAction(ContractActionType.PaperOfferUpload, null, 'Paper Offer Upload');
        }

        break;
    }
  }
  //#endregion
}
