import { DataFileUploadType } from './../classes';
import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { AdminService } from './../service/admin.service';
import { DataUtils } from './../../utils/DataUtils';
import { Validators, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import FormUtils from '../../utils/form/FormUtils';

@Component({
  selector: 'app-upload-data-file',
  templateUrl: './upload-data-file.component.html',
  styles: [
  ]
})
export class UploadDataFileComponent implements OnInit {
  data = DataUtils;
  fu = FormUtils;

  fgForm = this.fb.group({
    FileData: [null, Validators.required],
    Country: ['Canada', Validators.required],
    Province: ['AB', Validators.required],
    Board: [null],
    UploadType: [DataFileUploadType.Office, Validators.required]
  });

  _country: AbstractControl;
  _fileData: AbstractControl;
  _prov: AbstractControl;
  _board: AbstractControl;
  _uploadType: AbstractControl;

  _dfut = DataFileUploadType;
  _uploadTypes = [
    DataFileUploadType.Office,
    DataFileUploadType.User,
  ];

  constructor(private fb: UntypedFormBuilder, private svc: AdminService, private app: AppLayoutComponent) { }

  ngOnInit() {
    this._fileData = this.fgForm.get('FileData');
    this._country = this.fgForm.get('Country');
    this._prov = this.fgForm.get('Province');
    this._board = this.fgForm.get('Board');
    this._uploadType = this.fgForm.get('UploadType');
  }

  Submit_Click() {
    if (FormUtils.FormGroupHasError(this.fgForm, true)) { return; }

    this.app.ShowSpinner();
    this.svc.UploadDataFile('CA', this._prov.value, this._board.value, this._uploadType.value, this._fileData.value)
      .subscribe(
        _ => this.app.modal.ShowModal(`Well that was a bloody success, wasn't it? Let's hope it actually processes correctly`),
        error => {
          this.app.modal.ShowError(error);
          this.Reset();
        },
        () => this.Reset()
      );
  }

  public Reset() {
    this.fgForm.reset();
    this._country.setValue('Canada');
    this._prov.setValue('AB');
    this._board.setValue(null);
    this.app.HideSpinner();
  }

}
