import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, RoleType, RoleUtils } from '../services/auth.service';

@Injectable()
export class AuthGuardNonGuestUser implements CanActivate {

  constructor(
    private router: Router,
    private svc: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.svc.CurrentUser) {

      // ONLY USERS THAT PAY CAN ACCESS THIS GUARD
      if (!RoleUtils.HasSome(this.svc.CurrentUser.Role, RoleType.Agent | RoleType.ListingAgent)) {
        this.router.navigate(['/']);
        return false;
      }

      // IF FORCE RESET PASSWORD USER CANNOT ACCESS ANY OTHER ROUTES
      if (this.svc.CurrentUser.User.ForceChangePassword) {
        this.router.navigate(['/profile/password']);
        return false;
      } else if (this.svc.CurrentUser.User.ForceUpdatePaymentMethod) {
        // IF FORCE UPDATE PAYMENT METHOD USER CANNOT ACCESS ANY OTHER ROUTES
        this.router.navigate(['/profile/billing/paymethod']);
        return false;
      }

      return true;
    }

    // NOT LOGGED IN: REDIRECT TO LOGIN WITH THE CURRENT URL
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
