import { formatDate } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs'

export class DayjsResult {
  startDate: dayjs.Dayjs = null;
  endDate: dayjs.Dayjs = null;
}

export default class DateUtils {

  public static FixDate(value: any, dataFromAPI = false): any {

    // IF NO VALUE OR ALREADY A CALENDAR DATE OBJECT THEN RETURN AS IS
    if (!value) { return value; }

    // IF ALREADY A CALENDAR OBJECT DATE
    if (value.startDate && value.endDate) {
      return dataFromAPI ? value : DateUtils.FormatDate(value);
    }

    if (typeof value === 'string') {
      // RETURN AS IS IF CALENDAR DATE OBJECT NOT REQUIRED
      if (value.endsWith('Z')) {
        value = value.slice(0, -1);
      }
    }

    if (!dataFromAPI) { return value; }

    // RETURN CALENDAR DATE OBJECT
    return {
      startDate: dayjs(value),
      endDate: dayjs(value).hour(23).minute(59).second(59).millisecond(0)
    };
  }

  public static FormatDate(value: DayjsResult): string {
    const dt: dayjs.Dayjs = value.startDate;
    const month = dt.month() + 1 < 10 ? '0' + (dt.month() + 1) : dt.month() + 1;
    const day = dt.date() < 10 ? '0' + dt.date() : dt.date();
    const hour = dt.hour() < 10 ? '0' + dt.hour() : dt.hour();
    const minute = dt.minute() < 10 ? '0' + dt.minute() : dt.minute();

    return `${dt.year()}-${month}-${day} ${hour}:${minute}:00`;
  }

  public static GetDayjsResult(value: dayjs.Dayjs = dayjs()): DayjsResult {
    value = value.hour(0).minute(0).second(0).millisecond(0);
    return {
      startDate: value,
      endDate: value.add(1, 'day').subtract(1, 'second')
    };
  }

  public static GetToday(): dayjs.Dayjs {
    return dayjs().hour(0).minute(0).second(0).millisecond(0);
  }

  public static GetUTCDate(date: dayjs.Dayjs = dayjs()) {
    return date.add(date.utcOffset(), 'minute');
  }

  public static IsBetweenDates(d1: DayjsResult, d2: DayjsResult, isCompletionDate: boolean = true, allowBackDating: boolean = false): boolean {

    if (!d1 || !d2) { return true; }

    const a = this.GetUTCDate();
    const b = this.GetUTCDate(d1.startDate);
    let c = this.GetUTCDate(d2.startDate);

    // IF THIS IS THE COMPLETION DATE, AUTO SET IT TO NOON
    if (isCompletionDate) { c = c.add(12, 'hour'); }

    // IF ALLOW BACKDATING, DO NOT CHECK AGAINST THE CURRENT TIME
    return allowBackDating ? !(b > c) : !(a > b || b > c);
  }

  public static IsHoliday(date: dayjs.Dayjs): boolean {
    const holidays: dayjs.Dayjs[] = [
      dayjs('2024-01-01'),
      dayjs('2024-02-19'),
      dayjs('2024-03-29'),
      dayjs('2024-04-01'),
      dayjs('2024-05-20'),
      dayjs('2024-07-01'),
      dayjs('2024-08-05'),
      dayjs('2024-09-02'),
      dayjs('2024-09-30'),
      dayjs('2024-10-14'),
      dayjs('2024-11-11'),
      dayjs('2024-12-25'),
      dayjs('2024-12-26'),
      dayjs('2025-01-01'),
      dayjs('2025-02-17'),
      dayjs('2025-04-18'),
      dayjs('2025-04-21'),
      dayjs('2025-05-19'),
      dayjs('2025-07-01'),
      dayjs('2025-08-04'),
      dayjs('2025-09-01'),
      dayjs('2025-09-30'),
      dayjs('2025-10-13'),
      dayjs('2025-11-11'),
      dayjs('2025-12-25'),
      dayjs('2025-12-26')
    ];

    return holidays.some(x => x.year() == date.year() && x.month() == date.month() && x.date() == date.date());
  }

  public static IsUTCDateGTNow(utcDateString: string): boolean {
    const utc = DateUtils.FixDate(utcDateString, true);
    return dayjs() > DateUtils.GetUTCDate(utc.startDate);
  }

  // OLD DEPRECATED FUNCTIONS 
  static createDateAsUTC(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  static GetPrettyDate(date: Date): String {
    return formatDate(date, 'fullDate', 'en-CA');
  }

  static IsHolidayOld(date: NgbDateStruct): NgbDateStruct {
    const holidays: NgbDateStruct[] = [
      new NgbDate(2024, 1, 1),
      new NgbDate(2024, 2, 19),
      new NgbDate(2024, 3, 29),
      new NgbDate(2024, 4, 1),
      new NgbDate(2024, 5, 20),
      new NgbDate(2024, 7, 1),
      new NgbDate(2024, 8, 5),
      new NgbDate(2024, 9, 2),
      new NgbDate(2024, 9, 30),
      new NgbDate(2024, 10, 14),
      new NgbDate(2024, 11, 11),
      new NgbDate(2024, 12, 25),
      new NgbDate(2024, 12, 26),
      new NgbDate(2025, 1, 1),
      new NgbDate(2025, 2, 17),
      new NgbDate(2025, 4, 18),
      new NgbDate(2025, 4, 21),
      new NgbDate(2025, 5, 19),
      new NgbDate(2025, 7, 1),
      new NgbDate(2025, 8, 4),
      new NgbDate(2025, 9, 1),
      new NgbDate(2025, 9, 30),
      new NgbDate(2025, 10, 13),
      new NgbDate(2025, 11, 11),
      new NgbDate(2025, 12, 25),
      new NgbDate(2025, 12, 26)
    ];

    return holidays.find(x => x.year === date.year && x.month === date.month && x.day === date.day);
  }

  static IsTodayOld(date: NgbDate): boolean {
    const dt = new Date();
    const today = new NgbDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());

    return today.equals(date);
  }
}
