import { AuthGuardConveyancer } from './guards/auth.guard.conveyancer';
import { CalView } from './calendar/classes';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractSignerReportComponent } from './contract-reports/contract-signer-report/contract-signer-report.component';
import { EnvelopeSignerReportComponent } from './contract-reports/envelope-signer-report/envelope-signer-report.component';
import { FileRefType } from './forms/classes';
import { AuthGuardAdmin } from './guards/auth.guard.admin';
import { AuthGuardEnvelopeSigner } from './guards/auth.guard.envelopesigner';
import { AuthGuardSigner } from './guards/auth.guard.signer';
import { AuthGuardUsers } from './guards/auth.guard.users';
import { AuthGuardPassword } from './guards/auth.guardpassword';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { LoginComponent } from './login/login.component';
import { PasswordRecoveryComponent } from './login/password-recovery/password-recovery.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './shared/terms-of-service/terms-of-service.component';
import { RoleType } from './services/auth.service';

const appRoutes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'terms', component: TermsOfServiceComponent },
      { path: 'privacy', component: PrivacyPolicyComponent },
      { path: 'login/password.recovery', component: PasswordRecoveryComponent },
      { path: '', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) }
    ]
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: 'buyers', loadChildren: () => import('./buyers/buyers.module').then(m => m.BuyersModule), canActivate: [AuthGuardUsers] },
      { path: 'buyers/:id', loadChildren: () => import('./buyers/buyer/buyer.module').then(m => m.BuyerModule), canActivate: [AuthGuardUsers] },
      { path: 'contract', component: ContractSignerReportComponent, canActivate: [AuthGuardSigner] },
      {
        path: 'home', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardUsers], data: {
          userTypes: [RoleType.Agent, RoleType.Guest, RoleType.ListingAgent],
        }
      },
      {
        path: 'sellers', loadChildren: () => import('./listings/listings.module').then(m => m.ListingsModule), canActivate: [AuthGuardUsers], data: {
          userTypes: [RoleType.Agent, RoleType.Guest, RoleType.ListingAgent],
        }
      },
      {
        path: 'sellers/:id',
        loadChildren: () => import('./listings/listing/listing.module').then(m => m.ListingModule),
        canActivate: [AuthGuardUsers],
        data: {
          userTypes: [RoleType.Agent, RoleType.Guest, RoleType.ListingAgent],
        }
      },
      { path: 'sign', component: EnvelopeSignerReportComponent, canActivate: [AuthGuardEnvelopeSigner] },
      {
        path: 'offers', loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule), canActivate: [AuthGuardUsers]
      },
      { path: 'offers/:id', loadChildren: () => import('./offers/offer/offer.module').then(m => m.OfferModule), canActivate: [AuthGuardUsers] },
      { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuardAdmin] },
      { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuardPassword] },
      // tslint:disable-next-line: max-line-length
      { path: 'desk/signatures', loadChildren: () => import('./envelopes/envelopes.module').then(m => m.EnvelopesModule), canActivate: [AuthGuardUsers] },
      {
        path: 'desk/signatures/:id',
        loadChildren: () => import('./envelopes/envelope/envelope.module').then(m => m.EnvelopeModule),
        canActivate: [AuthGuardUsers]
      },
      {
        path: 'myforms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormsMainModule),
        canActivate: [AuthGuardUsers],
        data: { fileType: FileRefType.MyFile, userTypes: [RoleType.Agent, RoleType.Guest] }
      },
      {
        path: 'myforms/:id',
        loadChildren: () => import('./forms/form/form.module').then(m => m.FormModule),
        canActivate: [AuthGuardUsers],
        data: { fileType: FileRefType.MyFile, userTypes: [RoleType.Agent, RoleType.Guest] }
      },
      {
        path: 'realtorforms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormsMainModule),
        canActivate: [AuthGuardUsers],
        data: { fileType: FileRefType.RealtorFile }
      },
      {
        path: 'realtorforms/:id',
        loadChildren: () => import('./forms/form/form.module').then(m => m.FormModule),
        canActivate: [AuthGuardUsers],
        data: { fileType: FileRefType.RealtorFile }
      },
      {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [AuthGuardUsers],
        data: { view: CalView, userTypes: [RoleType.Agent, RoleType.Guest, RoleType.ListingAgent] }
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
        canActivate: [AuthGuardUsers],
        data: {
          userTypes: [RoleType.Agent, RoleType.Guest, RoleType.ListingAgent],
        }
      },
      {
        path: 'contacts/:id',
        loadChildren: () => import('./contacts/contact/contact.module').then(m => m.ContactModule),
        canActivate: [AuthGuardUsers],
        data: {
          userTypes: [RoleType.Agent, RoleType.Guest, RoleType.ListingAgent],
        }
      },
      {
        path: 'conveyancer',
        loadChildren: () => import('./conveyancer/conveyancer.module').then(m => m.ConveyancerModule),
        canActivate: [AuthGuardConveyancer]
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
