import {
  AfterViewInit, Component, ElementRef, EventEmitter, HostListener,
  Input, OnInit, Output, ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SignaturePad } from 'angular2-signaturepad';
import { Point } from 'signature_pad/dist/types/point';
import { Signature, SignatureConfig, SigningData, SigningStep } from '../../classes/signature';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { SignatureService } from '../../services/signature.service';

@Component({
  selector: 'app-signature',
  templateUrl: 'signature.component.html'
})

export class SignatureFieldComponent implements AfterViewInit, OnInit {

  //#region Variables
  @Input() parentId: string;
  @Input() fileRefId: string;
  @Input() signerId: string;
  @Input() signFor: string;
  @Output() signed = new EventEmitter<Signature>();

  _data = new SigningData();
  _options: Object = {};
  _signature: any = null;
  _signatureConfig: SignatureConfig = null;
  _signedInCity: string;
  _step = SigningStep.RedFlag;
  _steps = SigningStep;
  _questionsAnswered = 0;
  _drawnSigs = false;
  _cleanedInitialPreview = null;
  _cleanedSignaturePreview = null;
  _signedInCityError: boolean = false;
  _questionError: boolean = false;
  _hideError: boolean = false;
  _processing: boolean = null;

  @ViewChild(SignaturePad) public signaturePad: SignaturePad;
  @ViewChild('row') public row: ElementRef;
  @HostListener('window:resize', ['$event'])
  resize(event) { this.ResizeCanvas(); }
  //#endregion

  constructor(private svc: SignatureService, private app: AppLayoutComponent, private sanitizer: DomSanitizer) { }

  ngAfterViewInit() { this.ResizeCanvas(); }
  ngOnInit() {
    this.app.LoadCurrentUserIP();

    this.svc.getSignatureConfig(this.parentId, this.signerId)
      .subscribe(
        response => {
          this._signatureConfig = response;
          this.CleanPreviewData(response);
          // SKIP QUESTION STEP IF THERE ARE NONE
          if (response.Questions.length === 0) {
            this.Next();
          }
        },
        ex => console.log(ex),
        () => this.app.HideSpinner()
      );
  }

  public Clear() {
    this.signaturePad.clear();
  }

  public Close() {
    this.signed.emit(null);
    this.Clear();
  }

  public IsSignatureStep(): boolean {
    switch (this._step) {
      case SigningStep.Initial:
      case SigningStep.Signature:
        return true;
      default:
        return false;
    }
  }

  public Next() {
    switch (this._step) {
      case SigningStep.RedFlag:
        if (!this._hideError && (this._signatureConfig && this._questionsAnswered !== this._signatureConfig.Questions.length)) {
          this._questionError = true;
          return;
        }

        this._questionError = false;
        this._step = SigningStep.Preview;
        if (this.signaturePad) {
          this.ResizeCanvas();
        }
        this.app.HideSpinner();
        break;
      case SigningStep.Initial:
        this._data.Initial = JSON.stringify(this.signaturePad.toData().map((s: any) => s.points.map((p: Point) => ({ x: p.x, y: p.y }))));
        this._signatureConfig.Initial = this.signaturePad.toDataURL();
        this.Clear();
        this._step = SigningStep.Signature;
        if (this.signaturePad) {
          this.ResizeCanvas();
        }
        this.app.HideSpinner();
        break;
      case SigningStep.Signature:
        this.HandleSignatureStep();
        break;
      case SigningStep.Preview:
        this.HandlePreviewStep();
        break;
    }
    this.app.HideSpinner();
  }

  public DrawSigs_OnClick() {
    this._drawnSigs = true;
    this._hideError = true;
    this._data.Signature = null;
    this._data.Initial = null;
    this.Next();
  }

  public QuestionToggled(value: boolean) {
    value ? this._questionsAnswered++ : this._questionsAnswered--;
  }

  public ValidSignature() {
    return this.signaturePad.toData().some((x: any) => x.points.length > 3);
  }

  private async HandlePreviewStep() {
    if (!this._hideError && (!this._signedInCity || this._signedInCity.trim().length === 0)) {
      this._signedInCityError = true;
      return;
    }

    this._signedInCityError = false;
    this.app.ShowSpinner();
    this._data.SignedInCity = this._signedInCity;
    this._data.IPAddress = this.app.ipAddress;

    // SAVING AS PLAIN TEXT FOR TYPED SIGS
    if (!this._drawnSigs) {
      this._data.Signature = this.signFor;
      this._data.Initial = this.signFor.split(' ').map(x => x[0]).join('');
    }

    if (this._data.Signature != null) {
      this._processing = true;
      this.svc.postMasterSig(this.parentId, this.fileRefId, this.signerId, this._data)
        .subscribe(
          response => this.signed.emit(response),
          error => {
            console.log(error);
            this._processing = false;
          }
        );
    } else {
      this.app.HideSpinner();
      this._step = this._signatureConfig.RequiresInitials ? SigningStep.Initial : SigningStep.Signature;
      if (this.signaturePad) { this.ResizeCanvas(); }
      this.app.HideSpinner();
    }
  }

  private HandleSignatureStep() {
    this._data.Signature = JSON.stringify(this.signaturePad.toData().map((s: any) => s.points.map((p: Point) => ({ x: p.x, y: p.y }))));
    this._signatureConfig.Signature = this.signaturePad.toDataURL();
    this.Clear();
    this.app.ShowSpinner();
    this.svc.UpdatePreview(this._signatureConfig)
      .subscribe(
        response => this.CleanPreviewData(response),
        error => console.log(error),
        () => {
          this._step = SigningStep.Preview;
          this._hideError = false;
          if (this.signaturePad) {
            this.ResizeCanvas();
          }
          // this.app.HideSpinner();
        });
  }

  private CleanPreviewData(response: SignatureConfig) {
    this._cleanedSignaturePreview = this.sanitizer.bypassSecurityTrustUrl(response.SignaturePreview) as string;
    this._cleanedInitialPreview = this.sanitizer.bypassSecurityTrustUrl(response.InitialPreview) as string;
  }

  private ResizeCanvas() {
    switch (this._step) {
      case SigningStep.RedFlag:
      case SigningStep.Location:
      case SigningStep.Preview:
        this.row.nativeElement.style.maxWidth = '0px';
        this.row.nativeElement.style.maxHeight = '0px';
        break;
      case SigningStep.Initial:
        this.row.nativeElement.style.maxWidth = '152px';
        this.row.nativeElement.style.maxHeight = '152px';
        this.row.nativeElement.style.margin = 'auto';
        break;
      case SigningStep.Signature:
        this.row.nativeElement.style.maxWidth = 'unset';
        this.row.nativeElement.style.maxHeight = 'unset';
        this.row.nativeElement.style.margin = 'unset';
        break;
      default:
        return;
    }

    const clientWidth = this.row.nativeElement.clientWidth;
    this.signaturePad.set('canvasWidth', clientWidth);
    this.Clear();
  }
}
