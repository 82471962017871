<app-offer-tile *ngIf="_contract?.ContractType == _ct.Offer" [contract]="_contract" [largeTile]="true">
</app-offer-tile>

<app-listing-tile *ngIf="_contract?.ContractType == _ct.Listing" [contract]="_contract">
</app-listing-tile>

<app-buyer-tile *ngIf="_contract?.ContractType == _ct.Buyer" [contract]="_contract">
</app-buyer-tile>

<div class="row mt-3">
  <div class="d-grid col-md-6 col-12">
    <button class="btn btn-primary" (click)="DownloadFiles(files)">Download All Files</button>
  </div>
  <div class="d-grid col-md-6 col-12">
    <button class="btn btn-primary" [disabled]="!FilesAreSelected()"
      (click)="DownloadFiles(GetSelectedFiles())">
      Download Selected Files
    </button>
  </div>
</div>

<app-list-files [files]="GetContractFiles()" [selectedFiles]="selected" [headerText]="'CONTRACTS'"
  [contracts]="_contracts" (selected)="File_OnSelect($event[0], $event[1])" (viewFile)="ViewFile($event)"
  (downloadFile)="DownloadFile($event)" (printFile)="PrintFile($event)">
</app-list-files>

<app-list-files [files]="FilterFilesByType([_frt.SignCert])" [selectedFiles]="selected"
  [headerText]="'SIGNING CERTIFICATES'" (selected)="File_OnSelect($event[0], $event[1])" (viewFile)="ViewFile($event)"
  (downloadFile)="DownloadFile($event)" (printFile)="PrintFile($event)">
</app-list-files>

<app-list-files [files]="FilterFilesBySubContract([_sct.CRG])" [selectedFiles]="selected"
  [headerText]="'CONSUMER RELATIONSHIPS GUIDES'" (selected)="File_OnSelect($event[0], $event[1])"
  (viewFile)="ViewFile($event)" (downloadFile)="DownloadFile($event)" (printFile)="PrintFile($event)">
</app-list-files>

<app-list-files [files]="FilterFilesByType([_frt.SignedFile, _frt.SignCert], true)" [selectedFiles]="selected"
  [headerText]="'OTHER FILES'" (selected)="File_OnSelect($event[0], $event[1])" (viewFile)="ViewFile($event)"
  (downloadFile)="DownloadFile($event)" (printFile)="PrintFile($event)">
</app-list-files>

<ng-template #pdfModal>
  <div class="modal-header bg-primary p-0">
    <div class="col p-2">
      <h6 class="text-light m-0">{{ _filename.slice(0, _filename.length - 4) }}</h6>
    </div>
    <div class="col-auto p-0">
      <span class="align-middle">
        <button type="button" class="btn btn-danger btn-block" (click)="_modal.hide()">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </button>
      </span>
    </div>
  </div>
  <app-pdf-lite-viewer [pdf]="_pdf | async" [filename]="_filename" [showPrintButton]="true"
    (afterLoadComplete)="app.HideSpinner();">
  </app-pdf-lite-viewer>
  <!-- <app-pdf-viewer [pdf]="_pdf | async" [filename]="_filename" [modal]="_modal" (afterLoadComplete)="app.HideSpinner();">
  </app-pdf-viewer> -->
</ng-template>

<ng-template #imageModal>
  <div class="modal-header bg-primary p-0">
    <div class="col p-2">
      <h6 class="text-light m-0">{{ _filename }}</h6>
    </div>
    <div class="col-auto p-0">
      <span class="align-middle">
        <button type="button" class="btn btn-danger btn-block" (click)="CloseImageViewer()">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </button>
      </span>
    </div>
  </div>
  <angular-image-viewer [src]="_imageUrls" [(index)]="_imageIndex" [config]="_imageConfig">
  </angular-image-viewer>
</ng-template>