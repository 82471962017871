import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import ConfigUtils from './app/utils/ConfigUtils';
import { environment } from './environments/environment';

if (environment.Production) { enableProdMode(); }

(async () => {
  if (!ConfigUtils.isProdEnv()) { environment.IntercomAppID = 'mnhguv37'; }
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();
