<a id="__hiddenLauncher" class="hidden" target="_self" [href]="_safeUrl" [download]="_downloadName"></a>
<app-site-header *ngIf="_header"></app-site-header>
<div class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-lg-6 col-md-8 col-12">
            <div *ngIf="_alerts.length > 0">
                <app-dismissible-alerts [Alerts]="_alerts"></app-dismissible-alerts>
            </div>
        </div>
    </div>
    <h4>{{ _pageTitle }}</h4>
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<div *ngIf="modal.config">
    <app-modal [modalConfig]="modal.config" (selected)="modal.OnSelected($event);"></app-modal>
</div>