import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UserPaymentData, UserSubscriptionData } from '../classes/classes';
import ConfigUtils from '../utils/ConfigUtils';

export class TrialExtensionData {
  EndDate: Date;
  MaxNumDays: number;
}
@Injectable()
export class StripeService {

  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/stripe';
  }

  AddPaymentMethod(userId: string, token: any): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/AddPaymentMethod/${userId}`, JSON.stringify(token));
  }

  ApplyCoupon(userId: string, code: string): Observable<UserSubscriptionData> {
    return this.http.post<UserSubscriptionData>(`${this.baseUrl}/ApplyCoupon/${userId}`, JSON.stringify(code));
  }

  FreeTrialCheck(userId: string): Observable<TrialExtensionData> {
    return this.http.get<TrialExtensionData>(`${this.baseUrl}/FreeTrialCheck/${userId}`);
  }

  ExtendFreeTrial(userId: string, days: number): Observable<TrialExtensionData> {
    return this.http.get<TrialExtensionData>(`${this.baseUrl}/ExtendFreeTrial/${userId}/${days}`);
  }

  CancelSubscriptionPlan(userId: string, planId: string, canCancel: boolean): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/CancelSubscriptionPlan/${userId}`,
      {
        planId: planId,
        canCancel: canCancel
      });
  }
  GetTaxRate(brokerageId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/GetTaxRate', JSON.stringify(brokerageId));
  }
  GetSubscriptionPlans(): Observable<Array<any>> {
    return this.http.get<any>(this.baseUrl + '/GetSubscriptionPlans');
  }

  GetPaymentMethod(userId: string, cardId: string): Observable<UserPaymentData> {
    return this.http.get<UserPaymentData>(`${this.baseUrl}/GetPaymentMethod/${userId}/${cardId}`);
  }

  GetUserSubscriptions(userId: string): Observable<UserSubscriptionData> {
    return this.http.get<UserSubscriptionData>(`${this.baseUrl}/GetUserSubscriptions/${userId}`);
  }

  GetUserBillingDetails(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/GetUserBillingDetails/${id}`);
  }

  RemovePaymentMethod(userId: string, paymentMethodId: string): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + `/RemovePaymentMethod/${userId}`, JSON.stringify(paymentMethodId));
  }

  UnCancelSubscriptionPlan(userId: string, planId: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/UnCancelSubscriptionPlan/${userId}`, JSON.stringify(planId));
  }

  UpdateSubscriptionPlan(userId: string, newPlanId: string, quantity: number = 1): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/UpdateSubscriptionPlan/${userId}`,
      {
        newPlanId: newPlanId,
        quantity: quantity
      });
  }

  UpgradeAccount(token: any, plan: any, couponId: string): Observable<string> {
    return this.http.post<string>(this.baseUrl + '/UpgradeAccount',
      {
        token: token,
        plan: plan,
        couponId: couponId
      });
  }

  UpdatePaymentMethod(userId: string, paymentMethod: UserPaymentData): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/UpdatePaymentMethod/${userId}`, paymentMethod);
  }

  UpdateDefaultPaymentMethod(userId: string, paymentMethodId: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/UpdateDefaultPaymentMethod/${userId}`, JSON.stringify(paymentMethodId));
  }

  VerifyCoupon(code: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/VerifyCoupon', JSON.stringify(code));
  }
}
