import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'newDecimalAllowZero'
})
export class NewDecimalAllowZeroPipe implements PipeTransform {

  transform(value: string, decimals = 2, returnRawNumber = false): any {
    // This check needs to change for allowing zeros as in javascript
    //  !0 evaluates to true while 0 === null does not so it can not be !value check
    if (value === null) { return null; }

    // REMOVE ANYTHING THAT ISN'T A NUMBER OR DECIMAL
    value = value.toString().replace(/[^\d\.]+/g, '');

    const d = Math.pow(10, decimals);

    let n = 0;

    if (isFinite(value as any)) {
      n = Math.round(value as any * d) / d;
    }

    if (returnRawNumber) {
      return n;
    }

    if (n === 0) {
      return 0;
    } else {
      return new DecimalPipe('en-US').transform(n.toString(), '1.' + decimals + '-' + decimals);
    }
  }

}
