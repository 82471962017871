import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StripeService } from '../../services/stripe.service';
import FormUtils from '../../utils/form/FormUtils';
import { RegistrationFormUtils } from '../../utils/form/RegistrationFormUtils';
import { RegistrationFlowType } from '../classes';
import { RegisterService } from '../service/register.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

  @Input() fg: UntypedFormGroup;
  @Output() close = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();

  disableButton = false;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  token: string = null;
  style = {
    base: {
      color: '#32325d',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  _plan = null;
  _plans = [];
  _showPlan = false;
  _rft = RegistrationFlowType;
  _fu = FormUtils;
  _error = false;
  _paymentNotice = null;
  couponError = false;
  coupon = null;
  couponApplied = null;

  constructor(private ssvc: StripeService, private svc: RegisterService, private cd: ChangeDetectorRef, private spinner: NgxUiLoaderService) { }

  ngOnInit() {
    this.initLayout();
    RegistrationFormUtils.initCartStepValidation(this.fg);
  }
  assignCoupon(value: any) {
    if (!value) {
      this.coupon = null;
      this.couponError = true;
      this.couponApplied = null;
      return;
    }
    this.coupon = value.id;
    this.couponError = false;
    this.fg.get('Coupon').setValue(this.coupon);
    this.couponApplied = `Coupon code: <b>${value.name}</b> has been applied for a
     <b>${value.percent_off != null ? value.percent_off + '%' : '$' + value.amount_off}</b> discount.`;
  }
  initLayout() {
    this.spinner.start();
    this._showPlan = this.fg.get('RegistrationFlowType').value === RegistrationFlowType.GuestValidationWithPayment ||
      this.fg.get('RegistrationFlowType').value === RegistrationFlowType.PaymentOnly;

    this.ssvc.GetSubscriptionPlans()
      .subscribe(
        response => {
          this._plans = response;
          if (!this._showPlan) {
            this._plan = response.find(x => x.id === this.fg.get('PlanId').value);
            this._paymentNotice = this.fg.get('User').get('CustomerIdDefault').value ?
            this._plan.metadata['ReturningDescription'] :
            this._plan.metadata['Description'];
          }
        },
        error => {
          console.log(error);
        },
        () => this.spinner.stop()
      );
  }
  Plan_OnSelected() {
    this._plan = this._plans.find(x => x.id === this.fg.get('PlanId').value);

    this._paymentNotice = this.fg.get('User').get('CustomerIdDefault').value ?
          this._plan.metadata['ReturningDescription'] :
          this._plan.metadata['Description'];
  }
  ProcessPayment(value: any) {
    const token = value;
    this.fg.get('Token').setValue(token);
    this.Next();
  }
  Next() {
    this.svc.PostRegistrationData(this.fg.getRawValue())
    .subscribe(
      response => {
        this.fg.setValue(response);
          if (!response.Success) {
            this._error = true;
          }
      },
      error => console.log(error),
      () => { this.spinner.stop();
        if (this.fg.get('Success').value) { this.next.emit(); }
      }
    );
  }
  Back() {
    this.back.emit();
  }
  Close() {
    this.close.emit();
  }

  ngAfterViewInit() {
    this.card = elements.create('card', {style: this.style});
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    this.spinner.start();
    this._error = false;
    if (FormUtils.FormGroupHasError(this.fg, true)) { this.spinner.stop(); return; }

    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
      this.spinner.stop();
    } else {
      this.disableButton = true;
      this.ProcessPayment(token);
    }
  }
}
