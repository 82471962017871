import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, friendlyFormat = true): string {

    if (!value) { return null; }

    let country = 0;
    let areaCode: string;
    let phNumber: string;

    // REMOVE ANYTHING THAT ISN'T A NUMBER
    value = value.replace(/\D+/g, '');

    // ONLY NORTH AMERICA STARTS WITH 1
    if (value.startsWith('1')) {
      value = value.replace(/^1/g, '');
    }

    switch (value.length) {
      case 10:
        country = 1;
        areaCode = value.slice(0, 3);
        phNumber = value.slice(3);
        break;
    }

    if (country === 0) {
      return value;
    }

    // THIS WILL RETURN A NORMAL NORTH AMERICAN NUMBER, EVERYTHING ELSE IS JUST NUMBERS
    if (friendlyFormat) {
      return '(' + areaCode + ') ' + phNumber.slice(0, 3) + '-' + phNumber.slice(3);
    } else {
      return '+1-' + areaCode + '-' + phNumber.slice(0, 3) + '-' + phNumber.slice(3);
    }
  }

}
