import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { ContractStatus } from '../../classes/contract';
import { ContractService } from '../../services/contract.service';
import { LookupService } from '../../services/lookup.service';

export default class ValidatorUtils {

  public static _emailPattern = '[a-zA-Z0-9.\\-\\_]{1,}@[a-zA-Z0-9.\\-\\_]{2,}[.]{1}[a-zA-Z]{2,6}';
  private static _postalCodePattern = '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][ ]?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]';
  private static _zipCodePattern = '^(?!0{3})[0-9]{3,5}$';

  static MinLength(length = 10): any { return [Validators.minLength(length)]; }
  static NumberOfDigitsRequired(digits: number): any { return [Validators.required, Validators.pattern('[0-9]{' + digits + '}')]; }
  static PostalCode(): any { return [Validators.pattern(this._postalCodePattern)]; }
  static PostalCodeRequired(): any { return [Validators.required, Validators.pattern(this._postalCodePattern)]; }
  static RequiredPassword(): any { return [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}')]; }
  static ZipCode(): any { return [Validators.pattern(this._zipCodePattern)]; }
  static ZipCodeRequired(): any { return [Validators.required, Validators.pattern(this._zipCodePattern)]; }
  static Required(): any { return [Validators.required]; }

  //#region Email Validations
  private static NeverBounceCheck(svc: LookupService) {
    return (control: AbstractControl): Observable<ValidationErrors> => {

      if (!control.dirty) {
        return of(null).pipe(delay(10), take(1));
      }

      if (control.value === null || control.value.trim() === '') {
        return of(null).pipe(delay(10), take(1));
      }

      return timer(500).pipe(switchMap(() => {
        return svc.checkEmailAddress(control.value).pipe(map((res) => {
          return res === 'valid' || res === 'catchall' || res === 'unknown' ? null : { verifyEmail: true };
        }));
      }));
    };
  }
  static ValidEmail(control: AbstractControl, svc: LookupService): AbstractControl {
    control.setValidators(this.ValidEmailSync());
    control.setAsyncValidators(this.NeverBounceCheck(svc));
    control.updateValueAndValidity();
    return control;
  }
  static ValidEmailRequired(control: AbstractControl, svc: LookupService): AbstractControl {
    control.setValidators(this.ValidEmailRequiredSync());
    control.setAsyncValidators(this.NeverBounceCheck(svc));
    control.updateValueAndValidity();
    return control;
  }
  static ValidEmailRequiredSync(): any { return [Validators.required, Validators.pattern(this._emailPattern)]; }
  static ValidEmailSync(): any { return [Validators.pattern(this._emailPattern)]; }
  static ValidEmailAsync(svc: LookupService): any { return this.NeverBounceCheck(svc); }

  private static ContractStatusCheck(svc: ContractService, contractNumber: number, status: ContractStatus) {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return timer(1).pipe(switchMap(() => {
        return svc.GetContractStatus(contractNumber).pipe(map((res) => {
          return res === status ? null : { verifyStatus: true };
        }));
      }));
    };
  }
  static ValidContractActive(control: AbstractControl, contractNumber: number, svc: ContractService): AbstractControl {
    control.setAsyncValidators(this.ContractStatusCheck(svc, contractNumber, ContractStatus.Active));
    control.updateValueAndValidity();
    return control;
  }

  static ValidateRequired(control: AbstractControl): AbstractControl {
    control.setValidators(this.Required());
    control.updateValueAndValidity();
    return control;
  }
  //#endregion
}
