import { ConveyancerModule } from './conveyancer/conveyancer.module';
import { ContactModule } from './contacts/contact/contact.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArchive, faBan, faBars, faCaretLeft, faCaretRight, faCheck, faComments, faCopy, faDownload, faEdit,
  faEye, faFileAlt, faFileCheck, faFileContract, faFileEdit, faFileInvoice, faFileInvoiceDollar, faFileSignature,
  faHome, faIdBadge, faInfoSquare, faLightbulbExclamation, faPaperPlane, faPencil, faPrint, faPaperclip,
  faSave, faSearch, faThumbsDown, faThumbsUp, faTrashAlt, faFileDownload, faUndo, faCabinetFiling, faBooks,
  faHandshake, faCaretDown, faClock, faMapMarkerAlt, faMoneyCheckAlt, faChevronDoubleDown, faChevronDoubleRight,
  faEllipsisH, faSquare
} from '@fortawesome/pro-light-svg-icons';
import {
  faBuilding, faCalendar, faEllipsisV, faEnvelope, faFax, faPencilAlt, faPhone, faPhoneOffice, faPlus,
  faQuestion, faTimes, faUpload, faUndoAlt, faUserPlus, faCheckSquare
} from '@fortawesome/pro-solid-svg-icons';
import {
  faInfoCircle, faArrowsAlt, faLock, faUnlock
} from '@fortawesome/pro-regular-svg-icons';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { IntercomConfig, IntercomModule } from 'ng-intercom';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttachmentModule } from './attachment/attachment.module';
import { ContractReportsModule } from './contract-reports/contract-reports.module';
import { ContractModule } from './contract/contract.module';
import { AuthGuard } from './guards/auth.guard';
import { AuthGuardAdmin } from './guards/auth.guard.admin';
import { AuthGuardGuest } from './guards/auth.guard.guest';
import { AuthGuardNonAdmin } from './guards/auth.guard.nonadmin';
import { AuthGuardNonGuestUser } from './guards/auth.guard.nonguestuser';
import { AuthGuardPaid } from './guards/auth.guard.paid';
import { AuthGuardSigner } from './guards/auth.guard.signer';
import { AuthGuardUsers } from './guards/auth.guard.users';
import { AuthGuardPassword } from './guards/auth.guardpassword';
import { AppFooterComponent } from './layout/app-footer/app-footer.component';
import { AppHeaderComponent } from './layout/app-header/app-header.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { SiteHeaderComponent } from './layout/site-header/site-header.component';
import { MaintenanceDatePipe, SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { LoginComponent, MaintenancePipe } from './login/login.component';
import { PasswordRecoveryComponent } from './login/password-recovery/password-recovery.component';
import { AuthService } from './services/auth.service';
import { ContactService } from './services/contact.service';
import { ContractService } from './services/contract.service';
import { FormService } from './services/form.service';
import { LookupService } from './services/lookup.service';
import { RecoveryService } from './services/recovery.service';
import { SignatureService } from './services/signature.service';
import { SignerService } from './services/signer.service';
import { StripeService } from './services/stripe.service';
import { UserService } from './services/user.service';
import { VersionCheckService } from './services/version-check.service';
import { SharedModule } from './shared/shared.module';
import { HttpInterceptUtils } from './utils/HttpInterceptUtils';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { DashboardService } from './services/dashboard.service';
import { AppNavTopComponent } from './layout/app-nav-top/app-nav-top.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { FileService } from './services/file.service';
import { AuthGuardEnvelopeSigner } from './guards/auth.guard.envelopesigner';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AuthGuardPaymentMethod } from './guards/auth.guard.paymentmethod';
import { AuthGuardConveyancer } from './guards/auth.guard.conveyancer';
import { EmailService } from './services/email.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpInterceptDateUtils } from './utils/HttpInterceptDateUtils';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    AppFooterComponent,
    AppLayoutComponent,
    AppNavTopComponent,
    AppHeaderComponent,
    PasswordRecoveryComponent,
    MaintenancePipe,
    MaintenanceDatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiSwitchModule.forRoot({
      color: 'rgb(125, 219, 255)',
      defaultBgColor: '#FFFFFF',
      checkedLabel: 'Yes',
      uncheckedLabel: 'No'
    }),
    NgSelectModule,
    NgOptionHighlightModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    AttachmentModule,
    ContractModule,
    ContractReportsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgClickOutsideDirective,
    ContactModule,
    AdminModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    NgbTypeaheadModule,
    IntercomModule,
    NgxUiLoaderModule.forRoot(
      {
        'bgsColor': '#00ACC1',
        'bgsOpacity': 0.5,
        'bgsPosition': 'bottom-left',
        'bgsSize': 60,
        'bgsType': 'ball-spin-clockwise',
        'blur': 5,
        'delay': 0,
        'fgsColor': '#00ACC1',
        'fgsPosition': 'center-center',
        'fgsSize': 60,
        'fgsType': 'ball-spin-clockwise',
        'gap': 24,
        'logoPosition': 'center-center',
        'logoSize': 120,
        'logoUrl': '',
        'masterLoaderId': 'master',
        'overlayBorderRadius': '0',
        'overlayColor': 'rgba(40, 40, 40, 0.8)',
        'pbColor': '#00ACC1',
        'pbDirection': 'ltr',
        'pbThickness': 3,
        'hasProgressBar': false,
        'text': '',
        'textColor': '#FFFFFF',
        'textPosition': 'center-center',
        'maxTime': -1,
        'minTime': 300
      }
    ),
    MatTabsModule,
    NgxUiLoaderRouterModule,
    SharedModule,
    ModalModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ConveyancerModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptUtils, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptDateUtils, multi: true },
    {
      provide: IntercomConfig,
      useFactory: () => ({
        appId: environment.IntercomAppID,
        updateOnRouterChange: true
      })
    },
    AuthService,
    AuthGuard,
    AuthGuardAdmin,
    AuthGuardEnvelopeSigner,
    AuthGuardGuest,
    AuthGuardNonGuestUser,
    AuthGuardNonAdmin,
    AuthGuardPaid,
    AuthGuardPaymentMethod,
    AuthGuardUsers,
    AuthGuardSigner,
    AuthGuardPassword,
    AuthGuardConveyancer,
    ContactService,
    ContractService,
    DashboardService,
    FileService,
    FormService,
    LookupService,
    SignatureService,
    SignerService,
    UserService,
    RecoveryService,
    VersionCheckService,
    StripeService,
    EmailService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faCaretRight, faCaretLeft, faPhone, faEnvelope, faPlus, faPencilAlt, faBuilding,
      faSearch, faQuestion, faInfoSquare, faHome, faTrashAlt, faComments, faPencil, faFax, faPhoneOffice,
      faPrint, faFileInvoiceDollar, faCalendar, faTimes, faBars, faLightbulbExclamation, faInfoCircle, faBooks,
      faDownload, faFileEdit, faFileCheck, faFileAlt, faFileSignature, faFileContract, faEye, faCabinetFiling,
      faEdit, faThumbsUp, faThumbsDown, faPaperPlane, faCheck, faBan, faEllipsisV, faSave, faCopy, faArchive,
      faIdBadge, faFileInvoiceDollar, faFileInvoice, faLock, faUndo, faUndoAlt, faUnlock, faUpload, faFileDownload,
      faArrowsAlt, faPaperclip, faHandshake, faCaretDown, faClock, faMapMarkerAlt, faUserPlus, faMoneyCheckAlt, faChevronDoubleDown,
      faChevronDoubleRight, faEllipsisH, faCheckSquare, faSquare);
  }
}
