import { Component, OnInit, Input } from '@angular/core';
import { StripeService } from '../../services/stripe.service';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import FormUtils from '../../utils/form/FormUtils';
import { ActivatedRoute } from '@angular/router';
import GuidUtils from '../../utils/GuidUtils';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html'
})
export class AddPaymentMethodComponent implements OnInit {

  userId: string = null;
  token: any;
  public forceUpdate = false;

  constructor(private svc: StripeService, private app: AppLayoutComponent, private route: ActivatedRoute) {
    this.userId = this.route.snapshot.params['id'] || GuidUtils.EmptyGuid();
  }

  ngOnInit() {
    this.forceUpdate = this.app.cu.User.ForceUpdatePaymentMethod;
  }
  SaveToken(token: any) {
    this.token = token;
  }
  Back_OnClick() {
    const route = this.app.router.url.split('/');
    route.pop();
    this.app.router.navigate(route);
  }
  AddNewPaymentMethod() {
    this.svc.AddPaymentMethod(this.userId, this.token)
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error adding payment method... Try again later');
            this.token = null;
          } else {
            this.app.ShowSuccessMessage('Successfully added payment method');
            if (this.forceUpdate) {
              this.app.RefreshToken('/home');
            } else {
              this.Back_OnClick();
            }
          }
        },
        error => {
          console.log(error);
          this.app.ShowErrorMessage('Error adding payment method... Try again later');
          this.token = null;
        },
        () => {
          FormUtils.scrollToTop();
        }
      );
  }

}
