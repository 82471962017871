import { NEW_OFFER_STARTED } from './../../contract/classes';
import { DomSanitizer } from '@angular/platform-browser';
import { Contract, ContractStatus, ContractType, SubContractType } from './../../classes/contract';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FileRef, FileRefType } from './../../forms/classes';
import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { ContractService } from './../../services/contract.service';
import { FileService } from './../../services/file.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import GuidUtils from '../../utils/GuidUtils';
import ContractFormUtils from '../../utils/form/ContractFormUtils';

@Component({
  selector: 'app-conveyancer',
  templateUrl: './conveyancer-view.component.html',
  styles: []
})
export class ConveyancerViewComponent implements OnInit {
  @ViewChild('pdfModal', { static: true }) pdfModal: any;
  @ViewChild('imageModal') imageModal: any;

  contractNumber: number;
  files: FileRef[] = [];
  selected = new Map<string, boolean>();
  _filename: string = '';
  _modal: BsModalRef;
  _cfu = ContractFormUtils;
  _pdf: Observable<ArrayBuffer> = null;
  _frt = FileRefType;
  _sct = SubContractType;
  _ct = ContractType;
  _contract: Contract;
  _contracts: Contract[];

  // Image viewer stuff
  _image: Blob = null;
  _imageUrls: string[] = [];
  _imageIndex: number = 0;
  _imageConfig = {
    allowFullscreen: false
  };


  constructor(private route: ActivatedRoute,
    private svc: FileService,
    public app: AppLayoutComponent,
    private svcModal: BsModalService,
    private cSvc: ContractService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.app.ShowSpinner();
    this.contractNumber = this.route.snapshot.params['number'] || null;

    this.svc.GetConveyancerFiles(this.contractNumber).subscribe({
      next: files => {
        this.files = files;
        // this.files = files.sort((a, b) => a.ParentRev == b.ParentRev ? 0 : a.ParentRev < b.ParentRev ? -1 : 1);
        this.files.forEach(x => this.selected.set(x.FileRefId, false));
        const current = this.FilterFilesBySubContract([this._sct.CRG], this.FilterFilesByType([this._frt.SignedFile]), true).sort((a, b) => a.ParentRev < b.ParentRev ? 1 : -1).map(x => [x.ParentId, x.ParentNumber])[0];
        this.cSvc.GetLightContractsByNumber(current[1] as number).subscribe({
          next: contracts => {
            this._contract = contracts[0];
            this._contracts = contracts;
            this.app.HideSpinner();
          }
        });
      }
    });
  }

  public File_OnSelect(file: FileRef, value: boolean) {
    this.selected.set(file.FileRefId, value);
  }

  public DownloadFiles(files: FileRef[]) {
    if (files == null || files.length === 0) {
      return;
    }

    this.app.ShowSpinner();
    this.svc.DownloadZipArchive(files).subscribe({
      next: zip => this.app.DownloadFile(zip, `${this.contractNumber}.zip`, 'application/zip')
    });
  }

  public FilesAreSelected(): boolean {
    return [...this.selected.values()].some(x => x);
  }

  public GetSelectedFiles(): FileRef[] {
    return this.files.filter(x => this.selected.get(x.FileRefId));
  }

  public GetContractFiles(): FileRef[] {
    let files = this.FilterFilesBySubContract([this._sct.CRG], this.FilterFilesByType([this._frt.SignedFile]), true);

    let deadStatuses = [ContractStatus.Expired, ContractStatus.ConditionsNonWaived, ContractStatus.Rejected];
    if (this._contracts?.some((contract, i, arr) => {
      return deadStatuses.includes(contract.Status) &&
        arr[i - 1] != undefined &&
        arr[i - 1] != null &&
        arr[i - 1]?.SubContractType == SubContractType.Base;
    })) {
      let contracts: FileRef[] = [];
      files.map(value => { return [value, this._contracts.find(x => x.Rev == value.ParentRev)] as [FileRef, Contract]; }).forEach(([contractFile, contract], i, arr) => {
        if (deadStatuses.includes(contract.Status) && arr[i - 1] != undefined && arr[i - 1] != null && arr[i - 1][1].SubContractType == SubContractType.Base) {
          contracts.push(new FileRef({ Filename: NEW_OFFER_STARTED }));
        }
        contracts.push(contractFile);
      });
      files = contracts;
    }

    return files;
  }

  public FilterFilesByType(types: FileRefType[], excludeTypes: boolean = false): FileRef[] {
    if (excludeTypes)
      return this.files.filter(x => !types.includes(x.FileRefType));
    else
      return this.files.filter(x => types.includes(x.FileRefType));
  }

  public FilterFilesBySubContract(types: SubContractType[], files: FileRef[] = this.files, excludeTypes: boolean = false): FileRef[] {
    if (excludeTypes)
      return files.filter(x => !types.includes(x.SubContractType));
    else
      return files.filter(x => types.includes(x.SubContractType));
  }

  public ViewFile(file: FileRef) {
    this.app.ShowSpinner();

    if (file.FileExt == '.pdf') {
      this._filename = file.Filename.includes('.pdf') ? file.Filename.slice(0, file.Filename.length - 4) : file.Filename;
      this._pdf = this.svc.DownloadFile(file.FileRefId);
      this.LaunchViewer();
    } else if (['jpg', 'jpeg', 'png', 'tif', 'tiff'].includes(file.FileExt.replace('.', ''))) {
      this._filename = file.Filename.includes(file.FileExt) ? file.Filename.slice(0, file.Filename.length - file.FileExt.length) : file.Filename;
      this.svc.DownloadFile(file.FileRefId).subscribe({
        next: image => {
          this._image = new Blob([new Uint8Array(image)], { type: this.GetMimeType(file.FileExt) });
          const url = window.URL.createObjectURL(this._image);
          this._imageUrls = [this.sanitizer.bypassSecurityTrustUrl(url) as string];
          this.LaunchViewer(this.imageModal);
        }
      });
    }
  }

  public CloseImageViewer() {
    this._modal.hide();
    window.URL.revokeObjectURL(this._imageUrls[0]);
  }

  public DownloadFile(file: FileRef) {
    this.app.ShowSpinner();

    this.svc.DownloadFile(file.FileRefId).subscribe({
      next: pdf => {
        this.app.DownloadFile(pdf, `${file.Filename}${file.FileExt}`);
        this.app.HideSpinner();
      }
    });
  }

  public PrintFile(file: FileRef) {
    this.app.ShowSpinner();
    this.svc.DownloadFile(file.FileRefId).subscribe({
      next: pdf => {
        this.app.PrintFile(pdf);
        this.app.HideSpinner();
      }
    });
  }

  private LaunchViewer(viewer: any = this.pdfModal) {
    const config = new ModalOptions();
    config.ignoreBackdropClick = true;
    config.keyboard = false;
    config.class = 'modal-dialog-centered modal-lg';
    config.animated = true;
    this._modal = this.svcModal.show(viewer, config);
    this.app.HideSpinner();
  }

  private GetMimeType(ext: string): string {
    ext = ext[0] === '.' ? ext.slice(1) : ext;
    switch (ext) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'tif':
      case 'tiff':
        return 'image/tiff';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'application/pdf';
    }
  }

}
