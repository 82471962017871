<div id="divSubscriptionDetails">
  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col">
        SUBSCRIPTION DETAILS
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary btn-block" (click)="Route('subscription')">
          <fa-icon [icon]="['fal', 'pencil']" *ngIf="app.smallScreen"></fa-icon>
          <span *ngIf="!app.smallScreen"> Manage Subscription</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="billingData">
    <div *ngIf="billingData.Subscription.Plan">
      <div class="row mb-3 g-3">
        <div class="col">
          Type
        </div>
        <div class="col-auto fw-bold">
          {{ billingData.Subscription.Plan.PlanName }}
        </div>
      </div>
      <div *ngIf="billingData.Subscription.Quantity > 1" class="row mb-3 g-3">
        <div class="col">
          Number of Seats
        </div>
        <div class="col-auto fw-bold">
          {{ billingData.Subscription.Quantity }}
        </div>
      </div>
      <div class="row mb-3 g-3">
        <div class="col">
          Price
        </div>
        <div class="col-auto fw-bold">
          ${{ billingData.Subscription.Plan.PlanPrice == 0 ? '0.00' : newDP.transform((billingData.Subscription.Plan.PlanPrice / 100).toString())}}
        </div>
      </div>
      <div class="row mb-3 g-3">
        <div class="col">
          Status
        </div>
        <div class="col-auto fw-bold">
          {{ billingData.Subscription.Plan.EndDate ? ('Active until: ' + (billingData.Subscription.Plan.EndDate | newDate)) : 'Active' }}
        </div>
      </div>
      <div class="row mb-3 g-3">
        <div class="col">
          Current Billing Cycle
        </div>
        <div class="col-auto fw-bold">
          {{ (billingData.Subscription.Plan.BillingCycleStart | newDate) + ' - ' }}
          {{ billingData.Subscription.Plan.BillingCycleEnd | newDate }}
        </div>
      </div>
      <div *ngIf="billingData.Subscription.Quantity > 1" class="row mb-3 g-3">
        <div class="col">
          Current Contract Cycle
        </div>
        <div class="col-auto fw-bold">
          {{ (billingData.Subscription.Plan.StartDate | newDate) + ' - ' }}
          {{ billingData.Subscription.Plan.RenewalDate | newDate }}
        </div>
      </div>
      <br>
    </div>
    <div *ngIf="billingData.Subscription.NextPlan">
      <div class="row mb-3 g-3">
        <div class="col">
          Type
        </div>
        <div class="col-auto fw-bold">
          {{ billingData.Subscription.NextPlan.PlanName }}
        </div>
      </div>
      <div class="row mb-3 g-3">
        <div class="col">
          Price
        </div>
        <div class="col-auto fw-bold">
          ${{newDP.transform((billingData.Subscription.NextPlan.PlanPrice / 100).toString())}}
        </div>
      </div>
      <div class="row mb-3 g-3">
        <div class="col">
          Status
        </div>
        <div class="col-auto fw-bold">
          {{ 'Active starting: ' + (billingData.Subscription.Plan.EndDate | newDate) }}
        </div>
      </div>
      <div class="row mb-3 g-3">
        <div class="col">
          Next Billing Cycle Start
        </div>
        <div class="col-auto fw-bold">
          {{ (billingData.Subscription.NextPlan.BillingCycleStart | newDate) + ' - '}}
          {{ billingData.Subscription.NextPlan.BillingCycleEnd | newDate }}
        </div>
      </div>
      <br>
    </div>
    <div *ngIf="billingData.Subscription.CurrentCoupon || billingData.Subscription.NextCoupon">
      <div *ngIf="billingData.Subscription.CurrentCoupon" class="row mb-3 g-3">
        <div class="col">
          Coupon: <b>{{billingData.Subscription.CurrentCoupon.CouponId}}</b>
        </div>
        <div class="col-auto fw-bold">
          {{ getCurrentDiscount() + ' applied to ' +  billingData.Subscription.CurrentCoupon.Status}}
        </div>
      </div>
      <div *ngIf="billingData.Subscription.NextCoupon" class="row mb-3 g-3">
        <div class="col">
          Coupon: <b>{{billingData.Subscription.NextCoupon.CouponId}}</b>
        </div>
        <div class="col-auto fw-bold">
          {{ getNextDiscount() + ' applied to ' +  billingData.Subscription.NextCoupon.Status}}
        </div>
      </div>
    </div>
    <!-- UN-HIDE STRIPE TASK 481 07/20/2020 -->
    <div id="divCoupon">
      <br *ngIf="billingData.Subscription.CurrentCoupon || billingData.Subscription.NextCoupon">
      <app-coupon [customerError]="customerError" (couponData)="assignCoupon($event)"
        (customerErrorChanged)="customerError = $event">
      </app-coupon>
    </div>
  </div>
</div>
<div id="divInvoices">
  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col">
        INVOICES
      </div>
    </div>
  </div>
  <div *ngIf="billingData">
    <div id="divInvoiceSelector" class="row mb-3 g-3">
      <div class="col">
        <select #cs class="form-select pointerCursor">
          <option *ngFor="let invoice of billingData.Invoices;" value="{{invoice.InvoiceHostedUrl}}">
            {{ invoice.InvoicePaidOn != null ? (invoice.InvoicePaidOn | newDate) + ' (' + invoice.InvoiceStatus + ')': 'UnPaid' }}
          </option>
        </select>
      </div>
      <div class="col-auto">
        <span class="input-group-btn">
          <button type="button" class="btn btn-primary btn-block" style="height: 100%;"
            (click)="ViewInvoice(cs.value)">
            View
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
<div id="divPaymentMethod">
  <div class="alert alert-secondary" role="alert">
    <div class="row">
      <div class="col">
        PAYMENT METHOD
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary btn-block" (click)="Route('paymethod')">
          <fa-icon [icon]="['fas', 'plus']" *ngIf="app.smallScreen"></fa-icon>
          <span *ngIf="!app.smallScreen">Add Payment Method</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="billingData">
    <app-payment-methods [paymentMethods]="billingData.PaymentMethods" [app]="app" (reloadData)="ReloadData($event);">
    </app-payment-methods>
  </div>
</div>