import { ElementRef, Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User, YesNoPromptConfig } from '../../classes/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import GuidUtils from '../../utils/GuidUtils';
import { AdminService } from '../service/admin.service';

@Component({
  selector: 'app-user-selected',
  templateUrl: './user-selected.component.html',
  styles: [
  ]
})
export class UserSelectedComponent implements OnInit {
  @ViewChild('newPassTB') newPassTB: ElementRef;

  selectedUser: User;
  userId: string = null;

  config: YesNoPromptConfig = null;
  newPass: AbstractControl;

  frm: UntypedFormGroup;
  accessGroup: string[] = ['jesseh@rdtminc.com', 'gordanj@rdtminc.com'];
  hasAccess = false;

  constructor(private app: AppLayoutComponent, private asvc: AdminService, private fb: UntypedFormBuilder, private route: ActivatedRoute) {
    this.userId = this.route.snapshot.params['id'] || GuidUtils.EmptyGuid();
  }

  ngOnInit(): void {
    this.app.ShowSpinner();
    if (!this.app.cu.User.EmailAddress.includes('@rdtminc.com')) {
      this.app.router.navigate(['/']);
      return;
    }
    this.asvc.GetUser(this.userId)
    .subscribe(
      response => {
        this.selectedUser = response;
        this.init();
        if (!this.selectedUser?.CustomerId) { this.app.HideSpinner(); }
      },
      error => {
        this.app.ShowErrorMessage(error.error.Message);
        this.back_OnClick();
        this.app.HideSpinner();
    },
    );
  }
  init() {
    this.frm = this.fb.group({
      NewPass: null
    });
    this.newPass = this.frm.get('NewPass');
    // this.layout.SetPageTitle('Admin');
    this.hasAccess = this.accessGroup.includes(this.app.cu.User.EmailAddress);
  }
  back_OnClick() {
    this.app.router.navigate(['admin']);
  }
  prompt_OnValueSelected(value: boolean) {

    // CLOSE PROMPT
    if (!value) {
      if (this.config.NoFunction) {
        this.config.NoFunction();
      }
      this.clearPrompt();
      return;
    }

    // IF YES FUNCTION, RUN IT
    if (this.config.YesFunction) {
      this.config.YesFunction();
    }
  }
  generate_OnClick() {
    this.config = new YesNoPromptConfig();
    this.config.Message = 'Are you sure you would like to reset password for <b>' +
      this.selectedUser.FirstName + ' ' + this.selectedUser.LastName + '</b>? <br><br>' +
      'Selecting <b>Yes</b> will generate a new password for this user which you will have to give them.' +
      '<br><br>' +
      'Otherwise, select <b>No</b> to go back.';
    this.config.YesFunction = () => this.GeneratePassword();
  }

  copy_OnClick() {
    this.newPassTB.nativeElement.select();
    document.execCommand('copy');
    this.newPassTB.nativeElement.setSelectionRange(0, 0);
  }
  clearPrompt() {
    this.config = null;
  }
  GeneratePassword() {
    this.asvc.ResetUserPassword(this.selectedUser.UserId)
      .subscribe(
        response => this.newPass.setValue(response),
        error => console.log(error),
        () => this.clearPrompt()
      );
  }
}
