import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserSubscriptionData } from '../../classes/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { NewDecimalPipe } from '../../pipes/new-decimal.pipe';
import { StripeService } from '../../services/stripe.service';
import FormUtils from '../../utils/form/FormUtils';
import GuidUtils from '../../utils/GuidUtils';
declare var $: any;

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html'
})
export class ManageSubscriptionComponent implements OnInit {

  @Input() upgrade = false;
  @Input() title = 'Manage Subscription';
  @Output() selectedPlan = new EventEmitter<any>();

  plans = [];
  newDP = new NewDecimalPipe();
  currentPlan = null;
  newPlan = null;
  subscription: UserSubscriptionData;
  quantityArr = [];
  quantity = 2;
  userId: string = null;

  constructor(private app: AppLayoutComponent, private svc: StripeService, config: NgbDropdownConfig, private route: ActivatedRoute) {
    config.placement = 'top-right';
    this.userId = this.route.snapshot.params['id'] || GuidUtils.EmptyGuid();
  }

  ngOnInit() {
    this.FillQuantity();
    this.svc.GetSubscriptionPlans()
      .subscribe(
        response => {
          this.plans = response;
        },
        error => {
          console.log('Could not retrieve plans');
        },
        () => {
          if (!this.upgrade) {
            this.svc.GetUserSubscriptions(this.userId)
              .subscribe(
                response => {
                  this.subscription = response;
                  if (this.subscription.Quantity > 1) {
                    this.quantity = this.subscription.Quantity;
                  }
                  this.FixTileSizes();
                },
                error => {
                  console.log('Could not retrieve user subscriptions');
                }
              );
          } else {
            this.FixTileSizes();
          }
        }
      );
  }
  FillQuantity() {
    for (let i = 2; i < 16; i++) {
      this.quantityArr.push(i);
    }
  }
  updateQty(value: any) {
    this.quantity = value;
  }
  GetPrice(plan: any) {
    if (!plan.tiers) {
      return this.newDP.transform((plan.amount / 100).toString(), 0);
    } else {
      let price = 0;
      for (let i = 0; i < plan.tiers.length; i++) {
        const prev = i > 0 ? i - 1 : 0;
        if (this.quantity <= plan.tiers[i].up_to && this.quantity > plan.tiers[prev].up_to) {
          price = this.quantity * (plan.tiers[i].unit_amount / 100);
        } else if (plan.tiers[i].up_to == null && this.quantity > plan.tiers[prev].up_to) {
          price = this.quantity * (plan.tiers[i].unit_amount / 100);
        }
      }
      return this.newDP.transform(price.toString(), 0);
    }
  }
  Back_OnClick() {
    const route = this.app.router.url.split('/');
    route.pop();
    this.app.router.navigate(route);
  }
  FixTileSizes() {
    setTimeout(() => {

      const height = $('.card-Monthly').outerHeight();
      $('.button-guest').css('margin-top', height - $('.card-guest').outerHeight(true) + 'px');

      this.plans.forEach(x => {
        if (x.tiers) {
          $('.button-' + x.nickname).css('margin-top', height - 8 - $('.card-' + x.nickname).outerHeight(true) + 'px');
        } else {
          $('.button-' + x.nickname).css('margin-top', height - $('.card-' + x.nickname).outerHeight(true) + 'px');
        }
      });
    }, 10);
  }

  GetMinimum(plan: any) {
    return plan.metadata['Minimum'] == null ? '<br>' : plan.metadata['Minimum'];
  }

  GetSavings(plan: any) {
    return plan.metadata['Saving'] == null ? 'Cancel Anytime' : plan.metadata['Saving'];
  }

  CanUnCancel(plan: any) {
    const subscribed = this.subscription.Plan.PlanId === plan.id ? this.subscription.Plan :
      (this.subscription.NextPlan && this.subscription.NextPlan.PlanId === plan.id) ? this.subscription.NextPlan : null;
    if (subscribed == null) {
      return false;
    } else if ((subscribed.CanCancel === 'false' || this.subscription.Quantity > 1) && subscribed.EndDate != null) {
      return true;
    }
    return false;
  }
  CanCancel(plan: any) {
    const subscribed = this.subscription.Plan.PlanId === plan.id ? this.subscription.Plan :
      (this.subscription.NextPlan && this.subscription.NextPlan.PlanId === plan.id) ? this.subscription.NextPlan : null;
    if (subscribed == null) {
      return false;
    } else if ((this.subscription.NextPlan == null && subscribed.EndDate == null) ||
      (this.subscription.NextPlan && this.subscription.NextPlan.PlanId === plan.id)) {
      return true;
    }
    return false;
  }
  CancelPlan(plan: any) {
    const subscribed = this.subscription.Plan.PlanId === plan.id ? this.subscription.Plan :
      (this.subscription.NextPlan && this.subscription.NextPlan.PlanId === plan.id) ? this.subscription.NextPlan : null;

    this.svc.CancelSubscriptionPlan(this.userId, plan.id, subscribed.CanCancel === 'true')
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error canceling your subscription... Try again later');
          }
          this.app.ShowSuccessMessage('Successfully canceled your subscription');
          this.Back_OnClick();
          FormUtils.scrollToTop();
        },
        error => {
          this.app.ShowErrorMessage('Error canceling your subscription... Try again later');
          console.log(error);
        }
      );
    // CHECK PLAN AGAINST SUBSCRIPTIONS
    // CHOOSE APPROPRIATE CANCEL METHOD: IMMEDIATE/END OF PERIOD
  }
  UnCancelPlan(plan: any) {
    this.svc.UnCancelSubscriptionPlan(this.userId, plan.id)
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error reinstating your subscription... Try again later');
          }
          this.app.ShowSuccessMessage('Successfully reinstated your subscription');
          this.Back_OnClick();
          FormUtils.scrollToTop();
        },
        error => {
          this.app.ShowErrorMessage('Error reinstating your subscription... Try again later');
          console.log(error);
        }
      );
    // UNDO CANCEL END OF PERIOD
    // ALSO CANCEL ANY PENDING NEW PLANS
  }
  SwitchPlan(plan: any) {
    plan.metadata['Quantity'] = plan.tiers ? this.quantity : 1;

    if (this.upgrade) {
      this.selectedPlan.emit(plan);
      return;
    }

    this.svc.UpdateSubscriptionPlan(this.userId, plan.id, plan.metadata['Quantity'])
      .subscribe(
        response => {
          if (!response) {
            this.app.ShowErrorMessage('Error updating your subscription... Try again later');
          }
          this.app.ShowSuccessMessage('Successfully updated your subscription');
          this.Back_OnClick();
          FormUtils.scrollToTop();
        },
        error => {
          this.app.ShowErrorMessage('Error updating your subscription... Try again later');
          console.log(error);
        }
      );
  }
}
