import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  ApiCall, ApiRequest, ApiResponse, InviteUserClass, User, UserChangePassword,
  UserSearchData, UserUpdateDefaultCommunicationMethod
} from '../classes/classes';
import ConfigUtils from '../utils/ConfigUtils';
import { Association } from '../utils/data/AssociationUtils';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  private baseUrl: string;

  constructor(private http: HttpClient, private svc: AuthService) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/user';
  }

  changeUserPassword(oldPwd: string, newPwd: string): Observable<string> {
    const data: UserChangePassword = { CurrentPassword: oldPwd, NewPassword: newPwd };
    const request: ApiRequest = { Data: JSON.stringify(data), Method: ApiCall.UserChangePassword };
    return this.http.post<string>(this.baseUrl, request);
  }

  // tslint:disable-next-line: max-line-length
  changeDefaultCommunicationMethod(smsAlert: boolean, emailAlert: boolean, marketingAlert: boolean, quietTimeStart: string, quietTimeEnd: string): Observable<string> {
    const data: UserUpdateDefaultCommunicationMethod = {
      SMSAlert: smsAlert,
      EmailAlert: emailAlert,
      MarketingAlert: marketingAlert,
      QuietTimeStart: quietTimeStart,
      QuietTimeEnd: quietTimeEnd
    };
    const request: ApiRequest = { Data: JSON.stringify(data), Method: ApiCall.UserUpdateDefaultCommunicationMethod };

    return this.http.post<string>(this.baseUrl, request);
  }

  public GetCurrentUserIP(): Observable<any> {
    return this.http.get<any>(environment.IPLookupUrl);
  }

  postUser(request: ApiRequest): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl, request);
  }

  InviteUser(data: InviteUserClass): Observable<User> {
    return this.http.post<User>(this.baseUrl + '/InviteUser', data);
  }

  QueryAgents(searchText: string, excludeAgentIds: string[]): Observable<UserSearchData[]> {
    let exclude = '';
    excludeAgentIds.forEach(x => { exclude += x + ';'; });

    return this.http.post<UserSearchData[]>(this.baseUrl + '/QueryAgents',
      {
        SearchText: searchText,
        ExcludeUserIds: exclude
      });
  }

  AddConveyancer(conveyancer: User): Observable<User> {
    return this.http.post<User>(`${this.baseUrl}/conveyancer`, conveyancer);
  }

  UpdateUserConveyancers(conveyancers: User[]): Observable<User[]> {
    return this.http.put<User[]>(`${this.baseUrl}/conveyancers`, conveyancers);
  }

  GetSearchableConveyancers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}/conveyancers`);
  }

  GetUserConveyancers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}/userconveyancers`);
  }

  changeBusinessAddr(data: any): Observable<string> {
    const request: ApiRequest = { Data: JSON.stringify(data), Method: ApiCall.UserChangeBusinessAddr };
    return this.http.post<string>(this.baseUrl, request);
  }
}
