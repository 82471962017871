import { NEW_OFFER_STARTED } from './../../contract/classes';

import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { Contract, SubContractType } from './../../classes/contract';
import { FileRef } from './../../forms/classes';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import ContractFormUtils from '../../utils/form/ContractFormUtils';

@Component({
  selector: 'app-list-files',
  templateUrl: './list-files.component.html',
  styles: [`
    .file {
      transition: all 200ms ease;
    }

    .file:hover {
      margin-top: -0.25rem;
      margin-bottom: 0.25rem;
    }

    .selected {
      background-color: #00AFEF23;
    }

    .status-indicator {
      height: 30px;
      width: 100%;
    }
    
    .status-indicator>div {
      height: 50%;
      border-bottom: 2px dashed #00afef;
    }
  `]
})
export class ListFilesComponent implements OnInit {

  @Input() files: FileRef[];
  @Input() selectedFiles: Map<string, boolean>;
  @Input() headerText: string;
  @Input() contracts: Contract[] = null;
  @Output() selected = new EventEmitter<[FileRef, boolean]>();
  @Output() viewFile = new EventEmitter<FileRef>();
  @Output() downloadFile = new EventEmitter<FileRef>();
  @Output() printFile = new EventEmitter<FileRef>();
  _statusIndicatorName = NEW_OFFER_STARTED;

  constructor(private app: AppLayoutComponent) { }

  ngOnInit(): void {
  }

  public GetFileName(file: FileRef): string {
    if (this.contracts == null)
      return file.Filename;
    const summary = this.GetContractSummaryText(this.GetContractForFileRef(file));
    return summary;
  }

  private GetContractSummaryText(c: Contract): string {

    let value = c.Rev === 0 ?
      `Original - ${ContractFormUtils.GetContractName(c)}` :
      `${c.Rev} - ${ContractFormUtils.GetContractName(c)}`;
    if (c.IsCurrent) { value += ' (Current)'; }

    return value;
  }

  private GetContractForFileRef(file: FileRef): Contract {
    return this.contracts.find(x => x.ContractId == file.ParentId);
  }
}
