<div class="row mb-2">
    <div class="col-12">
        <input [(ngModel)]="search" name="search" type="text" class="form-control"
            placeholder="Search for a registered user..." autocomplete="off"
            (keyup)="searchText.next($any($event).target.value)" maxlength="30" />
    </div>
</div>

<div class="row mb-3" *ngIf="count > 0">
    <div class="col-12">
        <span *ngIf="count === 1" class="badge text-bg-dark">{{ count }} user found</span>
        <span *ngIf="count > 1" class="badge text-bg-dark">{{ count }} users found</span>
        <virtual-scroller #scroll [items]="users">
            <div *ngFor="let user of scroll.viewPortItems" class="col-lg-4 col-md-6 col-12">
                <app-user-tile [data]="user" (selected)="user_Selected($event)">
                </app-user-tile>
            </div>
        </virtual-scroller>
    </div>
</div>

<div class="alert alert-warning" role="alert" *ngIf="count === 0">
    <div class="row">
        <div class="col">
            The user you were looking for was not found.
        </div>
    </div>
</div>