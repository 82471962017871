import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportType, StatusGroup } from '../classes/classes';
import { Contract, ContractChange, ContractStatus, PaperType } from '../classes/contract';
import { DealFileSection } from '../contract/classes';
import ConfigUtils from '../utils/ConfigUtils';
import { Signer } from '../utils/data/SignerUtils';
import { ContractAction, ContractActionType } from '../utils/form/ContractActionUtils';
import { QRData } from './../attachment/classes';
import { ConveyancingPackage } from './../classes/classes';
import { FileRef, FintracData } from './../forms/classes';

export class ContractActionData {
  ActionType: ContractActionType = null;
  SubActionType: ContractActionType = null;
  ContractId: string = null;
  FileData: string = null;
  QRData: QRData = null;
  NewLastStep: number = null;
  NewStatus: ContractStatus = ContractStatus.InProgress;
  SellerAgent: boolean = null;
  PaperType: PaperType = null;
  IsSellerAction: boolean = null;
  IPAddress: string = null;
  constructor(init?: Partial<ContractActionData>) {
    Object.assign(this, init);
  }
}

export class ContractsAndActions {
  Contracts: Contract[];
  ContractActions: KeyValue<string, ContractAction[]>[];
}

export class NewContract {
  Contract: Contract = null;
  FileData: string = null;
}

@Injectable()
export class ContractService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${ConfigUtils.GetBaseApiUrl()}/contract`;
  }

  //#region Contract Functions
  public ProduceFintrac(contractId: string, contactId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/fintrac/${contractId}/${contactId}`, options);
  }

  public GetMasterFintrac(contactId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/fintrac/${contactId}`, options);
  }

  public GetSigningCertificate(contractId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/cert/${contractId}`, options);
  }

  public SaveFintrac(fintrac: FintracData): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/fintrac`, fintrac);
  }

  public BaselinePDF(contractId: string): Observable<Contract> {
    return this.http.put<Contract>(`${this.baseUrl}/baselinepdf/${contractId}`, null);
  }

  public DeleteContract(contractId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${contractId}`);
  }

  public ExpireNow(contractId: string): Observable<ContractsAndActions> {
    return this.http.post<ContractsAndActions>(`${this.baseUrl}/expirenow`, JSON.stringify(contractId));
  }

  public DownloadContract(contractId: string, forceSigned: boolean = false): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json',
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/download/${contractId}/${forceSigned}`, options);
  }

  public DownloadPreview(contractId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json',
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/downloadpreview/${contractId}`, options);
  }

  public DownloadGuestContract(contractId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/downloadguest/${contractId}`, options);
  }

  public GetContractSignerFiles(contractId: string, remainingOnly: boolean = false): Observable<Map<string, Array<FileRef>>> {
    return this.http.get<Map<string, Array<FileRef>>>(`${this.baseUrl}/files/${contractId}/${remainingOnly}`);
  }

  public GetClientsWithFiles(contractId: string): Observable<Signer[]> {
    return this.http.get<Signer[]>(`${this.baseUrl}/${contractId}/clientsWithFiles`);
  }

  public GetRemainingFilesDocument(contractId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/files/remaining/${contractId}`, options);
  }

  public DownloadTradeReport(contractId: string): Observable<ArrayBuffer> {
    const options = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/downloadtr/${contractId}`, options);
  }

  public GetContractBreakdown(contractId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/breakdown/${contractId}`);
  }

  public EditMode(contractId: string): Observable<ContractsAndActions> {
    return this.http.put<ContractsAndActions>(`${this.baseUrl}/editmode/${contractId}`, null);
  }

  public Finalize(contractId: string): Observable<Contract> {
    return this.http.get<Contract>(`${this.baseUrl}/finalize/${contractId}`);
  }

  public GetContract(contractId: string): Observable<Contract> {
    return this.http.get<Contract>(`${this.baseUrl}/${contractId}`);
  }

  public GetContractStatus(contractNumber: number): Observable<ContractStatus> {
    return this.http.get<ContractStatus>(`${this.baseUrl}/${contractNumber}/status`);
  }

  public GetContractsByNumber(contractId: string): Observable<ContractsAndActions> {
    return this.http.get<ContractsAndActions>(`${this.baseUrl}/GetContractsByNumber/${contractId}`);
  }

  public GetLightContractsByNumber(contractNumber: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.baseUrl}/lightcontracts/${contractNumber}`);
  }

  public GetAllContractsSearch(includeExpired = false, recentlyExpired = false): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.baseUrl}/GetAllContractsSearch/${includeExpired}/${recentlyExpired}`);
  }

  public GetActionsForContract(contractId: string): Observable<ContractAction[]> {
    return this.http.get<ContractAction[]>(`${this.baseUrl}/GetActionsForContract/${contractId}`);
  }

  public PostSearch(search: string, type: ReportType, group: StatusGroup = null): Observable<Contract[]> {
    return this.http.post<Contract[]>(`${this.baseUrl}/search`,
      {
        SearchText: search,
        ReportType: type,
        StatusGroup: group
      });
  }

  public PostAction(data: ContractActionData): Observable<ContractsAndActions> {
    return this.http.post<ContractsAndActions>(`${this.baseUrl}/action`, data);
  }

  public PostContract(contract: Contract, fileData: string = null): Observable<Contract> {
    const data: NewContract = { Contract: contract, FileData: fileData };
    return this.http.post<Contract>(`${this.baseUrl}`, data);
  }

  public SendSignerEmail(data: Signer): Observable<Contract> {
    return this.http.post<Contract>(`${this.baseUrl}/SendSignerEmail`, data);
  }

  public SendSignerEmails(contractId: string): Observable<Contract> {
    return this.http.post<Contract>(`${this.baseUrl}/SendSignerEmails`, JSON.stringify(contractId));
  }

  public UpdateContract(data: Contract): Observable<Contract> {
    return this.http.put<Contract>(this.baseUrl, data);
  }

  public UpdateContractChanges(data: Contract): Observable<Contract> {
    return this.http.put<Contract>(`${this.baseUrl}/changes`, data);
  }

  public SendConveyancingPackage(conveyancingPackage: ConveyancingPackage, id: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${id}/SendConveyancingPackage`, conveyancingPackage);
  }

  public SendForReview(message: string, id: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${id}/SendForReview`, JSON.stringify(message));
  }
  //#endregion

  //#region Offer-Only Functions
  public BackupWarning(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/backupwarning/${id}`);
  }

  public ResendOffer(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/resendoffer/${id}`);
  }

  public SendOffer(contractId: string): Observable<ContractsAndActions> {
    return this.http.get<ContractsAndActions>(`${this.baseUrl}/sendoffer/${contractId}`);
  }

  public GetDealFiles(contractNumber: number): Observable<Map<DealFileSection, Array<FileRef>>> {
    return this.http.get<Map<DealFileSection, Array<FileRef>>>(`${this.baseUrl}/dealFiles/${contractNumber}`);
  }

  public GetAllContractChanges(contractId: string): Observable<Map<string, Array<ContractChange>>> {
    return this.http.get<Map<string, Array<ContractChange>>>(`${this.baseUrl}/allChanges/${contractId}`);
  }

  public GetContractChanges(contractId: string): Observable<Array<ContractChange>> {
    return this.http.get<Array<ContractChange>>(`${this.baseUrl}/changes/${contractId}`);
  }
  //#endregion
}
