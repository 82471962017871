import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LookupList } from '../classes/form';
import ConfigUtils from '../utils/ConfigUtils';
import { Brokerage } from '../classes/classes';

@Injectable()
export class LookupService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ConfigUtils.GetBaseApiUrl() + '/lookup';
  }

  checkEmailAddress(email: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/CheckEmailAddress`, JSON.stringify(email));
  }

  getAddresses(searchText: string, albertaOnly: boolean = false): Observable<any> {
    switch (albertaOnly) {
      case true:
        return this.http.get<any>(`${environment.AddrLookupUrl}&administrativearea=AB&format=json&address1=${searchText}`);
      default:
        return this.http.get<any>(`${environment.AddrLookupUrl}&format=json&address1=${searchText}`);
    }
  }

  getBrokerages(country: string, provState: string): Observable<Brokerage[]> {
    return this.http.get<Brokerage[]>(`${this.baseUrl}/brokerages/${country}/${provState}`);
  }

  getLookupList(lookupListId: string): Observable<LookupList[]> {
    return this.http.get<LookupList[]>(`${this.baseUrl}/${lookupListId}`);
  }
}
