export class Country {
  Country: string;
  DisplayOrder: number;
}

export class Province {
  Province: string;
  Country: string;
  Abbrev: string;
}

export class DataUtils {

  public static Countries: Country[] =
    [
      {
        Country: 'Canada',
        DisplayOrder: 1
      },
      {
        Country: 'United States',
        DisplayOrder: 2
      },
      {
        Country: 'Afghanistan',
        DisplayOrder: 100
      },
      {
        Country: 'Albania',
        DisplayOrder: 100
      },
      {
        Country: 'Algeria',
        DisplayOrder: 100
      },
      {
        Country: 'Andorra',
        DisplayOrder: 100
      },
      {
        Country: 'Angola',
        DisplayOrder: 100
      },
      {
        Country: 'Antigua and Barbuda',
        DisplayOrder: 100
      },
      {
        Country: 'Argentina',
        DisplayOrder: 100
      },
      {
        Country: 'Armenia',
        DisplayOrder: 100
      },
      {
        Country: 'Aruba',
        DisplayOrder: 100
      },
      {
        Country: 'Australia',
        DisplayOrder: 100
      },
      {
        Country: 'Austria',
        DisplayOrder: 100
      },
      {
        Country: 'Azerbaijan',
        DisplayOrder: 100
      },
      {
        Country: 'Bahamas, The',
        DisplayOrder: 100
      },
      {
        Country: 'Bahrain',
        DisplayOrder: 100
      },
      {
        Country: 'Bangladesh',
        DisplayOrder: 100
      },
      {
        Country: 'Barbados',
        DisplayOrder: 100
      },
      {
        Country: 'Belarus',
        DisplayOrder: 100
      },
      {
        Country: 'Belgium',
        DisplayOrder: 100
      },
      {
        Country: 'Belize',
        DisplayOrder: 100
      },
      {
        Country: 'Benin',
        DisplayOrder: 100
      },
      {
        Country: 'Bhutan',
        DisplayOrder: 100
      },
      {
        Country: 'Bolivia',
        DisplayOrder: 100
      },
      {
        Country: 'Bosnia and Herzegovina',
        DisplayOrder: 100
      },
      {
        Country: 'Botswana',
        DisplayOrder: 100
      },
      {
        Country: 'Brazil',
        DisplayOrder: 100
      },
      {
        Country: 'Brunei',
        DisplayOrder: 100
      },
      {
        Country: 'Bulgaria',
        DisplayOrder: 100
      },
      {
        Country: 'Burkina Faso',
        DisplayOrder: 100
      },
      {
        Country: 'Burma',
        DisplayOrder: 100
      },
      {
        Country: 'Burundi',
        DisplayOrder: 100
      },
      {
        Country: 'Cabo Verde',
        DisplayOrder: 100
      },
      {
        Country: 'Cambodia',
        DisplayOrder: 100
      },
      {
        Country: 'Cameroon',
        DisplayOrder: 100
      },
      {
        Country: 'Central African Republic',
        DisplayOrder: 100
      },
      {
        Country: 'Chad',
        DisplayOrder: 100
      },
      {
        Country: 'Chile',
        DisplayOrder: 100
      },
      {
        Country: 'China',
        DisplayOrder: 100
      },
      {
        Country: 'Colombia',
        DisplayOrder: 100
      },
      {
        Country: 'Comoros',
        DisplayOrder: 100
      },
      {
        Country: 'Congo, Democratic Republic of the',
        DisplayOrder: 100
      },
      {
        Country: 'Congo, Republic of the',
        DisplayOrder: 100
      },
      {
        Country: 'Costa Rica',
        DisplayOrder: 100
      },
      {
        Country: 'Cote d\'Ivoire',
        DisplayOrder: 100
      },
      {
        Country: 'Croatia',
        DisplayOrder: 100
      },
      {
        Country: 'Cuba',
        DisplayOrder: 100
      },
      {
        Country: 'Curacao',
        DisplayOrder: 100
      },
      {
        Country: 'Cyprus',
        DisplayOrder: 100
      },
      {
        Country: 'Czechia',
        DisplayOrder: 100
      },
      {
        Country: 'Denmark',
        DisplayOrder: 100
      },
      {
        Country: 'Djibouti',
        DisplayOrder: 100
      },
      {
        Country: 'Dominica',
        DisplayOrder: 100
      },
      {
        Country: 'Dominican Republic',
        DisplayOrder: 100
      },
      {
        Country: 'East Timor (see Timor-Leste)',
        DisplayOrder: 100
      },
      {
        Country: 'Ecuador',
        DisplayOrder: 100
      },
      {
        Country: 'Egypt',
        DisplayOrder: 100
      },
      {
        Country: 'El Salvador',
        DisplayOrder: 100
      },
      {
        Country: 'Equatorial Guinea',
        DisplayOrder: 100
      },
      {
        Country: 'Eritrea',
        DisplayOrder: 100
      },
      {
        Country: 'Estonia',
        DisplayOrder: 100
      },
      {
        Country: 'Ethiopia',
        DisplayOrder: 100
      },
      {
        Country: 'Fiji',
        DisplayOrder: 100
      },
      {
        Country: 'Finland',
        DisplayOrder: 100
      },
      {
        Country: 'France',
        DisplayOrder: 100
      },
      {
        Country: 'Gabon',
        DisplayOrder: 100
      },
      {
        Country: 'Gambia, The',
        DisplayOrder: 100
      },
      {
        Country: 'Georgia',
        DisplayOrder: 100
      },
      {
        Country: 'Germany',
        DisplayOrder: 100
      },
      {
        Country: 'Ghana',
        DisplayOrder: 100
      },
      {
        Country: 'Greece',
        DisplayOrder: 100
      },
      {
        Country: 'Grenada',
        DisplayOrder: 100
      },
      {
        Country: 'Guatemala',
        DisplayOrder: 100
      },
      {
        Country: 'Guinea',
        DisplayOrder: 100
      },
      {
        Country: 'Guinea-Bissau',
        DisplayOrder: 100
      },
      {
        Country: 'Guyana',
        DisplayOrder: 100
      },
      {
        Country: 'Haiti',
        DisplayOrder: 100
      },
      {
        Country: 'Holy See',
        DisplayOrder: 100
      },
      {
        Country: 'Honduras',
        DisplayOrder: 100
      },
      {
        Country: 'Hong Kong',
        DisplayOrder: 100
      },
      {
        Country: 'Hungary',
        DisplayOrder: 100
      },
      {
        Country: 'Iceland',
        DisplayOrder: 100
      },
      {
        Country: 'India',
        DisplayOrder: 100
      },
      {
        Country: 'Indonesia',
        DisplayOrder: 100
      },
      {
        Country: 'Iran',
        DisplayOrder: 100
      },
      {
        Country: 'Iraq',
        DisplayOrder: 100
      },
      {
        Country: 'Ireland',
        DisplayOrder: 100
      },
      {
        Country: 'Israel',
        DisplayOrder: 100
      },
      {
        Country: 'Italy',
        DisplayOrder: 100
      },
      {
        Country: 'Jamaica',
        DisplayOrder: 100
      },
      {
        Country: 'Japan',
        DisplayOrder: 100
      },
      {
        Country: 'Jordan',
        DisplayOrder: 100
      },
      {
        Country: 'Kazakhstan',
        DisplayOrder: 100
      },
      {
        Country: 'Kenya',
        DisplayOrder: 100
      },
      {
        Country: 'Kiribati',
        DisplayOrder: 100
      },
      {
        Country: 'Kosovo',
        DisplayOrder: 100
      },
      {
        Country: 'Kuwait',
        DisplayOrder: 100
      },
      {
        Country: 'Kyrgyzstan',
        DisplayOrder: 100
      },
      {
        Country: 'Laos',
        DisplayOrder: 100
      },
      {
        Country: 'Latvia',
        DisplayOrder: 100
      },
      {
        Country: 'Lebanon',
        DisplayOrder: 100
      },
      {
        Country: 'Lesotho',
        DisplayOrder: 100
      },
      {
        Country: 'Liberia',
        DisplayOrder: 100
      },
      {
        Country: 'Libya',
        DisplayOrder: 100
      },
      {
        Country: 'Liechtenstein',
        DisplayOrder: 100
      },
      {
        Country: 'Lithuania',
        DisplayOrder: 100
      },
      {
        Country: 'Luxembourg',
        DisplayOrder: 100
      },
      {
        Country: 'Macau',
        DisplayOrder: 100
      },
      {
        Country: 'Macedonia',
        DisplayOrder: 100
      },
      {
        Country: 'Madagascar',
        DisplayOrder: 100
      },
      {
        Country: 'Malawi',
        DisplayOrder: 100
      },
      {
        Country: 'Malaysia',
        DisplayOrder: 100
      },
      {
        Country: 'Maldives',
        DisplayOrder: 100
      },
      {
        Country: 'Mali',
        DisplayOrder: 100
      },
      {
        Country: 'Malta',
        DisplayOrder: 100
      },
      {
        Country: 'Marshall Islands',
        DisplayOrder: 100
      },
      {
        Country: 'Mauritania',
        DisplayOrder: 100
      },
      {
        Country: 'Mauritius',
        DisplayOrder: 100
      },
      {
        Country: 'Mexico',
        DisplayOrder: 100
      },
      {
        Country: 'Micronesia',
        DisplayOrder: 100
      },
      {
        Country: 'Moldova',
        DisplayOrder: 100
      },
      {
        Country: 'Monaco',
        DisplayOrder: 100
      },
      {
        Country: 'Mongolia',
        DisplayOrder: 100
      },
      {
        Country: 'Montenegro',
        DisplayOrder: 100
      },
      {
        Country: 'Morocco',
        DisplayOrder: 100
      },
      {
        Country: 'Mozambique',
        DisplayOrder: 100
      },
      {
        Country: 'Namibia',
        DisplayOrder: 100
      },
      {
        Country: 'Nauru',
        DisplayOrder: 100
      },
      {
        Country: 'Nepal',
        DisplayOrder: 100
      },
      {
        Country: 'Netherlands',
        DisplayOrder: 100
      },
      {
        Country: 'New Zealand',
        DisplayOrder: 100
      },
      {
        Country: 'Nicaragua',
        DisplayOrder: 100
      },
      {
        Country: 'Niger',
        DisplayOrder: 100
      },
      {
        Country: 'Nigeria',
        DisplayOrder: 100
      },
      {
        Country: 'North Korea',
        DisplayOrder: 100
      },
      {
        Country: 'Norway',
        DisplayOrder: 100
      },
      {
        Country: 'Oman',
        DisplayOrder: 100
      },
      {
        Country: 'Pakistan',
        DisplayOrder: 100
      },
      {
        Country: 'Palau',
        DisplayOrder: 100
      },
      {
        Country: 'Palestinian Territories',
        DisplayOrder: 100
      },
      {
        Country: 'Panama',
        DisplayOrder: 100
      },
      {
        Country: 'Papua New Guinea',
        DisplayOrder: 100
      },
      {
        Country: 'Paraguay',
        DisplayOrder: 100
      },
      {
        Country: 'Peru',
        DisplayOrder: 100
      },
      {
        Country: 'Philippines',
        DisplayOrder: 100
      },
      {
        Country: 'Poland',
        DisplayOrder: 100
      },
      {
        Country: 'Portugal',
        DisplayOrder: 100
      },
      {
        Country: 'Qatar',
        DisplayOrder: 100
      },
      {
        Country: 'Romania',
        DisplayOrder: 100
      },
      {
        Country: 'Russia',
        DisplayOrder: 100
      },
      {
        Country: 'Rwanda',
        DisplayOrder: 100
      },
      {
        Country: 'Saint Kitts and Nevis',
        DisplayOrder: 100
      },
      {
        Country: 'Saint Lucia',
        DisplayOrder: 100
      },
      {
        Country: 'Saint Vincent and the Grenadines',
        DisplayOrder: 100
      },
      {
        Country: 'Samoa',
        DisplayOrder: 100
      },
      {
        Country: 'San Marino',
        DisplayOrder: 100
      },
      {
        Country: 'Sao Tome and Principe',
        DisplayOrder: 100
      },
      {
        Country: 'Saudi Arabia',
        DisplayOrder: 100
      },
      {
        Country: 'Senegal',
        DisplayOrder: 100
      },
      {
        Country: 'Serbia',
        DisplayOrder: 100
      },
      {
        Country: 'Seychelles',
        DisplayOrder: 100
      },
      {
        Country: 'Sierra Leone',
        DisplayOrder: 100
      },
      {
        Country: 'Singapore',
        DisplayOrder: 100
      },
      {
        Country: 'Sint Maarten',
        DisplayOrder: 100
      },
      {
        Country: 'Slovakia',
        DisplayOrder: 100
      },
      {
        Country: 'Slovenia',
        DisplayOrder: 100
      },
      {
        Country: 'Solomon Islands',
        DisplayOrder: 100
      },
      {
        Country: 'Somalia',
        DisplayOrder: 100
      },
      {
        Country: 'South Africa',
        DisplayOrder: 100
      },
      {
        Country: 'South Korea',
        DisplayOrder: 100
      },
      {
        Country: 'South Sudan',
        DisplayOrder: 100
      },
      {
        Country: 'Spain',
        DisplayOrder: 100
      },
      {
        Country: 'Sri Lanka',
        DisplayOrder: 100
      },
      {
        Country: 'Sudan',
        DisplayOrder: 100
      },
      {
        Country: 'Suriname',
        DisplayOrder: 100
      },
      {
        Country: 'Swaziland',
        DisplayOrder: 100
      },
      {
        Country: 'Sweden',
        DisplayOrder: 100
      },
      {
        Country: 'Switzerland',
        DisplayOrder: 100
      },
      {
        Country: 'Syria',
        DisplayOrder: 100
      },
      {
        Country: 'Taiwan',
        DisplayOrder: 100
      },
      {
        Country: 'Tajikistan',
        DisplayOrder: 100
      },
      {
        Country: 'Tanzania',
        DisplayOrder: 100
      },
      {
        Country: 'Thailand',
        DisplayOrder: 100
      },
      {
        Country: 'Timor-Leste',
        DisplayOrder: 100
      },
      {
        Country: 'Togo',
        DisplayOrder: 100
      },
      {
        Country: 'Tonga',
        DisplayOrder: 100
      },
      {
        Country: 'Trinidad and Tobago',
        DisplayOrder: 100
      },
      {
        Country: 'Tunisia',
        DisplayOrder: 100
      },
      {
        Country: 'Turkey',
        DisplayOrder: 100
      },
      {
        Country: 'Turkmenistan',
        DisplayOrder: 100
      },
      {
        Country: 'Tuvalu',
        DisplayOrder: 100
      },
      {
        Country: 'Uganda',
        DisplayOrder: 100
      },
      {
        Country: 'Ukraine',
        DisplayOrder: 100
      },
      {
        Country: 'United Arab Emirates',
        DisplayOrder: 100
      },
      {
        Country: 'United Kingdom',
        DisplayOrder: 100
      },
      {
        Country: 'Uruguay',
        DisplayOrder: 100
      },
      {
        Country: 'Uzbekistan',
        DisplayOrder: 100
      },
      {
        Country: 'Vanuatu',
        DisplayOrder: 100
      },
      {
        Country: 'Venezuela',
        DisplayOrder: 100
      },
      {
        Country: 'Vietnam',
        DisplayOrder: 100
      },
      {
        Country: 'Yemen',
        DisplayOrder: 100
      },
      {
        Country: 'Zambia',
        DisplayOrder: 100
      },
      {
        Country: 'Zimbabwe',
        DisplayOrder: 100
      }
    ];

  public static Provinces: Province[] =
    [
      {
        Province: 'Alberta',
        Country: 'Canada',
        Abbrev: 'AB'
      },
      {
        Province: 'British Columbia',
        Country: 'Canada',
        Abbrev: 'BC'
      },
      {
        Province: 'Manitoba',
        Country: 'Canada',
        Abbrev: 'MB'
      },
      {
        Province: 'New Brunswick',
        Country: 'Canada',
        Abbrev: 'NB'
      },
      {
        Province: 'Newfoundland and Labrador',
        Country: 'Canada',
        Abbrev: 'NL'
      },
      {
        Province: 'Northwest Territories',
        Country: 'Canada',
        Abbrev: 'NT'
      },
      {
        Province: 'Nova Scotia',
        Country: 'Canada',
        Abbrev: 'NS'
      },
      {
        Province: 'Nunavut',
        Country: 'Canada',
        Abbrev: 'NU'
      },
      {
        Province: 'Ontario',
        Country: 'Canada',
        Abbrev: 'ON'
      },
      {
        Province: 'Prince Edward Island',
        Country: 'Canada',
        Abbrev: 'PE'
      },
      {
        Province: 'Quebec',
        Country: 'Canada',
        Abbrev: 'QC'
      },
      {
        Province: 'Saskatchewan',
        Country: 'Canada',
        Abbrev: 'SK'
      },
      {
        Province: 'Yukon',
        Country: 'Canada',
        Abbrev: 'YT'
      },
      {
        Province: 'Alabama',
        Country: 'United States',
        Abbrev: 'AL'
      },
      {
        Province: 'Alaska',
        Country: 'United States',
        Abbrev: 'AK'
      },
      {
        Province: 'Arizona',
        Country: 'United States',
        Abbrev: 'AZ'
      },
      {
        Province: 'Arkansas',
        Country: 'United States',
        Abbrev: 'AR'
      },
      {
        Province: 'California',
        Country: 'United States',
        Abbrev: 'CA'
      },
      {
        Province: 'Colorado',
        Country: 'United States',
        Abbrev: 'CO'
      },
      {
        Province: 'Connecticut',
        Country: 'United States',
        Abbrev: 'CT'
      },
      {
        Province: 'Delaware',
        Country: 'United States',
        Abbrev: 'DE'
      },
      {
        Province: 'Florida',
        Country: 'United States',
        Abbrev: 'FL'
      },
      {
        Province: 'Georgia',
        Country: 'United States',
        Abbrev: 'GA'
      },
      {
        Province: 'Hawaii',
        Country: 'United States',
        Abbrev: 'HI'
      },
      {
        Province: 'Idaho',
        Country: 'United States',
        Abbrev: 'ID'
      },
      {
        Province: 'Illinois',
        Country: 'United States',
        Abbrev: 'IL'
      },
      {
        Province: 'Indiana',
        Country: 'United States',
        Abbrev: 'IN'
      },
      {
        Province: 'Iowa',
        Country: 'United States',
        Abbrev: 'IA'
      },
      {
        Province: 'Kansas',
        Country: 'United States',
        Abbrev: 'KS'
      },
      {
        Province: 'Kentucky',
        Country: 'United States',
        Abbrev: 'KY'
      },
      {
        Province: 'Louisiana',
        Country: 'United States',
        Abbrev: 'LA'
      },
      {
        Province: 'Maine',
        Country: 'United States',
        Abbrev: 'ME'
      },
      {
        Province: 'Maryland',
        Country: 'United States',
        Abbrev: 'MD'
      },
      {
        Province: 'Massachusetts',
        Country: 'United States',
        Abbrev: 'MA'
      },
      {
        Province: 'Michigan',
        Country: 'United States',
        Abbrev: 'MI'
      },
      {
        Province: 'Minnesota',
        Country: 'United States',
        Abbrev: 'MN'
      },
      {
        Province: 'Mississippi',
        Country: 'United States',
        Abbrev: 'MS'
      },
      {
        Province: 'Missouri',
        Country: 'United States',
        Abbrev: 'MO'
      },
      {
        Province: 'Montana',
        Country: 'United States',
        Abbrev: 'MT'
      },
      {
        Province: 'Nebraska',
        Country: 'United States',
        Abbrev: 'NE'
      },
      {
        Province: 'Nevada',
        Country: 'United States',
        Abbrev: 'NV'
      },
      {
        Province: 'New Hampshire',
        Country: 'United States',
        Abbrev: 'NH'
      },
      {
        Province: 'New Jersey',
        Country: 'United States',
        Abbrev: 'NJ'
      },
      {
        Province: 'New Mexico',
        Country: 'United States',
        Abbrev: 'NM'
      },
      {
        Province: 'New York',
        Country: 'United States',
        Abbrev: 'NY'
      },
      {
        Province: 'North Carolina',
        Country: 'United States',
        Abbrev: 'NC'
      },
      {
        Province: 'North Dakota',
        Country: 'United States',
        Abbrev: 'ND'
      },
      {
        Province: 'Ohio',
        Country: 'United States',
        Abbrev: 'OH'
      },
      {
        Province: 'Oklahoma',
        Country: 'United States',
        Abbrev: 'OK'
      },
      {
        Province: 'Oregon',
        Country: 'United States',
        Abbrev: 'OR'
      },
      {
        Province: 'Pennsylvania',
        Country: 'United States',
        Abbrev: 'PA'
      },
      {
        Province: 'Rhode Island',
        Country: 'United States',
        Abbrev: 'RI'
      },
      {
        Province: 'South Carolina',
        Country: 'United States',
        Abbrev: 'SC'
      },
      {
        Province: 'South Dakota',
        Country: 'United States',
        Abbrev: 'SD'
      },
      {
        Province: 'Tennessee',
        Country: 'United States',
        Abbrev: 'TN'
      },
      {
        Province: 'Texas',
        Country: 'United States',
        Abbrev: 'TX'
      },
      {
        Province: 'Utah',
        Country: 'United States',
        Abbrev: 'UT'
      },
      {
        Province: 'Vermont',
        Country: 'United States',
        Abbrev: 'VT'
      },
      {
        Province: 'Virginia',
        Country: 'United States',
        Abbrev: 'VA'
      },
      {
        Province: 'Washington',
        Country: 'United States',
        Abbrev: 'WA'
      },
      {
        Province: 'West Virginia',
        Country: 'United States',
        Abbrev: 'WV'
      },
      {
        Province: 'Wisconsin',
        Country: 'United States',
        Abbrev: 'WI'
      },
      {
        Province: 'Wyoming',
        Country: 'United States',
        Abbrev: 'WY'
      }
    ];
}
