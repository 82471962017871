import { Component, OnInit } from '@angular/core';
import { StripeService } from '../../services/stripe.service';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { UserBillingData } from '../../classes/classes';
import { NewDecimalPipe } from '../../pipes/new-decimal.pipe';
import { Input } from '@angular/core';
import GuidUtils from '../../utils/GuidUtils';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  @Input() userId: string = GuidUtils.EmptyGuid();

  adminMode = false;
  billingData: UserBillingData;
  newDP = new NewDecimalPipe();
  customerError = false;

  constructor(private svc: StripeService, public app: AppLayoutComponent) { }

  ngOnInit() {
    if (this.userId !== GuidUtils.EmptyGuid()) { this.adminMode = true; }
    // IF USERID INPUT IT IS ADMIN MAKING REQUESTS
    this.GetUserBillingDetails();
  }

  GetUserBillingDetails() {
    this.app.ShowSpinner();
    this.svc.GetUserBillingDetails(this.userId)
      .subscribe(
        response => {
          this.billingData = response;
        },
        ex => {
          this.app.ShowErrorMessage(ex.error.Message);
        },
        () => this.app.HideSpinner()
      );
  }
  getCurrentDiscount() {
    if (this.billingData.Subscription.CurrentCoupon.ValueOff != null) {
      return '$' + new NewDecimalPipe().transform((this.billingData.Subscription.CurrentCoupon.ValueOff / 100).toString());
    } else if (this.billingData.Subscription.CurrentCoupon.PercentOff != null) {
      return this.billingData.Subscription.CurrentCoupon.PercentOff + '%';
    }
  }
  getNextDiscount() {
    if (this.billingData.Subscription.NextCoupon.ValueOff != null) {
      return '$' + new NewDecimalPipe().transform((this.billingData.Subscription.NextCoupon.ValueOff / 100).toString());
    } else if (this.billingData.Subscription.NextCoupon.PercentOff != null) {
      return this.billingData.Subscription.NextCoupon.PercentOff + '%';
    }
  }
  ViewInvoice(invoiceURL: string) {
    window.open(invoiceURL);
  }

  assignCoupon(value: any) {
    if (value == null) {
      return;
    }

    this.svc.ApplyCoupon(this.userId, value.id)
      .subscribe(
        response => {
          if (response) {
            this.billingData.Subscription = response;
            this.app.ShowSuccessMessage('Successfully applied coupon code: ' + value.id);
          } else {
            this.customerError = true;
          }
        },
        error => {
          console.log(error);
        },
        () => this.app.HideSpinner()
      );
  }
  ReloadData(reload: boolean) {
    if (!reload) {
      return;
    }
    this.GetUserBillingDetails();
  }
  Route(route: string) {
    this.app.router.navigate([this.app.router.url, route]);
  }
}
