<p align="center">
    <strong>REAL DEAL TRANSACTION MANAGEMENT </strong>
    <strong>INC.</strong>
    <strong> </strong>
</p>
<p align="center">
    <strong>PRIVACY POLICY</strong>
    <strong></strong>
</p>
<div class="container-fluid col-10">
    <p>
        At Real Deal Transaction Management Inc. (“<strong>RDTM</strong>”), <strong> </strong> we are committed to
        maintaining the privacy, accuracy
        and security of your personal information. We have developed this website
        to describe our privacy policies and practices and to provide you with
        information about how we collect, use and disclose the personal information
        of the individuals who visit this website (the “<strong>Site</strong> ”) or
        use the services we offer thorough this Site (“<strong>Services</strong>
        ”).
    </p>
    <p>
        Please take a minute to read this policy so that you understand how we
        treat your personal information. If you are unsure <em>if</em> or <em>how</em> this privacy policy applies to
        you, please contact us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        to request more information.
    </p>
    <p>
        <strong><em>What is Personal Information?</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        For the purposes of this privacy policy, we consider <strong>personal information</strong> to be any information
        that describes
        you as an identifiable individual, other than your business title or
        business contact information when used or disclosed for the purpose of
        business communications.
    </p>
    <p>
        For example, personal information includes your name and date of birth, but
        it also includes your e-mail address, your preferences established as part
        of the Site and Services (such as asking to receive our emails or special
        offers) your usage habits (including how and when you use the Site and the
        Services) and, where applicable, payment details.
    </p>
    <p>
        <strong><em>What Personal Information Do We Collect?</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        You can visit the Site without directly telling us who you are. However, <strong>it is important to
            note</strong> that our web server collects
        information related to your visit to the Site or the Services, including
        the IP address and domain used to access the Site or the Services, the type
        and version of your browser, the website you came from to access the Site
        or the Services, the page you entered and exited at, any pages that are
        viewed by that IP address and the country from which you accessed the site.
    </p>
    <p>
        We use this information to monitor the performance of the Site and the
        Services (such as number of visits, average time spent per page, number of
        page views), to determine interest in the Site and Services, and for
        business purposes, such as working to continually upgrade the Site and
        Services to meet consumer needs.
    </p>
    <p>
        In addition, we collect the personal information that you submit to us,
        such as your name, address and any other contact or other information that
        you choose to provide by:
    </p>
    <ul>
        <li>
            registering as a member/subscriber on the Site or for the Services;
        </li>
        <li>
            using your membership or subscriber account to access the Site or the
            Services;
        </li>
        <li>
            contributing an article, photo, video, comment, data, or preferences to
            the Site or the Services;
        </li>
        <li>
            corresponding with us via e-mail using the hyperlinks created for that
            purpose; or
        </li>
        <li>
            through other communication methods.
        </li>
    </ul>
    <br>
    <p>
        Your personal information may also be collected when you participate in
        surveys or polls, or respond to special offers, give-aways or promotions on
        the Site. In addition, the Site may store your electronic chat and other
        communications, which are necessarily incidental to the transmission and
        delivery of those communications and to prevent misuse of our Site and
        Services.
    </p>
    <p>
        Other, more fulsome information, such as your account status and choice of
        Services and preferences is created and maintained by the Site and through
        our third party providers (each with their own privacy policies) in the
        normal course of providing Services to our members/subscribers and other
        users. This includes the tracking of time spent within the Services.
    </p>
    <p>
        This information is collected and aggregated with the information of other
        users in order to gain an understanding of the usage habits and preferences
        of our members/subscribers and to assist us with the selection and
        adjustment of future content and features for the Services.
    </p>
    <p>
        Aggregated and anonymized information may also be shared with third parties
        for a variety of purposes, including without limitation in order to permit
        us to improve the Site or Services, to engage in marketing or similar
        activities, or otherwise for business purposes. This information is
        aggregated across all members/subscribers in order to protect the usage
        habits of individual members/subscribers.
    </p>
    <p>
        <strong>
            <em>Why Do We Collect, Use, and Disclose Personal Information?</em>
        </strong>
        <strong><em></em></strong>
    </p>
    <p>
        We collect personal information from you for a variety of purposes,
        including to:
    </p>
    <ul>
        <li>
            establish, maintain and manage our relationship with you;
        </li>
        <li>
            enforce our <a target="_blank" routerLink="/terms">Terms of Use</a>, including investigating user conduct
            and, in
            some cases, banning a user temporarily or permanently from the Site or
            Services if that user engages in inappropriate conduct;
        </li>
        <li>
            review and improve the products and services that we provide to you;
        </li>
        <li>
            comply with your requests;
        </li>
        <li>
            notify you of products or services in which you may be interested;
        </li>
        <li>
            protect us against error, fraud, theft and damage to our goods and
            property; and
        </li>
        <li>
            comply with applicable law or regulatory requirements.
        </li>
    </ul>
    <br>
    <p>
        Additionally, we collect personal information for specific purposes that we
        may advise you of at or before the time that we collect such personal
        information.
    </p>
    <p>
        We may also use your contact information to provide you with notification
        regarding the Site or Services, such as any future amendments to this
        policy, and may disclose such information in response to law enforcement
        agencies or other public agencies, or if we feel that such disclosure may
        prevent the instigation of a crime, facilitate an investigation related to
        public safety, protect the security or integrity of the Site and Services,
        or enable us to take precautions against liability.
    </p>
    <p>
        Your personal information may be shared with our employees, contractors,
        consultants, affiliates and other third parties who require such
        information to assist us with establishing, maintaining and managing our
        relationship with you, notifying you of products or services in which you
        may be interested, or developing, operating and maintaining the Site and
        Services. In addition, your personal information may be disclosed or
        transferred to another party in the event of a change in ownership of, or a
        grant of a security interest in, all or a part of RDTM.
    </p>
    <p>
        For example, our secure billing service provider may provide certain
        information technology and data processing services to us from time to time
        so that we may operate our business. As a result, your personal information
        may be collected, used, processed, stored or disclosed in the United States
        of America, Canada and any other relevant locations.
    </p>
    <p>
        Further, your personal information may be disclosed:
    </p>

    <ul>
        <li>
            as permitted or required by applicable law or regulatory requirements
            (for example, in response to a court order or a subpoena);
        </li>
        <li>
            to protect the rights and property of RDTM;
        </li>
        <li>
            during emergency situations or where necessary to protect the safety of a
            person or group of persons;
        </li>
        <li>
            where the personal information is publicly available; or
        </li>
        <li>
            with your consent.
        </li>
    </ul>
    <br>
    <p>
        You acknowledge and agree that access to and use of this Site and the
        Services is provided via the Internet and that your information, including
        personal information, may be transferred across national borders and stored
        or processed in any country in the world.
        <strong>
            We may also collect, use or disclose your personal information without
            your knowledge or consent where we are permitted or required by
            applicable law or regulatory requirements to do so.
        </strong>
    </p>
    <p>
        <strong><em>We may send you E-mail</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        From time to time we may e-mail important information to you regarding the
        Site or the Services (such as a change to our service offering).
    </p>
    <p>
        In addition, when you request information from us or become a
        member/subscriber of the Site or Services, we may use the e-mail address
        that you provide to send you information about offers on products and
        services that we believe may be of interest to you. If you do not wish to
        receive such e-mails, you may opt out of receiving emails by simply
        clicking on the unsubscribe link embedded within any one of the emails sent
        by us.
    </p>
    <p>
        <strong><em>A Few Words about On-line Safety</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        It is important to remember that while we take steps to protect your
        personal information and the integrity of the Services that you sign up to
        enjoy, no security measures are perfect or impenetrable. In addition,
        whenever you voluntarily disclose personal information on message boards,
        in e-mails, or in chat or comment areas on the Site or through the
        Services, that information can be collected and used by others. If you post
        personal information in public places, others may send you unsolicited
        e-mail messages or attempt to contact you in other ways. RDTM is not
        responsible for the collection, usage or disclosure of your personal
        information by third parties to whom you voluntarily disclose such
        information.
    </p>
    <p>
        <strong>
            <u>
                Always be cautious about the information you provide or store
                online.
            </u>
        </strong>
        <strong><u></u></strong>
    </p>
    <p>
        <strong><em>How Do We Use Cookies?</em></strong>
    </p>
    <p>
        When you visit the Site or Services, we may place a “cookie” on the hard
        drive of your computer to track your visit. A cookie is a small data file
        that is transferred to your hard drive through your web browser that can
        only be read by the website that placed the cookie on your hard drive. The
        cookie acts as an identification card and allows the Site to identify you
        and to record your passwords and preferences. The cookie allows us to track
        your visit to the Site or Services so that we can better understand your
        use of the Site and Services and customize and tailor it to better meet
        your needs. For example, our cookies help us to learn about the usage of
        the Site and Services by helping us to track how many visitors we have, how
        often they visit various sections of the Site and Services, and their
        geographic location.
    </p>
    <p>
        Most web browsers are set to accept cookies. However, on most web browsers
        you may change this setting to have your web browser either: (1) notify you
        prior to a website placing a cookie on your hard drive so that you can
        decide whether or not to accept the cookie; or (2) automatically prevent
        the placing of a cookie on your hard drive. It should be noted that if
        cookies are not accepted, you will <strong>not</strong> be able to access a
        number of web pages found on the website.
    </p>
    <p>
        <strong><em>Your Consent is Important to Us</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        By using the Site or Services, we assume that you consent to the
        collection, use and disclosure of your personal information as explained in
        this privacy policy.
    </p>
    <p>
        In addition, you may change or withdraw your consent at any time, subject
        to legal or contractual restrictions and reasonable notice, by contacting
        us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        . In some circumstances, a change in or withdrawal of consent may severely
        limit or even prevent our ability to provide products or services to you.
        All communications with respect to such withdrawal or variation of consent
        should be in writing and addressed to
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        .
    </p>
    <p>
        <strong><em>How is Your Personal Information Protected?</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        RDTM endeavors to maintain physical, technical and procedural safeguards
        that are appropriate to the sensitivity of the personal information in
        question. These safeguards are designed to protect your personal
        information from loss and unauthorized access, copying, use, modification
        or disclosure.
    </p>
    <p>
        The security of your personal information is important to us. Please
        immediately advise us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        of any incident involving the loss of or unauthorized access to or
        disclosure of personal information that is in our custody or control.
    </p>
    <p>
        Unfortunately, no data transmission over the Internet can be guaranteed to
        be 100% secure. As a result, while this Site and the Services strive to
        protect your personal information, we cannot guarantee the confidentiality
        or security of any information you transmit to us, and you do so at your
        own risk.
    </p>
    <p>
        <strong>
            <em>
                What about Third Party Advertisements or Links to other Websites?
            </em>
        </strong>
        <strong><em></em></strong>
    </p>
    <p>
        <em>
            You should be aware that companies that advertise on our Site may use
            their own cookies. This Policy does not cover how these companies use
            their cookies.
        </em>
    </p>
    <p>
        Our Site and Services may contain links to other websites that may be
        subject to less stringent privacy standards. We cannot assume any
        responsibility for the privacy practices, policies or actions of the third
        parties that operate these websites. RDTM is not responsible for how such
        third parties collect, use or disclose your personal information. You
        should review the privacy policies of these websites before providing them
        with personal information.
    </p>
    <p>
        <strong><em>Updating Your Personal Information</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        It is important that the information contained in our records is both
        accurate and current. If your personal information happens to change during
        the course of our relationship, please keep us informed of such changes.
    </p>
    <p>
        In some circumstances, we may not agree with your request to change your
        personal information and will instead append an alternative text to the
        record in question.
    </p>
    <p>
        <strong><em>Access to Your Personal Information</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        You can ask to see the personal information that we hold about you. If you
        want to review, verify or correct your personal information, please contact
        us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        . Please note that any such communication must be in writing.
    </p>
    <p>
        When requesting access to your personal information, please note that we
        may request specific information from you to enable us to confirm your
        identity and right to access, as well as to search for and provide you with
        the personal information that we hold about you. We may charge you a fee to
        access your personal information; however, we will advise you of any fee in
        advance. If you require assistance in preparing your request, please
        contact us at
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        .
    </p>
    <p>
        Your right to access the personal information that we hold about you is not
        absolute. There are instances where applicable law or regulatory
        requirements allow or require us to refuse to provide some or all of the
        personal information that we hold about you. In addition, the personal
        information may have been destroyed, erased or made anonymous in accordance
        with our record retention obligations and practices.
    </p>
    <p>
        In the event that we cannot provide you with access to your personal
        information, we will endeavor to inform you of the reasons why, subject to
        any legal or regulatory restrictions.
    </p>
    <p>
        <strong><em>Inquiries or Concerns?</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        If you have any questions or concerns about our privacy policies, please
        send an e-mail with your questions or comments to
        <a href="mailto:support@dealsimple.ca" target="_blank">
            support@dealsimple.ca
        </a>
        or notify us by mail at:
    </p>
    <p>
        <strong>REAL DEAL TRANSACTION MANAGEMENT INC.</strong><br>
        2126 – 7 Avenue South<br>
        Lethbridge, Alberta, Canada<br>
        T1J 1M7<br>
        Attention: Privacy Officer
    </p>

    <p>
        <strong>
            <em>Application and Interpretation of this Privacy Policy</em>
        </strong>
        <strong><em></em></strong>
    </p>
    <p>
        It is our policy to comply with the privacy legislation within each
        jurisdiction in which we operate. Sometimes the privacy legislation and /
        or an individual’s right to privacy are different from one jurisdiction to
        another. This privacy policy has a limited scope and application.
        Consequently, the rights and obligations contained in this privacy policy
        may not be available to all individuals or in all jurisdictions.
    </p>
    <p>
        Any interpretation associated with this privacy policy will be made by our
        Board of Directors. This privacy policy includes examples but is not
        intended to be restricted in its application to such examples, therefore
        where the word ‘including’ is used, it shall mean ‘including without
        limitation’.
    </p>
    <p>
        This privacy policy does not create or confer upon any individual any
        rights, or impose upon RDTM any rights or obligations outside of, or in
        addition to, any rights or obligations imposed by applicable laws. Should
        there be, in a specific case, any inconsistency between this privacy policy
        and such laws, this privacy policy shall be interpreted, in respect of that
        case, to give effect to, and comply with, such laws.
    </p>
    <p>
        <strong><em>Modifications to this Privacy Policy</em></strong>
        <strong><em></em></strong>
    </p>
    <p>
        From time to time, we may make changes to this privacy policy to reflect
        changes in our legal or regulatory obligations or in the manner in which we
        deal with your personal information. We will post any revised version of
        this privacy policy on the Site and we encourage you to refer back to it on
        a regular basis. This privacy policy was last updated on <strong> July 16, 2019</strong>.
    </p>
</div>