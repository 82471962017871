import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalConfig } from '../../classes/classes';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  @ViewChild('template', {static: true}) template: any;

  @Input() modalConfig: ModalConfig;
  @Output() selected = new EventEmitter<boolean>();

  _modalOptions: ModalOptions;
  _modalRef: BsModalRef;

  constructor(private svc: BsModalService) {}

  ngOnInit() {
    this._modalOptions = {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-dialog-centered'
    };

    this.OpenModal(this.template);
   }

  private OpenModal(template: TemplateRef<any>) {
    this._modalRef = this.svc.show(template, this._modalOptions);
  }

  public Confirm(): void {
    this.selected.emit(true);
    this._modalRef.hide();
  }

  public Close(): void {
    this.selected.emit(false);
    this._modalRef.hide();
  }
}
