<div class="card" (click)="selected.emit(data);" [ngClass]="clickable ? 'hover': ''"
  [ngStyle]="{'cursor': clickable ? 'pointer' : 'auto'}">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <h5 class="card-title mb-0 text-truncate">
          {{ data.FirstName }} {{data.LastName }}
        </h5>
      </div>
      <div class="col-12 text-truncate">
        {{ data.Brokerage.Name }}
      </div>
      <div class="col-auto pe-0">
        <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      </div>
      <div class="col ps-2 text-truncate">
        {{data.EmailAddress}}
      </div>
      <div *ngIf="data.MobilePhoneNumber" class="col-auto pe-0">
        <fa-icon [icon]="['fas', 'phone']"></fa-icon>
      </div>
      <div *ngIf="data.MobilePhoneNumber" class="col ps-2 text-truncate">
        {{data.MobilePhoneNumber}}
      </div>
    </div>
  </div>
</div>