import { ContractType } from './../../classes/contract';
import { ContractDealFilesComponent } from './contract-deal-files.component';
import { FileRef, FileRefType } from './../../forms/classes';
import { DealFileSection } from './../classes';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getActions'
})
export class GetActionsPipe implements PipeTransform {
  transform(section: DealFileSection, file: FileRef, appDeal: ContractDealFilesComponent) {
    let actions: [string, (_: FileRef) => void][] = [];
    switch (section) {
      case DealFileSection.Contracts:
        actions.push(['View Signing Certificate', appDeal.ViewSignCert.bind(appDeal)]);
        actions.push(['Download', appDeal.DownloadFile.bind(appDeal)]);
        break;
      case DealFileSection.Attachments:
        actions.push(['Download', appDeal.DownloadFile.bind(appDeal)]);
        if (appDeal.df.app.cu.User.UserId == file.UserId) {
          if (appDeal.df.contract.ContractType == ContractType.Offer && file.FileRefType != FileRefType.FinalFINTRAC)
            actions.push([`Make ${file.IsPrivate ? 'Public' : 'Private'}`, appDeal.Toggle_Permissions.bind(appDeal)]);

          actions.push(['Rename', appDeal.RenameFile_OnClick.bind(appDeal)]);
          actions.push(['Delete', appDeal.DeleteFile_OnClick.bind(appDeal)]);
          actions.push(['Copy to Contract', appDeal.CopyToContract_OnClick.bind(appDeal)]);
        }
        break;
      case DealFileSection.CRGs:
        actions.push(['Download', appDeal.DownloadFile.bind(appDeal)]);
        break;
      case DealFileSection.Fintracs:
        actions.push(['Download', appDeal.DownloadFile.bind(appDeal)]);
        actions.push(['Delete', appDeal.DeleteFile_OnClick.bind(appDeal)]);
        break;
    }
    return actions;
  }
}