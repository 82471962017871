<nav *ngIf="!IsListingAgent() && trxShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link *ngFor="let link of trxLinks" [routerLink]="link.Route" routerLinkActive #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact:false}" [active]="rla.isActive">
    {{link.Label}}
  </a>
</nav>

<nav *ngIf="IsListingAgent() && listingShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link [routerLink]="'/sellers'" routerLinkActive #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact:false}" [active]="true">
    Sellers
  </a>
</nav>

<nav *ngIf="frmShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link *ngFor="let link of frmLinks" [routerLink]="link.Route" routerLinkActive #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact:false}" [active]="rla.isActive">
    {{link.Label}}
  </a>
</nav>

<nav *ngIf="sigShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link *ngFor="let link of sigLinks" [routerLink]="link.Route" routerLinkActive #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact:false}" [active]="rla.isActive">
    {{link.Label}}
  </a>
</nav>

<nav *ngIf="adminShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link *ngFor="let link of adminLinks" [routerLink]="link.Route" routerLinkActive #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact:true}" [active]="rla.isActive">
    {{link.Label}}
  </a>
</nav>
<nav *ngIf="calShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link *ngFor="let link of calLinks" [routerLink]="link.Route" [state]="{view: link.State}" routerLinkActive
    #rla="routerLinkActive" [routerLinkActiveOptions]="{exact:false}" [active]="CalLink_IsActive(link)">
    {{link.Label}}
  </a>
</nav>

<nav *ngIf="contactShow.test(router.url)" mat-tab-nav-bar class="mb-1 border-0">
  <a mat-tab-link *ngFor="let link of contactLinks" [routerLink]="link.Route" routerLinkActive #rla="routerLinkActive"
    [routerLinkActiveOptions]="{exact:false}" [active]="rla.isActive">
    {{link.Label}}
  </a>
</nav>