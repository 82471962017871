<div *ngIf="showForm">
    <div class="alert alert-secondary" role="alert">
        <div class="row">
            <div class="col">
                USER FREE TRIAL
            </div>
        </div>
    </div>
    <div class="row mb-3 g-3">
        <div class="col">
            Free Trial Ends:
        </div>
        <div class="col-auto fw-bold">
            {{ trialEndDate | newDate }}
        </div>
    </div>
    <div *ngIf="arrNumbers.length > 0" class="row mb-3 g-3">
        <div class="col">
            Extend Trial by <select #extension class="form-select" style="width: 80px; display: unset">
                <option *ngFor="let day of arrNumbers;" value="{{day}}">
                    {{day}}
                </option>
            </select> Days.
        </div>
        <div class="col-auto">
            <button type="button" class="btn btn-primary btn-block"
                (click)="UpdateTrial($any(extension.value))">Confirm</button>
        </div>
    </div>
    <div *ngIf="arrNumbers.length === 0" class="row mb-3 g-3">
        <div class="col-12">
            <b><i>Sorry it looks like this subscription's free trial has been extended to it's maximum length.</i></b>
        </div>
    </div>
</div>