<div *ngIf="paymentMethods" class="form-group">
  <span *ngIf="paymentMethods.length == 0">You currently have no payment method on file</span>
  <div *ngIf="paymentMethods && paymentMethods.length > 0">
    <div *ngFor="let card of paymentMethods;let i=index" class="form-row" style="padding-bottom: 0.5em">
      <div class="col">
        <i *ngIf="card.CardBrand === 'Visa'" class="fab fa-cc-visa align-middle-v" style="font-size: x-large;"></i>
        <i *ngIf="card.CardBrand === 'American Express'" class="fab fa-cc-amex align-middle-v"
          style="font-size: x-large;"></i>
        <i *ngIf="card.CardBrand === 'MasterCard'" class="fab fa-cc-mastercard align-middle-v"
          style="font-size: x-large;"></i>
        <span class="align-middle-v">&emsp;****{{card.Last4}}&emsp;{{card.ExpiryMonth}}/{{card.ExpiryYear}}</span>
      </div>
      <div class="col-auto">
        <span *ngIf="card.Default" class="badge badge-info align-middle-v">Default</span>
      </div>
      <div class="col-auto" style="float: right; padding-right: 1rem;">
        <button #db type="button" class="btn btn-danger btn-xs" (click)="acceptDelete(card, db, dcb, dd);" [hidden]="true">
          <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
        </button>
        <button #dcb type="button" class="btn btn-secondary btn-xs" (click)="toggleDelete(db, dcb, dd);" [hidden]="true">
          <fa-icon [icon]="['fal', 'ban']"></fa-icon>
        </button>
        <div ngbDropdown placement="bottom-right" class="d-inline-block" style="width: inherit;">
          <button #dd class="btn btn-dropdown dropdown-toggle dropdownButton" id="dropdown" ngbDropdownToggle>
            <fa-icon class="align-middle-v" [icon]="['fas', 'ellipsis-v']"></fa-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdown">
            <button class="dropdown-item pointerCursor" (click)="toggleDelete(db, dcb, dd)">Remove</button>
            <button class="dropdown-item pointerCursor" (click)="EditPaymentMethod(card.CardId)">Edit</button>
            <button [disabled]="card.Default" class="dropdown-item pointerCursor"
              (click)="UpdateDefaultPaymentMethod(card)">Make Default</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>