import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmailModule } from './../email/email.module';
import { SearchModule } from './../search/search.module';
import { PipesModule } from './../pipes/pipes.module';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ViewersModule } from '../viewers/viewers.module';
import { AttachmentModule } from './../attachment/attachment.module';
import { ContractBreakdownComponent } from './contract-breakdown/contract-breakdown.component';
import { ContractDealComponent } from './contract-deal/contract-deal.component';
import { ContractDocumentChecklistComponent } from './contract-document-checklist/contract-document-checklist.component';
import { ContractHistoryComponent } from './contract-history/contract-history.component';
import { ContractClientFilesComponent } from './contract-client-files/contract-client-files.component';
import { FilterSignersPipe } from './contract-client-files/filter-signers.pipe';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContractConveyancingComponent, ItemMatchesSearch } from './contract-conveyancing/contract-conveyancing.component';
import { ContractNavigationComponent } from './contract-navigation/contract-navigation.component';
import { ContractDealFilesComponent } from './contract-deal-files/contract-deal-files.component';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { DealFileSectionComponent } from './contract-deal-files/deal-file-section.component';
import { GetActionsPipe } from './contract-deal-files/get-actions.pipe';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    ViewersModule,
    AttachmentModule,
    NgbDropdownModule,
    NgSelectModule,
    NgOptionHighlightModule,
    PipesModule,
    SearchModule,
    EmailModule,
    NgbModule,
    AngularImageViewerModule,
    NgxPaginationModule,
    NgxUiLoaderModule
  ],
  declarations: [
    ContractHistoryComponent,
    ContractDocumentChecklistComponent,
    ContractDealComponent,
    ContractBreakdownComponent,
    ContractClientFilesComponent,
    FilterSignersPipe,
    ContractConveyancingComponent,
    ItemMatchesSearch,
    ContractNavigationComponent,
    ContractDealFilesComponent,
    GetActionsPipe,
    DealFileSectionComponent
  ],
  exports: [
    ContractHistoryComponent,
    ContractDocumentChecklistComponent,
    ContractDealComponent,
  ]
})
export class ContractModule { }
