import { Pipe, PipeTransform } from '@angular/core';
import { NewDecimalPipe } from './new-decimal.pipe';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'ifnullcurrency'
})
export class IfNullCurrencyPipe implements PipeTransform {
  transform(value: any, decimals = 2): any {
    const result = new NewDecimalPipe().transform(value, decimals);
    return !result ? 'Not Specified' : '$' + result;
  }
}
