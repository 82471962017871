import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import ConfigUtils from '../utils/ConfigUtils';

@Injectable()
export class VersionCheckService {

  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(private http: HttpClient) { }

  // DEFAULT FREQUENCY TO CHECK UPDATES EVERY 5 Min
  public initVersionCheck(frequency = 1000 * 60 * 2) {
    if (!environment.Production) {
      return;
    }
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }

  private checkVersion() {
    // TIMESTAMP REQUEST TO INVALIDATE CACHES
    this.http.get(ConfigUtils.GetBaseUrl() + '/version.json?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          if (hashChanged) {
            // FORCE RELOAD
            sessionStorage.clear();
            location.reload();
          }
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }
}
