import {Component, AfterViewInit, OnDestroy, ViewChild, OnInit, ElementRef, ChangeDetectorRef,
  Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewDecimalPipe } from '../pipes/new-decimal.pipe';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

  @Input() plan: any = null;
  @Input() buttonText = null;
  @Input() token: any;
  @Output() returnToken: EventEmitter<any> = new EventEmitter<any>();

  disableButton = false;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  style = {
    base: {
      color: '#32325d',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.plan != null && this.buttonText == null) {
      if (this.plan.tiers === null) {
        this.plan = new NewDecimalPipe().transform((this.plan.amount / 100).toString());
      } else {
        let price = 0;
        for (let i = 0; i < this.plan.tiers.length; i++) {
          const prev = i > 0 ? i - 1 : 0;
          if (this.plan.metadata['Quantity'] <= this.plan.tiers[i].up_to && this.plan.metadata['Quantity'] > this.plan.tiers[prev].up_to) {
            price = this.plan.metadata['Quantity'] * (this.plan.tiers[i].unit_amount / 100);
          } else if (this.plan.tiers[i].up_to == null && this.plan.metadata['Quantity'] > this.plan.tiers[prev].up_to) {
            price = this.plan.metadata['Quantity'] * (this.plan.tiers[i].unit_amount / 100);
          }
        }
        this.plan = new NewDecimalPipe().transform(price.toString());
      }
      this.buttonText = 'Pay $' + this.plan;
    }
  }

  ngAfterViewInit() {
    this.card = elements.create('card', {style: this.style});
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
    } else {
      this.disableButton = true;
      this.token = token;
      this.returnToken.emit(token);
    }
  }

}
